import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function SummaryWidget({ data, renderItem, border=true}) {
  return (
<Stack spacing={1} className='layoutWidget stats'>
  {data?.map((item, index)=>(
			<Box key={index} className={`widget-content stats-content ${!border ? 'line' : ''}`} >
        {renderItem(item)}
			</Box>
  ))}
		</Stack>
  );
}

export default SummaryWidget;
