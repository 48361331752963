import Stack from '@mui/material/Stack';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ContactSearch from '@pw/components_v2/search/contact';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import LedgerSearch from '@pw/components_v2/search/ledger';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ThingSelector } from '@pw/components_v2/search/thing/ThingSelector';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import ThingForm from '@pw/components_v2/Thing';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

function Properties({ entity }) {
	return (
		<FormikProvider path='properties'>
			<ThingSelector
				initial={entity?.properties?.parts}
				label='Parts'
				field='parts'
				filter={{
					[SEARCH_TYPES.THINGS]: {
						types: [THING_TYPES.PARTS],
					},
				}}
			/>
		</FormikProvider>
	);
}

const tabs = [
	{
		title: 'Properties',
		key: 'properties',
		properties: (entity, converter) => ({
			properties: {
				parts: [entity?.properties?.parts, yup.array().of(yup.object())],
			}
		}),
		Component: Properties
	},
	{
		title: 'Ownership',
		key: 'ownership',
		properties: (entity, converter) => ({
			ledger: [entity?.ledger, yup.object()],
		}),
		Component: (props) => (
			<Stack spacing="1.5rem">
				<LedgerSearch />
			</Stack>
		)
	},
	{
		title: 'Vendors',
		key: 'vendors',
		properties: (entity, converter) => ({
			vendors: [entity?.vendors, yup.array()],
		}),
		Component: ({ entity }) => (
			<VendorSelector initialValues={entity?.vendors} />
		)
	},
	{
		title: 'Policies',
		key: 'policies',
		properties: (entity, converter) => ({
			policies: [entity?.policies, yup.array()],
		}),
		Component: ({ entity }) => (
			<PolicySelector initialValues={entity?.policies} />
		)
	}
];

function ThingPage(props) {
	return (
		<ThingForm
			thingLabel='Part'
			thingType={THING_TYPES.PARTS}
			tabs={tabs}
		/>
	);
}

export default withAppLayout(ThingPage, {
	title: 'Parts',
});

