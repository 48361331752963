import { FormikNumberField } from '@pw/components/Forms/FormikForm';
import { FormikProvider } from '@pw/providers/FormikProvider';

function LiquidWeightImpl({ disabled = false }) {
	return (
		<FormikNumberField
			name='weightFactor'
			label='Weight Factor'
			fullWidth
			disabled={disabled}
		/>
	);
}

function LiquidWeight({
	name,
	title = 'Weight Settings',
	...props
}) {
	return (
		<FormikProvider path={name}>
			<LiquidWeightImpl title={title} {...props} />
		</FormikProvider>
	);
}

export default LiquidWeight;
