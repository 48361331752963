import useLilly from '@pw/utilities/hooks/ai/useLilly';

export const useAiSearch = () => {
  const lilly = useLilly();
  const aiSearch = async (spec, prompt) => {
    const generated = await lilly.chat(
      spec,
      `Generate a mongoose filter expression per the spec, the actual prompt is: '${prompt}'. Don't convert date but use $toDate. Output the json object which contains a valid $match.`
    );
    console.log('generated', generated);
    return generated ?? [];
  }

  return { aiSearch };
};
