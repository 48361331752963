import Box from '@mui/material/Box';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';

function FormButtons({ readonly, onCancel, saveTitle = 'Save' }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1.5rem'}}>
      {onCancel && (
        <TextButton
          size='small'
          handleClick={() => onCancel()}
          color='secondary'
        >
          Cancel
        </TextButton>
      )}
      <FilledButton type='submit' size='small' disabled={readonly}>
        {saveTitle}
      </FilledButton>
    </Box>
  );
}

export default FormButtons;
