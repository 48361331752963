import { Box, Collapse, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FormikDatePicker, FormikForm } from '@pw/components/Forms/FormikForm';
import { Filter } from '@pw/components/icons';
import { Liquid } from '@pw/components/icons';
import SvgForwardarrow from '@pw/components/icons/Forwardarrow';
import { Body2, Body3, H5 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import LayoutStatsWidget from './LayoutStatsWidget';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { UNIT } from '@pw/consts/units';
import { useStatsLazyQuery } from '@pw/utilities/hooks/service/useStatsQuery';

const LayoutLiquid = ({ currentWidth, currentHeight }) => {
  const [fetchWidgetData, { data: widgetData, isLoading, refetch }] =
    useStatsLazyQuery();
  const data = widgetData?.widgets[0].data;
  const liquid = 234;
  const total = 400;

  const converter = useConverter();

  const bl_unit = converter.unit(UNIT.BL);
  const la_unit = converter.unit(UNIT.LA);
  const {
    new_assets_count = 0,
    new_requests_count = 0,
    total_bl = 0,
    total_la = 0,
    total_la_in = 0,
    total_la_out = 0,
    total_bl_in = 0,
    total_bl_out = 0,
    total_bl_storage = 0,
    total_la_storage = 0,
    total_bl_operation = 0,
    total_la_operation = 0,
    start_date,
    end_date,
    report_type,
  } = data ?? {};

  const [tb, ta] = useMemo(() => {
    return [
      converter.from(total_bl ?? 0, UNIT.BL),
      converter.from(total_la ?? 0, UNIT.LA),
    ];
  }, [total_bl, total_la]);

  const [li, lo] = useMemo(() => {
    return [
      converter.from(total_la_in ?? 0, UNIT.LA),
      converter.from(Math.abs(total_la_out ?? 0), UNIT.LA),
    ];
  }, [total_la_in, total_la_out]);

  const [bi, bo] = useMemo(() => {
    return [
      converter.from(total_bl_in ?? 0, UNIT.BL),
      converter.from(Math.abs(total_bl_out ?? 0), UNIT.BL),
    ];
  }, [total_bl_in, total_bl_out]);

  const [tbs, tls] = useMemo(() => {
    return [
      converter.from(total_bl_storage ?? 0, UNIT.BL),
      converter.from(total_la_storage ?? 0, UNIT.LA),
    ];
  }, [total_bl_storage, total_la_storage]);

  const [tbo, tlo] = useMemo(() => {
    return [
      converter.from(total_bl_operation ?? 0, UNIT.BL),
      converter.from(total_la_operation ?? 0, UNIT.LA),
    ];
  }, [total_bl_operation, total_la_operation]);

  const isSmallScreen = currentWidth <= 600;
  const isMediumScreen = currentWidth > 600 && currentWidth <= 1024;

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  useEffect(() => {
    fetchWidgetData({ widget_ids: [4] });
  }, []);

  const changeSet = {
    start_date: [startDate, yup.number()],
    end_date: [endDate, yup.number()],
  };

  const onFilter = (values) => {
    const { start_date, end_date } = values;
    refetch({ start_date, end_date, widget_ids: [4] }).then((res) => {
      if (res) {
        setStartDate(start_date);
        setEndDate(end_date);
      }
    });
  };

  const styles = {
    filterButtonWrapper: {
      width: '50px',
      height: '42px',
      position: 'absolute',
      right: '4px',
      borderRadius: '5px',
      backgroundColor: '#C6C6C6',
    },
    updateButton: {
      width: isSmallScreen ? '100px' : 'calc(100vw * (250 / 1029))',
      height: isSmallScreen ? '40px' : '56px',
      borderRadius: '5px',
      backgroundColor: '#52535B',
      color: '#fff',
      fontSize: isSmallScreen ? '12px' : '14px',
      lineHeight: isSmallScreen ? '1.2' : '26px',
      fontWeight: '600',
    },
  };
  return (
    <Box className='content-row' id='LayoutLiquid'>
      <Box className='content-header'>
        <Box className='title-box'>
          <Liquid height={43} width={29} />
          <Box>
            <H5>LIQUID</H5>
            {!!startDate && !!endDate && (
              <Body3 sx={{ alignItems: 'center' }}>
                <Bolder style={{ paddingLeft: '0.25ch', fontWeight: '600' }}>
                  fr.
                </Bolder>
                {new Date(startDate).toLocaleDateString()}
                <SvgForwardarrow
                  width={17}
                  height={7}
                  style={{
                    marginLeft: '20px',
                    marginRight: '10px',
                  }}
                />
                <Bolder
                  style={{
                    paddingLeft: '0.25ch',
                    fontWeight: '600',
                  }}
                >
                  to.
                </Bolder>
                {new Date(endDate).toLocaleDateString()}
              </Body3>
            )}
          </Box>
        </Box>
        <Stack
          direction='row'
          justifyContent={'space-between'}
          alignItems={'center'}
          position={'relative'}
          padding={'10px'}
          gap={'10px'}
        >
          {isVisibleFilter && <Box sx={styles.filterButtonWrapper}></Box>}
          <IconCircleButton
            onClick={() => setIsVisibleFilter(!isVisibleFilter)}
          >
            <Filter color={isVisibleFilter ? '#fff' : '#7C7C7C'} />
          </IconCircleButton>
        </Stack>
      </Box>

      <Collapse in={isVisibleFilter}>
        <FormikForm changeSet={changeSet} onSubmit={onFilter}>
          <Stack
            gap={1.5}
            py={2}
            pl={1.5}
            pr={2}
            sx={{ backgroundColor: '#C6C6C6', borderRadius: '5px' }}
          >
            <Box display='flex' flexBasis='row' gap={0}>
              <FormikDatePicker
                name='start_date'
                label='From'
                fullWidth
                backgroundColor='#fff'
              />
              <FormikDatePicker
                name='end_date'
                label='To'
                fullWidth
                backgroundColor='#fff'
              />
              <FilledButton
                sx={{ marginLeft: '15px', ...styles.updateButton }}
                type='submit'
                // size='small'
                label='Update data'
                loading={isLoading}
              />
            </Box>
          </Stack>
        </FormikForm>
      </Collapse>

      <Box className='content-body content-3col'>
        <Stack className='content-section'>
          <H5 className='content-section-title'>Total Liquid</H5>
          <Stack className='content-section-content line'>
            <Stack>
              <Body2
                sx={{
                  fontWeight: '700',
                  fontSize: '13px',
                  lineHeight: '31px',
                  color: '#393C3C',
                }}
              >
                In
              </Body2>
              <Box
                sx={{
                  position: 'relative',
                  height: '32px',
                  backgroundColor: 'white',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    width: `${liquid / total}`,
                    backgroundColor: '#6B57D2',
                  }}
                />
                <Body2
                  sx={{
                    position: 'absolute',
                    width: `${liquid / total}`,
                    left: `0%`,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'white',
                    fontWeight: '700',
                    fontSize: '12px',
                    lineHeight: '31px',
                    textAlign: 'end',
                    paddingRight: '5%',
                  }}
                >
                  {liquid}L
                </Body2>
              </Box>
            </Stack>
            <Stack>
              <Body2
                sx={{
                  fontWeight: '700',
                  fontSize: '13px',
                  lineHeight: '31px',
                  color: '#393C3C',
                }}
              >
                Out
              </Body2>
              <Box
                sx={{
                  position: 'relative',
                  height: '32px',
                  backgroundColor: 'white',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#C7DA65',
                  }}
                />
                <Body2
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    left: `0%`,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'white',
                    fontWeight: '700',
                    fontSize: '12px',
                    lineHeight: '31px',
                    textAlign: 'end',
                    paddingRight: '5%',
                  }}
                >
                  {25}L
                </Body2>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack className='content-section'>
          <H5 className='content-section-title'>Total Production</H5>
          <Stack className='content-section-content'>
            <LayoutStatsWidget
              primary={{ value: tb, unit: bl_unit }}
              secondary={{ value: ta, unit: la_unit }}
              label='Total Liquid'
            />
            <LayoutStatsWidget
              primary={{ value: bi, unit: bl_unit }}
              secondary={{ value: li, unit: la_unit }}
              label='Bulk In'
            />
            <LayoutStatsWidget
              primary={{ value: bo, unit: bl_unit }}
              secondary={{ value: lo, unit: la_unit }}
              label='Bulk Out'
            />
            <LayoutStatsWidget
              primary={{ value: tbs, unit: bl_unit }}
              secondary={{ value: tls, unit: la_unit }}
              label='Storage'
            />
            <LayoutStatsWidget
              primary={{ value: tbo, unit: bl_unit }}
              secondary={{ value: tlo, unit: la_unit }}
              label='Operations'
              border={false}
            />
          </Stack>
        </Stack>
        <Stack className='content-section'>
          <H5 className='content-section-title'>Liquid Movement</H5>
          <Stack className='content-section-content'>
            <LayoutStatsWidget
              primary={{ value: tb, unit: bl_unit }}
              secondary={{ value: ta, unit: la_unit }}
              label='Total Liquid'
            />
            <LayoutStatsWidget
              primary={{ value: bi, unit: bl_unit }}
              secondary={{ value: li, unit: la_unit }}
              label='Samples'
            />
            <LayoutStatsWidget
              primary={{ value: bo, unit: bl_unit }}
              secondary={{ value: lo, unit: la_unit }}
              label='Transfers'
            />
            <LayoutStatsWidget
              primary={{ value: tbo, unit: bl_unit }}
              secondary={{ value: tlo, unit: la_unit }}
              label='Recask'
              border={false}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default LayoutLiquid;
