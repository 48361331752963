import { ACCOUNT_STATUS } from '@pw/consts/account';
import { UNIT_FORMAT_TYPES } from '@pw/consts/company';
import { UNIT, UNITS } from '@pw/consts/units';
import { createCompanyThunk } from '@pw/redux/thunks/company';
import {
  refetchFacilitiesThunk,
  upsertFacilityThunk,
} from '@pw/redux/thunks/facilities';
import { connectWithPartnerThunk } from '@pw/redux/thunks/partners';
import { recoverThunk, registerUserThunk } from '@pw/redux/thunks/register';
import { refetchSensors, upsertGatewayThunk } from '@pw/redux/thunks/sensors';
import {
  signinUserThunk,
  signinWithGoogleThunk,
  signinWithPasskeyThunk,
} from '@pw/redux/thunks/signin';
import { refetchTeamsThunk, upsertTeamThunk } from '@pw/redux/thunks/teams';
import {
  refetchUsersThunk,
  refreshTokenThunk,
  removeUserThunk,
  switchCompanyThunk,
  updateProfileThunk,
  upsertUserThunk,
} from '@pw/redux/thunks/user';
import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { SLICE } from './consts';

const processPayload = (state, payload) => {
  console.log('Processing account payload', payload);
  const { account, platform, chain, nbf: token_active, company } = payload;

  const account_id = account._id;
  const account_name = account.name;
  const account_identity = account.identity;
  const account_username = account.public_name;
  const account_status = account.status ?? ACCOUNT_STATUS.PENDING;
  const account_email = account.email;
  const account_perms = JSON.parse(account.permission ?? '{}'); // payload.account_perms;
  const account_notifications = account.notifications ?? {
    marketing: true,
    events: true,
  };
  const account_telephone = account?.telephone;
  const account_phone = account?.email;
  const account_address = account?.address ?? {};

  const account_location = account.location;
  const passkeys = account.passkeys ?? [];

  const unit_format = payload.imperial_units
    ? UNIT_FORMAT_TYPES.Imperial
    : UNIT_FORMAT_TYPES.Metric;

  const account_last_access = account.last_access;

  const units = {
    [UNIT.LIQ]: UNITS.liters,
    [UNIT.MAS]: UNITS.kilograms,
    [UNIT.ALC]: UNITS.abv,
    [UNIT.TMP]: UNITS.celsius,
    [UNIT.TIM]: UNITS.days,
    [UNIT.BL]: UNITS.liters,
    [UNIT.LA]: UNITS.liters,
  };

  // Company settings first
  Object.entries(company?.company_id?.base_units ?? {}).forEach(([unit, value]) => {
    units[unit] = value;
  });

  // User settings for the company
  Object.entries(company?.base_units ?? {}).forEach(([unit, value]) => {
    units[unit] = value;
  });

  // Override with personal preferences
  Object.entries(account.base_units ?? {}).forEach(([unit, value]) => {
    units[unit] = value;
  });

  return {
    account_notifications,
    account_telephone,
    account_phone,
    account_address,
    account_id,
    account_name,
    account_identity,
    account_username,
    account_status,
    account_email,
    account_location,
    passkeys,
    unit_format,
    account_last_access,
    platform,
    chain,
    company,
    units,
    token_active,
    permissions: account_perms,
  };
};

const initialState = {
  // mongoose state
  account_notifications: { marketing: true, events: true },
  account_telephone: '',
  account_phone: '',
  account_address: {},
  // account state
  account_id: '',
  account_name: '',
  account_identity: '',
  account_username: '',
  account_status: ACCOUNT_STATUS.PENDING,
  account_email: '',
  account_perms: '0b0',
  account_location: {},
  passkeys: [],
  unit_format: UNIT_FORMAT_TYPES.Metric,
  account_last_access: 0,
  chain: null,
  platform: null,
  // All CompanyView
  companies: [],
  // Current company
  company: null,
  token_active: 0,
  // Current units
  units: {
    [UNIT.LIQ]: UNITS.liters,
    [UNIT.MAS]: UNITS.kilograms,
    [UNIT.ALC]: UNITS.abv,
    [UNIT.TMP]: UNITS.celsius,
    [UNIT.TIM]: UNITS.days,
    [UNIT.BL]: UNITS.liters,
    [UNIT.LA]: UNITS.liters,
  },
  parsedLiquidData: null,
};

export const slice = createSlice({
  name: SLICE,
  initialState,
  reducers: {
    setBaseUnits(state, { payload }) {
      state.units = payload;
    },
    setParsedLiquidData: (state, action) => {
      state.parsedLiquidData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(updateProfileThunk.fulfilled, (state, { payload }) => {
      console.log('updateProfileThunk.fulfilled', payload);
      state.account_address = {
        ...state?.account_address,
        ...payload?.address,
      };
      state.units = { ...state?.units, ...payload?.relation?.base_units };
      state.account_notifications = {
        ...state?.account_notifications,
        ...payload?.notifications,
      };
      // return processPayload(state, payload);
    });
    builder.addCase(recoverThunk.fulfilled, (state, { payload }) => {
      console.log('recoverThunk.fulfilled', payload);
      return processPayload(state, payload);
    });
    builder.addCase(registerUserThunk.fulfilled, (state, { payload }) => {
      console.log('registerUserThunk.fulfilled', payload);
      return processPayload(state, payload);
    });
    builder.addCase(signinUserThunk.fulfilled, (state, { payload }) => {
      console.log('signinUser.fulfilled', payload);
      return processPayload(state, payload);
    });
    builder.addCase(signinWithPasskeyThunk.fulfilled, (state, { payload }) => {
      console.log('signinWithPasskeyThunk.fulfilled', payload);
      return processPayload(state, payload);
    });
    builder.addCase(signinWithGoogleThunk.fulfilled, (state, { payload }) => {
      console.log('signinWithGoogle.fulfilled', payload);
      return processPayload(state, payload);
    });

    builder.addCase(refreshTokenThunk.fulfilled, (state, { payload }) => {
      console.log('refreshToken.fulfilled', payload);
      return processPayload(state, payload);
    });

    builder.addCase(switchCompanyThunk.fulfilled, (state, { payload }) => {
      console.log('switchCompany.fulfilled', payload);
      return processPayload(state, payload);
    });

    builder.addCase(createCompanyThunk.fulfilled, (state, { payload }) => {
      console.log('createCompany.fulfilled', payload);
      return processPayload(state, payload);
    });

    builder.addCase(upsertFacilityThunk.fulfilled, (state, { payload }) => {
      console.log('upsertFacility.fulfilled', payload);
      // Get the facility and update the company
      const facility = payload;
      const facilities = state.company.facilities ?? [];

      const index = facilities.findIndex((f) => f._id === facility._id);
      if (index >= 0) {
        facilities[index] = facility;
      } else {
        facilities.push(facility);
      }

      state.company.facilities = facilities;
    });

    builder.addCase(refetchFacilitiesThunk.fulfilled, (state, { payload }) => {
      console.log('refetchFacilitiesThunk.fulfilled', payload);
      state.company.facilities = payload;
    });

    builder.addCase(connectWithPartnerThunk.fulfilled, (state, { payload }) => {
      console.log('connectWithPartnerThunk.fulfilled', payload);
      const partner = payload;
      const partners = state.company.partners ?? [];

      const index = partners.findIndex((p) => p.path === partner.path);
      if (index >= 0) {
        const clone = [...partners];
        clone[index] = partner;
        state.company.partners = clone;
      } else {
        if (!state?.company) state.company = {};
        if (!state?.company?.partners) state.company.partners = [];
        state.company.partners.push(partner);
      }
    });

    builder.addCase(refetchUsersThunk.fulfilled, (state, { payload }) => {
      console.log('refetchUsersThunk.fulfilled', payload);
      // state.company.users = payload;
    });

    builder.addCase(removeUserThunk.fulfilled, (state, { payload }) => {
      console.log('removeUserThunk.fulfilled', payload);
      // state.company.users = state.company.users.filter(
      //   (p) => p.account_id !== payload.account_id,
      // );
    });

    builder.addCase(upsertTeamThunk.fulfilled, (state, { payload }) => {
      console.log('upsertTeamThunk.fulfilled', payload);
      // state.company.teams = payload;
    });

    builder.addCase(refetchTeamsThunk.fulfilled, (state, { payload }) => {
      console.log('refetchTeamsThunk.fulfilled', payload);
      // state.company.teams = payload;
    });

    builder.addCase(refetchSensors.fulfilled, (state, { payload }) => {
      console.log('refetchSensors.fulfilled', payload);
      state.company.sensors = payload?.items ?? [];
    });

    builder.addCase(upsertGatewayThunk.fulfilled, (state, { payload }) => {
      console.log('upsertGatewayThunk.fulfilled', payload);
      // state.company.skus = payload;
      const gateway = payload;

      const gateways = state.company.sensors ?? [];

      const index = gateways.find((s) => s.path === gateway.path);
      if (index >= 0) {
        const clone = [...gateways];
        clone[index] = gateway;
        state.company.sensors = clone;
      } else {
        state.company.sensors = [...gateways, gateway];
      }
    });

    builder.addCase(PURGE, () => {
      console.log('PURGE');
      window.localStorage.removeItem('auth-token');
      window.localStorage.removeItem('company-id');
      window.localStorage.removeItem('account-id');
      return { ...initialState };
    });
  },
});

export const { reducer, actions } = slice;
export const { setBaseUnits, setParsedLiquidData } = actions;
