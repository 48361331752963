import Stack from '@mui/material/Stack';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import BulkLiquidLevel from '@pw/components_v2/Asset/BulkLiquidLevel';
import liquidLevelFields from '@pw/components_v2/Asset/liquidLevelFields';
import LiquidWeight from '@pw/components_v2/Asset/LiquidWeight';
import liquidWeightFields from '@pw/components_v2/Asset/liquidWeightFields';
import ThingSearch from '@pw/components_v2/search/thing';
import { LIQUID_ACCOUNT_TYPE, LIQUID_ACCOUNT_TYPE_OPTIONS } from '@pw/consts/asset';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

export function liquidPropertyFields(entity, converter) {
  return {
    batch: [entity?.batch ?? '', yup.string()],
    date: [entity?.date ?? Date.now(), yup.number()],
    tags: [entity?.tags, yup.array().of(yup.string())],
    account: [entity?.account ?? LIQUID_ACCOUNT_TYPE.STORAGE, yup.string()],
    thing: [entity?.thing, yup.object()],
    level: liquidLevelFields(entity?.level, converter),
    weight: liquidWeightFields(entity?.weight),
  };
}

function LiquidPropertiesImpl({ disabled = false }) {
  return (
    <Stack spacing="1.75rem">
      <ThingSearch field="thing" label='Thing' types={[THING_TYPES.LIQUID]} disabled={disabled} />

      <FormikSelect
        disabled={disabled}
        label='Liquid Account'
        name='account'
        options={LIQUID_ACCOUNT_TYPE_OPTIONS}
        fullWidth
      />

      <TagSelect disabled={disabled} name='tags' type={TAG_CATEGORY.LIQUID} />

      <BulkLiquidLevel name='level' disabled={disabled} />
      <LiquidWeight name='weight' disabled={disabled} />
    </Stack>
  );
}

function LiquidProperties({ name = 'properties', disabled = false }) {
  return (
    <FormikProvider path={name}>
      <LiquidPropertiesImpl disabled={disabled} />
    </FormikProvider>
  );
}

export default LiquidProperties;
