import { removeAssetThunk, upsertAssetThunk } from '@pw/redux/thunks/asset';
import {
  createCompanyThunk,
  upsertCompanyThunk,
} from '@pw/redux/thunks/company';
import {
  upsertContactThunk,
  upsertPartnerContactThunk,
} from '@pw/redux/thunks/contacts';
import { removeDesignThunk, upsertDesignThunk } from '@pw/redux/thunks/designs';
import {
  refetchFacilitiesThunk,
  upsertFacilityThunk,
} from '@pw/redux/thunks/facilities';
import { removeLedgerThunk, upsertLedgerThunk } from '@pw/redux/thunks/ledger';
import { setPageLoadingMessageThunk } from '@pw/redux/thunks/log';
import {
  resetOwnerPasswordThunk,
  uploadOwnersThunk,
} from '@pw/redux/thunks/owners';
import { connectWithPartnerThunk } from '@pw/redux/thunks/partners';
import { removePolicyThunk, upsertPolicyThunk } from '@pw/redux/thunks/policy';
import {
  removePriceCurveThunk,
  upsertPriceCurveThunk,
} from '@pw/redux/thunks/priceCurves';
import {
  challengeIdentResendThunk,
  challengeIdentThunk,
  recoverThunk,
  registerUserThunk,
  verifyIdentThunk,
  verifySocialsThunk,
} from '@pw/redux/thunks/register';
import {
  migrateRequestThunk,
  requestApproveRejectThunk,
  requestArchiveThunk,
  requestAssignThunk,
  requestCancelThunk,
  upsertRequestThunk,
} from '@pw/redux/thunks/request';
import { searchTeamThunk, searchUserThunk } from '@pw/redux/thunks/search';
import {
  refetchSensors,
  upsertGatewayThunk,
  upsertSensorThunk,
} from '@pw/redux/thunks/sensors';
import {
  removeSpecificationThunk,
  upsertSpecificationThunk,
} from '@pw/redux/thunks/specification';
import { syncChangesThunk } from '@pw/redux/thunks/sync';
import {
  refetchTeamsThunk,
  removeTeamThunk,
  upsertTeamThunk,
} from '@pw/redux/thunks/teams';
import { cloneThingThunk, upsertThingThunk } from '@pw/redux/thunks/thing';
import {
  inviteThunk,
  logoutThunk,
  refetchUsersThunk,
  refreshTokenThunk,
  registerPasskeyThunk,
  removeUserThunk,
  switchCompanyThunk,
  updatePasswordThunk,
  updateProfileThunk,
  upsertUserThunk,
} from '@pw/redux/thunks/user';
import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid/non-secure';
import { PURGE } from 'redux-persist';
import { setOnlineThunk } from '../../thunks/connection';
import {
  signinUserThunk,
  signinWithGoogleThunk,
  signinWithPasskeyThunk,
} from '../../thunks/signin';
import { SLICE } from './consts';
import { public_load_invite_service } from '@pw/services/company.service';
import { createPaymentThunk } from '@pw/redux/thunks/payment';

const pushMessage = (state, payload) => {
  const id = nanoid();
  state.messages.push({
    id,
    ...payload,
  });
};

const registerHandlers = (builder, thunk, pending, success, error) => {
  builder.addCase(thunk.pending, (state) => {
    state.page_loading = { message: pending.message ?? null };
  });
  builder.addCase(thunk.fulfilled, (state, { payload }) => {
    state.page_loading = null;
    if (success) {
      pushMessage(state, {
        severity: success.severity ?? 'success',
        title: success.title ?? 'Success',
        content: success.message ?? 'Operation completed successfully!',
      });
    }
  });
  builder.addCase(thunk.rejected, (state, { payload }) => {
    state.page_loading = null;
    pushMessage(state, {
      severity: error?.severity ?? 'error',
      title: error?.title ?? 'Error',
      content: payload ?? error?.message ?? 'Error executing operation!',
    });
  });
};

const registerHandlersRTQ = (builder, endpoint, pending, success, error) => {
  builder.addMatcher(endpoint.matchPending, (state) => {
    state.page_loading = { message: pending.message ?? null };
  });
  builder.addMatcher(endpoint.matchFulfilled, (state, { payload }) => {
    state.page_loading = null;
    if (success) {
      pushMessage(state, {
        severity: success.severity ?? 'success',
        title: success.title ?? 'Success',
        content: success.message ?? 'Operation completed successfully!',
      });
    }
  });
  builder.addMatcher(endpoint.matchRejected, (state, { payload }) => {
    state.page_loading = null;
    pushMessage(state, {
      severity: error?.severity ?? 'error',
      title: error?.title ?? 'Error',
      content: payload ?? error?.message ?? 'Error executing operation!',
    });
  });
};

const initialState = {
  active_list_item: null,
  page: {
    title: 'Proofworks',
  },
  page_loading: null,
  error: null,
  online: true,
  messages: [] /* Notification messages */,
};

export const slice = createSlice({
  name: SLICE,
  initialState,
  reducers: {
    setActiveListItem(state, { payload }) {
      console.log('Setting active item', payload);
      state.active_list_item = payload;
    },
    setPageLoadingMessage(state, { payload }) {
      state.page_loading = payload;
    },
    pushNotification(state, { payload }) {
      console.log('pushNotification', payload);
      pushMessage(state, payload);
    },
    popNotification(state, { payload: ids }) {
      console.log('popNotification', ids);
      state.messages = state.messages.filter((msg) => !ids.includes(msg.id));
    },
    setOnline(state, { payload }) {
      state.online = payload;
    },
    setPageTitle(state, { payload }) {
      console.log('setPageTitle', payload);
      state.page = {
        ...(state.page ?? {}),
        title: payload,
      };
    },
    clearPageLoading(state) {
      state.page_loading = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      setPageLoadingMessageThunk.fulfilled,
      (state, { payload }) => {
        console.log('setPageLoadingMessageThunk.fulfilled', payload);
        state.page_loading = payload;
      },
    );

    builder.addCase(setOnlineThunk.pending, (state, { payload }) => {
      state.page_loading = {
        message: payload ? 'Connecting...' : 'Disconnecting...',
      };
    });
    builder.addCase(setOnlineThunk.fulfilled, (state, { payload }) => {
      state.page_loading = null;
      state.online = payload;
    });
    builder.addCase(setOnlineThunk.rejected, (state, { payload }) => {
      state.page_loading = null;
      pushMessage(state, {
        severity: 'error',
        title: 'Connection Error',
        content: payload ?? 'Failed to change connection state!',
      });
    });

    registerHandlers(
      builder,
      challengeIdentThunk,
      { message: 'Sign up...' },
      null,
      { title: 'Signup Error' },
    );

    registerHandlers(
      builder,
      challengeIdentResendThunk,
      { message: 'Resending code...' },
      null,
      { title: 'Resending Code Error' },
    );

    registerHandlers(
      builder,
      registerUserThunk,
      { message: 'Registering user...' },
      null,
      { title: 'Registering user Error' },
    );

    registerHandlers(
      builder,
      verifySocialsThunk,
      { message: 'Registering user...' },
      null,
      { title: 'Registering user Error' },
    );

    registerHandlers(
      builder,
      recoverThunk,
      { message: 'Recovering password...' },
      null,
      { title: 'Recovering password Error' },
    );

    registerHandlers(
      builder,
      verifyIdentThunk,
      { message: 'Verifying identity...' },
      null,
      { title: 'Verifying identity Error' },
    );

    registerHandlers(
      builder,
      logoutThunk,
      { message: 'Logging out...' },
      null,
      { title: 'Logout Error' },
    );

    registerHandlers(
      builder,
      signinUserThunk,
      { message: 'Signing in...' },
      null,
      { title: 'Signin Error' },
    );

    registerHandlers(
      builder,
      signinWithPasskeyThunk,
      { message: 'Signing in...' },
      null,
      { title: 'Signin Error' },
    );

    registerHandlers(
      builder,
      signinWithGoogleThunk,
      { message: 'Signing in...' },
      null,
      { title: 'Signin Error' },
    );

    registerHandlers(
      builder,
      refreshTokenThunk,
      { message: 'Refreshing...' },
      null,
      { title: 'Failed to Refresh' },
    );

    registerHandlers(
      builder,
      switchCompanyThunk,
      { message: 'Switching...' },
      null,
      { title: 'Failed to Switch' },
    );

    registerHandlers(
      builder,
      updateProfileThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Account', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      updatePasswordThunk,
      { message: 'Updating...' },
      {
        severity: 'success',
        title: 'Password',
        content: 'Saved successfully!',
      },
      { title: 'Failed to Update' },
    );

    registerHandlers(
      builder,
      upsertCompanyThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Company', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      upsertPartnerContactThunk,
      { message: 'Saving...' },
      {
        severity: 'success',
        title: 'Partner contact',
        content: 'Saved successfully!',
      },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      upsertContactThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Contact', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      createCompanyThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Company', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      registerPasskeyThunk,
      { message: 'Registering...' },
      {
        severity: 'success',
        title: 'Passkey',
        content: 'Passkey registered successfully!',
      },
      { title: 'Failed to Register' },
    );

    registerHandlers(
      builder,
      refetchFacilitiesThunk,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(
      builder,
      upsertFacilityThunk,
      { message: 'Saving...' },
      {
        severity: 'success',
        title: 'Facility',
        content: 'Saved successfully!',
      },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      upsertPriceCurveThunk,
      { message: 'Saving...' },
      {
        severity: 'success',
        title: 'Price Curve',
        content: 'Saved successfully!',
      },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      removePriceCurveThunk,
      { message: 'Removing...' },
      {
        severity: 'success',
        title: 'Price Curve',
        content: 'Removed successfully!',
      },
      { title: 'Failed to Remove' },
    );

    registerHandlers(
      builder,
      refetchSensors,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(
      builder,
      upsertGatewayThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Gateway', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      upsertSensorThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Sensor', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      connectWithPartnerThunk,
      { message: 'Connecting...' },
      {
        severity: 'success',
        title: 'Partner',
        content: 'Connected successfully!',
      },
      { title: 'Failed to Connect' },
    );

    registerHandlers(
      builder,
      upsertSpecificationThunk,
      { message: 'Saving...' },
      {
        severity: 'success',
        title: 'Specification',
        content: 'Saved successfully!',
      },
      { title: 'Failed to save' },
    );

    registerHandlers(
      builder,
      removeSpecificationThunk,
      { message: 'Removing...' },
      {
        severity: 'success',
        title: 'Specification',
        content: 'Removed successfully!',
      },
      { title: 'Failed to Remove' },
    );

    registerHandlers(
      builder,
      uploadOwnersThunk,
      { message: 'Uploading...' },
      {
        severity: 'success',
        title: 'Owners',
        content: 'Uploaded successfully!',
      },
      { title: 'Failed to Upload' },
    );

    registerHandlers(
      builder,
      inviteThunk,
      { message: 'Inviting...' },
      {
        severity: 'success',
        title: 'Contact',
        content: 'Invited successfully!',
      },
      { title: 'Failed to Invite' },
    );

    registerHandlers(
      builder,
      resetOwnerPasswordThunk,
      { message: 'Resetting...' },
      {
        severity: 'success',
        title: 'Owner',
        content: 'Password reset successfully!',
      },
      { title: 'Failed to Reset' },
    );

    registerHandlers(
      builder,
      refetchTeamsThunk,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(
      builder,
      upsertTeamThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Team', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      removeTeamThunk,
      { message: 'Removing...' },
      { severity: 'success', title: 'Team', content: 'Removed successfully!' },
      { title: 'Failed to Remove' },
    );

    registerHandlers(
      builder,
      refetchUsersThunk,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(
      builder,
      upsertUserThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'User', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      removeUserThunk,
      { message: 'Removing...' },
      { severity: 'success', title: 'User', content: 'Removed successfully!' },
      { title: 'Failed to Remove' },
    );

    registerHandlers(
      builder,
      upsertAssetThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Asset', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      removeAssetThunk,
      { message: 'Deleting...' },
      { severity: 'success', title: 'Asset', content: 'Deleted successfully!' },
      { title: 'Failed to Delete' },
    );

    registerHandlers(
      builder,
      upsertRequestThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Request', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      requestApproveRejectThunk,
      { message: 'Approving...' },
      {
        severity: 'success',
        title: 'Request',
        content: 'Approved successfully!',
      },
      { title: 'Failed to Approve' },
    );

    registerHandlers(
      builder,
      migrateRequestThunk,
      { message: 'Migrating...' },
      {
        severity: 'success',
        title: 'Request',
        content: 'Migrated successfully!',
      },
      { title: 'Failed to Migrate' },
    );

    registerHandlers(
      builder,
      requestArchiveThunk,
      { message: 'Archiving...' },
      {
        severity: 'success',
        title: 'Request',
        content: 'Archived successfully!',
      },
      { title: 'Failed to Archive' },
    );

    registerHandlers(
      builder,
      requestCancelThunk,
      { message: 'Cancelling...' },
      {
        severity: 'success',
        title: 'Request',
        content: 'Cancelled successfully!',
      },
      { title: 'Failed to Cancel' },
    );

    registerHandlers(
      builder,
      requestAssignThunk,
      { message: 'Assigning...' },
      {
        severity: 'success',
        title: 'Request',
        content: 'Assigned successfully!',
      },
      { title: 'Failed to Assign' },
    );

    registerHandlers(
      builder,
      cloneThingThunk,
      { message: 'Cloning...' },
      { severity: 'success', title: 'Thing', content: 'Cloned successfully!' },
      { title: 'Failed to Clone' },
    );

    registerHandlers(
      builder,
      upsertThingThunk,
      { message: 'Saving Thing...' },
      { severity: 'success', title: 'Thing', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      syncChangesThunk,
      { message: 'Synchronizing...' },
      {
        severity: 'success',
        title: 'Sync',
        content: 'Synchronized successfully!',
      },
      { title: 'Failed to Synchronize' },
    );

    registerHandlers(
      builder,
      upsertPolicyThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Policy', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      removePolicyThunk,
      { message: 'Removing...' },
      {
        severity: 'success',
        title: 'Policy',
        content: 'Removed successfully!',
      },
      { title: 'Failed to Remove' },
    );

    registerHandlers(
      builder,
      searchUserThunk,
      { message: 'Searching...' },
      null,
      { title: 'Failed to Find' },
    );

    registerHandlers(
      builder,
      searchTeamThunk,
      { message: 'Searching...' },
      null,
      { title: 'Failed to Find' },
    );

    registerHandlers(
      builder,
      upsertDesignThunk,
      { message: 'Saving Design...' },
      { severity: 'success', title: 'Design', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      removeDesignThunk,
      { message: 'Removing...' },
      {
        severity: 'success',
        title: 'Design',
        content: 'Removed successfully!',
      },
      { title: 'Failed to Remove' },
    );

    registerHandlers(
      builder,
      upsertLedgerThunk,
      { message: 'Saving Ledger...' },
      { severity: 'success', title: 'Ledger', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(
      builder,
      removeLedgerThunk,
      { message: 'Removing...' },
      {
        severity: 'success',
        title: 'Ledger',
        content: 'Removed successfully!',
      },
      { title: 'Failed to Remove' },
    );

    registerHandlers(
      builder,
      createPaymentThunk,
      { message: 'Loading stripe...' },
      {
        severity: 'success',
        title: 'Limit payments',
        content: 'Redirecting to stripe page!',
      },
      { title: 'Failed to load' },
    );

    builder.addCase(PURGE, () => {
      return { ...initialState };
    });

    registerHandlersRTQ(
      builder,
      public_load_invite_service.endpoints.loadInvite,
      { message: 'Loading invite...' },
      null,
      { title: 'Invite Error' },
    );
  },
});

export const { reducer, actions } = slice;
export const {
  setActiveListItem,
  setPageLoadingMessage,
  pushNotification,
  popNotification,
  setOnline,
  setPageTitle,
  clearPageLoading,
} = actions;
