import { listTeams } from '@pw/services/company.service';
import { getAllFacilities } from '@pw/services/facility.service';
import { setPageLoadingMessageThunk } from '../log';

const loadCompanyState = async (company, dispatch, perms) => {
  // TODO: LOAD THIS
  dispatch(setPageLoadingMessageThunk({ message: 'Loading Facilities...' }));
  const facilities = await getAllFacilities({ id: company.company_id });
  console.log('facilities', facilities);

  //
  // TODO: LOAD THIS
  // dispatch(setPageLoadingMessageThunk({ message: 'Loading Users...' }))
  // const users = await listUsers({ id: company.company_id });
  // // console.log('users', users);
  //
  // TODO: THIS WILL NOT BE LOADED
  // dispatch(setPageLoadingMessageThunk({ message: 'Loading Sensors...' }))
  // const sensors = await listGateWay({ id: company.company_id });
  // // console.log('sensors', sensors);
  //
  // TODO: THIS WILL NOT BE LOADED
  // dispatch(setPageLoadingMessageThunk({ message: 'Loading Partners...' }))
  // const partners = await listPartners({ id: company.company_id });
  // // console.log('partners', partners);
  //
  // TODO: THIS WILL NOT BE LOADED
  // dispatch(setPageLoadingMessageThunk({ message: 'Loading Policies...' }))
  // const policies = await getPolicyList();
  //
  // TODO: THIS WILL NOT BE LOADED
  // dispatch(setPageLoadingMessageThunk({ message: 'Loading Specifications...' }))
  // const specifications = await getSpecificationList();
  //
  // TODO: THIS WILL NOT BE LOADED
  // dispatch(setPageLoadingMessageThunk({ message: 'Loading Price Curves...' }))
  // const priceCurves = await getAllPriceCurves();
  //
  // console.log('perms', perms);
  // let roles = [];
  // let permissions = [];
  // if (perms.companyAdmin || perms.userAdmin) {
  //  TODO: Check permission for role
  // dispatch(setPageLoadingMessageThunk({ message: 'Loading Roles...' }))
  // const res = await listRoles({ id: company.company_id });
  // roles = res?.items ?? res ?? [];
  // console.log('roles', roles);
  //
  //   TODO: THIS WILL BE LOADED
  dispatch(setPageLoadingMessageThunk({ message: 'Loading Teams...' }));
  const teams = await listTeams({ id: company.company_id });
  console.log('teams', teams);
  //
  //   TODO: THIS WILL BE LOADED
  //   dispatch(setPageLoadingMessageThunk({ message: 'Loading Permissions...' }))
  //   permissions = await listPermissions();
  //   console.log('permissions', permissions);
  // }

  return {
    ...company,
    facilities,
    // users,
    // sensors: sensors?.items ?? [],
    // partners: partners?.items ?? [],
    // owners: owners?.items ?? [],
    roles: [],
    teams,
    // permissions,
    // policies,
    // specifications,
    // price_curves: priceCurves
  };
};

export default loadCompanyState;
