function SvgGrainBin(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 14 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#GrainBin_svg__clip0_8296_1077)'>
        <path
          d='M6.765 0L.646 2.945c-.352.171-.542.479-.632.84L0 23.227c.038.41.253.617.63.771h1.208c.192-.045.628-.404.628-.586v-3.07l2.416 1.43c.093.1.033.51.06.69.071.483.402.706.88.741.617.045 1.866.064 2.465-.002.478-.051.75-.37.796-.82.018-.173-.05-.491.05-.609l2.415-1.43v3.024c0 .24.4.564.628.632h1.208c.262-.127.492-.245.582-.536L14 3.784c-.09-.36-.28-.668-.633-.839L7.248 0h-.483zM1.21 3.61L6.827.833h.36l5.617 2.775H1.21zm-.387.796H13.19v4.407H.823V4.405zm0 5.203H13.19v4.407H.823V9.609zm0 5.204H13.19v4.406H.823v-4.407zm11.546 5.203h.821v3.187h-.82v-3.187zm-8.792 0h6.86c-.322.323-2.174 1.162-2.174 1.57v.82H5.75v-.82c0-.092-.257-.35-.342-.418-.523-.425-1.312-.7-1.832-1.153zm-2.754 0h.821v3.187h-.82v-3.187z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='GrainBin_svg__clip0_8296_1077'>
          <path
            fill='#fff'
            transform='matrix(-1 0 0 1 14 0)'
            d='M0 0h14v24H0z'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgGrainBin;
