import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { RequestQrCodeSkeleton } from '@pw/components/QRImage/RequestQRCode';
import { H5 } from '@pw/components/Typography';
import styles from '@pw/styles/content.styles';

function SummarySkeleton() {
  return (
    <Stack sx={styles} className='root' spacing={3}>
      <FlexBox>
        <H5>...</H5>
        <Skeleton variant='text' width={100} />
      </FlexBox>

      <Box className='summary-qr'>
        <Box className='qr'>
          <RequestQrCodeSkeleton />
        </Box>
      </Box>

    </Stack>
  );
}

export default withSmallDialogCard(SummarySkeleton);
