import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components_v2/Request';
import SampleProperties from '@pw/components/SampleProperties';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import * as yup from 'yup';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import SvgAssets from '@pw/components/icons/Assets';
import {
  InventoryDestinations,
  InventorySources,
} from '@pw/components_v2/Request/sources';
import SvgPolicy from '@pw/components/icons/Policy';
import SvgInvoice from '@pw/components/icons/Invoice';
import InvoiceForm from '@pw/components_v2/Request/InvoiceForm';

const filter = {
  [SEARCH_TYPES.ASSETS]: {
    types: [ASSET_TYPES.CASK, ASSET_TYPES.IBC, ASSET_TYPES.FILLING_TANK],
  },
};
const filterDestination = {
  [SEARCH_TYPES.THINGS]: {
    types: [THING_TYPES.SERVICES],
  },
};

const tabs = [
  {
    title: 'Inventory',
    key: 'inventory',
    Icon: SvgAssets,
    properties: (entity) => ({
      sources: [entity?.sources, yup.object()],
    }),
    Component: (props) => <InventorySources filter={filter} {...props} />,
  },
  {
    title: 'Services',
    key: 'services',
    Icon: SvgAssets,
    properties: (entity) => ({
      destinations: [entity?.destinations, yup.object()],
    }),
    Component: (props) => (
      <InventoryDestinations filter={filterDestination} {...props} />
    ),
  },
  {
    title: 'Properties',
    key: 'propertieS',
    Icon: SvgInvoice,
    properties: (entity) => ({
      properties: [entity?.properties ?? {}, yup.object()],
    }),
    Component: (props) => <SampleProperties name='properties' {...props} />,
  },

  {
    title: 'Policy',
    key: 'policy',
    Icon: SvgPolicy,
    properties: (entity) => ({
      policies: [entity?.policies ?? [], yup.array()],
    }),
    Component: (props) => <PolicySelector {...props} />,
  },
  {
    title: 'Invoice',
    key: 'invoice',
    Icon: SvgInvoice,
    properties: (entity) => ({ client: [entity?.client, yup.object()] }),
    Component: (props) => <InvoiceForm {...props} />,
  },
];

function SampleForm() {
  //   let [searchParams] = useSearchParams();
  //   const asset = searchParams.get('asset');
  //   const [fetchAsset] = useAssetLazyQuery();
  //   const converter = useConverter();

  //   const [
  //     [],
  //     [sourceAssets, initSourceAssets],
  //     initSources,
  //     SourceInventory,
  //     SourceModals,
  //   ] = useSourceInventoryHook({
  //     title: 'Assets',
  //     filter: {
  //       asset_types: [
  //         ASSET_TYPES.CASK,
  //         ASSET_TYPES.IBC,
  //         ASSET_TYPES.FILLING_TANK,
  //       ],
  //     },
  //   });

  //   const [
  //     [destinationThings],
  //     [],
  //     initDestinations,
  //     DestinationInventory,
  //     DestinationModals,
  //   ] = useInventorySelectorHook({
  //     title: 'Services',
  //     filter: {
  //       thing_types: [THING_TYPES.SERVICES],
  //     },
  //     ThingModal: DestinationThingModal,
  //   });

  //   const changeSetGenerator = useMemo(
  //     () => (initialValues) => ({
  //       ...requestIdFields(initialValues),
  //       properties: sampleFields(initialValues?.properties),
  //       policies: [initialValues?.policies, yup.array()],
  //       client: clientFields(initialValues?.client),
  //     }),
  //     [],
  //   );

  //   /**
  //    * Initialization function
  //    * @type {(function(*): void)|*}
  //    */
  //   const handleInit = useCallback(
  //     (entity) => {
  //       initSources({
  //         ...entity,
  //         assets: entity?.sources?.assets,
  //       });
  //       initDestinations({ ...entity, things: entity?.destinations?.things });
  //     },
  //     [initSources, initDestinations],
  //   );

  //   /**
  //    * Prior to saving the entity, this is called to inject in the sources
  //    * @type {function(*): *&{sources: *, sku_sources: *}}
  //    */
  //   const handleBeforeSave = useCallback(
  //     (entity) => ({
  //       ...entity,
  //       sources: {
  //         assets: sourceAssets,
  //       },
  //       destinations: {
  //         things: destinationThings,
  //       },
  //       properties: {
  //         ...(entity?.properties ?? {}),
  //         sample_size: converter.cx(
  //           entity?.properties?.sample?.value,
  //           entity?.properties?.sample?.unit,
  //           UNITS.milliliters,
  //         ),
  //       },
  //     }),
  //     [destinationThings, sourceAssets],
  //   );

  //   useEffect(() => {
  //     if (asset) {
  //       fetchAsset({id: asset}).then((a) => {
  //         debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
  //       });
  //     }
  //   }, [asset, initSourceAssets, fetchAsset]);

  return (
    <>
      <RequestForm
        requestLabel='Sample'
        requestType={REQUEST_TYPES.SAMPLE}
        tabs={tabs}
      >
        {/* <SourceInventory />
        <DestinationInventory />

        <SampleProperties name='properties' />

        <PolicySelector /> */}
      </RequestForm>
      {/* 
      <SourceModals />
      <DestinationModals /> */}
    </>
  );
}

export default withAppLayout(SampleForm, { title: 'Sample' });
