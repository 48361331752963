import cardStyles from './card.styles';
import formStyles from './form.styles';
import listStyles from './list.styles';

const styles = {
	'&.root': {
		width: '100%',
		position: 'relative',

		'.header': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',

			'.button': {
				minWidth: 'max-content',
				maxWidth: 'max-content',
			},
		},

		...cardStyles,
		...formStyles,
		...listStyles,
	},

	'.creator-view': {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'stretch',

		'.column': {
			padding: '0.75rem',
			backgroundColor: 'rgba(0, 0, 0, 0.02)',
			borderColor: 'rgba(0, 0, 0, 0.15)',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderRadius: '5px',
			flexGrow: 1,
		},
	},

	'.photos-container': {
		display: 'none',
		paddingBottom: '0.5rem',
	},
	'.photos': {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr ',
		// margin: '1rem',
		maxWidth: '600px',
		background: '#fff',
		gap: '10px',
		width: '100%',
	},
	'.has-photo': {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	'.photo-item': {
		position: 'relative',
	},
	'.photo-style': {
		borderRadius: '5px',
		width: '100px',
		height: '100px',
	},
	'.photo-limit-warning': {
		display: 'none',
		position: 'absolute',
		color: 'red',
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		bottom: '5rem',
		right: '30%',
		top: 'auto',
		zIndex: '9',
		'&.show': {
			display: 'block',
		},
	},
	'.delete': {
		color: 'red',
		position: 'absolute',
		right: 0,
		top: 0,
	},
};

export default styles;
