import { useLoadInviteQuery } from '@pw/services/company.service';
import { useSearchParams } from 'react-router-dom';

export function useSearchParamByName(name = 'i') {
  const [searchParams] = useSearchParams();
  const invite = searchParams.get(name);
  return invite;
}

export function useRelationshipFromInviteQuery() {
  const invite = useSearchParamByName();
  const res = useLoadInviteQuery({ invite }, { skip: !invite });
  return res;
}
