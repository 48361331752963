import Close from '@mui/icons-material/Close';
import FlipCameraIos from '@mui/icons-material/FlipCameraIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import TitledButton from '@pw/components/Buttons/TitledButton';
import SvgQrScan3 from '@pw/components/icons/QrScan3';
import { useState } from 'react';
import QRCodeScanner from './QRCodeScanner';

const styles = {
  '&.root': {
    position: 'relative',
    borderRadius: '5px',
  },
  '.scanIcon': {
    fontSize: '1rem',
    width: '1.75em',
    height: '1.75em',
    padding: '0.125em',
  },
  '.MuiDialog-paper': {
    borderRadius: '2rem',
  },
  '.scanner': {
    position: 'relative',
    margin: 0,
    width: '100%',
    maxWidth: '600px',
    background: '#fff',
  },
  '.modalButton': {
    position: 'absolute',
    zIndex: 1,
    top: '1rem',
    padding: '1rem',
    borderRadius: '9999px',
    background: 'rgba(255,255,255, 0.2)',
    backdropFilter: 'blur(10px)',
  },
  '.dialogClose': {
    left: '1rem',
  },
  '.zoomContainer': {
    right: '1rem',
    top: '40%',
    position: 'absolute',
    zIndex: 1,
    // background: 'rgba(255,255,255, 0.2)',
    display: 'flex',
    flexDirection: 'column',
  },
  '.zoomIn': {
    position: 'relative',
  },
  '.zoomOut': {
    position: 'relative',
  },
  '.toggleFacingMode': {
    right: '1rem',
  },
  '.qrData': {
    position: 'absolute',
    top: '100%',
    right: 0,
    whiteSpace: 'nowrap',
  },
  containerStyle: {},
  videoContainerStyle: {},
  videoStyle: {
    objectFit: 'cover',
  },
};

function QRCode(props) {
  const {
    onSuccess,
    withLabel,
    Component = Button,
    closeOnSuccess = true,
    disabled = false,
    height = 42,
    width = 42,
    sx = { width: '78px', height: '55px' },
    ...rest
  } = props;

  const [showQRScanner, setShowQRScanner] = useState(false);

  const closeDialog = async () => {
    setShowQRScanner(false);
  };

  return (
    <>
      <TitledButton handleClick={() => !disabled && setShowQRScanner(true)} label="Scan" disabled={disabled}>
        <SvgQrScan3 height={24} width={24} />
      </TitledButton>
      {showQRScanner && (
        <QrScannerDialog
          closeDialog={closeDialog}
          closeOnSuccess={closeOnSuccess}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}

export default QRCode;

const FACING_MODE = {
  environment: 'environment',
  user: 'user',
};

export function QrScannerDialog({ closeDialog, closeOnSuccess, onSuccess }) {
  const [facingMode, setFacingMode] = useState(FACING_MODE.environment);

  const toggleFacingMode = () => {
    setFacingMode((prevMode) =>
      prevMode === FACING_MODE.environment
        ? FACING_MODE.user
        : FACING_MODE.environment,
    );
  };
  return (
    <Dialog
      sx={styles}
      className='dialog'
      open={true}
      onClose={closeDialog}
      fullWidth
    >
      <Box className='scanner'>
        <ButtonBase onClick={closeDialog} className='modalButton dialogClose'>
          <Close />
        </ButtonBase>
        <ButtonBase
          onClick={toggleFacingMode}
          className='modalButton toggleFacingMode'
        >
          <FlipCameraIos />
        </ButtonBase>

        <QRCodeScanner
          onSuccess={(data) => {
            if (closeOnSuccess) closeDialog();
            if (typeof onSuccess === 'function') onSuccess(data);
          }}
          facingMode={facingMode}
        />
      </Box>
    </Dialog>
  );
}
