import { LocalOfferOutlined, Percent, Scale, Straighten } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import {
  DISTANCE_UNITS,
  LIQUID_UNITS,
  MASS_UNITS,
  UNIT,
  UNITS,
} from '@pw/consts/units';
import useConverter from '@pw/utilities/hooks/logic/useConverter';

function AmountDisplay({ label = undefined, amount, unit = undefined}) {
	const converter = useConverter();

	if (!amount) return;

	return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        gap: '0.25rem',
      }}
    >
      {unit && unit === UNITS.perc && (
        <>
          <Percent style={{ width: '12px', height: '14px' }} />
          <Body3>
            <Bolder>{label ?? 'Amount'}</Bolder>
            &nbsp;
            {amount}
            {UNITS.perc}
          </Body3>
        </>
      )}
      {unit && Object.values(MASS_UNITS).includes(unit) && (
        <>
          <Scale style={{ width: '12px', height: 'auto' }} />
          <Body3>
            <Bolder>{label ?? 'Weight'}</Bolder>
            &nbsp;
            {converter.cx(amount, null, unit)} {converter.unit(unit)}
          </Body3>
        </>
      )}
      {unit && Object.values(LIQUID_UNITS).includes(unit) && (
        <>
          <Scale style={{ width: '12px', height: 'auto' }} />
          <Body3>
            <Bolder>{label ?? 'Volume'}</Bolder>
            &nbsp;
            {converter.cx(amount, null, unit)} {converter.unit(unit)}
          </Body3>
        </>
      )}
      {unit && Object.values(DISTANCE_UNITS).includes(unit) && (
        <>
          <Straighten style={{ width: '12px', height: 'auto' }} />
          <Body3>
            <Bolder>{label ?? 'Distance'}</Bolder>
            &nbsp;
            {converter.cx(amount, null, unit)} {converter.unit(unit)}
          </Body3>
        </>
      )}
      {(!unit || unit === UNITS.count) && (
        <>
          <LocalOfferOutlined style={{ width: '12px', height: 'auto' }} />
          <Body3>
            <Bolder>{label ?? 'Items'}</Bolder>
            &nbsp;
            {amount}
          </Body3>
        </>
      )}
    </Box>
  );
}

export default AmountDisplay;
