function SvgShipment(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 17 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M2.475 22c-.158-.114-.33-.212-.47-.344-.58-.55-1.147-1.114-1.727-1.666A.844.844 0 010 19.34C.002 13.14.002 6.938.002.74c0-.486.266-.74.77-.74h15.457c.503 0 .77.254.77.739 0 6.2 0 12.4.002 18.6a.848.848 0 01-.278.652c-.574.547-1.136 1.105-1.712 1.65-.144.137-.323.24-.486.359h-.31c-.161-.12-.337-.224-.482-.36-.442-.415-.873-.842-1.317-1.273-.43.418-.841.826-1.264 1.223-.161.151-.35.274-.525.41h-.31c-.191-.14-.396-.267-.569-.427-.418-.388-.822-.792-1.24-1.198-.07.063-.125.108-.175.158-.391.378-.776.763-1.175 1.132-.14.13-.315.225-.475.336h-.31c-.17-.131-.352-.252-.51-.396-.293-.27-.577-.55-.86-.832-.135-.136-.26-.283-.394-.431-.478.463-.904.888-1.343 1.3-.145.135-.32.24-.482.359h-.31V22zM1.33 1.297V11.501c0 2.492 0 4.985.002 7.477 0 .082-.005.193.044.242.41.41.83.808 1.257 1.219l1.372-1.331c.414-.4.748-.401 1.16-.002l1.414 1.376c.054-.081.088-.157.144-.212.425-.418.851-.834 1.284-1.246.3-.286.684-.285.985 0 .328.31.652.624.97.942.16.157.304.328.506.549.085-.122.122-.196.178-.252.415-.407.831-.814 1.252-1.215.333-.318.702-.318 1.032-.001.42.402.839.807 1.252 1.216.06.06.089.147.14.235.447-.435.819-.805 1.2-1.163a.445.445 0 00.15-.357c-.004-5.815-.004-11.63-.004-17.444v-.237H1.332z'
        fill='currentColor'
      />
      <g clipPath='url(#Shipment_svg__clip0_8428_3)'>
        <path
          d='M13.493 9.586h-2.04V8.451 5.504c0-.353-.15-.504-.498-.504H6.056c-.366 0-.508.146-.508.521v4.065H3.504c-.353 0-.504.154-.504.515v4.383c0 .367.145.516.5.516h10.003c.348 0 .497-.151.497-.505v-4.397c0-.359-.152-.512-.507-.512zm-6.865 1.078c-.02.022-.179.022-.306.022h-.92c-.096 0-.117-.012-.121-.015-.002-.003-.013-.022-.013-.118v-.133h1.37c.006.115.007.224-.01.243zm.814-.25h.643v3.758H3.82v-3.749h.64v.155c.003.278.09.512.252.676.162.165.391.252.663.253l.635.001.542-.001c.521-.002.88-.361.89-.893.002-.065.001-.13.001-.2zm1.474.006h.64v.15c.004.577.356.936.919.937l.593.001h.556c.272-.001.5-.089.663-.253.161-.164.249-.398.252-.676v-.165h.639v3.758H8.915V10.42zm2.804.25c-.01.008-.05.018-.188.018l-.107-.001a24.858 24.858 0 00-.32-.002h-.611c-.096 0-.116-.011-.118-.014-.002-.002-.014-.022-.014-.12v-.129h1.369c.004.116.006.226-.01.248h-.001zM9.184 6.03c-.001.052-.012.062-.063.062H7.879c-.054 0-.062-.013-.064-.06V5.83h1.37l-.001.2zM6.37 5.83h.639v.15c.004.575.356.936.917.94a104.448 104.448 0 001.15 0c.559-.004.91-.36.915-.929V5.83h.643v3.748H6.368V5.83h.001z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='Shipment_svg__clip0_8428_3'>
          <path fill='#fff' transform='translate(3 5)' d='M0 0h11v10H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgShipment;
