import { SwapVerticalCircleOutlined } from '@mui/icons-material';
import { Card, Box, Typography, Switch, IconButton } from '@mui/material';
import { CaskIcon, Liquid, Monitoring, QrScan3, Reports, Shipments, Warehousing } from '@pw/components/icons';
import TasksIcon from '@pw/components/icons/Tasks';
import React from 'react';

const widgetLibrary = [
  {
    name: 'Total Asset Count',
    component: 'LayoutAssets',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <QrScan3 height={24} width={24} />,
    id: 1,
  },
  {
    name: 'Asset Count by Type',
    component: 'LayoutAssetByTypeCountWidget',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <QrScan3 height={24} width={24} />,
    id: 2,
  },
  {
    name: 'Sensor Data Monitoring',
    component: 'LayoutMonitoring',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <Monitoring height={24} width={24} />,
    id: 3,
  },
  {
    name: 'Liquid by SKU',
    component: 'LayoutLiquid',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <Liquid height={24} width={24} />,
    id: 4,
  },
  {
    name: 'My Tasks',
    component: 'LayoutActivity',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <TasksIcon height={24} width={24} />,
    id: 5,
  },
  {
    name: 'Sales Orders',
    component: 'LayoutSalesOrders',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <Shipments height={24} width={24} />,
    id: 6,
  },
  {
    name: 'Purchase Orders',
    component: 'LayoutPurchaseOrders',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <SwapVerticalCircleOutlined height={24} width={24} />,
    id: 7,
  },
  {
    name: 'Invoices',
    component: 'LayoutInvoices',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <SwapVerticalCircleOutlined height={24} width={24} />,
    id: 8,
  },
  {
    name: 'Duty Payments',
    component: 'LayoutDutyPayments',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <Reports height={24} width={24} />,
    id: 9,
  },
  {
    name: 'Duty Submissions',
    component: 'LayoutDutySubmissions',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <Reports height={24} width={24} />,
    id: 10,
  },
  {
    name: 'Deliveries',
    component: 'LayoutDeliveries',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <Warehousing height={24} width={24} />,
    id: 11,
  },
  {
    name: 'Casks',
    component: 'LayoutAssetCasks',
    targetTabsetId: 'MONITORING_LAYOUT_TABSET',
    icon: <CaskIcon height={24} width={24} />,
    id: 12,
  },
];

const styles = {
  widgetCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '41px',
    boxShadow: '0px 3px 3px 0px #00000012',
    borderRadius: '5px',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  switch: {
    width: '57px',
    '& .MuiSwitch-thumb': {
      width: '9px',
      height: '9px',
      backgroundColor: '#fff',
    },
    '& .MuiSwitch-switchBase': {
      padding: '4px',
      top: '11px',
      left: '11px',
    },
    '& .MuiSwitch-track': {
      borderRadius: '10px',
      height: '15px',
    },
  },
  switchChecked: {
    backgroundColor: '#1976d2',
  },
  switchUnchecked: {
    backgroundColor: '#ffffff',
    border: 'solid 0.2px',
    opacity: '.3',
  },
  typography: {
    opacity: '1',
  },
  typographyUnchecked: {
    opacity: '0.3',
  },
  iconButton: {
    opacity: '1',
  },
  iconButtonUnchecked: {
    opacity: '0.3',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      lg: '1fr 1fr',
      xl: '1fr 1fr 1fr',
    },
    margin: 'auto',
    columnGap: '40px',
    rowGap: '7px',
    width: '100%',
    padding: '16px',
  },
};

const WidgetCard = ({ widget, checked, onChange }) => {
  return (
    <Card sx={styles.widgetCard}>
      {/* Left Side */}
      <Box sx={styles.leftSide}>
        <Switch
          disabled={widget.id === 2 || widget.id === 3}
          checked={checked}
          onChange={onChange}
          color='primary'
          sx={{
            ...styles.switch,
            '& .MuiSwitch-track': checked
              ? styles.switchChecked
              : styles.switchUnchecked,
          }}
        />
        <Typography
          variant='body2'
          sx={checked ? styles.typography : styles.typographyUnchecked}
        >
          {widget.name}
        </Typography>
      </Box>

      {/* Right Side */}
      <IconButton
        size='small'
        sx={checked ? styles.iconButton : styles.iconButtonUnchecked}
      >
        {widget.icon}
      </IconButton>
    </Card>
  );
};

const WidgetLibraryContainer = ({ selectedWidgets, handleWidgetSelect }) => {
  const handleSwitchChange = (widget) => {
    handleWidgetSelect(widget);
  };

  return (
    <Box sx={styles.container}>
      {widgetLibrary.map((widget, index) => (
        <Box key={index}>
          <WidgetCard
            widget={widget}
            checked={selectedWidgets.includes(widget.id) || widget.id === 3}
            // checked={!selectedWidgets.includes(widget.id) || widget.id === 3}
            onChange={() => handleSwitchChange(widget)}
          />
        </Box>
      ))}
    </Box>
  );
};

export default WidgetLibraryContainer;
