import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body2 } from '@pw/components/Typography';
import formatNumber from '@pw/utilities/formatNumber';

function LayoutStatsWidget({ primary, secondary, label, border=true}) {
  return (
<Stack spacing={1} className='layoutWidget stats'>
			<Box className={`widget-content stats-content ${!border ? 'line' : ''}`} >
				<Body2 className='widget-label'>{label}</Body2>
				<FlexBox style={{ width: 'auto' }}>
					<Box className='widget-text'>
						<Body2 className='widget-text-secondary'>{`${formatNumber(secondary.value)} ${secondary.unit}`}</Body2>/
						<Body2 className='widget-text-primary'>{`${formatNumber(primary.value)} ${primary.unit}`}</Body2>
					</Box>
				</FlexBox>
			</Box>
		</Stack>
  );
}

export default LayoutStatsWidget;
