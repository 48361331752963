import { UNIT } from '@pw/consts/units';
import * as yup from 'yup';

function fields(level, converter) {
	return {
		bl: [
			converter.from(level?.bl, UNIT.BL)?.toFixed(3) ?? '',
			yup.string(),
		],
		la: [
			converter.from(level?.la, UNIT.LA)?.toFixed(3) ?? '',
			yup.string(),
		],
		abv: [
			converter.from(level?.abv, UNIT.ALC)?.toFixed(2) ?? '',
			yup.string(),
		],
		tcf: [
			level?.tcf ?? '1.000',
			yup.string(),
		],
		action_date: [
			level?.action_date ?? level?.filled_date,
			yup.number(),
		],
	};
}

export default fields;
