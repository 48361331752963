import { Box, Stack } from '@mui/material';
import { Body2, H5 } from '@pw/components/Typography';
import { useMemo } from 'react';

const styles = {
  colorBox: {
    width: '15px',
    height: '14px',
    borderRadius: '2px',
    marginRight: '12px',
  },
  nameText: {
    fontSize: '13px',
    fontWeight: '700',
    color: '#333333',
  },
  countText: {
    fontSize: '13px',
    fontWeight: '400',
    color: '#333333',
  },
};

const LayoutAssetCountsWidget = ({data, title}) => {
    const { total_pallets = 0, total_ibcs = 0, total_casks = 0 } = data;
  
    const assets = useMemo(
      () => [
        {
          id: 0,
          label: 'Casks Produced',
          value: Number(total_casks),
          color: '#6B57D2',
        },
        {
          id: 1,
          label: 'Pallets',
          value: Number(total_pallets),
          color: '#C7DA65',
        },
        {
          id: 2,
          label: 'IBC',
          value: Number(total_ibcs),
          color: '#C753A8',
        },
      ],
      [total_casks, total_ibcs, total_pallets],
    );
  return (
    <Stack className='content-section'>
    <H5 className='content-section-title'>{title}</H5>
    <Stack className='content-section-content line'>
    <Box className='widget-list'>
      {assets.map((item, index) => (
        <Box
         className='listItem'
         key={index}
        >
          <Box className='listContent'>
          <Stack direction="row" sx={{alignItems:"center"}} justifyContent='space-between'>
            <Stack direction="row" alignItems={"center"}>
              <Box sx={{ ...styles.colorBox, backgroundColor: item.color }} />
              <Body2 sx={styles.nameText}>{item.label}:</Body2>
            </Stack >
            <Stack>
            <Body2 sx={styles.countText}>{item.value}</Body2>
            </Stack>
            </Stack>
          </Box>
        </Box>
      ))}
    </Box>      
    </Stack>
  </Stack>
  );
};

export default LayoutAssetCountsWidget;
