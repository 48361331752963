import { Box, Collapse, IconButton, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FormikDatePicker, FormikForm } from '@pw/components/Forms/FormikForm';
import { Filter, Monitoring } from '@pw/components/icons';
import SvgAirQuality from '@pw/components/icons/AirQuality';
import SvgForwardarrow from '@pw/components/icons/Forwardarrow';
import SvgHumidity from '@pw/components/icons/Humidity';
import SvgPower from '@pw/components/icons/Power';
import Temperature from '@pw/components/icons/Temperature';
import { Body1, Body3, H5 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { useStatsLazyQuery } from '@pw/utilities/hooks/service/useStatsQuery';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

const LayoutMonitoring = ({ currentWidth, currentHeight }) => {
  const [fetchWidgetData, { data: widgetData, isLoading, refetch }] =
    useStatsLazyQuery();
  const data = widgetData?.widgets[0].data;
  const isSmallScreen = currentWidth < 600;
  const isMediumScreen = currentWidth >= 600 && currentWidth < 1024;

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  useEffect(() => {
    fetchWidgetData({ widget_ids: [3] });
  }, []);

  const changeSet = {
    start_date: [startDate, yup.number()],
    end_date: [endDate, yup.number()],
  };

  const onFilter = (values) => {
    const { start_date, end_date } = values;
    refetch({ start_date, end_date, widget_ids: [3]}).then((res) => {
      if (res) {
        setStartDate(start_date);
        setEndDate(end_date);
      }
    });
  };

  const styles = {
    filterButtonWrapper: {
      width: '50px',
      height: '42px',
      position: 'absolute',
      right: '4px',
      borderRadius: '5px',
      backgroundColor: '#C6C6C6',
    },
    updateButton: {
      width: isSmallScreen ? '100px' : 'calc(100vw * (250 / 1029))',
      height: isSmallScreen ? '40px' : '56px',
      borderRadius: '5px',
      backgroundColor: '#52535B',
      color: '#fff',
      fontSize: isSmallScreen ? '12px' : '14px',
      lineHeight: isSmallScreen ? '1.2' : '26px',
      fontWeight: '600',
    },
  };
  return (
    <Box className='content-row' id='LayoutMonitoring'>
      <Box className='content-header'>
        <Box className='title-box'>
          <Monitoring height={43} width={29} />
          <Box>
            <H5>Monitoring</H5>
            {!!startDate && !!endDate && (
              <Body3 sx={{ alignItems: 'center' }}>
                <Bolder style={{ paddingLeft: '0.25ch', fontWeight: '600' }}>
                  fr.
                </Bolder>
                {new Date(startDate).toLocaleDateString()}
                <SvgForwardarrow
                  width={17}
                  height={7}
                  style={{
                    marginLeft: '20px',
                    marginRight: '10px',
                  }}
                />
                <Bolder
                  style={{
                    paddingLeft: '0.25ch',
                    fontWeight: '600',
                  }}
                >
                  to.
                </Bolder>
                {new Date(endDate).toLocaleDateString()}
              </Body3>
            )}
          </Box>
        </Box>
        <Stack
          direction='row'
          justifyContent={'space-between'}
          alignItems={'center'}
          position={'relative'}
          padding={'10px'}
          gap={'10px'}
        >
          {isVisibleFilter && <Box sx={styles.filterButtonWrapper}></Box>}
          <IconCircleButton
            onClick={() => setIsVisibleFilter(!isVisibleFilter)}
          >
            <Filter color={isVisibleFilter ? '#fff' : '#7C7C7C'} />
          </IconCircleButton>
        </Stack>
      </Box>

      <Collapse in={isVisibleFilter}>
        <FormikForm changeSet={changeSet} onSubmit={onFilter}>
          <Stack
            gap={1.5}
            py={2}
            pl={1.5}
            pr={2}
            sx={{ backgroundColor: '#C6C6C6', borderRadius: '5px' }}
          >
            <Box display='flex' flexBasis='row' gap={0}>
              <FormikDatePicker
                name='start_date'
                label='From'
                fullWidth
                backgroundColor='#fff'
              />
              <FormikDatePicker
                name='end_date'
                label='To'
                fullWidth
                backgroundColor='#fff'
              />
              <FilledButton
                sx={{ marginLeft: '15px', ...styles.updateButton }}
                type='submit'
                // size='small'
                label='Update data'
                loading={isLoading}
              />
            </Box>
          </Stack>
        </FormikForm>
      </Collapse>

      <Box className='content-body content-4col'>
        <SensorDataWidget
          title='Temperature'
          value={20.7}
          unit='°C'
          Icon={Temperature}
        />
        <SensorDataWidget
          title='Humidity'
          value={40.2}
          unit='%'
          Icon={SvgHumidity}
        />
        <SensorDataWidget
          title='Air Quality'
          value={1}
          unit='%'
          Icon={SvgAirQuality}
        />
        <SensorDataWidget title='Power' value={100} unit='%' Icon={SvgPower} />
      </Box>
    </Box>
  );
};

export default LayoutMonitoring;

export const SensorDataWidget = ({ title, Icon, value, unit }) => {
  return (
    <Stack className='content-section'>
      <H5 className='content-section-title'>{title}</H5>
      <Stack className='content-section-content line '>
        <Stack direction={'row'}>
          <IconButton sx={{ marginRight: '20px' }}>
            <Icon height={43} width={24} />
          </IconButton>
          <Body1
            sx={{
              fontWeight: '400',
              fontSize: '39px',
              lineHeight: '58px',
              color: '#393C3C',
            }}
          >
            {value} {unit}
          </Body1>
        </Stack>
      </Stack>
    </Stack>
  );
};
