import {
  LogoutOutlined,
  RefreshOutlined,
  SupervisedUserCircle,
  SwapVerticalCircleOutlined,
  SwitchAccount,
  WidgetsOutlined
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@pw/components/Avatar';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { ShareWithPartnerDialogQR } from '@pw/components/Company/admin/Partners/components/ShareWithPartnerDialogQR';
import Admin from '@pw/components/icons/Admin';
import Liquid from '@pw/components/icons/Liquid';
import Overview from '@pw/components/icons/Overview';
import Production from '@pw/components/icons/Production';
import QrScan3 from '@pw/components/icons/QrScan3';
import Reports from '@pw/components/icons/Reports';
import Shipments from '@pw/components/icons/Shipments';
import Warehousing from '@pw/components/icons/Warehousing';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Menu from '@pw/components/Layout/SideBar/Menu/Menu';
import MenuGroup from '@pw/components/Layout/SideBar/Menu/MenuGroup';
import SwitchCompanyModal from '@pw/components/Layout/SideBar/SwitchCompanyModal';
import { CountdownIndicator } from '@pw/components/Timer/Countdown';
import { Body2, Body3, H4, Overline } from '@pw/components/Typography';
import { COMPANY_TYPE, COMPANY_TYPE_REVERSE } from '@pw/consts/company';
import { PermissionKey, PermissionType } from '@pw/consts/permissions';
import CountdownContext from '@pw/context/CountdownContext';
import CountdownProvider from '@pw/providers/CountdownProvider';
import TimerProvider from '@pw/providers/TimerProvider';
import { useOnlineHook } from '@pw/redux/containers/App/hooks';
import {
  useAccountName,
  useAccountTokenActive,
  useCompanyId,
  useCurrentCompany
} from '@pw/redux/containers/User/hooks';
import { refreshTokenThunk } from '@pw/redux/thunks/user';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import usePermissions from '@pw/utilities/hooks/logic/usePermissions';
import isEmpty from 'lodash.isempty';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { NetworkSwitchMobile } from '../AppHeader/NetworkSwitch';
import { MenuHeader } from './Menu/MenuHeader';

function ActiveMenu({ active, companyName, companyType, companyFeatures }) {
  const { t } = useTranslation();
  const hasPermission = usePermissions();
  // const { online } = useOnlineHook();
  const online = true;

  const [switchCompany, setSwitchCompany] = useState(false);

  const features =
    companyFeatures?.warehousing ||
    companyFeatures?.production ||
    companyFeatures?.bottling;
  const warehousing = companyFeatures?.warehousing ?? false;
  const production = companyFeatures?.production ?? false;

  const menu = useMemo(
    () => ({
      overview: [
        { tag: '/app/overview', label: 'Dashboard', disabled: !online },
        { tag: '/app/tasks', label: 'Tasks', hidden: !active || !features },
        { tag: '/app/sync', label: 'Sync', hidden: !active || !features },
        { tag: '/app/explorer', label: 'Explorer', hidden: !active },
      ],
      assets: [
        {
          tag: '/app/cask',
          label: 'Casks',
          disabled: !hasPermission(
            PermissionKey.CASK,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/container',
          label: 'Containers',
          hidden:
            !hasPermission(
              PermissionKey.CONTAINER,
              Object.values(PermissionType),
            ) || !warehousing,
        },
        {
          tag: '/app/tank',
          label: 'Filling Tanks',
          hidden:
            !hasPermission(
              PermissionKey.FILLING_TANK,
              Object.values(PermissionType),
            ) || !warehousing,
        },
        {
          tag: '/app/ibc',
          label: t('IBCs'),
          disabled: !hasPermission(
            PermissionKey.IBC,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/machine',
          label: 'Machine',
          hidden:
            !hasPermission(
              PermissionKey.MACHINE,
              Object.values(PermissionType),
            ) || !warehousing,
        },
        {
          tag: '/app/pallet',
          label: 'Pallets',
          hidden:
            !hasPermission(
              PermissionKey.PALLET,
              Object.values(PermissionType),
            ) || !warehousing,
        },
        {
          tag: '/app/tanker',
          label: 'Tankers',
          hidden:
            !hasPermission(
              PermissionKey.TANKER,
              Object.values(PermissionType),
            ) || !warehousing,
        },
      ],
      warehousing: [
        {
          tag: '/app/inventory-audit',
          label: 'Audit',
          disabled: !hasPermission(
            PermissionKey.INVENTORY_AUDIT,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/delivery',
          label: 'Delivery',
          disabled: !hasPermission(
            PermissionKey.DELIVERY,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/maintenance',
          label: 'Maintenance',
          disabled: !hasPermission(
            PermissionKey.MAINTENANCE,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/pack',
          label: 'Pack',
          disabled: !hasPermission(
            PermissionKey.PACK,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/restock',
          label: 'Restock',
          disabled: !hasPermission(
            PermissionKey.RESTOCK,
            Object.values(PermissionType),
          ),
        },
      ],
      ownership: [
        {
          tag: '/app/change-ownership',
          label: 'Transfer',
          disabled: !hasPermission(
            PermissionKey.CHANGE_OWNERSHIP,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/ownership-audit',
          label: 'Audit',
          disabled: !hasPermission(
            PermissionKey.OWNERSHIP_AUDIT,
            Object.values(PermissionType),
          ),
        },
      ],
      liquid: [
        {
          tag: '/app/regauge',
          label: 'Regauge',
          disabled: !hasPermission(
            PermissionKey.REGAUGE,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/sample',
          label: 'Sample',
          disabled: !hasPermission(
            PermissionKey.SAMPLE,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/transfer',
          label: 'Transfer',
          disabled:
            !hasPermission(
              PermissionKey.TRANSFER,
              Object.values(PermissionType),
            ) || !features,
        },
      ],
      production: [
        { tag: '/app/production-schedule', label: 'Schedule' },
      ],
      fulfilment: [
        {
          tag: '/app/sales-order',
          label: 'Sales Order',
          disabled: !hasPermission(
            PermissionKey.SALES_ORDER,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/ship',
          label: 'Shipment',
          disabled: !hasPermission(
            PermissionKey.SHIP,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/sales-return',
          label: 'Sales Return',
          disabled: !hasPermission(
            PermissionKey.SALES_RETURN,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/dispatch',
          label: 'Dispatch',
          disabled: !hasPermission(
            PermissionKey.DISPATCH,
            Object.values(PermissionType),
          ),
        },
      ],
      tax: [
        {
          tag: '/app/duty-transfer',
          label: 'Payment',
          disabled: !online,
          hidden: !hasPermission(
            PermissionKey.DUTY_TRANSFER,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/duty-submission',
          label: 'Submission',
          disabled: !online,
          hidden: !hasPermission(
            PermissionKey.DUTY_SUBMISSION,
            Object.values(PermissionType),
          ),
        },
      ],
      purchase_orders: [
        {
          tag: '/app/purchase-order',
          label: 'Purchase Order',
          disabled: !online,
          hidden: !hasPermission(
            PermissionKey.PURCHASE_ORDER,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/purchase-return',
          label: 'Purchase Return',
          disabled: !online,
          hidden: !hasPermission(
            PermissionKey.PURCHASE_RETURN,
            Object.values(PermissionType),
          ),
        },
      ],
      invoicing: [
        {
          tag: '/app/invoice',
          label: 'Invoice',
          disabled: !online,
          hidden: !hasPermission(
            PermissionKey.INVOICE,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/invoice-payment',
          label: 'Payment',
          disabled: !online,
          hidden: !hasPermission(
            PermissionKey.INVOICE_PAYMENT,
            Object.values(PermissionType),
          ),
        },
        {
          tag: '/app/expense-report',
          label: 'Expense Report',
          disabled: !online,
          hidden: !hasPermission(
            PermissionKey.EXPENSE_REPORT,
            Object.values(PermissionType),
          ),
        },
      ],
      crm: [
        // { tag: '/admin/owners', label: 'Owners', disabled: !online },
        // { tag: '/admin/partners', label: 'Partners', disabled: !online },
        { tag: '/crm/contacts', label: 'Contacts', disabled: !online },
      ],
      things: [
        {
          disabled: !online,
          tag: '/admin/things/cons',
          label: 'Consumables',
        },
        {
          disabled: !online,
          tag: '/admin/things/exp',
          label: 'Expiring Materials',
        },
        {
          disabled: !online,
          tag: '/admin/things/finished',
          label: 'Finished Goods',
        },
        {
          disabled: !online,
          tag: '/admin/things/liquid',
          label: 'Liquids',
        },
        {
          disabled: !online,
          tag: '/admin/things/raw',
          label: 'Raw Material',
        },
        {
          disabled: !online,
          tag: '/admin/things/services',
          label: 'Services',
        },
        {
          disabled: !online,
          tag: '/admin/things/tracked',
          label: 'Tracked Assets',
        },
        {
          disabled: !online,
          tag: '/admin/things/waste',
          label: 'Waste Material',
        },
        {
          disabled: !online,
          tag: '/admin/things/expenses',
          label: 'Expenses',
        },
        {
          disabled: !online,
          tag: '/admin/things/parts',
          label: 'Parts',
        },
      ],
      // settings: [
      //   {
      //     hidden:
      //       !hasPermission(
      //         PermissionKey.FACILITY,
      //         Object.values(PermissionType),
      //       ) || !features,
      //     disabled: !online,
      //     tag: '/admin/facilities',
      //     label: 'Facilities',
      //   },
      //   {
      //     hidden:
      //       !hasPermission(
      //         PermissionKey.POLICY,
      //         Object.values(PermissionType),
      //       ) || !features,
      //     disabled: !online,
      //     tag: '/admin/policies',
      //     label: 'Policies',
      //   },
      //   {
      //     hidden:
      //       !hasPermission(
      //         PermissionKey.SPECIFICATION,
      //         Object.values(PermissionType),
      //       ) || !features,
      //     disabled: !online,
      //     tag: '/admin/specifications',
      //     label: 'Specifications',
      //   },
      //   {
      //     tag: '/admin/sensors',
      //     label: 'Sensors',
      //     disabled: !online,
      //     hidden:
      //       !hasPermission(
      //         PermissionKey.SENSOR,
      //         Object.values(PermissionType),
      //       ) || !features,
      //   },
      //   {
      //     tag: '/admin/plant',
      //     label: 'Plant Configuration',
      //     disabled: !online,
      //     hidden:
      //       !hasPermission(
      //         PermissionKey.PLANT,
      //         Object.values(PermissionType),
      //       ) || !features,
      //   },
      //   {
      //     hidden:
      //       !hasPermission(
      //         PermissionKey.PRICE_CURVE,
      //         Object.values(PermissionType),
      //       ) || !features,
      //     disabled: !online,
      //     tag: '/admin/price_curves',
      //     label: 'Price Curves',
      //   },
      //   // {
      //   // 	tag: '/admin/contracts',
      //   // 	label: 'Contracts',
      //   // 	disabled: true,
      //   // },
      // ],
      // users: [
      //   {
      //     disabled: !online,
      //     tag: '/admin/users',
      //     label: 'Users',
      //   },
      //   {
      //     disabled: !online,
      //     tag: '/admin/teams',
      //     label: 'Teams',
      //   },
      //   {
      //     disabled: !online,
      //     tag: '/admin/roles',
      //     label: 'Roles',
      //   },
      // ],
    }),
    [active, online],
  );

  const [showCompanyQr, setShowCompanyQr] = useState(false);

  return (
    <>
      <Stack spacing={0} className='company-header'>
        <Box className='company-name'>
          <Box className='avatar'>
            <Avatar alt={companyName} onClick={() => setShowCompanyQr(true)} />
          </Box>
          <Box className='label'>
            <Stack className='label-content' spacing={0.25}>
              <H4 className='text'>{companyName}</H4>
              <Overline>{COMPANY_TYPE_REVERSE[companyType]}</Overline>
            </Stack>
            {online && (
              <IconCircleButton
                onClick={() => setSwitchCompany(true)}
                size='small'
              >
                <SwitchAccount
                  sx={{ width: '16px', height: 'auto', color: '#C6DB5C' }}
                />
              </IconCircleButton>
            )}
          </Box>
        </Box>
      </Stack>
      {showCompanyQr && (
        <ShareWithPartnerDialogQR
          open={showCompanyQr}
          onClose={() => setShowCompanyQr(false)}
        />
      )}
      <Menu>
        <NetworkSwitchMobile />
        <MenuGroup
          title='Overview'
          show
          icon={<Overview />}
          options={menu.overview}
        />

        <MenuGroup
          title='Assets'
          show={active}
          icon={<QrScan3 />}
          options={menu.assets}
        />

        <MenuGroup
          title='Warehousing'
          show={active && warehousing}
          icon={<Warehousing />}
          options={menu.warehousing}
        />

        <MenuGroup
          title='Ownership'
          show={active && companyType === COMPANY_TYPE.BROKER}
          icon={<Warehousing />}
          options={menu.ownership}
        />

        <MenuGroup
          title='Liquid Operations'
          show={active}
          icon={<Liquid />}
          options={menu.liquid}
        />

        <MenuGroup
          title='Production'
          show={active && production}
          icon={<Production />}
          options={menu.production}
        />

        <MenuGroup
          title='Fulfilment'
          show={active && warehousing}
          icon={<Shipments />}
          options={menu.fulfilment}
        />

        <MenuGroup
          title='Tax'
          show={active && features}
          icon={<Reports />}
          options={menu.tax}
        />

        <MenuGroup
          title='Purchase Orders'
          show={active && features}
          icon={<SwapVerticalCircleOutlined />}
          options={menu.purchase_orders}
        />

        <MenuGroup
          title='Invoices'
          show={active && features}
          icon={<SwapVerticalCircleOutlined />}
          options={menu.invoicing}
        />

        <MenuGroup
          title='Ownership'
          show={active && warehousing}
          icon={<Warehousing />}
          options={menu.ownership}
        />

        {hasPermission(PermissionKey.CRM, Object.values(PermissionType)) && (
          <MenuGroup
            title='CRM'
            show={
              active &&
              hasPermission(PermissionKey.CRM, Object.values(PermissionType))
            }
            icon={<SupervisedUserCircle />}
            options={menu.crm}
          />
        )}

        {hasPermission(PermissionKey.THING, Object.values(PermissionType)) && (
          <MenuGroup
            title='Things'
            show={
              active &&
              hasPermission(
                PermissionKey.THING,
                Object.values(PermissionType),
              ) &&
              features
            }
            icon={<WidgetsOutlined />}
            options={menu.things}
          />
        )}

        {/* <MenuGroup
          title='Users'
          show={
            active &&
            hasPermission(PermissionKey.USER, Object.values(PermissionType))
          }
          icon={<Group />}
          options={menu.users}
        /> */}

        {/*<MenuGroup*/}
        {/*  title='Company'*/}
        {/*  show={*/}
        {/*    active &&*/}
        {/*    hasPermission(PermissionKey.CRM, Object.values(PermissionType))*/}
        {/*  }*/}
        {/*  icon={<Sku />}*/}
        {/*  options={menu.settings}*/}
        {/*/>*/}
      </Menu>
      {switchCompany && (
        <SwitchCompanyModal
          open={switchCompany}
          onClose={() => setSwitchCompany(false)}
        />
      )}
    </>
  );
}

function RefreshToken() {
  const dispatch = useDispatch();
  const { done } = useContext(CountdownContext);
  const { i18n } = useTranslation();

  const handleRefresh = () => {
    dispatch(refreshTokenThunk({ i18n }))
      .unwrap()
      .then((response) => {
        console.log('Refresh Token Success', response);
      });
  };

  return (
    <>
      {!done && (
        <FlexBox>
          <Body3>
            <CountdownIndicator />
          </Body3>
          <IconCircleButton
            onClick={handleRefresh}
            size='small'
            className='refresh-button'
          >
            <RefreshOutlined sx={{ width: '16px', height: '16px' }} />
          </IconCircleButton>
        </FlexBox>
      )}
      {done && (
        <Body3>
          <Link to='/signin'>Login</Link>
        </Body3>
      )}
    </>
  );
}

function AccountDisplay() {
  const currentUser = useAccountName();
  const authTokenStart = useAccountTokenActive();
  const endTime = useMemo(
    () => new Date((authTokenStart ?? 0) * 1000 + 10 * 60 * 60 * 1000),
    [authTokenStart],
  );

  console.log('Auth Token Start: ', authTokenStart, endTime);

  return (
    <Box>
      <Stack spacing={0} className='account-header'>
        <Box className='account-name'>
          <Box className='avatar'>
            <Avatar alt={currentUser} />
          </Box>
          <Box className='label'>
            <Stack className='label-content' spacing={0.25}>
              <H4 className='text'>{currentUser}</H4>

              <TimerProvider>
                <CountdownProvider end={endTime}>
                  <RefreshToken />
                </CountdownProvider>
              </TimerProvider>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}

function AccountMenu() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { online } = useOnlineHook();
  const company_id = useCompanyId();

  const menu = {
    account: [
      { tag: '/account/settings', label: 'Settings', disabled: !online },
      {
        tag: `/account/companies/${company_id}`,
        label: 'Companies',
        disabled: !online,
      },
    ],
  };

  return (
    <Menu>
      <MenuGroup title='Account' show icon={<Admin />} options={menu.account} />
      {isMobile && (
        <Stack className='menu-group' spacing={0.15}>
          <MenuHeader
            icon={<LogoutOutlined />}
            label='Logout'
            xs={false}
            onClick={() => navigate('/logout')}
          />
        </Stack>
      )}
    </Menu>
  );
}

function SideBar({ active, companyName, toggleMenu }) {
  const currentCompany = useCurrentCompany();
  return (
    <Stack className={`menu ${toggleMenu ? 'menu-show' : 'menu-hide'}`}>
      <Stack className='menu-box'>
        <AccountDisplay />
        {!isEmpty(companyName) && (
          <ActiveMenu
            active={active}
            companyName={companyName}
            companyType={currentCompany?.company_type ?? COMPANY_TYPE.VIEW}
            companyFeatures={currentCompany?.features}
          />
        )}
        <AccountMenu />
      </Stack>
      <Stack className='menu-footer'>
        <Body2 className='copy-right'>
          <strong>v1.2.3&nbsp;</strong>
          {`© ${new Date().getFullYear()} CaskStore AG. ALL RIGHTS RESERVED`}
        </Body2>
      </Stack>
    </Stack>
  );
}

export default SideBar;
