export const DESIGN_TYPE = {
  DISTILLERY: "distillery",
  BOTTLING_LINE: "bottling_line",
  LIQUID: "liquid",
  RELEASE: "release",
  WORKFLOW: "workflow",
};

export const DESIGN_VIEW_MODE = {
  DESIGN: 'design',   // Design view
  PROCESS: 'process', // Process view to collect/enter data
  VIEW: 'view',       // View only
};

export const DESIGN_NODE_TYPE = {
  MACHINE: 'machine',
  OBSERVATION: 'observation',
  POLICY: 'policy',
  MATERIAL: 'material',
  CALCULATIONS: 'calculations',
};
