import { Stack } from '@mui/material';
import {
  FormikDatePicker,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormikContext from '@pw/context/FormikContext';
import { useContext } from 'react';

export default function RequestID({ label }) {
  const { edit } = useContext(FormikContext);
  return (
    <Stack spacing='1.5rem'>
      <FormikTextField
        name='name'
        label={label}
        fullWidth
        disabled={edit}
        required
        autoFocus
      />
      <FlexBox>
        <FormikDatePicker name='created' label='Create Date' fullWidth />
        <FormikDatePicker name='due' label='Due Date' fullWidth />
      </FlexBox>
    </Stack>
  );
}
