import { Stack } from '@mui/material';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';

function CompanyCompliance({ name = 'compliance' }) {
  const { values } = useFormikContext();

  const isUK = useMemo(() => {
    return ['GB', 'GB-SCT', 'GB-NIR', 'GB-ENG', 'GB-WLS'].includes(
      values?.address?.country,
    );
  }, [values?.address?.country]);

  const isUS = useMemo(() => {
    return ['US', 'PR'].includes(
      values?.address?.country,
    );
  }, [values?.address?.country]);

  return (
    <FormikProvider path={name}>
      <Stack spacing="1.5rem">
        <FormikTextField name='registration_id' label='Registration id' />
        {isUS && (
          <>
            <FormikTextField name='ein' label='EIN' />
            <FormikTextField name='tin' label='TIN' />
          </>
        )}
        {/* Use translation for US: DSP, for UK/Ireland: Excise ID */}
        <FormikTextField name='bond_id' label='DSP/Excise id' />
        {isUK && (
          <FormikTextField name='wowgr' label='WOWGR' />
        )}
      </Stack>
    </FormikProvider>
  );
}

export default CompanyCompliance;
