// Icons
// Operations (TODO)
import { HelpCenter } from '@mui/icons-material';
import SvgBlendingTank from '@pw/components/icons/BlendingTank';
import SvgBottleCapper from '@pw/components/icons/BottleCapper';
import SvgBottleFiller from '@pw/components/icons/BottleFiller';
import SvgBottleFinishing from '@pw/components/icons/BottleFinishing';
import SvgBottleLabeller from '@pw/components/icons/BottleLabeller';
import SvgBottlePacking from '@pw/components/icons/BottlePacking';
import SvgBottleWasher from '@pw/components/icons/BottleWasher';
import SvgGrainBin from '@pw/components/icons/GrainBin';
import SvgLineTank from '@pw/components/icons/LineTank';
import SvgQrScan3 from '@pw/components/icons/QrScan3';
import { ASSET_TYPES, MACHINE_TYPES } from '@pw/consts/asset';
import { DESIGN_NODE_TYPE, DESIGN_TYPE } from '@pw/consts/designs';
import AntiMicrobial from '../icons/AntiMicrobial';
import BeerWell from '../icons/BeerWell';
import Brix from '../icons/Brix';
import Cask from '../icons/Cask2';
import ColumnStill from '../icons/ColumnStill';

// Operations
import Combine from '../icons/Combine';
import ContinuousMill from '../icons/ContinuousMill';
import DeFoamer from '../icons/DeFoamer';
import Enzyme from '../icons/Enzyme';
import Feints from '../icons/Feints';
// Ingredients
import Grain from '../icons/Grain';
import HammerMill from '../icons/HammerMill';
import KilnOven from '../icons/KilnOven';
import LiquidSolidSeparator from '../icons/LiquidSolidSeparator';
import Mash from '../icons/Mash';
import MashCooler from '../icons/MashCooler';
import Nutrients from '../icons/Nutrients';
import OG_SG from '../icons/OG_SG';
import Peat from '../icons/Peat';
// Observations
import Ph from '../icons/Ph';
import Policy from '../icons/Policy';
import PotStill from '../icons/PotStill';
import Receiver from '../icons/Receiver';
import Route from '../icons/Route';
import SpiritStill from '../icons/SpiritStill';
import Split from '../icons/Split';
import Start from '../icons/Start';
// Processes
import Steep from '../icons/Steep';
import Stop from '../icons/Stop';
import Temperature from '../icons/Temperature';
import Throttle from '../icons/Throttle';
import Washback from '../icons/Washback';
import WashStill from '../icons/WashStill';
import Water from '../icons/Water';
import Weight from '../icons/Weight';
import Yeast from '../icons/Yeast';
// Processes
// Observations

// Forms
// Ingredients

// type measuredByType = 'weightt' | 'volume' | 'unit';

export const createKey = ({ type, icon, variant = null }) =>
  [type, icon, variant || null].filter((v) => Boolean(v))
  .join('-')
  .replace(/(\W)/, '_') +
  `-${new Date().getTime()}`;

export const iconFromName = (name) => {
  switch (name) {
    // Ingredients
    case 'Grain':
      return <Grain />;
    case 'Water':
      return <Water />;
    case 'Peat':
      return <Peat />;
    case 'Yeast':
      return <Yeast />;
    case 'Enzyme':
      return <Enzyme />;
    case 'DeFoamer':
      return <DeFoamer />;
    case 'Nutrients':
      return <Nutrients />;
    case 'AntiMicrobial':
      return <AntiMicrobial />;

    // Processes
    case 'GrainBin':
      return <SvgGrainBin />;
    case 'KilnOven':
      return <KilnOven />;
    case 'HammerMill':
      return <HammerMill />;
    case 'ContinuousMill':
      return <ContinuousMill />;
    case 'Mash':
      return <Mash />;
    case 'MashCooler':
      return <MashCooler />;
    case 'BeerWell':
      return <BeerWell />;
    case 'LiquidSolidSeparator':
      return <LiquidSolidSeparator />;
    case 'Washback':
      return <Washback />;
    case 'WashStill':
      return <WashStill />;
    case 'SpiritStill':
      return <SpiritStill />;
    case 'ColumnStill':
      return <ColumnStill />;
    case 'PotStill':
      return <PotStill />;
    case 'Receiver':
      return <Receiver />;
    case 'Cask':
      return <Cask />;
    case 'Steep':
      return <Steep />;
    case 'Feints':
      return <Feints />;

    // Bottling
    case 'Blender':
      return <SvgBlendingTank />;
    case 'LineTank':
      return <SvgLineTank />;
    case 'Rinser':
      return <SvgBottleWasher />;
    case 'BottleFiller':
      return <SvgBottleFiller />;
    case 'BottleCapper':
      return <SvgBottleCapper />;
    case 'BottleLabeller':
      return <SvgBottleLabeller />;
    case 'BottleFinisher':
      return <SvgBottleFinishing />;
    case 'BottlePacker':
      return <SvgBottlePacking />;

    // Observations
    case 'Ph':
      return <Ph />;
    case 'Temperature':
      return <Temperature />;
    case 'Brix':
      return <Brix />;
    case 'OG_SG':
      return <OG_SG />;
    case 'Weight':
      return <Weight />;
    case 'Policy':
      return <Policy />;
    // Operations
    case 'Combine':
      return <Combine />;
    case 'Split':
      return <Split />;
    case 'Throttle':
      return <Throttle />;
    case 'Start':
      return <Start />;
    case 'Stop':
      return <Stop />;
    case 'Route':
      return <Route />;
    case 'Scan':
      return <SvgQrScan3 />;

    // Fallback
    default:
      return <HelpCenter />;
  }
};

export const formLookup = (form) => {
  switch (form) {
    // Materials
    // case 'CaskForm':
    // return <CaskForm />;
    case 'GrainForm':
      return <GrainForm />;
    // case 'SteepForm':
    // return <SteepForm />;
    case 'WaterForm':
      return <WaterForm />;
    case 'PeatForm':
      return <PeatForm />;
    case 'YeastForm':
      return <YeastForm />;

    // Processes
    // case 'KilnOvenForm':
    // return <KilnOvenForm />;
    case 'HammerMillForm':
      return <HammerMillForm />;
    case 'ContinuousMillForm':
      return <ContinuousMillForm />;
    case 'MashCoolerForm':
      return <MashCoolerForm />;
    case 'MashForm':
      return <MashForm />;
    case 'BeerWellForm':
      return <BeerWellForm />;
    case 'WashbackForm':
      return <WashbackForm />;
    case 'WashStillForm':
      return <StillForm />;
    case 'SpiritStillForm':
      return <StillForm />;
    case 'ColumnStillForm':
      return <StillForm />;
    case 'PotStillForm':
      return <StillForm />;
    // case 'ReceiverForm':
    // return <ReceiverForm />;

    // Observations
    case 'PhForm':
      return <PhForm />;
    case 'TemperatureForm':
      return <TemperatureForm />;
    case 'BrixForm':
      return <BrixForm />;
    case 'OgSgForm':
      return <OgSgForm />;
    case 'WeightForm':
      return <WeightForm />;

    // Operations
    // case 'CombineForm':
    //   return <CombineForm />;
    // case 'SplitForm':
    //   return <SplitForm />;
    // case 'ThrottleForm':
    //   return <ThrottleForm />;
    // case 'StartForm':
    //   return <StartForm />;
    // case 'StopForm':
    //   return <StopForm />;
    // case 'RouteForm':
    //   return <RouteForm />;

    // Fallback
    default:
      return null;
  }
};

export const sourceTypes = ['materialNode', 'processNode', 'operationNode'];
export const targetTypes = ['observationNode', 'processNode', 'operationNode'];

export const materials = [
  {
    category: DESIGN_NODE_TYPE.MATERIAL,
    icon: 'Grain',
    type: 'grain',
    label: 'Grain',
    outputType: ['grain']
  },
  {
    category: DESIGN_NODE_TYPE.MATERIAL,
    icon: 'Water',
    type: 'water',
    label: 'Water',
    outputType: ['water']
  },
  {
    category: DESIGN_NODE_TYPE.MATERIAL,
    icon: 'Yeast',
    type: 'yeast',
    label: 'Yeast',
    outputType: ['yeast']
  },
  {
    category: DESIGN_NODE_TYPE.MATERIAL,
    icon: 'Peat',
    type: 'peat',
    label: 'Peat',
    outputType: ['peat']
  },
  {
    category: DESIGN_NODE_TYPE.MATERIAL,
    icon: 'Enzyme',
    type: 'enzymes',
    label: 'Enzymes',
    outputType: ['enzymes']
  },
  {
    category: DESIGN_NODE_TYPE.MATERIAL,
    icon: 'DeFoamer',
    type: 'defoamer',
    label: 'Defoamer',
    outputType: ['defoamer']
  },
  {
    category: DESIGN_NODE_TYPE.MATERIAL,
    icon: 'Nutrients',
    type: 'nutrients',
    label: 'Nutrients',
    outputType: ['nutrients']
  },
  {
    category: DESIGN_NODE_TYPE.MATERIAL,
    icon: 'AntiMicrobial',
    type: 'anti-microbial',
    label: 'Anti-Microbial',
    outputType: ['anti-microbial']
  }
];

export const production = [
  // {
  //   category: DESIGN_NODE_TYPE.MACHINE,
  //   icon: 'Steep',
  //   type: MACHINE_TYPES.steep,
  //   label: 'Steeps',
  //   inputType: ['grain', 'water'],
  //   outputType: ['steeped_grain'],
  //   assetType: ASSET_TYPES.MACHINE
  // },
  // {
  //   category: DESIGN_NODE_TYPE.MACHINE,
  //   icon: 'KilnOven',
  //   type: MACHINE_TYPES.kiln_oven,
  //   label: 'Kiln Oven',
  //   inputType: ['steeped_grain', 'peat'],
  //   outputType: ['malted_grain'],
  //   assetType: ASSET_TYPES.MACHINE
  // },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'GrainBin',
    type: 'grain_bin',
    label: 'Grain Bin',
    inputType: [],
    outputType: ['grain'],
    assetType: ASSET_TYPES.CONTAINER
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'HammerMill',
    type: MACHINE_TYPES.hammer_mill,
    label: 'Hammer Mill',
    inputType: ['malted_grain', 'grain'],
    outputType: ['grain_milled'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'ContinuousMill',
    type: MACHINE_TYPES.roller_mill,
    label: 'Roller Mill',
    inputType: ['malted_grain', 'grain'],
    outputType: ['grain_milled'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'Mash',
    type: MACHINE_TYPES.mash,
    label: 'Mash Tun',
    inputType: ['grain_milled', 'water'],
    outputType: ['wort', 'mash_waste'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'LiquidSolidSeparator',
    type: MACHINE_TYPES.mash_filter,
    label: 'Mash Filter',
    inputType: ['mash_waste'],
    outputType: ['water', 'draff'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'MashCooler',
    type: MACHINE_TYPES.wort_cooler,
    label: 'Wort Cooler',
    inputType: ['wort'],
    outputType: ['wort_cooled'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'Washback',
    type: MACHINE_TYPES.washback,
    label: 'Washback',
    inputType: ['wort', 'wort_cooled', 'yeast', 'enzymes', 'defoamer', 'nutrients', 'anti-microbial'],
    outputType: ['wash'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'BeerWell',
    type: MACHINE_TYPES.wash_receiver,
    label: 'Wash Receiver',
    form: [DESIGN_TYPE.DISTILLERY],
    inputType: ['wash', 'defoamer', 'nutrients', 'anti-microbial'],
    outputType: ['wash'],
    assetType: ASSET_TYPES.FILLING_TANK
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'WashStill',
    type: MACHINE_TYPES.wash_still,
    label: 'Wash Still',
    inputType: ['wash'],
    outputType: ['low_wine', 'pot_ale'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'PotStill',
    type: MACHINE_TYPES.pot_still,
    label: 'Pot Still',
    inputType: ['wash', 'low_wine', 'charge'],
    outputType: ['foreshot', 'hearts', 'feints'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'ColumnStill',
    type: MACHINE_TYPES.column_still,
    label: 'Column Still',
    inputType: ['wash', 'low_wine', 'charge'],
    outputType: ['foreshot', 'hearts', 'feints'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'Receiver',
    type: 'isr',
    label: 'Receiver',
    form: [DESIGN_TYPE.DISTILLERY],
    inputType: ['hearts'],
    assetType: ASSET_TYPES.FILLING_TANK
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'Feints',
    type: 'feints',
    label: 'Low Wines/Feints',
    form: [DESIGN_TYPE.DISTILLERY],
    inputType: ['foreshot', 'feints'],
    outputType: ['charge'],
    assetType: ASSET_TYPES.FILLING_TANK
  }
];

export const bottling = [
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'Blender',
    type: 'blending_tank',
    label: 'Blending Tank',
    inputType: ['water', 'cleaner'],
    outputType: ['blend'],
    assetType: ASSET_TYPES.FILLING_TANK
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'LineTank',
    type: 'line_tank',
    label: 'Line Tank',
    inputType: ['blend', 'cleaner'],
    outputType: ['fill'],
    assetType: ASSET_TYPES.FILLING_TANK
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'Rinser',
    type: MACHINE_TYPES.rinser,
    label: 'Bottle Washer',
    inputType: ['bottles', 'water'],
    outputType: ['clean_bottles'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'BottleFiller',
    type: MACHINE_TYPES.bottle_filler,
    label: 'Bottle Filler',
    inputType: ['clean_bottles', 'fill'],
    outputType: ['filled_bottles'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'BottleCapper',
    type: MACHINE_TYPES.bottle_capper,
    label: 'Bottle Capper',
    inputType: ['filled_bottles', 'material'],
    outputType: ['capped_bottles'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'BottleLabeller',
    type: MACHINE_TYPES.bottle_labeller,
    label: 'Bottle Labeller',
    inputType: ['capped_bottles', 'material'],
    outputType: ['labelled_bottles'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'BottleFinisher',
    type: MACHINE_TYPES.bottle_finisher,
    label: 'Bottle Finishing',
    inputType: ['labelled_bottles'],
    outputType: ['finished_bottles'],
    assetType: ASSET_TYPES.MACHINE
  },
  {
    category: DESIGN_NODE_TYPE.MACHINE,
    icon: 'BottlePacker',
    type: MACHINE_TYPES.bottle_packer,
    label: 'Bottle Packing',
    inputType: ['finished_bottles'],
    outputType: ['finished_goods'],
    assetType: ASSET_TYPES.MACHINE
  }
];

export const productionObservations = [
  // {
  //   category: DESIGN_NODE_TYPE.OBSERVATION,
  //   icon: 'Ph',
  //   type: 'ph',
  //   label: 'PH'
  // },
  // {
  //   category: DESIGN_NODE_TYPE.OBSERVATION,
  //   icon: 'Temperature',
  //   type: 'temperature',
  //   label: 'Temperature'
  // },
  // {
  //   category: DESIGN_NODE_TYPE.OBSERVATION,
  //   icon: 'Brix',
  //   type: 'brix',
  //   label: 'Brix'
  // },
  // {
  //   category: DESIGN_NODE_TYPE.OBSERVATION,
  //   icon: 'OG_SG',
  //   type: 'og_sg',
  //   label: 'OG/SG'
  // },
  // {
  //   category: DESIGN_NODE_TYPE.OBSERVATION,
  //   icon: 'Weight',
  //   type: 'weight',
  //   label: 'Weight'
  // },
  {
    category: DESIGN_NODE_TYPE.OBSERVATION,
    icon: 'Start',
    type: 'start',
    label: 'Start Trigger'
  },
  {
    category: DESIGN_NODE_TYPE.OBSERVATION,
    icon: 'Policy',
    type: 'policy',
    label: 'Policy'
  }
];

export const productionCalculations = [
  {
    category: DESIGN_NODE_TYPE.CALCULATIONS,
    icon: 'Start',
    type: 'extract',
    label: 'Extract'
  },
  {
    category: DESIGN_NODE_TYPE.CALCULATIONS,
    icon: 'Start',
    type: 'mashing efficiency',
    label: 'Mashing Efficiency'
  },
  {
    category: DESIGN_NODE_TYPE.CALCULATIONS,
    icon: 'Start',
    type: 'yield and psy recovery',
    label: 'Yield and PSY recovery'
  },
  {
    category: DESIGN_NODE_TYPE.CALCULATIONS,
    icon: 'Start',
    type: 'water dilution',
    label: 'Water dilution'
  },
  {
    category: DESIGN_NODE_TYPE.CALCULATIONS,
    icon: 'Start',
    type: 'caramel addition',
    label: 'Caramel addition'
  }
];

export const bottlingObservations = [
  {
    category: DESIGN_NODE_TYPE.OBSERVATION,
    icon: 'Start',
    type: 'start',
    label: 'Start Trigger'
  },
  {
    category: DESIGN_NODE_TYPE.OBSERVATION,
    icon: 'Scan',
    type: 'scan',
    label: 'Scan QR'
  },
  {
    category: DESIGN_NODE_TYPE.OBSERVATION,
    icon: 'Policy',
    type: 'policy',
    label: 'Policy'
  }
];

// export const operations = [
//   // {
//   //   category: 'operation',
//   //   icon: 'Combine',
//   //   // form: 'CombineForm',
//   //   type: 'combine',
//   //   label: 'Combine',
//   //   // inputType: ['wort', 'yeast'],
//   //   // outputType: ['_washback_out'],
//   // },
//   {
//     category: 'operation',
//     icon: 'Split',
//     // form: 'SplitForm',
//     type: 'split',
//     label: 'Split',
//     // inputType: ['wort', 'yeast'],
//     // outputType: ['_washback_out'],
//   },
//   {
//     category: 'operation',
//     icon: 'Throttle',
//     // form: 'ThrottleForm',
//     type: 'throttle',
//     label: 'Throttle',
//     // inputType: ['wort', 'yeast'],
//     // outputType: ['_washback_out'],
//   },
//   {
//     category: 'operation',
//     icon: 'Start',
//     // form: 'StartForm',
//     type: 'start',
//     label: 'Start',
//     // inputType: ['wort', 'yeast'],
//     // outputType: ['_washback_out'],
//   },
//   {
//     category: 'operation',
//     icon: 'Stop',
//     // form: 'StopForm',
//     type: 'stop',
//     label: 'Stop',
//     // inputType: ['wort', 'yeast'],
//     // outputType: ['_washback_out'],
//   },
//   // {
//   //   category: 'operation',
//   //   icon: 'Route',
//   //   // form: 'RouteForm',
//   //   type: 'route',
//   //   label: 'Route',
//   //   // inputType: ['wort', 'yeast'],
//   //   // outputType: ['_washback_out'],
//   // },
// ];
//
// export const outputs = [
//   {
//     category: 'output',
//     // icon: 'Split',
//     // form: 'DraffForm',
//     type: 'draff',
//     label: 'Draff',
//   },
// ];
