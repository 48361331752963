import ContactSearch from '@pw/components_v2/search/contact';
import { FormikProvider } from '@pw/providers/FormikProvider';

// TODO: add services selection list
function InvoiceClient({ readonly }) {
  if (readonly) return null;
  return (
    <>
      <ContactSearch field="client" label="Client" />
    </>
  )
}

function InvoiceForm() {
  return (
    <FormikProvider path='client'>
      <InvoiceClient />
    </FormikProvider>
  );
}

export default InvoiceForm;
