function SvgInvoice(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 17 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M2.475 22c-.158-.114-.33-.212-.47-.344-.58-.55-1.147-1.114-1.727-1.666A.844.844 0 010 19.34C.002 13.14.002 6.938.002.74c0-.486.266-.74.77-.74h15.457c.503 0 .77.254.77.739 0 6.2 0 12.4.002 18.6a.848.848 0 01-.278.652c-.574.547-1.136 1.105-1.712 1.65-.144.137-.323.24-.486.359h-.31c-.161-.12-.337-.224-.482-.36-.442-.415-.873-.842-1.317-1.273-.43.418-.841.826-1.264 1.223-.161.151-.35.274-.525.41h-.31c-.191-.14-.396-.267-.569-.427-.418-.388-.822-.792-1.24-1.198-.07.063-.125.108-.175.158-.391.378-.776.763-1.175 1.132-.14.13-.315.225-.475.336h-.31c-.17-.131-.352-.252-.51-.396-.293-.27-.577-.55-.86-.832-.135-.136-.26-.283-.394-.431-.478.463-.904.888-1.343 1.3-.145.135-.32.24-.482.359h-.31V22zM1.33 1.297V11.501c0 2.492 0 4.985.002 7.477 0 .082-.005.193.044.242.41.41.83.808 1.257 1.219l1.372-1.331c.414-.4.748-.401 1.16-.002l1.414 1.376c.054-.081.088-.157.144-.212.425-.418.851-.834 1.284-1.246.3-.286.684-.285.985 0 .328.31.652.624.97.942.16.157.304.328.506.549.085-.122.122-.196.178-.252.415-.407.831-.814 1.252-1.215.333-.318.702-.318 1.032-.001.42.402.839.807 1.252 1.216.06.06.089.147.14.235.447-.435.819-.805 1.2-1.163a.445.445 0 00.15-.357c-.004-5.815-.004-11.63-.004-17.444v-.237H1.332z'
        fill='currentColor'
      />
      <path
        d='M7.836 4.832v-.677c.003-.41.287-.71.669-.706.382.004.656.3.66.715v.675c.29 0 .562-.017.83.004a.632.632 0 01.137 1.238c-.11.032-.232.036-.349.036-.576.004-1.152-.006-1.727.005-.56.01-.914.474-.751.962.102.306.38.502.742.51.34.008.68-.003 1.019.007a2.052 2.052 0 011.993 1.888c.078.975-.65 1.907-1.643 2.107-.079.016-.158.03-.252.046 0 .238.003.466 0 .694-.005.402-.28.684-.663.684-.382 0-.66-.283-.665-.683-.002-.22 0-.442 0-.694h-.62c-.497 0-.797-.24-.8-.639-.003-.407.299-.65.81-.65.561 0 1.122.002 1.683 0 .483-.001.83-.315.828-.74-.003-.42-.341-.72-.815-.728-.376-.006-.755.016-1.127-.026-1.069-.119-1.85-.97-1.857-1.991-.009-1.01.753-1.858 1.81-2.017.02-.003.042-.01.09-.021h-.002zM8.501 16.477c-1.158 0-2.316.001-3.475-.002-.455 0-.747-.258-.745-.646a.633.633 0 01.533-.622c.1-.018.205-.019.308-.019h6.751c.103 0 .208 0 .309.019a.634.634 0 01-.029 1.258c-.117.014-.236.012-.354.012H8.501z'
        fill='currentColor'
      />
    </svg>
  );
}
export default SvgInvoice;
