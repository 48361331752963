function SvgForwardarrow(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 18 8'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M17.9232 3.85667C17.8705 3.97773 17.776 4.07463 17.6633 4.16468C16.3646 5.20193 15.0678 6.24074 13.7702 7.27889C13.5198 7.47917 13.2323 7.53196 12.9709 7.40061C12.7264 7.27779 12.5192 7.1122 12.3649 6.91704C12.2255 6.74075 12.2545 6.55437 12.4061 6.38435C12.4439 6.34199 12.4906 6.3043 12.5369 6.26734C13.2608 5.68891 13.9854 5.11097 14.7096 4.53281C14.7398 4.50871 14.7685 4.48346 14.8007 4.45647C14.752 4.42976 14.7073 4.44082 14.6658 4.4408C12.2413 4.44014 3.17112 4.44037 0.746576 4.43998C0.410477 4.43993 0.165967 4.30518 0.107014 4.05737C0.0554024 3.84034 0.0574592 3.6146 0.105183 3.39735C0.156709 3.16278 0.400703 3.02883 0.721314 3.02874C2.84368 3.02814 11.6117 3.02837 13.734 3.02835C14.0435 3.02832 14.3529 3.02871 14.6624 3.0278C14.6969 3.02771 14.7344 3.03743 14.7683 3.01741C14.7559 2.98023 14.7164 2.96163 14.6868 2.9378C13.9581 2.35086 13.2294 1.76394 12.499 1.17837C12.2972 1.01662 12.2268 0.832096 12.3253 0.613013C12.3451 0.56894 12.3718 0.52736 12.4102 0.491658C12.5555 0.356924 12.7088 0.227953 12.8799 0.114627C13.1093 -0.0372379 13.4201 -0.0371921 13.6565 0.108749C13.7052 0.13887 13.7502 0.173429 13.7938 0.208353C15.0838 1.24023 16.3729 2.27282 17.6639 3.3039C17.7766 3.3939 17.8705 3.49098 17.9232 3.6119V3.85667Z' />
		</svg>
	);
}
export default SvgForwardarrow;
