import Stack from '@mui/material/Stack';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ContactSearch from '@pw/components_v2/search/contact';
import LedgerSearch from '@pw/components_v2/search/ledger';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import ThingForm from '@pw/components_v2/Thing';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing="1.5rem">
        <FormikTextField label='Type' name='type' fullWidth />
			</Stack>
		</FormikProvider>
	);
}

const tabs = [
	{
		title: 'Properties',
		key: 'properties',
		properties: (entity, converter) => ({
			properties: {
				type: [entity?.properties?.type, yup.string().required('Type is required!')],
			},
		}),
		Component: Properties,
	},
	{
		title: 'Grouping',
		key: 'grouping',
		properties: (entity, converter) => ({
			ledger: [entity?.ledger, yup.object()],
		}),
		Component: (props) => (
				<LedgerSearch />
		)
	},
	{
		title: 'Policies',
		key: 'policies',
		properties: (entity, converter) => ({
			policies: [entity?.policies, yup.array()],
		}),
		Component: ({ entity }) => (
			<PolicySelector initialValues={entity?.policies} />
		)
	}
];

function ThingPage(props) {
	return (
		<ThingForm
			thingLabel='Expenses'
			thingType={THING_TYPES.EXPENSES}
			tabs={tabs}
		/>
	);
}

export default withAppLayout(ThingPage, { title: 'Expenses' });
