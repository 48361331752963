import { WidgetsOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { THING_EDIT_URL } from '@pw/consts/thing';
import debounce from '@pw/utilities/debounce';
import { useCallback, useState } from 'react';

function ThingItem({ item, onSelect, onClick }) {

  const [, setSelected] = useState(false);

  const handleNavigate = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (item?._id && item?.type) {
      const route = THING_EDIT_URL?.[item?.type];
      console.log('Routing to', route);
      window.open(`${route}/${item?._id}`, '_blank').focus();
    }
  }, [item]);

  const handleClick = useCallback((e) => {
    if (onSelect) {
      setSelected((v) => {
        debounce(() => onSelect(item, !v), 25);
        return !v;
      });
    }
    if (onClick) {
      debounce(() => onClick(item), 25);
    }
  }, [item]);

  return (
    <Stack direction="row" spacing="0.75rem" alignItems="center" onClick={handleClick} sx={{ position: 'relative' }}>
      <Tooltip title={item?._id}>
        <Box>
          <WidgetsOutlined type={item?.type} style={{ width: '24px', height: '24px', cursor: 'pointer' }} onClick={handleNavigate} />
        </Box>
      </Tooltip>
      <Stack spacing='0.25rem' sx={{ borderLeft: '1px solid #d9d9d9', paddingLeft: '0.75rem'}}>
        <EntryTitle value={item?.name} />
        <EntryLabel value={item?.description} />
      </Stack>
    </Stack>
  );
}

export default ThingItem;
