import * as yup from 'yup';

function fields(level) {
	return {
		weightFactor: [
			level?.weightFactor,
			yup.number(),
		],
	};
}

export default fields;
