import AssetSelectorModal from '@pw/components/InventorySelector/AssetSelectorModal';
import { InventorySelectorV2 } from '@pw/components/InventorySelector/index';
import AssetInfo from '@pw/components/ThingSelector/items/AssetInfo';
import { Body1 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import {
  ASSET_PROCESSED_STATUS,
  REQUEST_STATUS,
  REQUEST_TYPES,
} from '@pw/consts/requests';
import { setActiveListItem } from '@pw/redux/containers/App';
import { useCompanyId } from '@pw/redux/containers/User/hooks';
import toThingItem from '@pw/utilities/adapters/toThingItem';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import debounce from '@pw/utilities/debounce';
import handleOnQrRead from '@pw/utilities/handleOnQrRead';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useAssetApproveHook from '@pw/utilities/hooks/logic/useAssetApproveHook';
import useAssetConfirmHook from '@pw/utilities/hooks/logic/useAssetConfirmHook';
import { useIsSameCompany } from '@pw/utilities/hooks/logic/useCheckCompany';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useParentAssetHook from '@pw/utilities/hooks/logic/useParentAssetHook';
import useThingApproveHook from '@pw/utilities/hooks/logic/useThingApproveHook';
import useThingAssetPick from '@pw/utilities/hooks/logic/useThingAssetPick';
import useThingConfirmHook from '@pw/utilities/hooks/logic/useThingConfirmHook';
import { useToggleState } from '@pw/utilities/hooks/logic/useToggleState';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import UploadAssetsModal from './UploadAssetsModal';
import AssetFillModal from './AssetFillModal';
import { useRequestAssetsLazyQuery } from '@pw/utilities/hooks/service/useRequestAssetsQuery';
import RequestAssetSelectorModal from './RequestAssetSelectorModal';
import ThingItemInfo from '../ThingSelector/items/ThingItemInfo';
import { THING_TYPES } from '@pw/consts/thing';
import { COMP, ID } from '@pw/utilities/comp';

function useInventorySelectorHook(props) {
  const {
    title,
    filter = {},
    assetFilter,
    thing_filter = [],
    single = false,
    assetAdapter = toTaggedAsset,
    assetConverter = (i, s) => toTaggedAsset(i, s),
    extendedSummary = false,
    dutyPaidSummary = false,
    initialThings = [],
    initialAssets = [],
    displayThing = (value) => <ThingItemInfo thing={value} />,
    displayAsset = (value) => <AssetInfo item={value} />,
    submitForm,
    ThingModal,
    thingModalProps = {},
    AssetModal,
    assetModalProps = {},
    parentAssetSupport = false,
    onThingAdded,
    onThingRemoved,
    onAssetAdded,
    onAssetRemoved,
    allowAssetSelector = true,
  } = props;

  console.log('Inventory Selector', props);

  const { enqueueSnackbar } = useSnackbar();
  const isSameCompany = useIsSameCompany();

  const confirm = useConfirm();
  const dispatch = useDispatch();

  const [fetchAsset, { isLoading: assetLoading }] = useAssetLazyQuery();
  const [fetchRequest, { isLoading: requestLoading }] =
    useRequestAssetsLazyQuery();

  const companyId = useCompanyId();

  const [autoSubmit, setAutoSubmit] = useState(true);
  const [enable, setEnable] = useState(true);
  const [reports, setReports] = useState(false);
  const [fill, toggleFill] = useToggleState(false);
  const [upload, toggleUpload] = useToggleState(false);
  const [currentRequest, setCurrentRequest] = useState({});

  const [things, initThings, , upsertThing, removeThings] = useItemListManager({
    id: ID.thing,
    comp: COMP.thing,
    initialData: initialThings,
  });
  const [assets, initAssets, , upsertAssets, removeAssets] = useItemListManager(
    { initialData: initialAssets },
  );

  const caskExists = useMemo(() => {
    return !!assets?.filter((i) => i.asset_type === ASSET_TYPES.CASK)?.length;
  }, [assets]);

  const [applyParentAsset, changeParentAsset, closeParentAsset] =
    useParentAssetHook(parentAssetSupport, assets, upsertAssets);

  // This will make sure any picked assets match the things
  useThingAssetPick(assets, things, upsertThing);

  // Used to confirm things/assets based on a scan
  const thingConfirmHook = useThingConfirmHook(things, upsertThing);
  const assetConfirmHook = useAssetConfirmHook(assets, upsertAssets);

  // This is used to approve all the things/assets
  const approveAllThings = useThingApproveHook(things, upsertThing);
  const approveAllAssets = useAssetApproveHook(assets, upsertAssets);

  // Currently selected thing
  const [selectedThing, setSelectedThingImpl] = useState(null);

  // Currently selected asset
  const [selectedAsset, setSelectedAssetImpl] = useState(null);

  // This is used on first load of a pallet to select the assets from the pallet
  const [assetSelector, setAssetSelector] = useState(null);

  // This is used on first load of a request to select the assets from the request
  const [requestAssets, setRequestAssets] = useState(null);

  const setSelectedAsset = useCallback(
    (item) => {
      setSelectedAssetImpl(item);
      if (item) {
        dispatch(setActiveListItem(item));
      }
    },
    [dispatch],
  );

  const setSelectedThing = (item) => {
    if (ThingModal) setSelectedThingImpl(item);
    else upsertThing(item);
    if (item) {
      dispatch(setActiveListItem(item));
    }
  };

  const addNewThing = (thing) => {
    if (ThingModal) {
      setSelectedThing(thing);
    } else {
      upsertThing(thing);
      if (onThingAdded) {
        onThingAdded(thing);
      }
    }
  };

  const addNewAsset = useCallback(
    (asset, scan) => {
      console.log(
        '>>addNewAsset',
        scan,
        asset,
        asset.id,
        asset.name,
        asset.type,
      );
      const convertedAsset = assetConverter(asset, scan);
      if (AssetModal && (!assetFilter || assetFilter(asset))) {
        setSelectedAsset(convertedAsset);
      } else {
        upsertAssets(convertedAsset);
      }
    },
    [AssetModal, assetConverter, assetFilter, setSelectedAsset, upsertAssets],
  );

  const init = useCallback(
    (entity) => {
      debounce(() => {
        const {
          assets = [],
          things = [],
          status = REQUEST_STATUS.PENDING,
          company_id,
        } = entity;
        console.log('>>init', title, company_id, status);
        setCurrentRequest({ ...entity });
        if (!isSameCompany(company_id)) {
          setEnable(false);
        } else {
          if (
            [
              REQUEST_STATUS.DONE,
              REQUEST_STATUS.CANCELLED,
              REQUEST_STATUS.REJECTED,
              REQUEST_STATUS.ARCHIVED,
            ].includes(status)
          ) {
            setEnable(false);
          }
          if (![REQUEST_STATUS.PENDING].includes(status)) {
            setReports(true);
          }
        }
        initThings(things);
        initAssets(assets);
      }, 50);
    },
    [setEnable, setReports, isSameCompany, initThings, initAssets],
  );

  /**
   * On editing a Thing, remove any linked assets
   * @type {(function(*): void)|*}
   */
  const removeLinkedAssets = useCallback(
    (thing) => {
      if (thing.include_holder) {
        // Get the entries and storage within that
        const entries = thing.entries ?? [];
        const assetIds = [];
        entries.forEach((e) => {
          const { storage = [] } = e;
          storage.forEach((s) => {
            assetIds.push(s.asset?.id);
          });
        });

        const remainingAssets = assets.filter((a) => !assetIds.includes(a.id));
        debounce(() => initAssets(remainingAssets), 50);
      }
    },
    [assets, initAssets],
  );

  /**
   * Load the asset details from the back-end
   * @type {(function(*): void)|*}
   */
  const loadAsset = useCallback(
    (assetId, scan = false) => {
      console.log('>>loadAsset', assetId, scan);
      fetchAsset({ id: assetId })
        .then((res) => {
          const { child_assets = [] } = res;
          console.log(' --> loaded asset', res, child_assets.length);

          if (allowAssetSelector && child_assets.length > 0) {
            setAssetSelector({
              ...res,
              processed: scan
                ? ASSET_PROCESSED_STATUS.CONFIRMED
                : ASSET_PROCESSED_STATUS.PENDING,
            });
          } else {
            const adaptedAsset = assetAdapter(res);
            if (adaptedAsset) {
              const updatedAsset = changeParentAsset(adaptedAsset);
              addNewAsset(updatedAsset, scan);
            } else {
              enqueueSnackbar(`Failed to add asset!`, { variant: 'error' });
            }
          }
        })
        .catch((e) => {
          console.log('Get asset', e);
          enqueueSnackbar(`Failed to find asset!`, { variant: 'error' });
          throw e;
        });
    },
    [
      fetchAsset,
      setAssetSelector,
      setSelectedAsset,
      upsertAssets,
      enqueueSnackbar,
      assetAdapter,
      changeParentAsset,
    ],
  );

  /**
   * Conditionally add an asset
   * @type {(function(*): void)|*}
   */
  const addAsset = useCallback(
    (asset) => {
      // Now, we know at this stage, this asset doesn't exist in our list
      loadAsset(asset._id);
    },
    [loadAsset],
  );

  /**
   * Conditionally add a request
   * @type {(function(*): void)|*}
   */
  const addRequest = useCallback(
    (request) => {
      // TODO: Fix this shit
      // Limited set of requests can be injected here..
      if (
        [
          REQUEST_TYPES.CHANGE_OWNERSHIP,
          REQUEST_TYPES.DELIVERY,
          REQUEST_TYPES.TRANSFER,
        ].includes(request.request_type)
      ) {
        console.log('>>addRequest', request.name);
        fetchRequest(request._id)
          .then((r) => {
            let assets = [];
            let things = [];
            // From the pick, extract the things and assets, and add them all..
            const { sources = {}, destinations = {} } = r;
            const { assets: sourceAssets, things: sourceThings } = sources;
            const { assets: destAssets } = destinations;
            if (request.request_type === REQUEST_TYPES.PICK) {
              assets = sourceAssets;
              things = sourceThings
                .filter(
                  (item) =>
                    thing_filter.length === 0 ||
                    thing_filter.includes(item.type),
                )
                .map((item) => toThingItem(item));
            } else {
              assets = destAssets;
            }

            if (assets.length) {
              setRequestAssets(assets);
            }
            if (things.length > 0) {
              debounce(() => upsertThing(things), 25);
              things.map((s) => {
                if (onAssetAdded) {
                  onAssetAdded(s);
                }
              });
            }
            if (!things.length && !assets?.length) {
              enqueueSnackbar(`Nothing could be added from ${request.name}!`, {
                variant: 'warning',
              });
            }
          })
          .catch((e) => {
            console.log('Get request', e);
            enqueueSnackbar(`Failed to get inventory from ${request.name}!`, {
              variant: 'error',
            });
          });
      } else {
        enqueueSnackbar(`Request ${request.name} cannot be used here!`, {
          variant: 'error',
        });
      }
    },
    [assets],
  );

  /**
   * Add an item after selecting from the search results
   * @type {(function(*): void)|*}
   */
  const handleAdd = useCallback(
    (item) => {
      console.log('>>handleAdd', item?.name, item?.type);

      const { type } = item;

      const request_types = Object.values(REQUEST_TYPES);
      const asset_types = Object.values(ASSET_TYPES);
      const thing_types = Object.values(THING_TYPES);

      if (asset_types.includes(type)) {
        addAsset(item);
        return;
      }

      if (request_types.includes(type)) {
        addRequest(item);
        return;
      }

      // Adding a Thing, so allow the user to select the properties of the sku
      if (thing_types.includes(type)) {
        let entries = [];
        if (![REQUEST_TYPES.PURCHASE_ORDER].includes(currentRequest.type)) {
          entries = [{ amount: 0 }];
        }
        setSelectedThing({ ...toThingItem(item), entries });
      }
    },
    [addAsset, addRequest],
  );

  /**
   * Scan an item
   * @type {(function(*): void)|*}
   */
  const handleScan = useCallback(
    async (assetId) => {
      console.log('>>handleScan', assetId);

      // First - check if this asset is within the SKU
      const assetFound = thingConfirmHook(assetId);
      // If this asset is in the SKU, then potentially also confirm the asset and then we're done
      if (assetFound) {
        assetConfirmHook(assetId);
        if (submitForm && autoSubmit) {
          submitForm();
        }
        return;
      }

      // Find this asset first and confirm it..
      if (AssetModal) {
        // See if we have this asset
        const asset = assets.find((a) => a.asset_id === assetId);
        console.log('Found asset', assetId, asset);
        if (asset) {
          const updatedAsset = changeParentAsset(asset);
          if (!assetFilter || assetFilter(asset)) {
            setSelectedAsset(updatedAsset);
          } else {
            const result = assetConfirmHook(asset.asset_id, updatedAsset);
            if (!result) {
              upsertAssets(updatedAsset);
            }
            if (submitForm && autoSubmit) {
              submitForm();
            }
          }
        } else {
          // Fetch the asset first...
          loadAsset(assetId, true);
        }
      } else {
        const result = assetConfirmHook(assetId, {});
        if (!result) {
          loadAsset(assetId, true);
        } else {
          if (submitForm && autoSubmit) {
            submitForm();
          }
        }
      }
    },
    [
      thingConfirmHook,
      AssetModal,
      assetConfirmHook,
      submitForm,
      autoSubmit,
      assets,
      assetFilter,
      setSelectedAsset,
      changeParentAsset,
      upsertAssets,
      loadAsset,
    ],
  );

  const handleRemoveThing = (thing) => (removeFunc) => {
    confirm({
      title: 'Remove Thing',
      content: <Body1>{`Remove ${thing?.name}?`}</Body1>,
    })
      .then(() => removeFunc(thing))
      .catch(() => {});
  };

  const handleRemoveAsset = (asset) => (removeFunc) => {
    confirm({
      title: 'Remove Asset',
      content: <Body1>{`Remove ${asset?.name}?`}</Body1>,
    })
      .then(() => removeFunc(asset))
      .catch(() => {});
  };

  function InventoryComponent({ enableFill = false, enableUpload = false }) {
    const editAsset = useCallback(
      (asset) => {
        if (assetFilter && assetFilter(asset)) {
          setSelectedAsset(assetAdapter(asset));
        }
      },
      [assetFilter, setSelectedAsset],
    );

    const handleThingEdit = useCallback(
      (thing) => {
        // Remove any linked assets
        removeLinkedAssets(thing);
        setSelectedThing(thing);
      },
      [removeLinkedAssets, setSelectedThing],
    );

    const removeSelectedThing = useCallback(
      (thing) => {
        // Remove any linked assets
        removeLinkedAssets(thing);
        removeThings(thing);
      },
      [removeLinkedAssets, removeThings],
    );

    return (
      <>
        <InventorySelectorV2
          title={title}
          types={filter}
          onAdd={handleAdd}
          onQrScan={(params) => handleOnQrRead(params)(handleScan)}
          onProcessAll={() => {
            approveAllAssets();
            approveAllThings();
          }}
          onFill={caskExists && enableFill ? toggleFill : null}
          onUpload={enableUpload ? toggleUpload : null}
          things={[
            things,
            ThingModal ? handleThingEdit : null,
            (s) => handleRemoveThing(s)(removeSelectedThing),
            displayThing,
          ]}
          assets={[
            assets,
            AssetModal ? editAsset : null,
            (a) => handleRemoveAsset(a)(removeAssets),
            displayAsset,
          ]}
          report={reports}
          enable={enable}
          loading={assetLoading || requestLoading}
          single={single}
          extendedSummary={extendedSummary}
          dutyPaidSummary={dutyPaidSummary}
          autoSubmit={[autoSubmit, setAutoSubmit]}
        />
      </>
    );
  }

  function ModalComponents() {
    const addSelectedAssets = useCallback(
      async (asset) => {
        console.log(
          '>>addSelectedAssets',
          asset?.asset_id,
          asset?.rw_asset_id,
          asset?.path,
          asset?.asset_type,
          asset?.children?.length,
        );

        if (asset) {
          // Reset the parent asset
          closeParentAsset(asset);

          // Add all the selected assets
          const update = [asset, ...(asset.children ?? [])];

          debounce(
            () => upsertAssets(update.map(assetAdapter).filter((a) => a)),
            25,
          );

          // Hide the asset selector
          setAssetSelector(null);

          // // Flip to the asset modal for specific properties...
          // if (!assetFilter || assetFilter(asset)) {
          // 	const adaptedAsset = assetAdapter(asset);
          // 	if (adaptedAsset) {
          // 		setSelectedAsset(asset);
          // 	} else {
          // 		enqueueSnackbar(`Cannot add ${asset.rw_asset_id}!`, {
          // 			variant: 'error',
          // 		});
          // 	}
          // }
        } else {
          // close was triggered
          setAssetSelector(null);
        }
      },
      [
        closeParentAsset,
        upsertAssets,
        assetAdapter,
        assetFilter,
        setSelectedAsset,
      ],
    );

    const handleThingUpdate = useCallback(
      (thing) => {
        if (thing) {
          if (thing.include_holder) {
            // Get the entries and storage within that
            const entries = thing.entries ?? [];
            const assetIds = {};
            entries.forEach((e) => {
              const { storage = [] } = e;
              storage.forEach((s) => {
                let v = true;
                // eslint-disable-next-line no-prototype-builtins
                if (assetIds.hasOwnProperty(s.asset_id)) {
                  v = assetIds[s.asset_id];
                }
                console.log(
                  'Storage state',
                  s.asset_id,
                  v,
                  s.amount,
                  s.available_quantity,
                );
                assetIds[s.asset_id] = v && s.amount >= s.available_quantity;
              });
            });
            const existingAssetIds = assets.map((a) => a.asset_id);
            const newAssetsIds = Object.entries(assetIds)
              .filter(([k, v]) => v && !existingAssetIds.includes(k))
              .map(([k]) => k);
            if (newAssetsIds.length > 0) {
              console.log('Need to load these assets', newAssetsIds);
              newAssetsIds.forEach((id) =>
                fetchAsset(id).then((r) =>
                  debounce(() => upsertAssets(assetConverter(r)), 25),
                ),
              );
            }
          }
          thing.thing = thing.id;
          upsertThing(thing);
          if (submitForm && autoSubmit) {
            submitForm();
          }
        }
        if(thing?.trackedAssets?.length){
          console.log(`thing?.trackedAssets `, thing?.trackedAssets);
          upsertAssets(thing?.trackedAssets?.map(a=>assetConverter(a)), 25)
        }
        setSelectedThing(null);
      },
      [upsertThing, upsertAssets, setSelectedThing],
    );

    const handleAssetUpdate = useCallback(
      async (asset) => {
        console.log(
          '>>handleAssetUpdate',
          asset?.asset_id,
          asset?.rw_asset_id,
          asset?.path,
          asset?.asset_type,
          asset?.children?.length,
        );
        if (asset) {
          const updatedAsset = applyParentAsset(asset);
          const result = assetConfirmHook(asset.asset_id, updatedAsset);
          if (!result) {
            upsertAssets(updatedAsset);
          }
          if (submitForm && autoSubmit) {
            submitForm();
          }
        }
        setSelectedAsset(null);
      },
      [applyParentAsset, assetConfirmHook, upsertAssets, setSelectedAsset],
    );

    const handleAssetUpload = useCallback(
      (assets) => {
        console.log('>>handleAssetUpload', assets?.length);
        if (assets && assets.length > 0) {
          upsertAssets(assets);
        }
        toggleUpload();
      },
      [upsertAssets, toggleUpload],
    );

    const handleRequestAssets = useCallback(
      (assets) => {
        console.log('>>handleRequestAssets', assets?.length);
        if (assets && assets.length > 0) {
          upsertAssets(assets);
        }
        setRequestAssets(null);
      },
      [upsertAssets],
    );

    return (
      <>
        {AssetModal && !!selectedAsset && (
          <AssetModal
            open={!!selectedAsset}
            item={selectedAsset}
            items={assets}
            onClose={handleAssetUpdate}
            {...assetModalProps}
          />
        )}

        {ThingModal && !!selectedThing && (
          <ThingModal
            open={!!selectedThing}
            item={selectedThing}
            onClose={handleThingUpdate}
            {...thingModalProps}
          />
        )}

        {!!assetSelector && (
          <AssetSelectorModal
            open={!!assetSelector}
            item={assetSelector}
            onClose={addSelectedAssets}
          />
        )}
        {!!fill && (
          <AssetFillModal
            open={!!fill}
            items={assets}
            upsertAssets={upsertAssets}
            onClose={toggleFill}
          />
        )}

        {!!upload && (
          <UploadAssetsModal
            open={upload}
            assetAdapter={assetAdapter}
            onClose={handleAssetUpload}
          />
        )}
        {!!requestAssets && (
          <RequestAssetSelectorModal
            open={!!requestAssets}
            assets={requestAssets}
            onClose={handleRequestAssets}
          />
        )}
      </>
    );
  }

  return [
    [things, initThings, addNewThing, upsertThing, removeThings],
    [assets, initAssets, addNewAsset, upsertAssets, removeAssets],
    init,
    InventoryComponent,
    ModalComponents,
  ];
}

export default useInventorySelectorHook;
