function SvgDutySubmission(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 17 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M7.443 14.745c-.351 0-.681-.004-1.01 0-.175.003-.32-.046-.398-.22-.08-.176-.014-.32.104-.454.85-.964 1.699-1.93 2.548-2.895.207-.235.419-.235.627 0 .85.964 1.698 1.931 2.548 2.896.118.133.182.279.103.453-.079.173-.224.222-.399.22-.33-.005-.659-.002-1.009-.002V17.52c0 .351-.125.48-.463.48H7.886c-.315 0-.445-.136-.446-.466v-2.791l.003.002zm-.279-.743h.54c.325 0 .451.133.451.475V17.246h1.692v-.184-2.552c0-.391.114-.508.495-.508h.496l-1.837-2.089-1.837 2.09z'
        fill='currentColor'
      />
      <path
        d='M2.475 22c-.158-.114-.33-.212-.47-.344-.58-.55-1.147-1.114-1.727-1.666A.844.844 0 010 19.34C.002 13.14.002 6.938.002.74c0-.486.266-.74.77-.74h15.457c.503 0 .77.254.77.739 0 6.2 0 12.4.002 18.6a.848.848 0 01-.278.652c-.574.547-1.136 1.105-1.712 1.65-.144.137-.323.24-.486.359h-.31c-.161-.12-.337-.224-.482-.36-.442-.415-.873-.842-1.317-1.273-.43.418-.841.826-1.264 1.223-.161.151-.35.274-.525.41h-.31c-.191-.14-.396-.267-.569-.427-.418-.388-.822-.792-1.24-1.198-.07.063-.125.108-.175.158-.391.378-.776.763-1.175 1.132-.14.13-.315.225-.475.336h-.31c-.17-.131-.352-.252-.51-.396-.293-.27-.577-.55-.86-.832-.135-.136-.26-.283-.394-.431-.478.463-.904.888-1.343 1.3-.145.135-.32.24-.482.359h-.31V22zM1.33 1.297V11.501c0 2.492 0 4.985.002 7.477 0 .082-.005.193.044.242.41.41.83.808 1.257 1.219l1.372-1.331c.414-.4.748-.401 1.16-.002l1.414 1.376c.054-.081.088-.157.144-.212.425-.418.851-.834 1.284-1.246.3-.286.684-.285.985 0 .328.31.652.624.97.942.16.157.304.328.506.549.085-.122.122-.196.178-.252.415-.407.831-.814 1.252-1.215.333-.318.702-.318 1.032-.001.42.402.839.807 1.252 1.216.06.06.089.147.14.235.447-.435.819-.805 1.2-1.163a.445.445 0 00.15-.357c-.004-5.815-.004-11.63-.004-17.444v-.237H1.332z'
        fill='currentColor'
      />
      <path
        d='M12.932 3h.99l-1.117 3.302L14 9.8h-1.127l-.864-2.69h-.02l-.874 2.69h-1l1.195-3.497L10.193 3H11.3l.796 2.506h.02L12.932 3zM8.773 3L9.86 9.799H8.782l-.184-1.234H7.287L7.102 9.8h-.98L7.208 3h1.564zm-.845 1.204l-.505 3.438h1.03l-.506-3.438h-.02zM3 3.971V3h3.302v.971H5.185V9.8H4.117V3.97H3z'
        fill='currentColor'
      />
    </svg>
  );
}
export default SvgDutySubmission;
