import { withAppLayout } from '@pw/components/Layout/AppLayout';
import CompanyView from '@pw/components_v2/CompanyView';
import contactFields from '@pw/components_v2/contact/contactFields';
import { useMemo } from 'react';

function CompanyViewPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues, properties) => ({
			...contactFields(initialValues, properties),
		}),
		[],
	);


	return (
		<CompanyView
			changeSetGenerator={changeSetGenerator}
			{...props}
		>
		</CompanyView>
	);
}

export default withAppLayout(CompanyViewPage, { title: 'Companies' });
