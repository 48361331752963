import { Box, Tab, Tabs } from '@mui/material';
import AssetID from '@pw/components/AssetID';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import TabbedViewSkeleton from '@pw/components/sekeletons/TabbedViewSkeleton';
import AssetSummary from '@pw/components_v2/Asset/AssetSummary';
import LatestAddedAssets from '@pw/components_v2/Asset/LatestAddedAssets';
import AccessLog from '@pw/components_v2/elements/AccessLog';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { ASSET_EDIT_URL } from '@pw/consts/asset';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { upsertAssetThunk } from '@pw/redux/thunks/asset';
import styles from '@pw/styles/content.styles';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useReadOnly from '@pw/utilities/hooks/logic/useReadOnly';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import useAssetQuery from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

const AssetFormHandlerImpl = withDialogCard(({
    assetType,
    entity,
    onSave,
    readonly = false,
    tabs = []
  }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const converter = useConverter();

    console.log('Asset', entity);

    const [target, scroll] = useScrollTarget();

    // Current tab
    const [tab, setTab] = useState(entity?._id ? 'summary' : 'id');

    const changeSet = useMemo(() => ({
      name: [entity?.name ?? '', yup.string().required('Name is required!')],
      created: [entity?.created ?? Date.now(), yup.number().required('Date is required!')],
      ...tabs.reduce((acc, t) => ({ ...acc, ...(t?.properties?.(entity, converter) || {}) }), {}),
    }), [entity, tabs]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(scroll, []);

    const handleChange = (event, newValue) => setTab(newValue);

    const handleSubmit = useCallback((values) => {

      const request = {
        ...entity,
        ...values
      };

      console.log('Submitting', request);

      dispatch(upsertAssetThunk(request))
      .unwrap()
      .then((result) => {
        console.log('Mutated asset', result);
        const route = ASSET_EDIT_URL?.[assetType];
        navigate(`${route}/${result?._id}`, { replace: true });
      });
    }, [entity]);

    return (
      <Box sx={styles} className="root" ref={target}>
        <FormikForm
          changeSet={changeSet}
          onSubmit={handleSubmit}
          enableReinitialize
          readonly={readonly}
        >
          <Tabs value={tab} onChange={handleChange} aria-label="request form" variant="scrollable" scrollButtons="auto" >
            {entity?._id && (
              <Tab label="Summary" {...a11yProps('summary')} iconPosition="start" />
            )}
            <Tab label="Asset" {...a11yProps('id')} iconPosition="start" />
            {tabs.map(({ title, key, Icon, disable }) => (
              <Tab label={title} {...(Icon ? { icon: <Icon /> } : {})} {...a11yProps(key)} iconPosition="start" disabled={disable?.(entity)} />
            ))}
            {entity?.access_log && entity?.access_log.length > 0 && (
              <Tab label="Log" {...a11yProps('log')} />
            )}
          </Tabs>

          {entity?._id && (
            <CustomTabPanel value={tab} index="summary">
              <AssetSummary entity={entity} refresh={onSave} />
            </CustomTabPanel>
          )}
          <CustomTabPanel value={tab} index="id">
            <AssetID label="Name" disabled={!!entity?._id} />
          </CustomTabPanel>
          {tabs.map(({ key, Component }) => (
            <CustomTabPanel value={tab} index={key}>
              <Component entity={entity} readonly={readonly} />
            </CustomTabPanel>
          ))}
          {entity?.access_log && entity?.access_log.length > 0 && (
            <CustomTabPanel value={tab} index="log">
              <AccessLog access_log={entity?.access_log} />
            </CustomTabPanel>
          )}

          {!['summary', 'log'].includes(tab) && (
            <FormButtons readonly={readonly} />
          )}
        </FormikForm>
      </Box>
    );
  }
);

function NewForm({ assetType, assetLabel, ...rest }) {
  let [searchParams] = useSearchParams();

  const entity = useMemo(() => {
    // See if there is a clone parameter
    let clone = searchParams.get('clone');

    if (clone) {
      try {
        const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
        clone = JSON.parse(cv);
      } catch (e) {
        console.log('Failed to decode clone!', e);
      }
    }

    if (clone) {
      console.log('Cloning', clone);
      return {
        ...clone,
        _id: undefined,
        name: null,
        created: new Date().getTime(),
        access_log: null,
        audit: null,
      };
    }
    return {
      type: assetType,
      created: new Date().getTime()
    };
  }, [assetType]);

  usePageTitleHook(`Create ${assetLabel}`);

  return <AssetFormHandlerImpl assetType={assetType} entity={entity} {...rest} />;
}

function ShowForm({
  id,
  assetType,
  assetLabel,
  ...rest
}) {
  const { data: entity, isError, refetch, isLoading } = useAssetQuery({ id });
  const { readonly } = useReadOnly(entity);

  usePageTitleHook(`${assetLabel} ${entity?.name ?? '...'}`);

  const refreshData = useCallback(() => refetch({ id }), [id, refetch]);

  return (
    <>
      {isLoading && (
        <TabbedViewSkeleton />
      )}

      {isError && (
        <NewForm
          assetType={assetType}
          assetLabel={assetLabel}
          {...rest}
        />
      )}

      {entity && (
        <AssetFormHandlerImpl
          entity={entity}
          readonly={readonly}
          onSave={refreshData}
          {...rest}
        />
      )}
    </>
  );
}

// {/*<AssetSummary entity={entity} refresh={refreshData} />*/}

function AssetForm(props) {
  const { id } = useParams();
  console.log('AssetFormHandler', id);

  return (
    <Box className="adaptive-content">
      {!id && <NewForm {...props} />}
      {id && <ShowForm id={id} {...props} />}
      <LatestAddedAssets assetType={props.assetType} label={props.assetLabel} />
    </Box>
  );
}

export default AssetForm;
