import { Stack } from '@mui/material';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SingleSelect from '@pw/components_v2/elements/SingleSelect';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import { COMPANY_TYPE_REVERSE } from '@pw/consts/company';
import { RELATIONSHIP_TYPE } from '@pw/consts/relationship';
import FormikContext from '@pw/context/FormikContext';
import { useContext, useMemo } from 'react';

function ContactSearch({ label = 'Client', field = 'client', disabled = false }) {
  const { values } = useContext(FormikContext);

  const search = useMemo(() => ({
    [SEARCH_TYPES.CONTACTS]: {
      types: [RELATIONSHIP_TYPE.PARTNER, RELATIONSHIP_TYPE.CUSTOMER, RELATIONSHIP_TYPE.INTER_COMPANY],
    },
  }), []);

  console.log('Selected client', values?.[field])

  const { _id, name, company_type, compliance } = values?.[field]?.target_id ?? {};

  return (
    <SingleSelect search={search} label={label} field={field} disabled={disabled}>
      {_id && (
        <Stack>
          <EntryTitle value={name} />
          <FlexBox>
            <IDDisplay value={_id} />
            <EntryLabel label="Profile" value={COMPANY_TYPE_REVERSE[company_type]} />
            {compliance?.registration_id && (<EntryLabel label="Registration" value={compliance?.registration_id} />)}
          </FlexBox>
        </Stack>
      )}
    </SingleSelect>
  );
}

export default ContactSearch;
