import { Stack } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import SvgDelete from '@pw/components/icons/Delete';
import { Body1 } from '@pw/components/Typography';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import Search from '@pw/components_v2/search/general';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import FormikContext from '@pw/context/FormikContext';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useState } from 'react';

function Assignee({ item }) {
  const { target_id } = item;
  return (
    <Stack>
      <EntryTitle value={target_id?.name} />
    </Stack>
  );
}

const search = {
  [SEARCH_TYPES.TEAMS]: { }
};

function AssigneeSelector({ field = 'assignees', initialValues, readonly }) {
  const { setFieldValue } = useContext(FormikContext);
  const confirm = useConfirm();

  const [assignees, initAssignees, , upsertAssignees, removeAssignees] = useItemListManager({
    id: COMP._id,
    comp: COMP._id,
    initialData: [],
  });

  useEffect(() => {
    if (initialValues) {
      initAssignees(initialValues);
    }
  }, [initialValues, initAssignees]);

  useEffect(() => {
    debounce(() => setFieldValue(field, assignees), 25);
  }, [assignees]);


  const handleRemove = useCallback((removedVendor) => {
    if (removedVendor) {
      confirm({
        title: 'Remove Assignee',
        content: <Body1>{`Remove '${removedVendor?.target_id?.name}'?`}</Body1>,
      })
      .then(() => removeAssignees(removedVendor))
      .catch(() => {
      });
    }
  }, [removeAssignees]);

  const Toolbar = ({ onClose }) => {
    const [selectedVendor, setSelectedVendor] = useState(null);

    const handleClear = useCallback(() => {
      onClose();
      confirm({
        title: 'Clear Assignees',
        content: <Body1>Clear all?</Body1>,
      })
      .then(() => debounce(() => initAssignees([]), 25))
      .catch(() => {
      });
    }, [initAssignees]);

    const handleSelect = useCallback((value) => {
      if (value && value.length === 1) {
        console.log(`value `,value);
        const users = value[0]?.roles.map(({users})=> users ?? [])?.flat();
        debounce(() => {
          upsertAssignees(users);
        }, 25);
        onClose();
      }
    }, [setSelectedVendor, onClose]);

    return (
      <>
        <Search multi={false} types={search} onSelected={handleSelect} />
        {assignees && assignees.length > 0 && (
          <TitledButton handleClick={handleClear} label="Clear">
            <SvgDelete height={24} width={24} />
          </TitledButton>
        )}
      </>
    )
  }

  const tools = [
    {
      title: 'Remove vendor',
      Icon: SvgDelete,
      handler: handleRemove
    }
  ];

  console.log(`assignees `, assignees);
  

  return (
    <ToolbarSection title="Assignees" Toolbar={Toolbar}>
      <BasicList Content={Assignee} items={assignees} tools={tools} identity={(i) => i?._id} />
    </ToolbarSection>
  );
}

export default AssigneeSelector;
