import { Close, LogoutOutlined, Menu, NotificationsOutlined, SettingsOutlined } from '@mui/icons-material';
import { Badge, Popover } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SvgQrScan3 from '@pw/components/icons/QrScan3';
import SvgSearch from '@pw/components/icons/Search';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import Logo from '@pw/components/Layout/Logo';
import ScanQR from '@pw/components/ScanQR';
import SearchInput from '@pw/components/Search/SearchInput';
import { useOnlineHook } from '@pw/redux/containers/App/hooks';
// import { notificationCount } from '@pw/redux/containers/Notifications';
import { black } from '@pw/styles/colors';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import NotificationContent from '../Notification';
import { NetworkSwitch } from './NetworkSwitch';

function AppHeader({ disable, toggleMenu, setToggleMenu }) {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const notificationsCount = { totalCount: 0 };
	const { enqueueSnackbar } = useSnackbar();
	const { online } = useOnlineHook();
	const isMobile = useIsMobile();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOnQrRead = useCallback(
		({ text: qrString }) => {
			console.log('QR Code', qrString);
			const qrStringUrl = new URL(qrString);
			if (
				['.proofworks.com', '.metacask.com'].some((domain) =>
					qrStringUrl.hostname.includes(domain),
				)
			) {
				window.location.href = qrString;
			} else {
				enqueueSnackbar('Invalid QR Code. Please scan a valid QR code.');
			}
		},
		[enqueueSnackbar],
	);

	return (
		<>
			<Box className='app-header'>
				{isMobile && setToggleMenu && (
					<IconCircleButton
						onClick={setToggleMenu}
						color='secondary'
						className='menu-icon'
					>
						{toggleMenu ? <Close /> : <Menu />}
					</IconCircleButton>
				)}
				<Logo />
				{!disable && !isMobile && (
					<Box className='app-header-wrapper'>
						<IconCircleButton>
							<SvgQrScan3 />
						</IconCircleButton>
						<IconCircleButton>
							<SvgSearch />
						</IconCircleButton>
						{/*<SearchInput*/}
						{/*	Icon={() => (*/}
						{/*		<ScanQR*/}
						{/*			onSuccess={handleOnQrRead}*/}
						{/*			variant='contained'*/}
						{/*			sx={{ width: 36, height: 36 }}*/}
						{/*			height={24}*/}
						{/*			width={24}*/}
						{/*			Component={IconButton}*/}
						{/*		/>*/}
						{/*	)}*/}
						{/*/>*/}
						<Box className='app-header-icons'>
							<IconCircleButton
								aria-describedby={open ? 'notification-popover' : undefined}
								variant='contained'
								onClick={handleClick}
							>
								<Badge
									badgeContent={notificationsCount.totalCount}
									color='primary'
									max={10}
								>
									<NotificationsOutlined />
								</Badge>
							</IconCircleButton>
							<NetworkSwitch />
							<IconCircleButton
								disabled={!online}
								onClick={() => navigate('/account/settings')}
							>
								<SettingsOutlined />
							</IconCircleButton>
							<IconCircleButton
								onClick={() => navigate('/logout')}
							>
								<LogoutOutlined />
							</IconCircleButton>
						</Box>
					</Box>
				)}
				{!disable && isMobile && (
					<ScanQR
						onSuccess={handleOnQrRead}
						height={32}
						width={32}
						className='scan-icon'
						Component={IconButton}
					/>
				)}
			</Box>
			{!disable && (
				<Popover
					id='notification-popover'
					open={open}
					sx={{ zIndex: 999, position: 'absolute' }}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<div
						style={{
							width: '287px',
							backgroundColor: black,
							color: 'white',
							padding: '20px',
						}}
					>
						<NotificationContent />
					</div>
				</Popover>
			)}
		</>
	);
}

export default AppHeader;
