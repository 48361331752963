function SvgMonitoring(props) {
  return (
    <svg
      data-name='Group 1484'
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 32.129 40.708'
      className='MuiSvgIcon-root'
      {...props}
    >
      <defs>
        <style>{'.Monitoring_svg{stroke-width:0}'}</style>
      </defs>
      <g data-name='Group 1483' clipPath='url(#Monitoring_svg__a)'>
        <path
          className='Monitoring_svg__a'
          data-name='Path 10246'
          d='M16.06 40.707h-1.152a1.229 1.229 0 01-1.322-1.3c-.013-.7-.012-1.4 0-2.1a.417.417 0 00-.335-.475 1.223 1.223 0 01-.9-1.276 189.48 189.48 0 010-3.455.494.494 0 00-.427-.57C5.364 29.538 1.37 25.162.231 18.412a15.244 15.244 0 013.892-13.06A15.211 15.211 0 0112.88.331a15.62 15.62 0 0113.935 3.816 15.574 15.574 0 015.214 10.2A16.034 16.034 0 0120.35 31.488a.634.634 0 00-.568.757c.03 1.068.012 2.138.009 3.208a1.265 1.265 0 01-.962 1.4.384.384 0 00-.272.437c.006.644 0 1.289 0 1.933 0 1.032-.453 1.486-1.471 1.488H16.06m14.8-24.643a14.8 14.8 0 10-14.784 14.766 14.725 14.725 0 0014.784-14.77M16.041 35.642c.7 0 1.4-.008 2.1 0 .278 0 .4-.092.392-.38-.01-1-.007-2 0-3 0-.261-.064-.356-.36-.317a16.234 16.234 0 01-4.178 0c-.318-.041-.381.06-.379.338.008.986.011 1.973 0 2.96 0 .31.122.4.416.4.671-.014 1.343 0 2.014 0m1.252 2.577v-.985c0-.221-.091-.327-.317-.325q-.9.007-1.805 0c-.223 0-.325.095-.323.319 0 .643.005 1.286 0 1.928 0 .241.09.347.339.343q.882-.014 1.764 0c.244 0 .352-.091.345-.337-.008-.314 0-.629 0-.944'
        />
        <path
          data-name='Path 10247'
          d='M16.079 29.608A13.567 13.567 0 1129.643 16.12a13.528 13.528 0 01-13.564 13.488m12.314-13.559a12.327 12.327 0 10-12.347 12.324 12.255 12.255 0 0012.347-12.324'
        />
        <path
          data-name='Path 10248'
          d='M16.034 5.922a10.123 10.123 0 017.389 17.075c-.456.5-.755.5-1.24.024-.2-.2-.415-.4-.606-.615a.6.6 0 01-.02-.852.585.585 0 01.848-.034c.41.317.411.318.732-.1a8.945 8.945 0 001.766-4.412c.029-.24-.047-.334-.279-.328a.885.885 0 01-.2-.015c-.346-.072-.578-.255-.567-.64a.549.549 0 01.571-.582c.49.005.5-.2.444-.6a9.12 9.12 0 00-1.78-4.235c-.218-.291-.378-.366-.664-.063a.551.551 0 01-.842.012.578.578 0 01.013-.875c.3-.269.221-.414-.054-.625a9.119 9.119 0 00-4.313-1.823c-.38-.056-.593.009-.572.47.016.35-.235.546-.578.573a.6.6 0 01-.612-.592c-.051-.485-.089-.517-.577-.445a9.031 9.031 0 00-4.3 1.82c-.26.2-.332.34-.054.6a.589.589 0 01.051.877.571.571 0 01-.9-.029c-.268-.278-.413-.185-.606.067a9.06 9.06 0 00-1.838 4.512c-.029.241.05.334.28.328a.917.917 0 01.2.016c.328.067.558.235.566.6a.554.554 0 01-.573.622c-.493 0-.5.2-.443.606a9.079 9.079 0 001.781 4.23c.218.29.379.367.665.062a.549.549 0 01.835-.017.6.6 0 010 .881 8.465 8.465 0 01-.633.645c-.472.437-.75.429-1.185-.033A10.128 10.128 0 0114.657 6.01c.465-.066.929-.053 1.374-.088'
        />
        <path
          data-name='Path 10249'
          d='M16.027 26.151c-.944 0-1.889.006-2.834 0a1.239 1.239 0 01-1.347-1.358c0-.4-.009-.794 0-1.191a1.2 1.2 0 011.2-1.206q3.019-.017 6.037 0a1.2 1.2 0 011.2 1.209c.013.438.011.876 0 1.314a1.208 1.208 0 01-1.217 1.226c-1.013.015-2.026 0-3.039 0zm.013-1.211h2.221c.922-.005.8.1.832-.846.014-.412-.145-.506-.526-.5-1.549.017-3.1 0-4.646.009-.972 0-.843-.131-.871.886-.011.374.137.468.483.46.836-.019 1.672-.006 2.508-.006'
        />
        <path
          data-name='Path 10250'
          d='M16.083 14.173c.082 0 .18-.029.244 0 .549.292.843-.081 1.172-.417.775-.792 1.567-1.569 2.347-2.356a.725.725 0 01.691-.269c.5.1.635.672.231 1.1-.632.663-1.293 1.3-1.943 1.946-.126.125-.252.251-.385.369a1.293 1.293 0 00-.5 1.365 1.839 1.839 0 01-2.322 1.953.588.588 0 00-.708.206 1.537 1.537 0 01-.268.253.556.556 0 01-.811-.053.514.514 0 01-.037-.774 1.33 1.33 0 00.4-1.4 1.829 1.829 0 011.888-1.927m.628 1.852a.638.638 0 10-.629.673.668.668 0 00.629-.673'
        />
      </g>
    </svg>
  );
}
export default SvgMonitoring;
