// @ts-nocheck
import { AutofpsSelect } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { clientFields } from '@pw/components/Client/clientFields';
import SvgAssets from '@pw/components/icons/Assets';
import Instructions from '@pw/components/Instructions';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationThingModal from '@pw/components/ThingSelector/modals/DestinationThingModal';
import ThingAuditConfirmModal from '@pw/components/ThingSelector/modals/ThingAuditConfirmModal';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import { useAuditListLazyQuery } from '@pw/utilities/hooks/service/useAuditListQuery';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo, useState } from 'react';
import SourceInventory, {
  InventoryDestinations,
  InventorySources,
} from '@pw/components_v2/Request/sources';
import * as yup from 'yup';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import SvgTeams from '@pw/components/icons/Teams';
import AssetAccounts from '@pw/components/AssetAccounts';
import SvgInvoice from '@pw/components/icons/Invoice';
import InvoiceForm from '@pw/components_v2/Request/InvoiceForm';
import RequestForm from '@pw/components_v2/Request';
import FormikContext from '@pw/context/FormikContext';

function AuditAssetSelector({ assets, updateAssets, location }) {
  const { enqueueSnackbar } = useSnackbar();

  const [fetchAuditList, { isLoading }] = useAuditListLazyQuery();

  const handleGenerateAssets = useCallback(() => {
    fetchAuditList({ location })
      .then((res) => updateAssets(res.map((t) => ({ ...t, asset: t }))))
      .catch((err) => {
        console.log('Generate audit list', err);
        enqueueSnackbar(`Failed to generate audit asset list!`, {
          variant: 'error',
        });
      });
  }, [fetchAuditList, location, updateAssets, enqueueSnackbar]);

  if (!location?.location_id) return;

  return (
    <Stack spacing='2rem'>
      {assets.length === 0 && (
        <Instructions>
          Generate a list of assets to audit for this location
        </Instructions>
      )}
      {assets.length > 0 && (
        <Instructions>List of assets to audit</Instructions>
      )}

      <FlexBox>
        {assets.length === 0 && (
          <>
            <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
            {!isLoading && (
              <TitledButton handleClick={handleGenerateAssets} label='Find'>
                <AutofpsSelect />
              </TitledButton>
            )}
            {isLoading && <LoadingSmall />}
          </>
        )}
      </FlexBox>
    </Stack>
  );
}

const filter = {
  [SEARCH_TYPES.ASSETS]: {
    types: [
      ASSET_TYPES.CASK,
      ASSET_TYPES.IBC,
      ASSET_TYPES.PALLET,
      ASSET_TYPES.CONTAINER,
      ASSET_TYPES.FILLING_TANK,
    ],
  },
};

function Properties(props) {
  const [location, setLocation] = useState(null);
  const [assets, setAssets] = useState([]);
  const { values } = useContext(FormikContext);

  return (
    <Stack spacing='1.5rem'>
      <FormikProvider path='properties'>
        <Location name='location' setLocation={setLocation} />
      </FormikProvider>

      <AuditAssetSelector
        location={location}
        assets={values?.sources?.assets ?? []}
        updateAssets={setAssets}
      />

      <InventorySources
        filter={filter}
        {...props}
        updatedAssets={assets}
        AssetModal={ThingAuditConfirmModal}
      />
    </Stack>
  );
}

const filterDestination = {
  [SEARCH_TYPES.THINGS]: {
    types: [THING_TYPES.SERVICES],
  },
};

const tabs = [
  {
    title: 'Inventory',
    key: 'inventory',
    Icon: SvgAssets,
    properties: (entity) => ({ sources: [entity?.sources, yup.object()] }),
    Component: (props) => <Properties {...props} />,
  },
  {
    title: 'Services',
    key: 'services',
    Icon: SvgAssets,
    properties: (entity) => ({
      destinations: [entity?.destinations, yup.object()],
    }),
    Component: (props) => (
      <InventoryDestinations
        filter={filterDestination}
        {...props}
        ThingModal={DestinationThingModal}
      />
    ),
  },
  {
    title: 'Invoice',
    key: 'invoice',
    Icon: SvgInvoice,
    properties: (entity) => ({ client: [entity?.client, yup.object()] }),
    Component: (props) => <InvoiceForm {...props} />,
  },
];

function InventoryAudit(props) {
  //   const assetFilter = useCallback((asset) => {
  //     return asset?.things?.length;
  //   }, []);

  //   const [
  //     [sourceThings],
  //     [sourceAssets, initSourceAssets],
  //     initSources,
  //     SourceInventory,
  //     SourceModals,
  //   ] = useSourceInventoryHook({
  //     title: 'Audit Inventory',
  //     filter: {
  //       asset_types: [
  //         ASSET_TYPES.CASK,
  //         ASSET_TYPES.IBC,
  //         ASSET_TYPES.PALLET,
  //         ASSET_TYPES.CONTAINER,
  //         ASSET_TYPES.FILLING_TANK,
  //       ],
  //     },
  //     assetFilter,
  //     AssetModal: ThingAuditConfirmModal,
  //   });

  //   const [
  //     [destinationThings],
  //     [],
  //     initDestinations,
  //     DestinationInventory,
  //     DestinationModals,
  //   ] = useInventorySelectorHook({
  //     title: 'Services',
  //     filter: {
  //       thing_types: [THING_TYPES.SERVICES],
  //     },
  //     thingModalProps: { calculateThingLiquid: true },
  //     ThingModal: DestinationThingModal,
  //   });

  //   const changeSetGenerator = useMemo(
  //     () => (initialValues) => ({
  //       ...requestIdFields(initialValues),
  //       properties: propertyFields(initialValues?.properties),
  //       client: clientFields(initialValues.client),
  //     }),
  //     [],
  //   );

  //   /**
  //    * Initialization function
  //    * @type {(function(*): void)|*}
  //    */
  //   const handleInit = useCallback(
  //     (entity) => {
  //       initSources({
  //         ...entity,
  //         assets: (entity?.sources?.assets ?? []).map(asset=>({ ...(asset?.asset ?? {}), ...asset})),
  //         things: entity?.sources?.things,
  //       });
  //       initDestinations({
  //         ...entity,
  //         assets: (entity?.destinations?.assets ?? []).map(asset=>({ ...(asset?.asset ?? {}), ...asset})),
  //         things: entity?.destinations?.things
  //       });
  //     },
  //     [initSources, initDestinations],
  //   );

  //   /**
  //    * Prior to saving the entity, this is called to inject in the sources
  //    * @type {function(*): *&{sources: *, sku_sources: *}}
  //    */
  //   const handleBeforeSave = useCallback(
  //     (entity) => ({
  //       ...entity,
  //       sources: {
  //         things: sourceThings,
  //         assets: sourceAssets,
  //       },
  //       destinations: {
  //         things: destinationThings,
  //       },
  //     }),
  //     [sourceThings, sourceAssets, destinationThings],
  //   );

  return (
    <>
      <RequestForm
        requestLabel='Audit'
        requestType={REQUEST_TYPES.INVENTORY_AUDIT}
        tabs={tabs}
      >
        {/* <SourceInventory />
        <DestinationInventory /> */}
      </RequestForm>
      {/* 
      <SourceModals />
      <DestinationModals /> */}
    </>
  );
}

export default withAppLayout(InventoryAudit, { title: 'Audit' });
