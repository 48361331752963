import { Tab, Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import Pagination from '@pw/components/Pagination';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import RequestItem from '@pw/components_v2/elements/display/request/RequestItem';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import RequestSearch from '@pw/components_v2/search/general/forms/RequestsSearch';
import { REQUEST_EDIT_URL } from '@pw/consts/requests';
import styles from '@pw/styles/content.styles';
import { useRecentRequestQueryPaginated } from '@pw/utilities/hooks/service/useRecentRequestQuery';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchForm({ label, requestType }) {
	const navigate = useNavigate();

	const goToItem = useCallback((params) => {
		const id = params?._id ?? 'new';
		const route = REQUEST_EDIT_URL?.[requestType];
		console.log('Navigating to', `${route}/${id}`);
		navigate(`${route}/${id}`, { replace: true });
	}, [requestType]);

	return (
		<RequestSearch label={label} types={[requestType]} onClose={goToItem} />
	);
}

function LatestAdded({ requestType }) {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState('5');

	const reqParams = useMemo(() => {
		return {
			type: requestType,
			limit: Number(pageSize),
		};
	}, [requestType, pageSize]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
		useRecentRequestQueryPaginated(reqParams);

	const goToItem = useCallback((params) => {
		const id = params?._id ?? 'new';
		const route = REQUEST_EDIT_URL?.[requestType];
		console.log('Navigating to', `${route}/${id}`);
		navigate(`${route}/${id}`, { replace: true });
	}, [requestType]);

	return (
		<Stack spacing={0.5}>
			<Pagination
				hasNextPage={hasNext}
				hasPrevPage={hasPrev}
				onPressNextPage={loadNext}
				onPressPrevPage={loadPrev}
				onChangePageSize={setPageSize}
				defaultPageSize={pageSize}
			/>
			<BasicList
				loading={isLoading}
				Content={RequestItem}
				items={page}
				identity={(i) => i._id}
				onClick={goToItem}
			/>
		</Stack>
	);
}

function LatestAddedRequests({ label, requestType }) {
	// Current tab
	const [tab, setTab] = useState('search');

	const handleChange = (event, newValue) => setTab(newValue);

	return (
		<Stack sx={styles} className='root' spacing={3}>
			<Tabs value={tab} onChange={handleChange} aria-label="latest requests" variant="fullWidth" >
				<Tab label="Search" {...a11yProps('search')} />
				<Tab label="Latest" {...a11yProps('latest')} />
			</Tabs>

			<CustomTabPanel value={tab} index="search">
				<SearchForm label={label} requestType={requestType} />
			</CustomTabPanel>
			<CustomTabPanel value={tab} index="latest">
				<LatestAdded requestType={requestType} />
			</CustomTabPanel>
		</Stack>
	);
}

export default withSmallDialogCard(LatestAddedRequests);
