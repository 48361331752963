const formStyles = {
	'.form': {
		paddingTop: '1.5rem',
		paddingBottom: '1.5rem',
		width: '100%',

		'.group': {
			display: 'flex',
			flexDirection: {
				xs: 'column',
				md: 'row',
			},
			alignItems: 'center',
			gap: '0.5rem',
		},
	},

	'.action-buttons': {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginTop: 3,
		gap: 1,

		button: {
			maxWidth: 'min-content',
		},
	},
};

export default formStyles;
