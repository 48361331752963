import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import DesignerContext from '@pw/context/DesignerContext';
import { useContext, useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const {
    selectedNode,
    nodesData,
  } = useContext(DesignerContext);

  const existingData = nodesData?.find((n) => n.id === selectedNode?.id) ?? { id: selectedNode?.id };

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      policies: [initialValues?.policies, yup.array()],
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator} useDefault={false}>
      <PolicySelector initialValues={existingData?.policies} />
    </ModalWrapper>
  );
}

export default NodeModal;
