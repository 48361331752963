import SvgAssets from '@pw/components/icons/Assets';
import SvgInvoice from '@pw/components/icons/Invoice';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components_v2/Request';
import InvoiceForm from '@pw/components_v2/Request/InvoiceForm';
import {
  InventoryDestinations,
  InventorySources,
} from '@pw/components_v2/Request/sources';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import * as yup from 'yup';
import SvgPolicy from '@pw/components/icons/Policy';

const filter = {
  [SEARCH_TYPES.THINGS]: {
    types: [THING_TYPES.PARTS],
  },
};
const filterDestination = {
  [SEARCH_TYPES.ASSETS]: {
    types: [ASSET_TYPES.MACHINE],
  },
};

const tabs = [
  {
    title: 'Parts',
    key: 'parts',
    Icon: SvgAssets,
    properties: (entity) => ({
      sources: [entity?.sources, yup.object()],
    }),
    Component: (props) => <InventorySources filter={filter} {...props} />,
  },
  {
    title: 'Machines',
    key: 'machines',
    Icon: SvgAssets,
    properties: (entity) => ({
      destinations: [entity?.destinations, yup.object()],
    }),
    Component: (props) => (
      <InventoryDestinations filter={filterDestination} {...props} />
    ),
  },

  {
    title: 'Policy',
    key: 'policy',
    Icon: SvgPolicy,
    properties: (entity) => ({
      policies: [entity?.policies ?? [], yup.array()],
    }),
    Component: (props) => <PolicySelector {...props} />,
  },
  {
    title: 'Invoice',
    key: 'invoice',
    Icon: SvgInvoice,
    properties: (entity) => ({ client: [entity?.client, yup.object()] }),
    Component: (props) => <InvoiceForm {...props} />,
  },
];

function Maintenance() {
  //   const sourceProps = useMemo(
  //     () => ({
  //       title: 'Parts',
  //       filter: {
  //         thing_types: [THING_TYPES.PARTS],
  //       },
  //       assetFilter: (a) => false,
  //       ThingModal: SourceThingModal,
  //     }),
  //     [],
  //   );

  //   const [
  //     [sourceThings, , , upsertSourceThings, removeSourceThings],
  //     [],
  //     initSources,
  //     SourceInventory,
  //     SourceModals,
  //   ] = useInventorySelectorHook(sourceProps);

  //   const onDestinationThingAdded = useCallback(
  //     (thing) => {
  //       //
  //       //
  //       // 	if (skus.length === 0) {
  //       // 		skus = sku?.properties?.skus.filter((s) => [SKU_TYPES.CONSUMABLE, SKU_TYPES.FINISHED].includes(s.sku_type)).map((s) => ({
  //       // 			...s,
  //       // 			amount: Number(amount) * Number(s?.amount),
  //       // 		}));
  //       // 	}
  //       //
  //       // 	console.log('SKUs', skus);
  //       //
  //       // 	debounce(() => {
  //       // 		upsertSourceSkus(skus);
  //       // 	}, 25);
  //       // }
  //       // TODO: load policies from this thing...
  //       // loadPolicies(thing?.policies);
  //     },
  //     [],
  //   );

  //   const onDestinationThingRemoved = useCallback(
  //     (thing) => {
  //       console.log('Destination Thing removed', thing);
  //       // if (
  //       // 	sku?.sku_type === SKU_TYPES.FINISHED &&
  //       // 	sku?.properties?.skus?.length > 0
  //       // ) {
  //       // 	removeSourceSkus(sku?.properties?.skus);
  //       // }
  //     },
  //     [removeSourceThings],
  //   );

  //   const destProps = useMemo(
  //     () => ({
  //       title: 'Machines',
  //       filter: {
  //         asset_types: [ASSET_TYPES.MACHINE],
  //       },
  //     }),
  //     [onDestinationThingAdded, onDestinationThingRemoved],
  //   );

  //   const [
  //     [],
  //     [destinationAssets],
  //     initDestinations,
  //     DestinationInventory,
  //     DestinationModals,
  //   ] = useInventorySelectorHook(destProps);

  //   const changeSetGenerator = useMemo(
  //     () => (initialValues) => ({
  //       ...requestIdFields(initialValues),
  //       policies: [initialValues?.policies, yup.array()],
  //       client: clientFields(initialValues?.client),
  //     }),
  //     [],
  //   );

  //   /**
  //    * Initialization function
  //    * @type {(function(*): void)|*}
  //    */
  //   const handleInit = useCallback(
  //     (entity) => {
  //       initSources({
  //         ...entity,
  //         things: entity?.sources?.things,
  //       });
  //       initDestinations({
  //         ...entity,
  //         assets: entity?.destinations?.assets,
  //       });
  //     },
  //     [initSources, initDestinations],
  //   );

  //   /**
  //    * Prior to saving the entity, this is called to inject in the sources
  //    * @type {function(*): *&{sources: *, sku_sources: *}}
  //    */
  //   const handleBeforeSave = useCallback(
  //     (entity) => {
  //       return {
  //         ...entity,
  //         sources: {
  //           things: sourceThings,
  //         },
  //         destinations: {
  //           assets: destinationAssets,
  //         },
  //       };
  //     },
  //     [sourceThings, destinationAssets],
  //   );

  return (
    <>
      <RequestForm
        requestLabel='Maintenance'
        requestType={REQUEST_TYPES.MAINTENANCE}
        tabs={tabs}
      >
        {/* <SourceInventory />
        <DestinationInventory />

        <PolicySelector /> */}
      </RequestForm>

      {/* <SourceModals />
      <DestinationModals /> */}
    </>
  );
}

export default withAppLayout(Maintenance, { title: 'Maintenance' });
