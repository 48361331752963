import Stack from '@mui/material/Stack';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';

function ThingStorageItem({ item }) {
  return (
    <Stack
      direction='row'
      spacing='0.75rem'
      alignItems='center'
      sx={{ position: 'relative' }}
    >
      <Stack spacing='0.25rem'>
        <EntryTitle value={item?.thing?.name} />
        <AmountDisplay
          amount={item?.amount?.value}
          unit={item?.thing?.properties?.unit}
        />
        <AmountDisplay
          label='Reserved'
          amount={item?.reserved_quantity}
          unit={item?.thing?.properties?.unit}
        />
        <AmountDisplay
          label='Available'
          amount={item?.available_quantity}
          unit={item?.thing?.properties?.unit}
        />
      </Stack>
    </Stack>
  );
}

export default ThingStorageItem;
