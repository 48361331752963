import { Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { FormikCheckBox, FormikDatePicker, FormikTextField } from '@pw/components/Forms/FormikForm';
import SvgAssets from '@pw/components/icons/Assets';
import SvgInvoice from '@pw/components/icons/Invoice';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import RequestForm from '@pw/components_v2/Request';
import DeliveryDestinationAssetModal from '@pw/components/ThingSelector/modals/DeliveryDestinationAssetModal';
import DeliveryThingModal from '@pw/components/ThingSelector/modals/DeliveryThingModal';
import { H5 } from '@pw/components/Typography';
import InvoiceForm from '@pw/components_v2/Request/InvoiceForm';
import { InventoryDestinations } from '@pw/components_v2/Request/sources';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useContext } from 'react';
import * as yup from 'yup';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import SvgPolicy from '@pw/components/icons/Policy';

function Properties() {
  const { values, readonly } = useContext(FormikContext);
  return (
    !readonly && (
      <FormikProvider path='properties'>
        <Stack spacing={2}>
          <H5>Invoice Details</H5>
          <FlexBox alignItems='top'>
            <FormikTextField
              name='invoice_number'
              label='Invoice Number'
              fullWidth
            />
            <FormikDatePicker
              name='invoice_date'
              label='Invoice Date'
              fullWidth
            />
          </FlexBox>
          <FlexBox alignItems='top'>
            <FormikTextField
              name='invoice_amount'
              label='Total Cost'
              fullWidth
            />
            <FormikTextField name='tax_amount' label='Tax' fullWidth />
          </FlexBox>
          <Stack>
            <FormikCheckBox name='paid' label='Paid' />
            <Collapse in={Boolean(values?.properties?.paid)}>
              <FormikTextField
                name='payment_confirmation'
                label='Payment confirmation'
                fullWidth
              />
            </Collapse>
          </Stack>
        </Stack>
      </FormikProvider>
    )
  );
}

function propertyFields(fields = {}) {
  const {
    invoice_number = '',
    invoice_date = Date.now(),
    invoice_amount = '',
    tax_amount = '',
    paid = false,
    payment_confirmation = '',
  } = fields;

  return {
    invoice_number: [invoice_number, yup.string()],
    invoice_date: [invoice_date, yup.number()],
    invoice_amount: [invoice_amount, yup.number()],
    tax_amount: [tax_amount, yup.number()],
    paid: [paid, yup.bool()],
    payment_confirmation: [payment_confirmation, yup.string()],
  };
}

const filter = {
  [SEARCH_TYPES.ASSETS]: {
    types: [
      ASSET_TYPES.PALLET,
      ASSET_TYPES.IBC,
      ASSET_TYPES.CASK,
      ASSET_TYPES.TANKER,
      ASSET_TYPES.CONTAINER,
    ],
  },
  [SEARCH_TYPES.THINGS]: {
    types: [
      THING_TYPES.TRACKED,
      THING_TYPES.RAW,
      THING_TYPES.EXPIRING,
      THING_TYPES.CONSUMABLE,
      THING_TYPES.SERVICES,
      THING_TYPES.FINISHED,
    ],
  },
};

const tabs = [
  {
    title: 'Inventory',
    key: 'inventory',
    Icon: SvgAssets,
    properties: (entity) => ({ sources: [entity?.sources, yup.object()] }),
    Component: (props) => (
      <InventoryDestinations
        filter={filter}
        {...props}
        ThingModal={DeliveryThingModal}
        AssetModal={DeliveryDestinationAssetModal}
      />
    ),
  },
  {
    title: 'Policy',
    key: 'policy',
    Icon: SvgPolicy,
    properties: (entity) => ({ policies: [entity?.policies, yup.array()] }),
    Component: (props) => <PolicySelector {...props} />,
  },
  {
    title: 'Properties',
    key: 'properties',
    Icon: SvgAssets,
    properties: (entity) => ({
      properties: propertyFields(entity?.properties),
    }),
    Component: (props) => <Properties {...props} />,
  },
  {
    title: 'Invoice',
    key: 'invoice',
    Icon: SvgInvoice,
    properties: (entity) => ({ client: [entity?.client, yup.object()] }),
    Component: (props) => <InvoiceForm {...props} />,
  },
];

function DeliveryForm(props) {
  //   const location = useLocation();

  //   const [, submitForm] = useFormSubmissionHook();
  //   const { thing_id } = location.state ?? {};

  //   const things = useCompanyThings();

  //   const onThingAdded = useCallback(
  //     (thing) => {
  //       // TODO: Load policies
  //     },
  //     [],
  //   );

  //   const [
  //     [destinationThings],
  //     [destinationAssets],
  //     initDestinations,
  //     DestinationInventory,
  //     DestinationModals,
  //   ] = useInventorySelectorHook({
  //     title: 'Material & Services',
  //     filter: {
  //       thing_types: [
  //         THING_TYPES.TRACKED,
  //         THING_TYPES.RAW,
  //         THING_TYPES.EXPIRING,
  //         THING_TYPES.CONSUMABLE,
  //         THING_TYPES.SERVICES,
  //         THING_TYPES.FINISHED,
  //       ],
  //     },
  //     submitForm,
  //     assetAdapter: toTaggedAsset,
  //     assetFilter: (asset) =>
  //       [
  //         ASSET_TYPES.PALLET,
  //         ASSET_TYPES.IBC,
  //         ASSET_TYPES.CASK,
  //         ASSET_TYPES.TANKER,
  //         ASSET_TYPES.CONTAINER,
  //       ].includes(asset.asset_type),
  //     ThingModal: DeliveryThingModal,
  //     AssetModal: DeliveryDestinationAssetModal,
  //     parentAssetSupport: true,
  //     onThingAdded,
  //   });

  //   const changeSetGenerator = useMemo(
  //     () => (initialValues) => ({
  //       ...requestIdFields(initialValues),
  //       properties: propertyFields(initialValues?.properties),
  //       policies: [initialValues?.policies, yup.array()],
  //       client: [initialValues?.client, yup.object()],
  //     }),
  //     [],
  //   );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  //   const handleInit = useCallback(
  //     (entity) => {
  //       console.log('Loading delivery', entity);

  //       const { things = [], assets = [] } = entity?.destinations ?? {};

  //       if (thing_id) {
  //         const exists = things.find((s) => s._id === thing_id);
  //         if (!exists) {
  //           const selectedThing = things.find((s) => s._id === thing_id);
  //           if (selectedThing) {
  //             things.push(selectedThing);
  //           }
  //         }
  //       }

  //       initDestinations({
  //         ...entity,
  //         things: things.map((i) => {
  //           return {
  //             ...(i?.thing ?? {}),
  //             ...i,
  //             entries: i.entries.map((e) => ({
  //               ...e,
  //               storage: e.storage.map((s) => ({
  //                 ...(s?.asset ?? {}),
  //                 ...s,
  //               })),
  //             })),
  //           };
  //         }),
  //         assets,
  //       });
  //     },
  //     [thing_id, things, initDestinations],
  //   );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  //   const handleBeforeSave = useCallback(
  //     (entity) => ({
  //       ...entity,
  //       destinations: {
  //         things: destinationThings,
  //         assets: destinationAssets,
  //       },
  //     }),
  //     [destinationThings, destinationAssets],
  //   );

  return (
    <>
      <RequestForm
        requestLabel='Delivery'
        tabs={tabs}
        requestType={REQUEST_TYPES.DELIVERY}
      >
        {/* <DestinationInventory />

        <Properties />

        <PolicySelector /> */}
      </RequestForm>

      {/* <DestinationModals /> */}
    </>
  );
}

export default withAppLayout(DeliveryForm, { title: 'Deliveries' });
