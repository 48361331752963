import Stack from '@mui/material/Stack';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import SvgFillingtank from '@pw/components/icons/FillingTank';
import SvgLiquid from '@pw/components/icons/Liquid';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import AssetForm from '@pw/components_v2/Asset';
import LiquidProperties, {
  liquidPropertyFields,
} from '@pw/components_v2/Asset/LiquidProperties';
import LedgerSearch from '@pw/components_v2/search/ledger';
import ThingSearch from '@pw/components_v2/search/thing';
import { ASSET_TYPES } from '@pw/consts/asset';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

const tabs = [
  {
    title: 'Parent',
    key: 'parent',
    properties: (entity) => ({
      ledger: [entity?.ledger, yup.object()],
    }),
    Component: ({ entity }) => (
      <Stack spacing='1.5rem'>
        <LedgerSearch field='ledger' label='Ledger' disabled={!!entity._id} />
      </Stack>
    ),
  },
  {
    title: 'Properties',
    key: 'properties',
    Icon: SvgFillingtank,
    properties: (entity, converter) => ({
      properties: {
        tags: [entity?.properties?.tags, yup.array().of(yup.string())],
        thing: [entity?.properties?.thing, yup.object()],
        liquid: liquidPropertyFields(entity.properties?.liquid, converter),
      },
    }),
    Component: ({ entity }) => (
      <FormikProvider path='properties'>
        <Stack spacing='1.5rem'>
          <ThingSearch
            field='thing'
            label='Thing'
            types={[THING_TYPES.TRACKED]}
          />
          <TagSelect name='tags' type={TAG_CATEGORY.ASSET} />
        </Stack>
      </FormikProvider>
    ),
  },
  {
    title: 'Liquid',
    key: 'liquid',
    Icon: SvgLiquid,
    disable: (entity) => !entity._id,
    Component: ({ entity }) => (
      <FormikProvider path='properties'>
        <LiquidProperties name='liquid' disabled={!!entity._id} />
      </FormikProvider>
    ),
  },
];

function TankForm(props) {
  return (
    <AssetForm
      assetLabel='Filling Tank'
      assetType={ASSET_TYPES.FILLING_TANK}
      tabs={tabs}
    />
  );
}

export default withAppLayout(TankForm, { title: 'Filling Tank Management' });
