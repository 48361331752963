import { useCallback, useState } from 'react';

// import { useAccount } from '@pw/redux/containers/User/hooks';

import { Box, Button, /* Select, MenuItem, TextField */ } from "@mui/material";

import { East, West } from "@mui/icons-material";

import { viewModes } from './consts';
// import FilledButton from "@pw/components/Buttons/FilledButton";
// import DatePicker from '@pw/components/Forms/DatePicker';
// import TimePicker from '@pw/components/Forms/TimePicker';

// import { parseRecipe } from './functions';
// import { createNewBatch } from "@pw/components/ProductionDesigner/functions/designServices";
// import { createWorkflow } from '@pw/services/design.service';

import styles from './TopBar.styles';

const clickEvent = new MouseEvent("click", {
  view: window,
  bubbles: true,
  cancelable: false
});

const programmaticallyClick = (target) => {
  target.dispatchEvent(clickEvent);
}

const PrevNextButtons = ({ viewing, handleRangeChange, buttonsRef }) => (
  <Box className="flex paging-buttons">
    <Button className="pan prev" 
      onClick={(e) => { 
        console.log('prev', e, buttonsRef.current.pagPrev);
        programmaticallyClick(buttonsRef.current.pagPrev);
      }}
    >
      <West />
    </Button>
    <Box className="viewing">{viewing}</Box>
    <Button className="pan next" 
      onClick={(e) => { 
        console.log('prev', e, buttonsRef.current.pagNext);
        programmaticallyClick(buttonsRef.current.pagNext) ;
      }}
    >
      <East />
    </Button>
  </Box>
);

const TimeframeButtons = ({ viewMode, setViewMode, buttonsRef }) => {
  return (
    <Box className="flex timeframe-buttons">
      {viewModes.map(({ label, key }) => (
        <Button
          key={key}
          className={`viewmode ${viewMode === key ? 'active' : ''} `}
          // disabled={key !== 'day'}
          onClick={(e) => {
            if (key === viewMode) return;
              const targetBtn = key > viewMode 
              ? buttonsRef.current.zoomIn
              : buttonsRef.current.zoomOut;
              console.log('timeframe', e , targetBtn)
             setViewMode(key);
             programmaticallyClick(targetBtn);
          }}
          >{label}</Button>
      ))}
    </Box>
  );
}

// const ScheduleWorkflow = ({ items, item, setItem, workflow, workflows, setWorkflows, }) => {
//   const user = useAccount();
//   const d = new Date();
//   const roundingMS =  (15 * 60 * 1_000);
//   const nowRounded = Math.ceil(d.getTime() / roundingMS) * roundingMS;
//   const [startDate, setStartDate] = useState(new Date(nowRounded));


//   // const toSchedule = workflows.find(({ _id }) => _id === workflowId);
//   // console.log('ScheduleWorkflow', { toSchedule });


//   const scheduleWorkflow = useCallback(async () => {
//     const newWorkflow = await createWorkflow({ ...workflow, 
//       startDate: startDate.getTime(),

//     });
//     setWorkflows([...workflows, { ...newWorkflow, ...parseRecipe(item) }]);
//   }, [workflow, setWorkflows, user?.account_name, startDate]);

//   return (
//     <Box className="create-workflow-root flex">
//       <Select
//         className="create-workflow-select"
//         value={item?._id || 'select'}
//         onChange={({ target: { value: v } }) => {
//           console.log({ v });
//           const newItem = items.find(({ _id }) => _id === v);
//           console.log('selected Item', v, newItem)
//           setItem(v);
//         }}>
//         <MenuItem value='select'>Select Recipe</MenuItem>
//         {items.map(({_id, name }) => (
//           <MenuItem key={name} value={_id} selected={item === _id}>
//             {name} 
//           </MenuItem>
//         ))}
//       </Select>
//       <DatePicker />
//       <TimePicker />
//       <FilledButton
//         handleClick={() => scheduleWorkflow()}
//         disabled={!workflow?.name}
//       >
//         + Schedule new Workflow
//       </FilledButton>
//     </Box>
//   )
// }

const TopBar = ({
  viewMode, setViewMode, // updatePanOffset, viewing, 
  items, item, setItem, workflows, setWorkflows,
  range, setRange, handleRangeChange, buttonsRef,
}) => (
  <Box className="top-bar-root" sx={styles}>
     <PrevNextButtons /* viewing={viewing} */ handleRangeChange={handleRangeChange} buttonsRef={buttonsRef} />
    <TimeframeButtons viewMode={viewMode} setViewMode={setViewMode} buttonsRef={buttonsRef} />
    {/* <ScheduleWorkflow
      items={items}
      item={item}
      setItem={setItem}
      workflows={workflows}
      setWorkflows={setWorkflows}
    /> */}
  </Box>
);

export default TopBar;
