import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { IDENTITY } from '@pw/consts/account';
import FormikContext from '@pw/context/FormikContext';
import { MuiTelInput } from 'mui-tel-input';
import { useContext, useEffect, useMemo, useState } from 'react';
import IdentitySelector from './IdentitySelector';
import PassKeyButton from './PasskeyButton';
import styles from './identity.styles';

function Identity(props) {
	const {
		type,
		name,
		nopasskey = false,
		handlePasskeyClick,
		disabled = false,
	} = props;

	// console.log('Identity type', type, name);

	const {
		values, withErrors, handleChange, handleBlur,
	} = useContext(FormikContext);

	const [passkey, setPasskey] = useState(0);

	useEffect(() => {
		if (window.PublicKeyCredential) {
			window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
				.then((available) => {
					if (available) {
						console.log('Supported.');
						setPasskey(1);
					} else {
						console.log(
							'WebAuthn supported, Platform Authenticator *not* supported.',
						);
						setPasskey(2);
					}
				})
				.catch((err) => console.log('Something went wrong.', err));
		} else {
			console.log('Not supported.');
		}
	}, []);

	const handleChangeTel = (newPhone, info) => {
		handleChange({ target: { name, value: info.numberValue } });
	};

	const inputProps = useMemo(
		() =>
			passkey && !nopasskey && !disabled
				? {
						disableUnderline: true,
						endAdornment: (
							<InputAdornment position='end'>
								<PassKeyButton
									handleClick={() =>
										handlePasskeyClick(values?.[type], values?.[name])
									}
								/>
							</InputAdornment>
						),
					}
				: {
						disableUnderline: true,
					},
		[passkey, nopasskey, values, handlePasskeyClick],
	);

	return (
		<Box sx={styles} className='root'>
			<IdentitySelector name={type} />
			{/* {(!values[type] || values[type] === IDENTITY.EMAIL) && ( */}
				<TextField
					fullWidth
					className='identity'
					variant='filled'
					label='Email'
					disabled={disabled}
					required
					name={name}
					value={values?.[name]}
					onChange={handleChange}
					onBlur={handleBlur}
					InputLabelProps={{ shrink: true }}
					InputProps={inputProps}
					{...withErrors(name)}
				/>
			{/* )} */}
			{/* {values[type] === IDENTITY.PHONE && (
				<MuiTelInput
					fullWidth
					className='identity'
					variant='filled'
					label='Phone'
					disabled={disabled}
					required
					name={name}
					value={values?.[name]}
					onChange={handleChangeTel}
					onBlur={handleBlur}
					InputLabelProps={{ shrink: true }}
					InputProps={inputProps}
					{...withErrors(name)}
				/>
			)} */}
		</Box>
	);
}

export default Identity;
