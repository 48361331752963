import Stack from '@mui/material/Stack';
import { FormikNumberField, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ContactSearch from '@pw/components_v2/search/contact';
import LedgerSearch from '@pw/components_v2/search/ledger';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import ThingForm from '@pw/components_v2/Thing';
import { ASSET_OPTIONS } from '@pw/consts/asset';
import { THING_TYPES } from '@pw/consts/thing';
import { MASS_UNIT_OPTIONS, UNITS_OPTIONS } from '@pw/consts/units';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

const tabs = [
	{
		title: 'Identity',
		key: 'identity',
		properties: (entity, converter) => ({
			properties: {
				capacity: unitField(entity?.properties?.capacity),
				gross_weight: unitField(entity?.properties?.gross_weight),
				net_weight: unitField(entity?.properties?.net_weight),
				asset_type: [entity?.properties?.asset_type, yup.string().required('Asset type is required!')],
				prefix: [entity?.properties?.prefix, yup.string().required('Prefix is required!')],
				last_number: [
					entity?.properties?.last_number,
					yup.string().required('Last Number is required!'),
				],
			},
		}),
		Component: (props) => (
			<FormikProvider path='properties'>
				<Stack spacing="1.5rem">
					<FormikSelect
						fullWidth
						options={ASSET_OPTIONS}
						label='Asset Type'
						name='asset_type'
					/>
					<FlexBox alignItems='top'>
						<FormikTextField
							label='Prefix'
							name='prefix'
							fullWidth
							required
							autoComplete='prefix'
						/>
						<FormikNumberField
							label='Last Number'
							name='last_number'
							required
							fullWidth
						/>
					</FlexBox>
				</Stack>
			</FormikProvider>
		),
	},
	{
		title: 'Properties',
		key: 'properties',
		Component: (props) => (
			<FormikProvider path='properties'>
				<Stack spacing="1.5rem">
					<FormikUnitField label='Capacity' name='capacity' options={UNITS_OPTIONS} fullWidth />
					<FlexBox alignItems='top'>
						<FormikUnitField
							label='Gross Weight'
							name='gross_weight'
							options={MASS_UNIT_OPTIONS}
							fullWidth
						/>
						<FormikUnitField
							label='Net Weight'
							name='net_weight'
							options={MASS_UNIT_OPTIONS}
							fullWidth
						/>
					</FlexBox>
				</Stack>
			</FormikProvider>
		),
	},
	{
		title: 'Ownership',
		key: 'ownership',
		properties: (entity, converter) => ({
			client: [entity?.client, yup.object()],
			ledger: [entity?.ledger, yup.object()],
		}),
		Component: (props) => (
			<Stack spacing="1.5rem">
				<ContactSearch />
				<LedgerSearch />
			</Stack>
		)
	},
	{
		title: 'Vendors',
		key: 'vendors',
		properties: (entity, converter) => ({
			vendors: [entity?.vendors, yup.array()],
		}),
		Component: ({ entity }) => (
			<VendorSelector initialValues={entity?.vendors} />
		)
	},
	{
		title: 'Policies',
		key: 'policies',
		properties: (entity, converter) => ({
			policies: [entity?.policies, yup.array()],
		}),
		Component: ({ entity }) => (
			<PolicySelector initialValues={entity?.policies} />
		)
	}
];

function ThingPage(props) {
	return (
		<ThingForm
			thingLabel='Tracked Asset'
			thingType={THING_TYPES.TRACKED}
			tabs={tabs}
		/>
	);
}

export default withAppLayout(ThingPage, { title: 'Tracked Assets' });
