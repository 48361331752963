import { createAsset, getAsset, removeAsset, updateAsset } from '@pw/services/asset.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAssetThunk = createAsyncThunk('asset/get', async (params, { rejectWithValue }) => {
  try {
    return await getAsset(params);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const upsertAssetThunk = createAsyncThunk('asset/upsert', async (asset, { rejectWithValue }) => {
  try {
    return (asset?._id) ? await updateAsset(asset) : await createAsset(asset);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const removeAssetThunk = createAsyncThunk('asset/delete', async (_id, { rejectWithValue }) => {
  try {
    return await removeAsset({_id});
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
