import Stack from '@mui/material/Stack';
import AssetAccounts from '@pw/components/AssetAccounts';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import SvgCask2 from '@pw/components/icons/Cask2';
import SvgCaskIcon from '@pw/components/icons/CaskIcon';
import SvgIbc from '@pw/components/icons/Ibc';
import SvgLiquid from '@pw/components/icons/Liquid';
import SvgTeams from '@pw/components/icons/Teams';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import AssetForm from '@pw/components_v2/Asset';
import LiquidProperties, {
  liquidPropertyFields,
} from '@pw/components_v2/Asset/LiquidProperties';
import AssetSearch from '@pw/components_v2/search/asset';
import LedgerSearch from '@pw/components_v2/search/ledger';
import ThingSearch from '@pw/components_v2/search/thing';
import { ASSET_TYPES } from '@pw/consts/asset';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

const tabs = [
  {
    title: 'Parent',
    key: 'parent',
    properties: (entity) => ({
      thing: [entity?.thing, yup.object()],
      ledger: [entity?.ledger, yup.object()],
      parent_asset: [entity?.parent_asset, yup.object()],
    }),
    Component: ({ entity }) => (
      <Stack spacing='1.5rem'>
        <ThingSearch
          field='thing'
          label='Sales Thing'
          types={[THING_TYPES.FINISHED]}
        />
        <AssetSearch
          field='parent_asset'
          label='Pallet'
          types={[ASSET_TYPES.PALLET]}
          disabled={!!entity._id}
        />
        <LedgerSearch field='ledger' label='Ledger' disabled={!!entity._id} />
      </Stack>
    ),
  },
  {
    title: 'Wood',
    key: 'properties',
    Icon: SvgCaskIcon,
    properties: (entity, converter) => ({
      properties: {
        tags: [entity?.properties?.tags, yup.array().of(yup.string())],
        thing: [entity?.properties?.thing, yup.object()],
        compliance: {
          source: [entity.properties?.compliance?.source, yup.string()],
          duty_suspended: [
            entity.properties?.compliance?.duty_suspended,
            yup.boolean(),
          ],
        },
        liquid: liquidPropertyFields(entity.properties?.liquid, converter),
      },
    }),
    Component: () => (
      <FormikProvider path='properties'>
        <Stack spacing='1.5rem'>
          <ThingSearch
            field='thing'
            label='Thing'
            types={[THING_TYPES.TRACKED]}
          />
          <TagSelect name='tags' type={TAG_CATEGORY.ASSET} />
        </Stack>
      </FormikProvider>
    ),
  },
  {
    title: 'Liquid',
    key: 'liquid',
    Icon: SvgLiquid,
    disable: (entity) => !entity._id,
    Component: ({ entity }) => (
      <FormikProvider path='properties'>
        <LiquidProperties name='liquid' disabled={!!entity._id} />
      </FormikProvider>
    ),
  },
  {
    title: 'Accounts',
    key: 'accounts',
    disable: (entity) => !entity._id,
    properties: (entity) => ({
      accounts: assetAccountsFields(entity?.accounts),
    }),
    Icon: SvgTeams,
    Component: ({ entity }) => (
      <AssetAccounts entity={entity} disabled={!!entity?._id} />
    ),
  },
];

function CaskPage(props) {
  return (
    <AssetForm assetLabel='Cask' assetType={ASSET_TYPES.CASK} tabs={tabs} />
  );
}

export default withAppLayout(CaskPage, { title: 'Cask Management' });
