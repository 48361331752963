import { DragHandleRounded } from '@mui/icons-material';
import { Box, Collapse, Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import debounce from '@pw/utilities/debounce';
import { useCallback, useRef, useState } from 'react';

function ToolbarButton({ button, item }) {
  const { title, handler, Icon } = button;

  const clickHandler = useCallback((e) => {
    if (handler) {
      handler(item);
    }
    e.stopPropagation();
  }, [handler]);

  return (
    <IconCircleButton size='small' onClick={clickHandler} title={title}>
      <Icon style={{ height: '16px', width: '16px' }} color="secondary" />
    </IconCircleButton>
  );
}

const shadowStyle = {
  '--shadow-color': '0deg 0% 62%',
  boxShadow: `0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.47),
    1px 2px 2.6px -2.2px hsl(var(--shadow-color) / 0.53)`,
  backgroundColor: '#fff',
  border: '1px solid #000',
  borderRadius: '4px'
};

function Toolbar({ tools, item }) {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    setOpen((v) => !v);
  }, [setOpen]);

  return (
    <Stack
      spacing="0.25rem"
      sx={{
        padding: '0.25rem',
        borderRadius: '5px',
        overflow: 'hidden',
        border: '1px solid #C6DB5C',
        backgroundColor: '#C6DB5C',
        transition: `background-color 0.2s ease-in-out,
            hidden 0.4s ease-in-out,`,
        cursor: 'pointer',
        ...(open ? shadowStyle : {})
      }}
    >
      {tools && tools[0] && (
        <ToolbarButton button={tools[0]} item={item} />
      )}
      {tools.length > 1 && (
        <>
          <Collapse in={open} unmountOnExit>
            {tools.slice(1).filter((b) => (b.filter)? b.filter(item) : true).map((b) => <ToolbarButton button={b} item={item} key={b.title} />)}
          </Collapse>
          <DragHandleRounded onClick={handleClick} />
        </>
      )}
    </Stack>
  );
}

function ListItem({ item, Content, tools, readonly, onSelect, onClick }) {
  const [selected, setSelected] = useState(false);

  const handleSelect = useCallback((i, v) => {
    if (onSelect) {
      setSelected(v);
      debounce(() => onSelect(i, v));
    }
  }, [onSelect, setSelected]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'stretch',
        overflow: 'hidden',
        border: (selected) ? '1px solid #C6DB5C': '1px solid #E3E2E2',
        borderRadius: '5px',
        padding: '0.25rem',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ flexGrow: 1, overflow: 'hidden', padding: { xs: '0.25rem', md: '0.5rem' } }}>
        <Content item={item} onSelect={handleSelect} onClick={onClick} />
      </Box>

      {!readonly && tools && (
        <Box sx={{ position: 'relative' }}>
          <Toolbar tools={tools} item={item} />
        </Box>
      )}
    </Box>
  );
}

export default ListItem;
