import { Stack, Tab, Tabs } from '@mui/material';
import SourceTypeIcon from '@pw/components/Liquid/SourceTypeIcon';
import QRImage from '@pw/components/QRImage/index';
import SWABarCode from '@pw/components/SWABarCode';
import { Body2 } from '@pw/components/Typography';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import { ASSET_EDIT_URL } from '@pw/consts/asset';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { useMemo, useState } from 'react';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    pageBreakInside: 'none',
    pageBreakAfter: 'auto'
  },
  rootFullScreen: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    pageBreakInside: 'none',
    pageBreakAfter: 'auto'
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  footer: {
    borderTop: '1px solid',
    paddingTop: '0.25rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  qrDetails: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  qrIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    // border: '2px solid',
    padding: '5px',
    height: '144px',
    width: '144px',
    borderRadius: '50%'
  },
  type: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    marginTop: '.25rem'
  },
  title: {
    fontSize: '12pt',
    fontWeight: '600',
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif'
  }
};

function QrDetails({ item }) {
  return (
    <div style={styles.qrDetails}>
      <div style={styles.qrIcon}>
        <SourceTypeIcon
          type={item?.type}
          height={84}
          width={84}
        />
      </div>
    </div>
  );
}

function QRCode({ asset, fullScreen = true }) {
  const loc = typeof window !== 'undefined' && window?.location?.origin;
  const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
  const route = ASSET_EDIT_URL[asset?.type];

  const [tab, setTab] = useState('qr');

  const qrValue = useMemo(
    () => `${origin}${route}/${asset?._id}`,
    [asset, origin]
  );

  const barcodeValue = useMemo(
    () => asset?.name,
    [asset]
  );

  const handleChange = (event, newValue) => setTab(newValue);

  return (
    <Stack  sx={{ width: '100%' }}>
      <Tabs value={tab} onChange={handleChange} aria-label="digital code" variant="fullWidth">
        <Tab label="QR" {...a11yProps('qr')} />
        <Tab label="Barcode" {...a11yProps('barcode')} />
      </Tabs>

      <CustomTabPanel value={tab} index="qr">
        <div style={fullScreen ? styles.rootFullScreen : styles.root}>
          <div style={styles.content}>
            <QRImage value={qrValue} />
            <QrDetails item={asset} />
          </div>
          <div style={styles.footer}>
            {asset?.name && (
              <Body2 sx={styles.title}>{asset?.name}</Body2>
            )}
            {/*<LongLogo height={24} width={108} />*/}
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={tab} index="barcode">
        <div style={fullScreen ? styles.rootFullScreen : styles.root}>
          <div style={styles.content}>
            <SWABarCode value={barcodeValue} />
            <QrDetails item={asset} />
          </div>
        </div>
      </CustomTabPanel>
    </Stack>
  );
}

export default QRCode;
