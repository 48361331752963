import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components_v2/Request';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import RestockAssetModal from '@pw/components/SKUSelector/modals/RestockAssetModal';
import RestockSKU from '@pw/components/ThingSelector/modals/RestockThingModal';
import SourceThingModal from '@pw/components/ThingSelector/modals/SourceThingModal';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { useCallback, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import SvgAssets from '@pw/components/icons/Assets';
import {
  InventoryDestinations,
  InventorySources,
} from '@pw/components_v2/Request/sources';
import SvgPolicy from '@pw/components/icons/Policy';
import SvgInvoice from '@pw/components/icons/Invoice';
import InvoiceForm from '@pw/components_v2/Request/InvoiceForm';

const filter = {
  [SEARCH_TYPES.THINGS]: {
    types: [
      THING_TYPES.RAW,
      THING_TYPES.EXPIRING,
      THING_TYPES.CONSUMABLE,
      THING_TYPES.FINISHED,
    ],
  },
  [SEARCH_TYPES.ASSETS]: {
    types: [
      ASSET_TYPES.CASK,
      ASSET_TYPES.IBC,
      ASSET_TYPES.PALLET,
      ASSET_TYPES.CONTAINER,
    ],
  },
};
const filterDestination = {
  [SEARCH_TYPES.THINGS]: {
    types: [
      THING_TYPES.RAW,
      THING_TYPES.EXPIRING,
      THING_TYPES.CONSUMABLE,
      THING_TYPES.FINISHED,
    ],
  },
};

const tabs = [
  {
    title: 'Inventory',
    key: 'inventory',
    Icon: SvgAssets,
    properties: (entity) => ({
      sources: [entity?.sources, yup.object()],
    }),
    Component: (props) => <InventorySources filter={filter} {...props} />,
  },
  {
    title: 'Restocked Inventory',
    key: 'restocked_inventory',
    Icon: SvgAssets,
    properties: (entity) => ({
      destinations: [entity?.destinations, yup.object()],
    }),
    Component: (props) => (
      <InventoryDestinations filter={filterDestination} {...props} />
    ),
  },

  {
    title: 'Policy',
    key: 'policy',
    Icon: SvgPolicy,
    properties: (entity) => ({
      policies: [entity?.policies ?? [], yup.array()],
    }),
    Component: (props) => <PolicySelector {...props} />,
  },
  {
    title: 'Invoice',
    key: 'invoice',
    Icon: SvgInvoice,
    properties: (entity) => ({ client: [entity?.client, yup.object()] }),
    Component: (props) => <InvoiceForm {...props} />,
  },
];

function RestockForm(props) {
  //   const onThingAdded = useCallback(
  //     (thing) => {
  //       // TODO: Load policies
  //     },
  //     [],
  //   );

  //   const [
  //     [sourceThings],
  //     [sourceAssets],
  //     initSources,
  //     SourceInventory,
  //     SourceModals,
  //   ] = useSourceInventoryHook({
  //     title: 'Source Inventory',
  //     assetFilter: () => true,
  //     filter: {
  //       thing_types: [
  //         THING_TYPES.RAW,
  //         THING_TYPES.EXPIRING,
  //         THING_TYPES.CONSUMABLE,
  //         THING_TYPES.FINISHED,
  //       ],
  //       asset_types: [
  //         ASSET_TYPES.CASK,
  //         ASSET_TYPES.IBC,
  //         ASSET_TYPES.PALLET,
  //         ASSET_TYPES.CONTAINER,
  //       ],
  //       request_types: [REQUEST_TYPES.DELIVERY, REQUEST_TYPES.TRANSFER],
  //     },
  //     ThingModal: SourceThingModal,
  //     AssetModal: RestockAssetModal,
  //   });

  //   const [
  //     [destinationThings, , , upsertDestinationThings],
  //     [destinationAssets],
  //     initDestinations,
  //     DestinationInventory,
  //     DestinationModals,
  //   ] = useSourceInventoryHook({
  //     title: 'Restocked Inventory & Services',
  //     filter: {
  //       thing_types: [
  //         THING_TYPES.RAW,
  //         THING_TYPES.EXPIRING,
  //         THING_TYPES.CONSUMABLE,
  //         THING_TYPES.FINISHED,
  //       ],
  //     },
  //     ThingModal: RestockSKU,
  //     onThingAdded: onThingAdded,
  //   });

  //   useEffect(() => {
  //     const things = sourceThings.filter(
  //       (s) => !destinationThings.some((ds) => ds._id === s._id),
  //     );
  //     upsertDestinationThings([
  //       ...things.map((s) => ({
  //         ...s,
  //         entries: s.entries.map((e) => ({ ...e, storage: [] })),
  //       })),
  //     ]);
  //   }, [sourceThings]);

  //   const changeSetGenerator = useMemo(
  //     () => (initialValues) => ({
  //       ...requestIdFields(initialValues),
  //       policies: [initialValues?.policies, yup.array()],
  //     }),
  //     [],
  //   );

  //   /**
  //    * Initialization function
  //    * @type {(function(*): void)|*}
  //    */
  //   const handleInit = useCallback(
  //     (entity) => {
  //       initSources({
  //         ...entity,
  //         assets: entity?.sources?.assets,
  //         things: entity?.sources?.things,
  //       });
  //       initDestinations({
  //         ...entity,
  //         assets: entity?.destinations?.assets,
  //         things: entity?.destinations?.things,
  //       });
  //     },
  //     [initSources, initDestinations],
  //   );

  //   /**
  //    * Prior to saving the entity, this is called to inject in the sources
  //    * @type {function(*): *&{sources: *, sku_sources: *}}
  //    */
  //   const handleBeforeSave = useCallback(
  //     (entity) => ({
  //       ...entity,
  //       sources: {
  //         things: sourceThings,
  //         assets: sourceAssets,
  //       },
  //       destinations: {
  //         things: destinationThings,
  //         assets: destinationAssets,
  //       },
  //     }),
  //     [
  //       sourceThings,
  //       sourceAssets,
  //       destinationAssets,
  //       destinationThings,
  //     ],
  //   );

  return (
    <>
      <RequestForm
        requestLabel='Restock'
        requestType={REQUEST_TYPES.RESTOCK}
        tabs={tabs}
      >
        {/* <SourceInventory />
        <DestinationInventory />

        <PolicySelector /> */}
      </RequestForm>

      {/* <SourceModals />
      <DestinationModals /> */}
    </>
  );
}

export default withAppLayout(RestockForm, { title: 'Restock' });
