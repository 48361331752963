import Stack from '@mui/material/Stack';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ThingForm from '@pw/components_v2/Thing';
import { THING_TYPES } from '@pw/consts/thing';
import { UNITS_OPTIONS } from '@pw/consts/units';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

function Properties() {
  return (
    <FormikProvider path="properties">
      <Stack spacing="1.5rem">
        <FormikSelect label="Unit" name="unit" options={UNITS_OPTIONS} />
      </Stack>
    </FormikProvider>
  );
}

const tabs = [
  {
    title: 'Properties',
    key: 'properties',
    properties: (entity, converter) => ({
      properties: {
        unit: [entity?.properties?.unit, yup.string().required('Unit is required!')]
      }
    }),
    Component: Properties
  }
];

function ThingPage(props) {
  return (
    <ThingForm
      thingLabel="Waste Material"
      thingType={THING_TYPES.WASTE}
      tabs={tabs}
    />
  );
}

export default withAppLayout(ThingPage, { title: 'Waste Material' });
