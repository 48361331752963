import { baseUnitFields } from '@pw/components/BaseUnits/baseUnitFields';
import { notificationFields } from '@pw/components/NotificationSettings/notificationFields';
import addressFields from '@pw/components_v2/Address/addressFields';
import { companyTypeFields } from '@pw/components_v2/contact/companyTypeFields';
import complianceFields from '@pw/components_v2/contact/complianceFields';
import { ContactStatus } from '@pw/components_v2/contact/consts';
import { DEFAULT_UNITS } from '@pw/consts/units';
import * as yup from 'yup';

function contactFields(params, properties) {
  return {
    _id: [params?._id, yup.string()],
    contact_type: [params?.type, yup.string()],
    referrer: [params?.referrer, yup.string()],
    name: [params?.name ?? '', yup.string().required('Name is required!')],
    public_name: [
      params?.public_name ?? '',
      yup.string().required('Public name is required!'),
    ],
    base_units: baseUnitFields(params?.base_units ?? DEFAULT_UNITS),
    status: [params?.status ?? ContactStatus.READONLY, yup.string()],
    // communication details
    telephone: [params?.telephone ?? '', yup.string()],
    phone: [params?.phone ?? '', yup.string()],
    email: [params?.email ?? '', yup.string()],
    address: addressFields(params?.address, properties),
    // profile: publicProfile,
    notifications: notificationFields(params?.notifications),
    // Extended contact properties
    ...companyTypeFields(params),
    compliance: complianceFields(params?.compliance),
  };
}

export function partnerContactFields(params, properties) {
  return {
    ...contactFields(params, properties),
    contact_tag: [params?.contact_tag, yup.string()],
  };
}

export function interCompanyContactFields(params, properties) {
  return {
    ...contactFields(params, properties),
    ownership: [params?.ownership, yup.string()],
  };
}

export default contactFields;
