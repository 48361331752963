import Stack from '@mui/material/Stack';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ContactSearch from '@pw/components_v2/search/contact';
import LedgerSearch from '@pw/components_v2/search/ledger';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import ThingForm from '@pw/components_v2/Thing';
import materials from '@pw/consts/materials';
import { THING_TYPES } from '@pw/consts/thing';
import { UNITS_OPTIONS } from '@pw/consts/units';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

function Properties() {
  return (
    <FormikProvider path="properties">
      <Stack spacing="1.5rem">
        <FormikSelect label="Kind" name="kind" options={materials} />
        <FormikSelect label="Unit" name="unit" options={UNITS_OPTIONS} />
      </Stack>
    </FormikProvider>
  );
}

const tabs = [
  {
    title: 'Properties',
    key: 'properties',
    properties: (entity, converter) => ({
      properties: {
        kind: [entity?.properties?.kind, yup.string()],
        unit: [entity?.properties?.unit, yup.string().required('Unit is required!')],
      },
    }),
    Component: Properties,
  },
  {
    title: 'Ownership',
    key: 'ownership',
    properties: (entity, converter) => ({
      client: [entity?.client, yup.object()],
      ledger: [entity?.ledger, yup.object()],
    }),
    Component: (props) => (
      <Stack spacing="1.5rem">
        <ContactSearch />
        <LedgerSearch />
      </Stack>
    )
  },
  {
    title: 'Vendors',
    key: 'vendors',
    properties: (entity, converter) => ({
      vendors: [entity?.vendors, yup.array()],
    }),
    Component: ({ entity }) => (
      <VendorSelector initialValues={entity?.vendors} />
    )
  },
  {
    title: 'Policies',
    key: 'policies',
    properties: (entity, converter) => ({
      policies: [entity?.policies, yup.array()],
    }),
    Component: ({ entity }) => (
      <PolicySelector initialValues={entity?.policies} />
    )
  }
];

function ThingPage(props) {

  return (
    <ThingForm
      thingLabel="Raw Material"
      thingType={THING_TYPES.RAW}
      tabs={tabs}
    />
  );
}

export default withAppLayout(ThingPage, { title: 'Raw Materials' });
