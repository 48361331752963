import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import fetchPut from '@pw/utilities/fetchPut';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw`;

export const getRequest = async (params, headers = {}) =>
  fetchGet(
    `${BASE}/v2/get${objectToQueryString({ ...params, type: 'request' })}`,
    headers,
  );

export const createRequest = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/create`, headers, { ...data, request_type: data.type, type: 'request' });

export const updateRequest = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/update`, headers, { ...data, request_type: data.type, type: 'request' });

export const recent = async (request, headers = {}) =>
  fetchPost(`${BASE}/v2/list`, headers, {
    ...(request.type ? { filter_types: [request.type] } : {}),
    ...request,
    type: 'request',
  });

export const getRequestsList = async (request, headers = {}) =>
  fetchPost(`${BASE}/v2/list`, headers, {
    ...(request.type ? { filter_types: [request.type] } : {}),
    ...request,
    type: 'request',
  });

export const listRequests = async (request, headers = {}) =>
  fetchPost(`${APP_API_GATEWAY}/pw/v2/list`, headers, {
    ...request,
    type: 'request',
  });

export const updateRequestAdmin = async (request, headers = {}) =>
  fetchPut(`${BASE}/request/admin`, headers, request);

export const updateRequestAssign = async (request, headers = {}) =>
  fetchPut(`${BASE}/request/assign`, headers, request);

export const migrateRequest = async (request, headers = {}) =>
  fetchPost(`${BASE}/migrate`, headers, request);

export const getRequestAssets = async (params, headers = {}) =>
  fetchGet(`${BASE}/assets${objectToQueryString(params)}`, headers);
