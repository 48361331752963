import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import {
  FormikForm,
  FormikTextField,
  RenderIF,
} from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import { H5 } from '@pw/components/Typography';
import Address from '@pw/components_v2/Address';
import CompanyCompliance from '@pw/components_v2/ContactView/CompanyCompliance';
import { upsertContactThunk } from '@pw/redux/thunks/contacts';
import { useDispatch } from 'react-redux';
import { ContactType } from '../contact/consts';
import ContactTypeSelector from './ContactTypeSelector';
import { LimitsView } from '../CompanyView/CompanyLimits';

function Editor({
  id,
  entity,
  changeSet,
  edit = false,
  onCancel,
  onBeforeSave,
  onSave,
}) {
  const dispatch = useDispatch();
  const { entries = [] } = entity ?? {};

  const handleSubmit = async (values) => {
    const updated = onBeforeSave ? onBeforeSave(values) : values;

    const request = {
      ...(entity ?? {}),
      ...updated,
    };

    console.log('Submitting', request);

    dispatch(upsertContactThunk(request))
      .unwrap()
      .then((response) => onSave(response));
  };

  return (
    <>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit} edit={edit}>
        <FormWrapper>
          <FormikTextField name='name' label='Name' fullWidth required />

          <FormikTextField
            name='public_name'
            label='Display Name'
            fullWidth
            required
          />
          {/* we can only assigne at creation */}
          <ContactTypeSelector disabled={edit} />

          <RenderIF
            check={({ values }) => values.contact_type === ContactType.COMPANY}
          >
            <CompanyCompliance />
            <Stack spacing='1.5rem'>
              <H5>General Contact</H5>
              <FormikTextField name='telephone' label='Telephone' fullWidth />
              <FormikTextField name='email' label='Email' fullWidth />
            </Stack>
            <LimitsView limits={entity?.limits ?? {}} />
          </RenderIF>

          <RenderIF
            check={({ values }) => values.contact_type === ContactType.PERSON}
          >
            <Stack spacing='1.5rem'>
              <H5>Contact Details</H5>
              <FormikTextField name='telephone' label='Telephone' fullWidth />
              <FormikTextField name='phone' label='Mobile' fullWidth />
              <FormikTextField name='email' label='Email' fullWidth required />
            </Stack>
          </RenderIF>

          <Address />

          <Box className='action-buttons'>
            {onCancel && (
              <TextButton
                size='small'
                handleClick={onCancel}
                color='secondary'
                label='Cancel'
              />
            )}
            <FilledButton type='submit' size='small'>
              {id?.length ? 'Update' : 'Add'}
            </FilledButton>
          </Box>
        </FormWrapper>
      </FormikForm>
    </>
  );
}

export default Editor;
