import AssetItem from '@pw/components_v2/elements/display/asset/AssetItem';
import SingleSelect from '@pw/components_v2/elements/SingleSelect';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import FormikContext from '@pw/context/FormikContext';
import { useContext, useMemo } from 'react';

function AssetSearch({ label = 'Asset', field, types = [], disabled = false }) {
  const { values } = useContext(FormikContext);

  const search = useMemo(
    () => ({
      [SEARCH_TYPES.ASSETS]: {
        types,
      },
    }),
    [],
  );

  return (
    <SingleSelect search={search} label={label} field={field} disabled={disabled}>
      {values?.[field]?._id && (
        <AssetItem item={values?.[field]} />
      )}
    </SingleSelect>
  );
}

export default AssetSearch;
