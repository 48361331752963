import { Box } from '@mui/material';
// import ProofworksIcon from '@pw/components/icons/ProofworksIcon';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { styles } from '@pw/components/QRImage/styles';
import CircleLogo from '@pw/components/icons/CircleLogo';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';

function SWABarCode({
  value = null,
  title = null,
  ...props
}) {

  console.log('Barcode', value);

  return (
    <FlexBox justifyContent='center' style={styles.wrapper} {...props}>
      <Barcode value={value} format="CODE39" />
    </FlexBox>
  );
}

export default SWABarCode;
