import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Pack1 from '@pw/components/icons/Pack1';
import Pack2 from '@pw/components/icons/Pack2';
import Pack3 from '@pw/components/icons/Pack3';
import PackCheck from '@pw/components/icons/PackCheck';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, Overline } from '@pw/components/Typography';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { createPaymentThunk } from '@pw/redux/thunks/payment';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const style = {
  card: {
    boxShadow: '2px 2px 7px 0px #00000040',
    borderRadius: '5px',
    padding: '30px',
    background: '#D9D9D9',
    width: '316px',
  },
  label: {
    fontSize: '36px',
    fontWeight: 600,
  },
  description: {
    fontSize: '13px',
    lineHeight: '15px',
    maxWidth: '65px',
  },
  top: {
    borderBottom: '1px solid #404040',
    justifyContent: 'space-between',
    pb: '22px',
    mb: '14px',
  },
  select: {
    background: '#000000',
    color: '#fff',
    borderRadius: '30px',
    padding: '5px 14px',
    gap: '5px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '14px',
    minHeight: 'auto',
    width: 'auto',
    '&:hover': {
      background: '#3C3D37',
    },
  },
  size: { fontSize: '16px', textTransform: 'uppercase' },
  row: { gap: '16px', justifyContent: 'flex-start' },
  container: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
};

function LimitCard({ selected, item, icon, onSelect }) {
  const { quantity, price = {} } = item ?? {};
  return (
    <Stack
      sx={{ ...style.card, ...(selected ? { background: '#C7DA65' } : {}) }}
    >
      <FlexBox sx={style.top}>
        <FlexBox sx={style.row}>
          <Box sx={style.label}>{quantity}</Box>
          <Box sx={style.description}>TRANSFER CREDITS</Box>
        </FlexBox>
        {icon}
      </FlexBox>
      <FlexBox>
        <Box sx={style.size}>
          <strong>{price?.value}</strong> {price?.unit}
        </Box>
        <TextButton
          sx={style.select}
          handleClick={() => onSelect(item?.quantity)}
        >
          {selected && <PackCheck />}
          {selected ? 'Selected' : 'Select'}
        </TextButton>
      </FlexBox>
    </Stack>
  );
}

function LimitsEditor({ company, open, onClose, companyId }) {
  console.log('companyId', companyId);
  const dispatch = useDispatch();
  const [pack, setPack] = useState(null);

  const { limits = {} } = company ?? {};
  const packs = limits?.[REQUEST_TYPES.CHANGE_OWNERSHIP]?.packs ?? [];

  const calculateAmount = useCallback((amount, currency) => {
    switch (currency) {
      case 'usd':
      case 'eur':
        return Number(amount) * 100;
      default:
        return Number(amount);
    }
  }, []);

  const getIcon = (index) => {
    switch (index) {
      case 0:
        return <Pack1 />;
      case 1:
        return <Pack2 />;
      default:
        return <Pack3 />;
    }
  };

  const handlePurchase = useCallback(() => {
    const selected = packs.find((p) => p.quantity === pack);
    const price = selected?.price;
    const data = {
      name: 'Pack',
      amount: calculateAmount(price?.value, price?.unit?.toLowerCase()),
      currency: price?.unit,
      quantity: 1,
      pack,
      return_url: `${window.location.origin}/account/companies/${companyId}`,
      cancel_url: `${window.location.origin}/account/companies/${companyId}`,
      companyId,
    };

    dispatch(createPaymentThunk(data))
      .unwrap()
      .then((res) => {
        const { paymentRequest } = res;
        window.location.href = paymentRequest.url;
      });
  }, [onClose, pack, companyId, calculateAmount, packs]);

  return (
    <ModalWithClose onClose={onClose} open={open}>
      <Stack spacing='2.5rem'>
        <Stack spacing='0.5rem'>
          <Overline
            sx={{ fontWeight: '600', fontSize: '30px', color: '#393C3C' }}
          >
            Add Transfer Credits LIMITS
          </Overline>
          <Body1>
            Select the pack of credits you wish to purchase to increase your
            limits
          </Body1>
        </Stack>
        <Stack>
          <FlexBox sx={style.container}>
            {packs.map((i, index) => (
              <LimitCard
                onSelect={setPack}
                item={i}
                selected={pack === i?.quantity}
                icon={getIcon(index)}
              />
            ))}
          </FlexBox>

          <Box className='action-buttons'>
            {
              <TextButton
                size='small'
                handleClick={() => onClose()}
                color='secondary'
              >
                Cancel
              </TextButton>
            }
            <FilledButton
              type='button'
              size='small'
              handleClick={handlePurchase}
              disabled={!pack}
            >
              Purchase
            </FilledButton>
          </Box>
        </Stack>
      </Stack>
    </ModalWithClose>
  );
}

export default LimitsEditor;
