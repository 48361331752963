import { Draw } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal, Stack } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import styles from '@pw/components/ProductionDesigner/designer.modal.styles';
import DesignerView from '@pw/components/ProductionDesigner/DesignerView';
import StaticEdge from '@pw/components/ProductionDesigner/Diagram/Edges/StaticEdge';
import Node from '@pw/components/ProductionDesigner/Diagram/NodeBase';
import { productionObservations, production, productionCalculations } from '@pw/components/ProductionDesigner/nodes';
import { H5 } from '@pw/components/Typography';
import DesignerProvider from '@pw/components_v2/CompanyView/modals/plant/DesignerProvider';
import ModalSwitch from '@pw/components_v2/CompanyView/modals/plant/modals';
import { DESIGN_NODE_TYPE, DESIGN_TYPE } from '@pw/consts/designs';
import { useCallback, useMemo, useState } from 'react';

function DesignerModal({ name, state, open, onClose }) {

  const nodeGroups = useMemo(() => ({
    'Distillery': production,
    'Observations': productionObservations,
    'Calculations': productionCalculations,
  }), []);

  // Supported edge types
  const edgeTypes = useMemo(() => ({
    default: StaticEdge,
  }), []);

  // Supported node types
  const nodeTypes = useMemo(() => ({
    [DESIGN_NODE_TYPE.MACHINE]: Node,
    [DESIGN_NODE_TYPE.OBSERVATION]: Node,
    [DESIGN_NODE_TYPE.CALCULATIONS]: Node
  }), []);

  const [selectedNode, setSelectedNode] = useState(null);

  return (
    <Modal open={open} onClose={onClose}>
      <Stack sx={styles} className="root" spacing={2}>
        <FlexBox>
          <Stack direction="row" spacing={2} alignItems="center">
            <H5>Distillery Design - {name}</H5>
          </Stack>

          <IconButton
            onClick={() => onClose()}
            className="close-btn"
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </FlexBox>

        <DesignerProvider
          name={name}
          state={{ ...state, node: [selectedNode, setSelectedNode] }}
          type={DESIGN_TYPE.DISTILLERY}
          nodeGroups={nodeGroups}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
        >
          <DesignerView>
            <ModalSwitch />
          </DesignerView>
        </DesignerProvider>
      </Stack>
    </Modal>
  );
}

function DistilleryDesigner({ name, state }) {
  const [showDesigner, setShowDesigner] = useState(false);

  const handleClose = useCallback(() => {
    setShowDesigner(false);
  }, [setShowDesigner]);

  return (
    <Stack className="section">
      <H5 className="section-title">Distillery Design</H5>
      <TitledButton handleClick={() => setShowDesigner(true)} label="Design">
        <Draw height={24} width={24} />
      </TitledButton>

      {showDesigner && (
        <DesignerModal
          name={name}
          state={state}
          open={showDesigner}
          onClose={handleClose}
        />
      )}
    </Stack>
  );
}

export default DistilleryDesigner;
