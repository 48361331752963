import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import AssetSearch from '@pw/components_v2/search/asset';
import { ASSET_TYPES } from '@pw/consts/asset';
import { LIQUID_UNIT_OPTIONS, MASS_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      container: [initialValues?.container ?? {}, yup.object()],
      capacity: unitField(initialValues?.capacity),
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator} useDefault={false}>
      <AssetSearch field="container" label='Container' types={[ASSET_TYPES.CONTAINER]} />
      <FormikUnitField name="capacity" label="Capacity" options={[...LIQUID_UNIT_OPTIONS, ...MASS_UNIT_OPTIONS]} />
    </ModalWrapper>
  );
}

export default NodeModal;
