import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const PUB_BASE = `${APP_API_GATEWAY}/accounts-pub`;
const PRIV_BASE = `${APP_API_GATEWAY}/accounts`;

// CRUD
/** List user's CompanyView */
export const listCompanies = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/list`, headers, { ...request, type: 'company' });
/** Get the details of a specific company */
export const getCompany = async (params = {}, headers = {}) =>
  fetchGet(
    `${PRIV_BASE}/v2/get${objectToQueryString({ ...params, type: 'company' })}`,
    headers,
  );
/** Create a new company */
export const createCompany = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/create`, headers, { ...request, type: 'company' });
/** update company */
export const updateCompany = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/update`, headers, { ...request, type: 'company' });

// CRUD CONTACTS
/** List user's ContactView */
export const listContacts = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/list`, headers, {
    ...request,
    type: 'contact',
  });
/** Get the details of a specific contact */
export const getContact = async (params = {}, headers = {}) =>
  fetchGet(
    `${PRIV_BASE}/v2/get${objectToQueryString({ ...params, type: 'contact' })}`,
    headers,
  );
/** Create a new Contact */
export const createContact = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/create`, headers, {
    ...request,
    type: 'contact',
    relationship_type:
      request.contact_type === 'person' ? 'customer' : 'partner', // this is mapped for creation
  });
/** update contact */
export const updateContact = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/update`, headers, {
    ...request,
    type: 'contact',
    relationship_type:
      request.contact_type === 'person' ? 'customer' : 'partner', // this is mapped for mutation
  });
/** load owned assets of contact */
export const contactAssets = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/contact/assets`, headers, request);

// PARTNER CONTACTS
/** Create|upsert a new Partner Contact */
export const createPartnerContact = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/create`, headers, {
    ...request,
    relationship_type: 'contact',
    contact_type: 'person',
    type: 'contact',
  });
/** update partner contact */
export const updatePartnerContact = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/update`, headers, {
    ...request,
    relationship_type: 'contact',
    type: 'relationship',
  });
/** used to get relation object */
export const getRelationship = async (request, headers = {}) =>
  fetchGet(
    `${PRIV_BASE}/v2/get${objectToQueryString({ ...request, type: 'relationship' })}`,
    headers,
  );

/** used to get relation object */
export const getPartnerContactV2 = async (request, headers = {}) =>
  fetchGet(
    `${PRIV_BASE}/v2/get${objectToQueryString({ ...request, type: 'relationship-v2' })}`,
    headers,
  );

// USERS
export const listUsers = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/list`, headers, {
    ...request,
    filter_types: ['user'],
    type: 'contact',
  });
export const createUser = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/create`, headers, {
    ...request,
    relationship_type: 'user',
    contact_type: 'person',
    type: 'contact',
  });
export const updateUser = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/update`, headers, {
    ...request,
    relationship_type: 'user',
    contact_type: 'person',
    type: 'contact',
  });

export const getDashboard = async (params = {}, headers = {}) =>
  fetchGet(
    `${PRIV_BASE}/v2/get${objectToQueryString({ ...params, type: 'dashboard' })}`,
    headers,
  );

export const updateDashboard = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/update`, headers, {
    ...request,
    type: 'dashboard',
  });

export const removeUser = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/remove`, headers, {
    ...request,
    relationship_type: 'user',
    type: 'contact',
  });

// TEAMS
/** Get the details of a specific contact */
export const getTeam = async (params = {}, headers = {}) =>
  fetchGet(
    `${PRIV_BASE}/v2/get${objectToQueryString({ ...params, type: 'team' })}`,
    headers,
  );
export const listTeams = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/list`, headers, {
    ...request,
    type: 'team',
  });
export const createTeam = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/create`, headers, {
    ...request,
    type: 'team',
  });
export const updateTeam = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/update`, headers, {
    ...request,
    type: 'team',
  });
export const removeTeam = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/remove`, headers, {
    ...request,
    type: 'team',
  });

// PERMISSIONS
export const listPermissions = async () => fetchGet(`${PUB_BASE}/perm`);

// CUSTOMERS
export const resetCustomerPassword = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/reset`, headers, request);
export const uploadOwners = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/customer/upload`, headers, request);

// PARTNERS
export const connectWithPartner = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/connect`, headers, request);

// INVITE [user,contact,partner]
export const invite = async (request, headers = {}) =>
  fetchPost(`${PRIV_BASE}/v2/invite`, headers, request);

export const public_load_invite_service = createApi({
  reducerPath: 'public_load_invite_service',
  baseQuery: fetchBaseQuery({ baseUrl: PUB_BASE }),
  endpoints: (builder) => ({
    loadInvite: builder.query({
      query: (params) => `/v2/invite${objectToQueryString({ ...params })}`,
    }),
  }),
});

export const { useLoadInviteQuery, useLazyLoadInviteQuery } =
  public_load_invite_service;
