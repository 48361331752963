function SvgInvite(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 27 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Invite_svg__clip0_8235_3153)'>
        <path
          d='M0 5.72c.101-.22.287-.31.518-.355C5.135 4.41 9.753 3.458 14.37 2.499L26.234.045c.068-.017.135-.034.208-.04.434-.05.67.243.519.655a752.353 752.353 0 01-1.391 3.785c-1.594 4.338-3.193 8.681-4.787 13.02-.028.084-.056.168-.1.242-.147.287-.406.366-.682.203-.203-.119-.4-.26-.597-.39-1.464-.953-2.934-1.906-4.398-2.865-.146-.096-.242-.101-.394 0-1.588 1.072-3.187 2.132-4.775 3.198-.433.288-.732.124-.816-.395-.164-1.026-.355-2.047-.54-3.068-.237-1.326-.485-2.646-.716-3.971a.441.441 0 00-.265-.35A4255.188 4255.188 0 01.44 6.34C.275 6.256.145 6.103 0 5.985V5.72zm12.061 5.957l7.98 5.195a31776.46 31776.46 0 005.551-15.095c-.017-.011-.034-.017-.05-.028-4.483 3.3-8.965 6.6-13.48 9.928zM1.791 6.025c.135.078.208.124.281.163 1.932 1.021 3.869 2.042 5.8 3.069.18.096.32.107.507.005 4.87-2.504 9.747-5.009 14.623-7.508.073-.04.146-.084.225-.124 0-.011-.01-.028-.017-.04C16.087 3.064 8.97 4.536 1.79 6.026zm7.793 9.442s.04 0 .056-.005c.372-1.207.749-2.415 1.11-3.627.084-.288.23-.491.467-.672 3.361-2.465 6.717-4.941 10.073-7.412.073-.05.141-.107.209-.163a.501.501 0 00-.231.062c-4.145 2.126-8.283 4.253-12.428 6.368-.191.096-.214.203-.18.39.214 1.139.417 2.284.62 3.43.095.54.197 1.082.298 1.624l.006.005zm.743.908s.028.023.045.034c1.194-.795 2.387-1.596 3.61-2.408-.828-.542-1.628-1.06-2.45-1.597-.405 1.348-.805 2.663-1.2 3.971h-.005z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='Invite_svg__clip0_8235_3153'>
          <path fill='#fff' d='M0 0h27v18H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgInvite;
