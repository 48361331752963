import Stack from '@mui/material/Stack';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ContactSearch from '@pw/components_v2/search/contact';
import LedgerSearch from '@pw/components_v2/search/ledger';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import ThingForm from '@pw/components_v2/Thing';
import kinds from '@pw/consts/expiringMaterials';
import categories from '@pw/consts/materialCategories';
import { THING_TYPES } from '@pw/consts/thing';
import { UNITS_OPTIONS } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useContext } from 'react';
import * as yup from 'yup';

function Kind() {
  const { values } = useContext(FormikContext);
  const category = values?.category;

  return (
    <>
      <FormikSelect label="Category" name="category" options={categories} />
      {category === 'fermentable' && <FormikSelect label="Fermentable" name="fermentable" options={kinds} />}
    </>
  );
}

function Properties() {
  return (
    <FormikProvider path="properties">
      <Stack spacing="1.5rem">
        <Kind />
        <FormikSelect label="Unit" name="unit" options={UNITS_OPTIONS} />
      </Stack>
    </FormikProvider>
  );
}

const tabs = [
  {
    title: 'Properties',
    key: 'properties',
    properties: (entity, converter) => ({
      properties: {
        category: [entity?.properties?.category, yup.string()],
        fermentable: [entity?.properties?.fermentable, yup.string()],
        unit: [entity?.properties?.unit, yup.string().required('Unit is required!')],
      },
    }),
    Component: Properties,
  },
  {
    title: 'Ownership',
    key: 'ownership',
    properties: (entity, converter) => ({
      client: [entity?.client, yup.object()],
      ledger: [entity?.ledger, yup.object()],
    }),
    Component: (props) => (
      <Stack spacing="1.5rem">
        <ContactSearch />
        <LedgerSearch />
      </Stack>
    )
  },
  {
    title: 'Vendors',
    key: 'vendors',
    properties: (entity, converter) => ({
      vendors: [entity?.vendors, yup.array()],
    }),
    Component: ({ entity }) => (
      <VendorSelector initialValues={entity?.vendors} />
    )
  },
  {
    title: 'Policies',
    key: 'policies',
    properties: (entity, converter) => ({
      policies: [entity?.policies, yup.array()],
    }),
    Component: ({ entity }) => (
      <PolicySelector initialValues={entity?.policies} />
    )
  }
];

function ThingPage(props) {
  return (
    <ThingForm
      thingLabel="Expiring Materials"
      thingType={THING_TYPES.EXPIRING}
      tabs={tabs}
    />
  );
}

export default withAppLayout(ThingPage, { title: 'Expiring Materials' });
