import { Box, Skeleton, Stack } from '@mui/material';
import IdIcon from '@pw/components/icons/Id';
import SourceTypeIcon from '@pw/components/Liquid/SourceTypeIcon';
import { Body2, Body3 } from '@pw/components/Typography';

function ParentAssetDisplay({ asset }) {
	if (!asset) return;

	return (
		<Stack
			direction="row"
			alignItems="center"
			sx={{
				gap: '0.25rem',
				padding: '0.2rem',
				paddingLeft: '0.4rem',
				paddingRight: '0.4rem',
				backgroundColor: 'rgba(125,185,125,0.15)',
				border: '1px solid rgba(125,185,125,0.6)',
				borderRadius: '50px',
				overflow: 'hidden',
				width: 'min-content',
			}}
		>
			<SourceTypeIcon type={asset?.type} style={{ width: '14px', height: '14px' }} />
			<Body2 sx={{ whiteSpace: 'nowrap' }}>{asset?.name}</Body2>
		</Stack>
	);
}

export default ParentAssetDisplay;
