import { Tab, Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import Pagination from '@pw/components/Pagination';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import ThingItem from '@pw/components_v2/elements/display/thing/ThingItem';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import ThingsSearch from '@pw/components_v2/search/general/forms/ThingsSearch';
import { THING_EDIT_URL } from '@pw/consts/thing';
import styles from '@pw/styles/content.styles';
import { useRecentThingQueryPaginated } from '@pw/utilities/hooks/service/useRecentThingQuery';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchForm({ label, thingType }) {
  const navigate = useNavigate();

  const goToItem = useCallback((params) => {
    const route = THING_EDIT_URL[thingType];
    const id = params?._id ?? '';
    console.log('Navigating to', params, route, id);
    navigate(`${route}/${id}`);
  }, [thingType]);

  return (
    <ThingsSearch label={label} types={[thingType]} onClose={goToItem} />
  );
}

function LatestAdded({ thingType }) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState('5');

  const reqParams = useMemo(() => {
    return {
      type: thingType,
      limit: Number(pageSize)
    };
  }, [thingType, pageSize]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useRecentThingQueryPaginated(reqParams);

  const goToItem = useCallback((params) => {
    const route = THING_EDIT_URL[thingType];
    const id = params?._id ?? '';
    console.log('Navigating to', params, route, id);
    navigate(`${route}/${id}`);
  }, [thingType]);

  return (
    <Stack spacing={0.5}>
      <Pagination
        hasNextPage={hasNext}
        hasPrevPage={hasPrev}
        onPressNextPage={loadNext}
        onPressPrevPage={loadPrev}
        onChangePageSize={setPageSize}
        defaultPageSize={pageSize}
      />
      <BasicList
        loading={isLoading}
        Content={ThingItem}
        items={page}
        identity={(i) => i._id}
        onClick={goToItem}
      />
    </Stack>
  );
}

function ThingNavigation({ label, thingType, thingId }) {
  // Current tab
  const [tab, setTab] = useState('search');

  const handleChange = (event, newValue) => setTab(newValue);

  return (
    <Stack sx={styles} className="root" spacing={3}>
      <Tabs value={tab} onChange={handleChange} aria-label="latest things" variant="fullWidth">
        <Tab label="Search" {...a11yProps('search')} />
        <Tab label="Latest" {...a11yProps('latest')} />
      </Tabs>

      <CustomTabPanel value={tab} index="search">
        <SearchForm label={label} thingType={thingType} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index="latest">
        <LatestAdded thingType={thingType} />
      </CustomTabPanel>
    </Stack>
  );
  // const navigate = useNavigate();
  //
  // const goToItem = (params) => {
  //   const route = THING_EDIT_URL[thingType];
  //   const id = params?._id ?? '';
  //   console.log('Navigating to', params, route, id);
  //   navigate(`${route}/${id}`);
  // };
  //
  // const goToAssetItem = (params) => {
  //   const { _id = '', type } = params;
  //   if (type) {
  //     navigate(`${ASSET_EDIT_URL[type]}/${_id}`);
  //     return;
  //   }
  // };
  //
  // return (
  //   <Stack sx={styles} className='root' spacing={3}>
  //     <Box className='section'>
  //       <GeneralSearch
  //         title='Search'
  //         types={{ thing_types: [thingType] }}
  //         label={THING_TYPE_REVERSE[thingType]}
  //         buttonIcon={<ArrowForward />}
  //         onItemClick={goToItem}
  //       />
  //     </Box>
  //     <ThingList thingType={thingType} />
  //     {[THING_TYPES.LIQUID, THING_TYPES.TRACKED, THING_TYPES.WIP].includes(thingType) && !!thingId && (
  //       <ThingAssets
  //         thing_id={thingId}
  //         thing_type={thingType}
  //         buttonIcon={<ArrowForward />}
  //         onItemClick={goToAssetItem}
  //       />
  //     )}
  //     {/*{[THING_TYPES.LIQUID, THING_TYPES.TRACKED, THING_TYPES.WIP].includes(skuType) &&*/}
  //     {/*	!!skuId && (*/}
  //     {/*		<SKUAssets*/}
  //     {/*			sku_id={skuId}*/}
  //     {/*			sku_type={skuType}*/}
  //     {/*			buttonIcon={<ArrowForward />}*/}
  //     {/*			onItemClick={goToAssetItem}*/}
  //     {/*		/>*/}
  //     {/*	)}*/}
  //   </Stack>
  // );
}

export default withSmallDialogCard(ThingNavigation);
