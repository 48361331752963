import { Box, Tab, Tabs } from '@mui/material';
import SvgAssets from '@pw/components/icons/Assets';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import { useMemo, useState } from 'react';
import CustomTabPanel, { a11yProps } from '../../CustomTabPanel';
import { WidgetsOutlined } from '@mui/icons-material';
import AssetItem from './AssetItem';
import BasicList from '../../lists/BasicList';
import ThingStorageItem from '../thing/ThingStorageItem';

const AssetHoldings = ({ entity, filter = {} }) => {
  const tabs = useMemo(() => Object.keys(filter), [filter]);
  const [value, setValue] = useState(tabs[0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        border: '1px solid #d9d9d9',
        borderRadius: '5px',
        overflow: 'hidden',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='source inventory management'
      >
        {filter[SEARCH_TYPES.ASSETS] && (
          <Tab
            label='Assets'
            icon={<SvgAssets />}
            {...a11yProps(SEARCH_TYPES.ASSETS)}
            iconPosition='start'
          />
        )}
        {filter[SEARCH_TYPES.THINGS] && (
          <Tab
            label='Things'
            icon={<WidgetsOutlined />}
            {...a11yProps(SEARCH_TYPES.THINGS)}
            iconPosition='start'
          />
        )}
      </Tabs>
      {filter[SEARCH_TYPES.ASSETS] && (
        <CustomTabPanel value={value} index={SEARCH_TYPES.ASSETS}>
          <BasicList
            Content={AssetItem}
            items={entity?.child_assets ?? []}
            identity={(i) => i?._id}
            item={(i) => i}
            readonly
          />
        </CustomTabPanel>
      )}
      {filter[SEARCH_TYPES.THINGS] && (
        <CustomTabPanel value={value} index={SEARCH_TYPES.THINGS}>
          <BasicList
            Content={ThingStorageItem}
            items={entity?.things ?? []}
            identity={(i) => i.thing._id}
            item={(i) => i}
            readonly
          />
        </CustomTabPanel>
      )}
    </Box>
  );
};

export default AssetHoldings;
