import { Box } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import SvgAddNew from '@pw/components/icons/AddNew';
import SvgDelete from '@pw/components/icons/Delete';
import ScanQR from '@pw/components/ScanQR';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import Search from '@pw/components_v2/search/general';
import { ASSET_TYPES } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import debounce from '@pw/utilities/debounce';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext } from 'react';

function SingleSelectV2({ label, field, search, children, onNew, onScan, disabled = false }) {
  const { values, setFieldValue } = useContext(FormikContext);

  const Toolbar = ({ onClose }) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleClear = useCallback(() => {
      debounce(() => {
        setFieldValue(field, null);
      }, 25);
    }, [setFieldValue]);

    const handleSelect = useCallback((value) => {
      if (value && value.length === 1) {
        debounce(() => {
          onClose();
          setFieldValue(field, value[0]);
        }, 25);
      }
    }, [setFieldValue]);

    const handleOnQrRead = useCallback(({ text: qrString }) => {
      console.log('Read QR code', qrString);
      try {
        const [token_address, chain, platform] = qrString.split('/').reverse();
        if (Object.keys(ASSET_TYPES).some((item) => qrString.includes(item))) {
          onScan({ id: token_address, platform, chain, type: 'asset' });
          // getAsset({  }).then((asset) => onScan(asset));
        } else {
          onScan({ id: token_address, platform, chain, type: 'request' });
          // getRequest({ id: token_address, platform, chain }).then((request) => onScan(request));
        }
      } catch (error) {
        enqueueSnackbar('Failed to scan the QR code!', { variant: 'info' });
        console.log(error);
      }
    }, [onScan]);

    return (
      <>
        {onScan && (
          <ScanQR
            onSuccess={handleOnQrRead}
            closeOnSuccess
            variant="outlined"
            color="secondary"
            width={24}
            height={24}
            withLabel
            Component={TitledButton}
          />
        )}
        <Search multi={false} types={search} onSelected={handleSelect} />
        {onNew && (
          <TitledButton handleClick={onNew} label="Clear">
            <SvgAddNew height={24} width={24} />
          </TitledButton>
        )}
        {values?.[field] && (
          <TitledButton handleClick={handleClear} label="Clear">
            <SvgDelete height={24} width={24} />
          </TitledButton>
        )}
      </>
    );
  };

  return (
    <ToolbarSection title={label} Toolbar={Toolbar} disabled={disabled}>
      {children && (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'stretch',
            overflow: 'hidden',
            border: '1px solid #E3E2E2',
            borderRadius: '5px',
            padding: '0.25rem'
          }}
        >
          <Box sx={{ flexGrow: 1, overflow: 'hidden', padding: { xs: '0.25rem', md: '0.5rem' } }}>
            {children}
          </Box>
        </Box>
      )}
    </ToolbarSection>
  );
}

export default SingleSelectV2;
