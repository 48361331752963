import handleError from '@pw/redux/thunks/utils/handleError';
import { createPayment } from '@pw/services/payment.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createPaymentThunk = createAsyncThunk(
  `payment/create`,
  async (payment, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Creating payment', payment);
      const response = await createPayment(payment);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);
