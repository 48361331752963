import { Skeleton, Tab, Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import styles from '@pw/styles/content.styles';

function TabbedViewSkeleton() {
  return (
    <Stack sx={styles} className='root' spacing={3}>
      <Tabs value={'0'} aria-label="request form" variant="scrollable" >
        <Tab label={(<Skeleton variant='text' width={50} />)} {...a11yProps('0')} />
        <Tab label={(<Skeleton variant='text' width={50} />)} {...a11yProps('1')} />
        <Tab label={(<Skeleton variant='text' width={50} />)} {...a11yProps('2')} />
        <Tab label={(<Skeleton variant='text' width={50} />)} {...a11yProps('3')} />
        <Tab label={(<Skeleton variant='text' width={50} />)} {...a11yProps('4')} />
      </Tabs>

      <CustomTabPanel value={'0'} index="0">
        <Skeleton variant='text' width={100} />
        <Skeleton variant='text' width={100} />
        <Skeleton variant='text' width={100} />
      </CustomTabPanel>
    </Stack>
  );
}

export default withSmallDialogCard(TabbedViewSkeleton);
