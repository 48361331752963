import Stack from '@mui/material/Stack';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import SvgAssets from '@pw/components/icons/Assets';
import SvgPallet from '@pw/components/icons/Pallet';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import AssetForm from '@pw/components_v2/Asset';
import AssetHoldings from '@pw/components_v2/elements/display/asset/AssetHoldings';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import LedgerSearch from '@pw/components_v2/search/ledger';
import ThingSearch from '@pw/components_v2/search/thing';
import { ASSET_TYPES } from '@pw/consts/asset';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

const tabs = [
  {
    title: 'Parent',
    key: 'parent',
    properties: (entity) => ({
      ledger: [entity?.ledger, yup.object()],
    }),
    Component: ({ entity }) => (
      <Stack spacing='1.5rem'>
        <LedgerSearch field='ledger' label='Ledger' disabled={!!entity._id} />
      </Stack>
    ),
  },
  {
    title: 'Properties',
    key: 'properties',
    Icon: SvgPallet,
    properties: (entity, converter) => ({
      properties: {
        tags: [entity?.properties?.tags, yup.array().of(yup.string())],
        thing: [entity?.properties?.thing, yup.object()],
      },
    }),
    Component: ({ entity }) => (
      <FormikProvider path='properties'>
        <Stack spacing='1.5rem'>
          <ThingSearch
            field='thing'
            label='Thing'
            types={[THING_TYPES.TRACKED]}
          />
          <TagSelect name='tags' type={TAG_CATEGORY.ASSET} />
        </Stack>
      </FormikProvider>
    ),
  },
  {
    title: 'Inventory',
    key: 'inventory',
    Icon: SvgAssets,
    disable: (entity) => !entity._id,
    Component: ({ entity }) => (
      <AssetHoldings
        entity={entity}
        filter={{
          [SEARCH_TYPES.ASSETS]: {},
          [SEARCH_TYPES.THINGS]: {},
        }}
      />
    ),
  },
];

function PalletForm(props) {
  return (
    <AssetForm assetLabel='Pallet' assetType={ASSET_TYPES.PALLET} tabs={tabs} />
  );
}

export default withAppLayout(PalletForm, { title: 'Pallet Management' });
