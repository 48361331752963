function PackCheck() {
  return (
    <svg
      width='10'
      height='9'
      viewBox='0 0 10 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_8519_2669)'>
        <path
          d='M0.0153285 4.94752C0.0437662 5.05664 0.0888289 5.17203 0.153142 5.29954C0.734583 6.4501 1.56671 7.42966 2.37959 8.31724C2.56466 8.51918 2.79172 8.70857 3.05378 8.87956C3.34779 9.07104 3.60198 9.03467 3.85441 8.76459C3.96554 8.64585 4.05567 8.52628 4.12217 8.40964C4.84405 7.1462 5.72299 5.98478 6.56168 4.90321C7.39731 3.82581 8.22857 2.78312 9.20988 1.87422C9.40151 1.69653 9.59314 1.48499 9.81276 1.20822C9.87183 1.1338 9.90945 1.04099 9.94926 0.943155C9.96501 0.903856 9.98164 0.863302 9.99958 0.824002C9.95933 0.577335 9.86658 0.379584 9.70864 0.202736C9.5052 -0.0246992 9.33107 -0.0573094 9.08651 0.0877642C8.82007 0.245798 8.61138 0.402996 8.44775 0.568556C8.28894 0.729516 8.12925 0.889641 7.96913 1.04935C7.39294 1.6263 6.79662 2.22248 6.2668 2.85085C5.67661 3.55114 5.11748 4.29574 4.57673 5.01609C4.36104 5.30331 4.13792 5.60014 3.9161 5.89071C3.8531 5.97307 3.7936 6.06087 3.73585 6.14532C3.66454 6.24984 3.59104 6.35812 3.50966 6.4593C3.39897 6.59643 3.30447 6.65705 3.2056 6.65621C3.10453 6.65538 3.00916 6.58974 2.89672 6.44383L2.7156 6.20929C2.33234 5.71303 1.93597 5.20004 1.5409 4.69918C1.30946 4.40569 1.11696 4.26689 0.879834 4.26689C0.71577 4.26689 0.529832 4.33336 0.294455 4.4613L0.28308 4.46757C0.254205 4.48345 0.226642 4.49809 0.20083 4.51481C0.0293286 4.6256 -0.0314843 4.76733 0.014891 4.9471L0.0153285 4.94752Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_8519_2669'>
          <rect width='10' height='9' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PackCheck;
