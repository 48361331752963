import { ArrowForward, Delete, Edit, TaskOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import LiquidContent from '@pw/components/AssetListItem/LiquidContent';
import LocationContent from '@pw/components/AssetListItem/LocationContent';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import Switch from '@pw/components/SwitchComponent';
import { ASSET_EDIT_URL, ASSET_TYPES } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import { forwardRef, useCallback, useContext } from 'react';

function CaskContent({ item }) {
  const { location, properties = {} } = item ?? {};

  return (
    <>
      <LocationContent location={location} />
      <LiquidContent {...properties} />
    </>
  );
}

function IBCContent({ item }) {
  const { location, properties = {} } = item ?? {};

  return (
    <>
      <LocationContent location={location} />
      <LiquidContent {...properties} />
    </>
  );
}

function TankerContent({ item }) {
  const { properties = {} } = item ?? {};

  return <LiquidContent {...properties} />;
}

function FillingTankContent({ item }) {
  const { properties = {} } = item ?? {};

  return <LiquidContent {...properties} />;
}

function PalletContent({ item }) {
  const { location } = item ?? {};

  return <LocationContent location={location} />;
}

function ContainerContent({ item }) {
  const { location } = item ?? {};

  return <LocationContent location={location} />;
}

export function AssetListItemComponent({ item, display }) {
  const assetType = item?.type ?? '';

  const openAsset = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const route = ASSET_EDIT_URL?.[item?.type];
      console.log('Routing to', route);
      window.open(`${route}/${item?._id ?? item?.asset?._id ?? item?.asset}`, '_blank').focus();
    },
    [item],
  );

  return (
    <Stack spacing={0.5} sx={{ flexGrow: 1, textAlign: 'left' }}>
      <Grid container>
        <Grid item xs={6}>
          <IDDisplay value={item?.name} onClick={openAsset} />
        </Grid>
        <Grid item xs={6}>
          <IDDisplay value={item?.properties?.reference} />
        </Grid>
      </Grid>
      <SourceDisplay type={assetType} name={assetType?.toUpperCase()} />
      <Switch value={item?.type}>
        <Switch.Case condition={ASSET_TYPES.CASK}>
          <CaskContent item={item} />
        </Switch.Case>
        <Switch.Case condition={ASSET_TYPES.IBC}>
          <IBCContent item={item} />
        </Switch.Case>
        <Switch.Case condition={ASSET_TYPES.TANKER}>
          <TankerContent item={item} />
        </Switch.Case>
        <Switch.Case condition={ASSET_TYPES.FILLING_TANK}>
          <FillingTankContent item={item} />
        </Switch.Case>
        <Switch.Case condition={ASSET_TYPES.PALLET}>
          <PalletContent item={item} />
        </Switch.Case>
        <Switch.Case condition={ASSET_TYPES.CONTAINER}>
          <ContainerContent item={item} />
        </Switch.Case>
        <Switch.Default></Switch.Default>
      </Switch>
      {display && display(item)}
    </Stack>
  );
}

/**
 * Unified Asset component
 * @param item
 * @param selected function to check if the item should be displayed "selected"
 * @param display function to call to display additional state
 * @param buttonIcon
 * @param onClick
 * @param onEdit
 * @param onRemove
 * @returns {JSX.Element}
 * @constructor
 */
export const AssetListComponentV2 = forwardRef(
  (
    {
      item,
      selected = undefined,
      display = undefined,
      buttonIcon = <ArrowForward />,
      onClick = undefined,
      onEdit = undefined,
      onRemove = undefined,
      children,
    },
    ref,
  ) => {
    const { readonly } = useContext(FormikContext);

    return (
      <Box className='listItem' ref={ref}>
        {selected && selected(item) && (
          <Box className='listSelected'>
            <TaskOutlined className='check' />
          </Box>
        )}

        <Box className='listContent'>
          <Stack spacing={0.75}>
            {children ? (
              children
            ) : (
              <AssetListItemComponent item={item} display={display} />
            )}
          </Stack>
        </Box>

        <Stack className='listButtons'>
          {!readonly && onClick && (
            <IconCircleButton onClick={() => onClick(item)}>
              {buttonIcon}
            </IconCircleButton>
          )}
          {!readonly && onEdit && (
            <IconCircleButton onClick={() => onEdit(item)}>
              <Edit />
            </IconCircleButton>
          )}
          {!readonly && onRemove && (
            <IconCircleButton onClick={() => onRemove(item)}>
              <Delete />
            </IconCircleButton>
          )}
        </Stack>
      </Box>
    );
  },
);

AssetListComponentV2.displayName = 'AssetListComponentV2';
