import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SvgLabel from '@pw/components/icons/Label';
import AccountDisplay from '@pw/components/properties/AccountDisplay';
import DateDisplay from '@pw/components/properties/DateDisplay';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';

function LogItem({ item }) {
	return (
		<Stack direction="row" spacing="0.75rem" alignItems="center" sx={{ position: 'relative' }}>
			<Box>
				<SvgLabel type={item?.type} style={{ width: '24px', height: '24px' }} />
			</Box>
			<Stack spacing='0.25rem' sx={{ borderLeft: '1px solid #d9d9d9', paddingLeft: '0.75rem'}}>
				<EntryTitle value={item?.message} />
				<Stack direction="row" spacing="0.75rem">
					<DateDisplay date={item?.date} />
					<AccountDisplay name={item?.id?.target_id?.name} />
				</Stack>
			</Stack>
		</Stack>
	);
}

function AccessLog({ access_log = [] }) {
	console.log('Access log', access_log);
	return (
		<BasicList Content={LogItem} items={access_log} identity={(i) => i.date} />
	);
}

export default AccessLog;
