const styles = {
  '.top-bar-root': {
    // position: 'absolute',
    position: 'relative',
    zIndex: 99,
    // width: 'calc(100vw - 250px - 1rem)',
    width: '100%',
    backgroundColor: 'white',
  },

  '.flex': {
    display: 'flex',
    flexWrap: 'row wrap',
  },
  '.container': {
    position: 'relative',
    paddingTop: '90px',
    boxSizing: 'border-box',
    height: 'calc(100vh - (80px + 4rem))',
  },
  '.timeline-container': {
    position: 'relative',
    height: '100%',
    height: 'calc(100vh - (80px + 90px + 76px))',
  },

  '.content .wrapper-content': { paddingBottom: '1rem' },

  // scheduler widget
  '#reactSchedulerOutsideWrapper': {
    overflow: 'hidden',
    top: '1rem',
    borderRadius: '0.5rem',
    // hide empty message
    'div[width]': { display: 'none !important', },
    '> div:first-of-type': {
      '> div:first-of-type': {
        '> div:first-of-type': {
          '> div:first-of-type': {
            height: '80px !important',
          },
        },
      }
    },
  },
};

export default styles;
