import { WidgetsOutlined } from '@mui/icons-material';
import { Tab } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import SvgSearch from '@pw/components/icons/Search';
import { Body1 } from '@pw/components/Typography';
import {
  a11yProps,
} from '@pw/components_v2/elements/CustomTabPanel';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import SearchForm from '@pw/components_v2/search/general/SearchForm';
import FormikContext from '@pw/context/FormikContext';
import { COMP } from '@pw/utilities/comp';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useState } from 'react';

function ThingItem({ item }) {
  return (
    <Stack>
      <EntryTitle value={item?.name} />
      <EntryLabel value={item?.description} />
    </Stack>
  );
}

const shadowStyle = {
  '--shadow-color': '0deg 0% 70%',
  boxShadow: `0px 0.5px 0.5px hsl(var(--shadow-color) / 0.32),
    0px 1px 1px -0.9px hsl(var(--shadow-color) / 0.28),
    0px 2.1px 2px -1.8px hsl(var(--shadow-color) / 0.25),
    -0.1px 4.6px 4.5px -2.7px hsl(var(--shadow-color) / 0.21),
    -0.1px 9.3px 9px -3.5px hsl(var(--shadow-color) / 0.17)`,
  backgroundColor: '#fff',
  border: '1px solid #000',
  borderRadius: '5px',
  padding: '0.25rem',
};

export function ThingSelector({
  filter,
  initial = [],
  ThingModal,
  label = 'Things',
  field = 'things',
  thingConverter: converter = (t) => ({ ...t?.thing, ...t }),
}) {
  const confirm = useConfirm();

  const { setFieldValue, readonly } = useContext(FormikContext);
  const [things, , , upsertThings, removeThings] = useItemListManager({
    id: (a) => a.thing?._id,
    comp: COMP._id,
    initialData: initial?.map((t) => converter(t)),
  });

  // Currently selected thing
  const [selectedThing, setSelectedThing] = useState(null);

  useEffect(() => {
    setFieldValue(field, things);
  }, [things]);

  const handleRemoveThing = useCallback(
    (thing) => {
      confirm({
        title: 'Remove Thing',
        content: <Body1>{`Remove ${thing?.name}?`}</Body1>,
      })
        .then(() => removeThings(thing))
        .catch(() => {});
    },
    [removeThings],
  );

  const handleThingUpdate = useCallback(
    (thing) => {
      if (thing) {
        upsertThings({ ...thing, thing });
      }
      setSelectedThing(null);
    },
    [upsertThings, setSelectedThing],
  );

  const Toolbar = () => {
    const [search, setSearch] = useState(false);

    const handleSearchResult = useCallback(
      (selected, type) => {
        if (selected && selected.length > 0) {
          if (type === SEARCH_TYPES.THINGS) {
            upsertThings(selected.map((t) => ({ ...t, thing: t })));
          }
        }
        setSearch(false);
      },
      [upsertThings],
    );

    const openSearch = useCallback(
      (e) => {
        setSearch(true);
      },
      [setSearch],
    );

    return (
      <>
        <TitledButton
          handleClick={openSearch}
          label='Search'
          disabled={readonly}
        >
          <SvgSearch height={24} width={24} />
        </TitledButton>

        {!!search && (
          <SearchForm
            open={!!search}
            types={filter}
            multi={true}
            onClose={handleSearchResult}
          />
        )}
      </>
    );
  };

  const thingTools = [
    ...(ThingModal
      ? [
          {
            title: 'Edit thing',
            Icon: SvgEdit,
            handler: setSelectedThing,
          },
        ]
      : []),
    {
      title: 'Remove thing',
      Icon: SvgDelete,
      handler: handleRemoveThing,
    },
  ];

  return (
    <Stack spacing='1.5rem'>
      <Stack direction='row' spacing='0.5rem' sx={shadowStyle}>
        <Toolbar />
      </Stack>

      {!!things.length && (
        <Box
          sx={{
            border: '1px solid #d9d9d9',
            borderRadius: '5px',
            overflow: 'hidden',
          }}
        >
          <Tab
            label={label}
            icon={<WidgetsOutlined />}
            {...a11yProps(SEARCH_TYPES.THINGS)}
            iconPosition='start'
          />
          <BasicList
            Content={ThingItem}
            items={things}
            tools={thingTools}
            identity={(i) => i._id}
            item={(i) => i}
            readonly={readonly}
          />
          {ThingModal && selectedThing && (
            <ThingModal
              open={!!selectedThing}
              item={selectedThing}
              onClose={handleThingUpdate}
            />
          )}
        </Box>
      )}
    </Stack>
  );
}
