function Pack1() {
  return (
    <svg
      width='31'
      height='32'
      viewBox='0 0 31 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_8519_2640)'>
        <path
          d='M0.901653 24.8708C1.88938 25.3717 2.8771 25.8727 3.87388 26.3781C4.39946 26.6464 4.92957 26.9103 5.46421 27.1831L7.5937 28.252C9.87271 29.4104 12.1608 30.5598 14.4398 31.7227C15.1557 32.0894 15.8353 32.0939 16.5512 31.7227C18.8302 30.5598 21.1183 29.4149 23.3973 28.252C23.4018 28.252 23.4109 28.252 23.4154 28.2431L27.3391 26.2618C28.2453 25.8056 29.1469 25.3449 30.0531 24.8887C30.4654 24.683 30.7735 24.3923 30.9139 23.9495C31.1631 23.1847 30.8143 22.478 29.9806 22.0576C25.5086 19.799 21.0367 17.5404 16.5693 15.2728C15.8444 14.9061 15.1557 14.8927 14.4262 15.2683C12.6093 16.2031 10.7834 17.12 8.96202 18.0413C6.29335 19.3875 3.62922 20.7382 0.960554 22.08C0.548247 22.2857 0.231089 22.563 0.0770402 23.0013C-0.176687 23.7348 0.145003 24.4772 0.892591 24.8574L0.901653 24.8708ZM2.38324 23.2562C6.6377 21.105 10.8922 18.9582 15.1466 16.8024C15.3822 16.6817 15.5725 16.6503 15.8217 16.7801C20.1487 18.9761 24.4847 21.1676 28.8162 23.3591C28.8932 23.3994 28.9657 23.4441 29.0835 23.5156C27.2757 24.4325 25.5177 25.3181 23.7643 26.2036C21.1273 27.5364 18.4904 28.8692 15.858 30.211C15.6088 30.3362 15.4139 30.3452 15.1557 30.2155C10.874 28.0418 6.59239 25.8771 2.30621 23.7124C2.19294 23.6543 2.07967 23.5917 1.92562 23.5112C2.09779 23.4173 2.23825 23.3367 2.38324 23.2652V23.2562Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_8519_2640'>
          <rect
            width='31'
            height='17'
            fill='white'
            transform='translate(0 15)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pack1;
