import Stack from '@mui/material/Stack';
import useLiquidTypes from '@pw/compliance/useLiquidTypes';
import useTaxCalculator from '@pw/compliance/useTaxCalculator';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import CostDisplay from '@pw/components/properties/CostDisplay';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import DependentThingModal from '@pw/components/ThingSelector/modals/DependentThingModal';
import { Body1, Overline } from '@pw/components/Typography';
import ContactSearch from '@pw/components_v2/search/contact';
import CurveSearch from '@pw/components_v2/search/curves';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import LedgerSearch from '@pw/components_v2/search/ledger';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ThingSelector } from '@pw/components_v2/search/thing/ThingSelector';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import ThingForm from '@pw/components_v2/Thing';
import { THING_TYPES } from '@pw/consts/thing';
import { ABV_UNIT_OPTIONS } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useIsUsUser } from '@pw/redux/containers/User';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function LiquidTaxSettings() {
  const { values } = useContext(FormikContext);
  const isUsUser = useIsUsUser();

  const { getTaxCode } = useTaxCalculator();

  const liquidType = values?.['liquid_type'];
  const abv = values?.['abv'];

  const taxCode = useMemo(() => getTaxCode(liquidType, abv), [values]);

  if (!liquidType || !abv || !abv.value || !abv.unit) {
    return;
  }

  if (!taxCode) {
    return (
      <FlexBox className="creator-view">
        <Stack spacing={0.5} className="column">
          <Overline>TAX CODE REQUIRED</Overline>
          <Body1>Please contact support@proofworks.com to enable this</Body1>
        </Stack>
      </FlexBox>
    );
  }

  return (
    <FlexBox className="creator-view">
      <Stack spacing={0.5} className="column">
        <Overline>TAX CODE</Overline>
        <IDDisplay value={taxCode?.code} />
        <DescriptionDisplay value={taxCode?.name} />

        {isUsUser && (
          <CostDisplay label={`Price per ${taxCode?.unit?.toLocaleUpperCase()} (<100K)`} value={taxCode?.rate[0].rate}
                       code={taxCode?.currency} />
        )}
        {!isUsUser && (
          <CostDisplay label={`Standard per ${taxCode?.unit?.toLocaleUpperCase()}`} value={taxCode?.rate[0].rate}
                       code={taxCode?.currency} />
        )}

        {taxCode?.rate.length === 2 && (
          <>
            {isUsUser && (
              <CostDisplay label={`Price per ${taxCode?.unit?.toLocaleUpperCase()} (>100K)`}
                           value={taxCode?.rate[1].rate} code={taxCode?.currency} />
            )}
            {!isUsUser && (
              <CostDisplay label={`Reduced per ${taxCode?.unit?.toLocaleUpperCase()}`} value={taxCode?.rate[1].rate}
                           code={taxCode?.currency} />
            )}
          </>
        )}
      </Stack>
    </FlexBox>
  );
}

function Properties() {
  const { t } = useTranslation();

  const liquidTypes = useLiquidTypes();

  return (
    <FormikProvider path="properties">
      <Stack spacing="1.5rem">
        <FlexBox alignItems="top">
          <FormikSelect
            label="Liquid type"
            name="liquid_type"
            options={liquidTypes}
            fullWidth
          />

          <FormikUnitField
            label={`${t('abvTarget')}`}
            name="abv"
            options={ABV_UNIT_OPTIONS}
            fullWidth
          />
        </FlexBox>

        <LiquidTaxSettings />

        <CurveSearch field="price_curve" />
      </Stack>
    </FormikProvider>
  );
}

const tabs = [
  {
    title: 'Properties',
    key: 'properties',
    properties: (entity, converter) => ({
      properties: {
        abv: unitField(entity?.properties?.abv),
        liquid_type: [
          entity?.properties?.liquid_type,
          yup.string().required('Liquid type is required!')
        ],
        price_curve: [entity?.properties?.price_curve, yup.object()],
        things: [entity?.properties?.things, yup.array().of(yup.object())]
      }
    }),
    Component: Properties
  },
  {
    title: 'BOM',
    key: 'bom',
    Component: ({ entity }) => (
      <FormikProvider path="properties">
        <ThingSelector
          initial={entity?.properties?.things}
          label="BOM"
          field="things"
          ThingModal={DependentThingModal}
          filter={{
            [SEARCH_TYPES.THINGS]: {
              types: [THING_TYPES.LIQUID]
            }
          }}
        />
      </FormikProvider>
    )
  },
  {
    title: 'Ownership',
    key: 'ownership',
    properties: (entity, converter) => ({
      client: [entity?.client, yup.object()],
      ledger: [entity?.ledger, yup.object()]
    }),
    Component: (props) => (
      <Stack spacing="1.5rem">
        <ContactSearch />
        <LedgerSearch />
      </Stack>
    )
  },
  {
    title: 'Vendors',
    key: 'vendors',
    properties: (entity, converter) => ({
      vendors: [entity?.vendors, yup.array()]
    }),
    Component: ({ entity }) => (
      <VendorSelector initialValues={entity?.vendors} />
    )
  },
  {
    title: 'Policies',
    key: 'policies',
    properties: (entity, converter) => ({
      policies: [entity?.policies, yup.array()]
    }),
    Component: ({ entity }) => (
      <PolicySelector initialValues={entity?.policies} />
    )
  }
];

function ThingPage(props) {
  return (
    <ThingForm
      thingLabel="Liquid"
      thingType={THING_TYPES.LIQUID}
      tabs={tabs}
    />
  );
}

export default withAppLayout(ThingPage, { title: 'Liquids' });
