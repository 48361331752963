import QRImage from '@pw/components/QRImage/index';
import { Body1, Body2 } from '@pw/components/Typography';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { THING_EDIT_URL, THING_TYPES } from '@pw/consts/thing';
import { useMemo } from 'react';

const styles = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		pageBreakInside: 'none',
		pageBreakAfter: 'auto',
	},
	rootFullScreen: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
	},
	content: {
		// alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: '1rem',
		justifyContent: 'space-between',
	},
	qrDetails: {
		width: '100%',
		display: 'flex',
		'justify-content': 'center',
	},
	qrDetails2: {
		width: '100%',
	},
	qrIcon: {
		display: 'flex',
		'flex-direction': 'column',
		'align-items': 'center',
		'justify-content': 'space-evenly',
		border: '2px solid',
		padding: '5px',
		height: '72px',
		width: '72px',
		'border-radius': '50%',
	},
	type: {
		'text-transform': 'uppercase',
		'font-size': '0.8rem',
		'margin-top': '.25rem',
	},
	title: {
		borderTop: '1px solid',
		paddingTop: '0.25rem',
		fontSize: '1rem',
		fontWeight: '600',
	},
	m5: {
		margin: '5px',
	},
};

function QrDetails({ item = {}, companyName, unit, abvUnit, abv }) {
	return (
		<div style={styles.qrDetails2}>
			<Body1 sx={styles.m5}>{companyName}</Body1>
			<Body1 sx={styles.m5}>{item?.product}</Body1>
			<Body1 sx={styles.m5}>{item?.sku_name}</Body1>
			{[THING_TYPES.TRACKED].includes(item.sku_type) && (
				<>
					<Body1 sx={styles.m5}>
						{item.capacity} {unit}
					</Body1>
					<Body1 sx={styles.m5}>{item?.asset_type}</Body1>
				</>
			)}
			{item.sku_type === THING_TYPES.FINISHED && (
				<>
					<Body1 sx={styles.m5}>
						{item.count}x{item.size} {unit}
					</Body1>
					<Body1 sx={styles.m5}>
						ABV {abv}
						{abvUnit}
					</Body1>
				</>
			)}
			{[THING_TYPES.WASTE, THING_TYPES.RAW, THING_TYPES.EXPIRING].includes(item.sku_type) && (
				<Body1 sx={styles.m5}>{unit}</Body1>
			)}
		</div>
	);
}

function SKUQRCode({ sku, companyName, unit, abvUnit, abv, fullScreen = true, withDetails = true }) {
	const loc = typeof window !== 'undefined' && window?.location?.origin;
	const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
	const route = THING_EDIT_URL[sku?.type];
	const qrValue = useMemo(
		() => `${origin}${route}/${sku?._id}`,
		[sku, origin],
	);

	return (
		<div style={fullScreen ? styles.rootFullScreen : styles.root}>
			<div style={styles.content}>
				<QRImage value={qrValue} />
				{withDetails && (
					<QrDetails
						item={sku}
						companyName={companyName}
						unit={unit}
						abvUnit={abvUnit}
						abv={abv}
					/>
				)}
			</div>
			<div style={styles.footer}>
				{sku?.name && (
					<Body2 sx={styles.title}>{sku?.name}</Body2>
				)}
			</div>
		</div>
	);
}

export default SKUQRCode;
