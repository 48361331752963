import { WidgetsOutlined } from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import SvgAssets from '@pw/components/icons/Assets';
import SvgCompany from '@pw/components/icons/Company';
import SvgCurve from '@pw/components/icons/Curve';
import SvgLabel from '@pw/components/icons/Label';
import SvgPlant from '@pw/components/icons/Plant';
import SvgPolicy from '@pw/components/icons/Policy';
import SvgRegauge from '@pw/components/icons/Regauge';
import SvgSpec from '@pw/components/icons/Spec';
import SvgTeams from '@pw/components/icons/Teams';
import SvgUser from '@pw/components/icons/User';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import AssetsSearch from '@pw/components_v2/search/general/forms/AssetsSearch';
import CompaniesSearch from '@pw/components_v2/search/general/forms/CompaniesSearch';
import ContactsSearch from '@pw/components_v2/search/general/forms/ContactsSearch';
import CurvesSearch from '@pw/components_v2/search/general/forms/CurvesSearch';
import DesignsSearch from '@pw/components_v2/search/general/forms/DesignsSearch';
import LedgersSearch from '@pw/components_v2/search/general/forms/LedgersSearch';
import PoliciesSearch from '@pw/components_v2/search/general/forms/PoliciesSearch';
import RequestsSearch from '@pw/components_v2/search/general/forms/RequestsSearch';
import SpecificationsSearch from '@pw/components_v2/search/general/forms/SpecificationsSearch';
import TeamsSearch from '@pw/components_v2/search/general/forms/TeamsSearch';
import ThingsSearch from '@pw/components_v2/search/general/forms/ThingsSearch';
import UsersSearch from '@pw/components_v2/search/general/forms/UsersSearch';
import { useCallback, useMemo, useState } from 'react';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  console.log('Re-rendering tab', index);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    value: index,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: { pl: '1rem', pr: '1rem' }
  };
}

function SearchForm({ open, types, multi, onClose }) {
  // Default to first type given
  const forms = useMemo(() => Object.keys(types), [types]);

  console.log('Search modal', forms);

  const [value, setValue] = useState(forms[0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = useCallback((v, t) => onClose(v, t), [onClose]);

  return (
    <ModalWithClose open={open} onClose={onClose} title="Search">

      <Box sx={{ border: '1px solid #d9d9d9', borderRadius: '5px', overflow: 'hidden' }}>
        <Tabs value={value} onChange={handleChange} aria-label="search management">
          {types[SEARCH_TYPES.USERS] && (
            <Tab label="Users" icon={<SvgUser />} {...a11yProps(SEARCH_TYPES.USERS)} iconPosition="start" />)}
          {types[SEARCH_TYPES.TEAMS] && (
            <Tab label="Teams" icon={<SvgTeams />} {...a11yProps(SEARCH_TYPES.TEAMS)} iconPosition="start" />)}
          {types[SEARCH_TYPES.POLICIES] && (
            <Tab label="Policies" icon={<SvgPolicy />} {...a11yProps(SEARCH_TYPES.POLICIES)} iconPosition="start" />)}
          {types[SEARCH_TYPES.SPECIFICATIONS] && (
            <Tab label="Specifications" icon={<SvgSpec />} {...a11yProps(SEARCH_TYPES.SPECIFICATIONS)}
                 iconPosition="start" />)}
          {types[SEARCH_TYPES.LEDGERS] && (
            <Tab label="Ledgers" icon={<SvgLabel />} {...a11yProps(SEARCH_TYPES.LEDGERS)} iconPosition="start" />)}
          {types[SEARCH_TYPES.CURVES] && (
            <Tab label="Curves" icon={<SvgCurve />} {...a11yProps(SEARCH_TYPES.CURVES)} iconPosition="start" />)}
          {types[SEARCH_TYPES.CONTACTS] && (
            <Tab label="Contacts" icon={<SvgUser />} {...a11yProps(SEARCH_TYPES.CONTACTS)} iconPosition="start" />)}
          {types[SEARCH_TYPES.COMPANIES] && (
            <Tab label="Companies" icon={<SvgCompany />} {...a11yProps(SEARCH_TYPES.COMPANIES)}
                 iconPosition="start" />)}
          {types[SEARCH_TYPES.ASSETS] && (
            <Tab label="Assets" icon={<SvgAssets />} {...a11yProps(SEARCH_TYPES.ASSETS)} iconPosition="start" />)}
          {types[SEARCH_TYPES.REQUESTS] && (
            <Tab label="Requests" icon={<SvgRegauge />} {...a11yProps(SEARCH_TYPES.REQUESTS)} iconPosition="start" />)}
          {types[SEARCH_TYPES.DESIGNS] && (
            <Tab label="Designs" icon={<SvgPlant />} {...a11yProps(SEARCH_TYPES.DESIGNS)} iconPosition="start" />)}
          {types[SEARCH_TYPES.THINGS] && (
            <Tab label="Things" icon={<WidgetsOutlined />} {...a11yProps(SEARCH_TYPES.THINGS)} iconPosition="start" />)}
        </Tabs>

        {types[SEARCH_TYPES.USERS] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.USERS}>
            <UsersSearch {...types[SEARCH_TYPES.USERS]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.USERS)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.TEAMS] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.TEAMS}>
            <TeamsSearch {...types[SEARCH_TYPES.TEAMS]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.TEAMS)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.POLICIES] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.POLICIES}>
            <PoliciesSearch {...types[SEARCH_TYPES.POLICIES]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.POLICIES)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.SPECIFICATIONS] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.SPECIFICATIONS}>
            <SpecificationsSearch {...types[SEARCH_TYPES.SPECIFICATIONS]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.SPECIFICATIONS)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.LEDGERS] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.LEDGERS}>
            <LedgersSearch {...types[SEARCH_TYPES.LEDGERS]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.LEDGERS)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.CURVES] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.CURVES}>
            <CurvesSearch {...types[SEARCH_TYPES.CURVES]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.CURVES)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.CONTACTS] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.CONTACTS}>
            <ContactsSearch {...types[SEARCH_TYPES.CONTACTS]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.CONTACTS)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.COMPANIES] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.COMPANIES}>
            <CompaniesSearch {...types[SEARCH_TYPES.COMPANIES]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.COMPANIES)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.ASSETS] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.ASSETS}>
            <AssetsSearch {...types[SEARCH_TYPES.ASSETS]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.ASSETS)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.REQUESTS] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.REQUESTS}>
            <RequestsSearch {...types[SEARCH_TYPES.REQUESTS]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.REQUESTS)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.DESIGNS] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.DESIGNS}>
            <DesignsSearch {...types[SEARCH_TYPES.DESIGNS]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.DESIGNS)} />
          </CustomTabPanel>
        )}
        {types[SEARCH_TYPES.THINGS] && (
          <CustomTabPanel value={value} index={SEARCH_TYPES.THINGS}>
            <ThingsSearch {...types[SEARCH_TYPES.THINGS]} multi={multi} onClose={(v) => handleClose(v, SEARCH_TYPES.THINGS)} />
          </CustomTabPanel>
        )}
      </Box>
    </ModalWithClose>
  );
}

export default SearchForm;
