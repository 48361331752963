import { Box, Collapse, Stack, Typography } from '@mui/material';
import { Filter } from '@pw/components/icons';
import { QrScan3 } from '@pw/components/icons';
import { useEffect, useMemo, useState } from 'react';
import LayoutRecentAssetsWidget from './LayoutRecentAssetsWidget';
import TasksWidget from './TasksWidget';
import LayoutReportWidget from './LayoutReportWidget';
import { processAssetList, processRequestList } from '@pw/components/Tasks';
import { useNavigate } from 'react-router-dom';
import { ASSET_EDIT_URL } from '@pw/consts/asset';
import * as yup from 'yup';
import { Body3, H5 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import SvgForwardarrow from '@pw/components/icons/Forwardarrow';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FormikDatePicker, FormikForm } from '@pw/components/Forms/FormikForm';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { useStatsLazyQuery } from '@pw/utilities/hooks/service/useStatsQuery';

const LayoutActivity = ({
  currentWidth,
  currentHeight,
}) => {
  const [fetchWidgetData, { data: widgetData, isLoading, refetch }] =
    useStatsLazyQuery();
  const data = widgetData?.widgets[0].data;

  const {
    awaitingRequests = [],
    pendingAssets = [],
    requests = [],
  } = data ?? {};

  const [activeTab, setActiveTab] = useState(2);

  const tabs = [
    { id: 1, label: '+ Task' },
    { id: 2, label: '+ Shipment' },
    { id: 3, label: '+ Delivery' },
  ];

  const tasks = useMemo(
    () => [
      ...processAssetList({ items: pendingAssets }),
      ...processRequestList(awaitingRequests),
    ],
    [awaitingRequests, pendingAssets],
  );

  const isSmallScreen = currentWidth <= 600;
  const isMediumScreen = currentWidth > 600 && currentWidth <= 1024;

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  useEffect(() => {
    fetchWidgetData({ widget_ids: [5] });
  }, []);
  const changeSet = {
    start_date: [startDate, yup.number()],
    end_date: [endDate, yup.number()],
  };

  const onFilter = (values) => {
    const { start_date, end_date } = values;
    refetch({ start_date, end_date, widget_ids: [5] }).then((res) => {
      if (res) {
        setStartDate(start_date);
        setEndDate(end_date);
      }
    });
  };
  const styles = {
    tab: (isActive) => ({
      padding: currentWidth < 420 ? '5px 10px' : '10px 20px',
      backgroundColor: isActive ? '#C6C6C6' : 'transparent',
      cursor: 'pointer',
      borderRadius: '4px',
    }),
    tabText: (isActive) => ({
      fontSize: currentWidth < 420 ? '14px' : '16px',
      fontWeight: 400,
      lineHeight: '19px',
      color: isActive ? '#fff' : '#393C3C',
    }),
    filterButtonWrapper: {
      width: '50px',
      height: '42px',
      position: 'absolute',
      right: '0',
      // top: '10px',
      borderRadius: '5px',
      backgroundColor: '#C6C6C6',
    },
    updateButton: {
      width: isSmallScreen ? '100px' : 'calc(100vw * (250 / 1029))',
      height: isSmallScreen ? '40px' : '56px',
      borderRadius: '5px',
      backgroundColor: '#52535B',
      color: '#fff',
      // '&:hover': {
      //   backgroundColor: '#1565c0',
      // },
      fontSize: isSmallScreen ? '12px' : '14px',
      lineHeight: isSmallScreen ? '1.2' : '26px',
      fontWeight: '600',
    },
  };

  const navigate = useNavigate();

  const goToItem = (params) => {
    const id = params?.path ?? params?.asset_id ?? 'new';
    const assetType = params?.type ?? '1';
    const route = ASSET_EDIT_URL?.[assetType];
    navigate(`${route}/${id}`);
  };
  return (
    <Box className='content-row' id='layoutActivity'>
      <Box className='content-header'>
        <Box className='title-box'>
          <QrScan3 height={43} width={29} />
          <Box>
            <H5>ACTIVITY</H5>
            {!!startDate && !!endDate && (
              <Body3 sx={{ alignItems: 'center' }}>
                <Bolder style={{ paddingLeft: '0.25ch', fontWeight: '600' }}>
                  fr.
                </Bolder>
                {new Date(startDate).toLocaleDateString()}
                <SvgForwardarrow
                  width={17}
                  height={7}
                  style={{
                    marginLeft: '20px',
                    marginRight: '10px',
                  }}
                />
                <Bolder
                  style={{
                    paddingLeft: '0.25ch',
                    fontWeight: '600',
                  }}
                >
                  to.
                </Bolder>
                {new Date(endDate).toLocaleDateString()}
              </Body3>
            )}
          </Box>
        </Box>
        <Stack
          direction='row'
          justifyContent={'space-between'}
          alignItems={'center'}
          position={'relative'}
          padding={'10px'}
          gap={'10px'}
        >
          {/* Tabs */}
          <Stack direction={'row'} gap={'10px'}>
            {tabs.map((tab) => (
              <Box
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                sx={styles.tab(activeTab === tab.id)}
              >
                <Typography sx={styles.tabText(activeTab === tab.id)}>
                  {tab.label}
                </Typography>
              </Box>
            ))}
          </Stack>
          {isVisibleFilter && <Box sx={styles.filterButtonWrapper}></Box>}
          <IconCircleButton
            onClick={() => setIsVisibleFilter(!isVisibleFilter)}
          >
            <Filter color={isVisibleFilter ? '#fff' : '#7C7C7C'} />
          </IconCircleButton>
        </Stack>
      </Box>

      <Collapse in={isVisibleFilter}>
        <FormikForm changeSet={changeSet} onSubmit={onFilter}>
          <Stack
            gap={1.5}
            py={2}
            pl={1.5}
            pr={2}
            sx={{ backgroundColor: '#C6C6C6', borderRadius: '5px' }}
          >
            <Box display='flex' flexBasis='row' gap={0}>
              <FormikDatePicker
                name='start_date'
                label='From'
                fullWidth
                backgroundColor='#fff'
              />
              <FormikDatePicker
                name='end_date'
                label='To'
                fullWidth
                backgroundColor='#fff'
              />
              <FilledButton
                sx={{ marginLeft: '15px', ...styles.updateButton }}
                type='submit'
                // size='small'
                label='Update data'
                loading={isLoading}
              />
            </Box>
          </Stack>
        </FormikForm>
      </Collapse>

      <Box className='content-body content-3col'>
        <TasksWidget
          data={tasks}
          refetch={() => console.log('Refetch tasks')}
          title='Your Tasks'
        />
        <Stack className='content-section'>
          <H5 className='content-section-title'>Shipment/Deliveries</H5>
          <Stack className='content-section-content line widget-list recent-list'>
            {requests?.map((item, index) => (
              <LayoutRecentAssetsWidget key={index} data={item} />
            ))}
            {/* {data?.casks?.map((i) => (
					<AssetListComponentV2
						key={i.path}
						item={i}
						buttonIcon={<ArrowForward />}
						onClick={() => goToItem(i)}
					/>
				))} */}
          </Stack>
        </Stack>
        <LayoutReportWidget title='Report' currentWidth={currentWidth} />
      </Box>
    </Box>
  );
};

export default LayoutActivity;
