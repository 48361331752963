import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Pagination from '@pw/components/Pagination';
import ScanQR from '@pw/components/ScanQR';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import AssetItem from '@pw/components_v2/elements/display/asset/AssetItem';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import AssetSearch from '@pw/components_v2/search/general/forms/AssetsSearch';
import { ASSET_EDIT_URL } from '@pw/consts/asset';
import styles from '@pw/styles/content.styles';
import { useRecentAssetQueryPaginated } from '@pw/utilities/hooks/service/useRecentAssetQuery';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchForm({ label, assetType }) {
  const navigate = useNavigate();

  const handleOnQrRead = useCallback(
  ({ text: qrString }) => {
    console.log('Read QR code', qrString);
    const [id] = qrString.split('/').reverse();
    const route = ASSET_EDIT_URL?.[assetType];
    navigate(`${route}/${id}`, { replace: true });
  }, [assetType]);

  const goToItem = useCallback((params) => {
    if (params?.length === 1) {
      const id = params[0]?._id ?? '';
      const route = ASSET_EDIT_URL?.[assetType];
      navigate(`${route}/${id}`, { replace: true });
    }
  }, [assetType]);

  return (
    <Stack direction="row" gap="0.75rem" alignItems="top">
      <ScanQR onSuccess={handleOnQrRead} closeOnSuccess />
      <Box sx={{ flexGrow: 1 }}>
        <AssetSearch label={label} types={[assetType]} onClose={goToItem} />
      </Box>
    </Stack>
  );
}

function LatestAdded({ assetType }) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState('5');

  const reqParams = useMemo(() => {
    return {
      type: assetType,
      limit: Number(pageSize),
    };
  }, [assetType, pageSize]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useRecentAssetQueryPaginated(reqParams);

  const goToItem = useCallback((params) => {
    const id = params?._id ?? '';
    const route = ASSET_EDIT_URL?.[assetType];
    navigate(`${route}/${id}`, { replace: true });
  }, [assetType]);

  return (
    <Stack spacing={0.5}>
      <Pagination
        hasNextPage={hasNext}
        hasPrevPage={hasPrev}
        onPressNextPage={loadNext}
        onPressPrevPage={loadPrev}
        onChangePageSize={setPageSize}
        defaultPageSize={pageSize}
      />
      <BasicList
        loading={isLoading}
        Content={AssetItem}
        items={page}
        identity={(i) => i._id}
        item={(i) => i}
        onClick={goToItem}
      />
    </Stack>
  );
}

function LatestAddedAssets({ label, assetType }) {
  // Current tab
  const [tab, setTab] = useState('search');

  const handleChange = (event, newValue) => setTab(newValue);

  return (
    <Stack sx={styles} className='root' spacing={3}>
      <Tabs value={tab} onChange={handleChange} aria-label="latest companies" variant="fullWidth" >
        <Tab label="Search" {...a11yProps('search')} />
        <Tab label="Latest" {...a11yProps('latest')} />
      </Tabs>

      <CustomTabPanel value={tab} index="search">
        <SearchForm label={label} assetType={assetType} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index="latest">
        <LatestAdded assetType={assetType} />
      </CustomTabPanel>
    </Stack>
  );
}

export default withSmallDialogCard(LatestAddedAssets);
