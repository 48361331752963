import { Box } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { Body1 } from '@pw/components/Typography';
import { inviteThunk } from '@pw/redux/thunks/user';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function InviteCustomerConfirmDialog({ contact, id, handleClose }) {
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    () => dispatch(inviteThunk({ id })).unwrap().then(handleClose),
    [dispatch, contact, handleClose],
  );

  return (
    <ModalWithClose
      title='Invite Customer'
      open={!!contact}
      onClose={handleClose}
    >
      <Body1>Full name: {contact?.name}</Body1>
      <Body1>Contact email: {contact?.email}</Body1>

      <Box className='action-buttons'>
        <TextButton size='small' handleClick={handleClose} color='secondary'>
          Cancel
        </TextButton>
        <FilledButton type='submit' size='small' handleClick={handleSubmit}>
          Invite
        </FilledButton>
      </Box>
    </ModalWithClose>
  );
}
