const styles = {
	'&.dialog-card': {
		// width: '100vw',
		// minWidth: {
		//   xs: '360px',
		//   md: '360px',
		// },
		// maxWidth: {
		//   xs: '100vw',
		//   md: '560px',
		// },
		width: {
			xs: '100%',
			md: '560px',
		},
		display: 'flex',
		flexDirection: 'row',
		// marginTop: {
		//   xs: '1rem',
		//   lg: '2rem',
		// },
		// marginBottom: {
		//   xs: '1rem',
		//   lg: '2rem',
		// },
		marginRight: 'auto',
		marginLeft: 'auto',
		padding: {
			// xs: '1rem',
			// lg: '2rem',
		},
		overflow: 'hidden',
		backgroundColor: '#fff',
		borderRadius: {
			xs: '0',
			md: '5px',
		},
		'--shadow-color': '0deg 0% 54%',
		boxShadow: {
			xs: 'none',
			md: `0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
        0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
        1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34)`,
		},
	},

	'&.ext-dialog-card': {
		width: {
			xs: '100%',
			// lg: '60vw',
		},
		maxWidth: {
			xs: '100%',
			// lg: '60vw',
		},
		display: 'flex',
		flexDirection: 'column',
		marginTop: {
			xs: '1rem',
			lg: '2rem',
		},
		marginBottom: {
			xs: '1rem',
			lg: '2rem',
		},
		padding: {
			// xs: '1rem',
			// lg: '2rem',
		},
		overflow: 'hidden',
		backgroundColor: '#fff',
		borderRadius: {
			xs: '0',
			md: '5px',
		},
		'--shadow-color': '0deg 0% 54%',
		boxShadow: {
			xs: 'none',
			md: `0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
        0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
        1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34)`,
		},
	},

	'&.empty-card': {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		// padding: {
		//   xs: '1rem',
		//   lg: '2rem',
		// },
	},

	'&.small-dialog-card': {
		height: 'max-content',
		// width: '100%',
		minWidth: {
			xs: '100%',
			md: '380px',
		},
		display: 'flex',
		flexDirection: 'column',
		// marginTop: {
		//   xs: '1rem',
		//   lg: '2rem',
		// },
		// marginBottom: {
		//   xs: '1rem',
		//   lg: '2rem',
		// },
		marginRight: 'auto',
		marginLeft: 'auto',
		padding: {
			// xs: '1rem',
			// lg: '2rem',
		},
		backgroundColor: '#fff',
		borderRadius: {
			xs: '0',
			md: '5px',
		},
		overflow: 'hidden',
		'--shadow-color': '0deg 0% 54%',
		boxShadow: {
			xs: 'none',
			md: `0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
        0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
        1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34)`,
		},
	},

	'.summary-qr': {
		position: 'relative',
		borderRadius: '4px',
		border: '1px solid rgba(0, 0, 0, 0.15)',
		// padding: '0.75rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'start',

		'.qr': {
			flexGrow: 1,
			display: 'flex',
			width: '100%',
		},
	},

	'.creator-view': {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'stretch',

		'.column': {
			padding: '0.75rem',
			backgroundColor: 'rgba(0, 0, 0, 0.02)',
			border: '1px solid rgba(0, 0, 0, 0.15)',
			borderRadius: '4px',
			flexGrow: 1,
		},
	},
};

export default styles;
