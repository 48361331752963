import Box from '@mui/material/Box';
import LocationIcon from '@pw/components/icons/Location';
import { Body2 } from '@pw/components/Typography';
import { useCompanyFacilities } from '@pw/redux/containers/User';
import { toLocationText } from '@pw/utilities/format';

function LocationContent({ location = undefined }) {
	const locations = useCompanyFacilities();
	// if (!type || type === '0') return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				alignItems: 'center',
				gap: '0.25rem',
			}}
		>
			<LocationIcon style={{ width: '12px', height: '14px' }} />
			{location && location.enable ? (
				<Body2>
					{toLocationText(location, locations)}
				</Body2>
			) : (<Body2>No Location</Body2>)}
		</Box>
	);
}

export default LocationContent;
