import handleError from '@pw/redux/thunks/utils/handleError';
import { createDesign, removeDesign, updateDesign, createWorkflow  } from '@pw/services/design.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const upsertDesignThunk = createAsyncThunk(
  `design/upsert`,
  async (design, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Upserting design', design);
      const response = (design._id) ? await updateDesign(design) : await createDesign(design);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);

export const removeDesignThunk = createAsyncThunk(
  `design/remove`,
  async (design, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Removing design', design);
      const response = await removeDesign(design);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);

export const upsertWorkflowThunk = createAsyncThunk(
  `workflow/upsert`,
  async (workflow, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Upserting workflow', workflow);
      const response = (workflow._id) ? await updateWorkflow(workflow) : await createWorkflow(workflow);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);

export const removeWorkflowThunk = createAsyncThunk(
  `workflow/remove`,
  async (workflow, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Removing workflow', workflow);
      const response = await removeWorkflow(workflow);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);