import Stack from '@mui/material/Stack';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import LedgerSearch from '@pw/components_v2/search/ledger';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import ThingForm from '@pw/components_v2/Thing';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

function Properties() {
  return (
    <FormikProvider path="properties">
      <Stack spacing="1.5rem">
        <FormikTextField label="Unit Price" name="unit_price" fullWidth />
      </Stack>
    </FormikProvider>
  );
}

const tabs = [
  {
    title: 'Properties',
    key: 'properties',
    properties: (entity, converter) => ({
      properties: {
        unit_price: [entity?.properties?.unit_price, yup.string().required('Unit Price is required!')]
      }
    }),
    Component: Properties
  },
  {
    title: 'Grouping',
    key: 'grouping',
    properties: (entity, converter) => ({
      ledger: [entity?.ledger, yup.object()],
    }),
    Component: (props) => (
      <Stack spacing="1.5rem">
        <LedgerSearch />
      </Stack>
    )
  },
  {
    title: 'Policies',
    key: 'policies',
    properties: (entity, converter) => ({
      policies: [entity?.policies, yup.array()],
    }),
    Component: ({ entity }) => (
      <PolicySelector initialValues={entity?.policies} />
    )
  }
];

function ThingPage(props) {
  return (
    <ThingForm
      thingLabel='Service'
      thingType={THING_TYPES.SERVICES}
      tabs={tabs}
    />
  );
}

export default withAppLayout(ThingPage, { title: 'Services' });
