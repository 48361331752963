import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw`;

export const getThing = async (params, headers = {}) =>
	fetchGet(`${BASE}/v2/get${objectToQueryString({...params, type: 'thing'})}`, headers);

export const createThing = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/create`, headers, { ...data, thing_type: data.type, type: 'thing' });

export const updateThing = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/update`, headers, { ...data, thing_type: data.type, type: 'thing' });

export const listThings = async (request, headers = {}) =>
	fetchPost(`${BASE}/v2/list`, headers, {
		...(request.type ? { filter_types: [request.type] } : {}),
		...request,
		type: 'thing',
	});

export const cloneThing = async (sku, headers = {}) =>
	fetchPost(`${BASE}/thing/clone`, headers, sku);

export const getThingsByType = async (params = {}, headers = {}) =>
	fetchGet(`${BASE}/thing/list_by_type${objectToQueryString(params)}`, headers);

export const getThingInventory = async (request, headers = {}) =>
	fetchPost(`${BASE}/thing/inventory`, headers, request);

export const getThingRecords = async (request, headers = {}) =>
	fetchPost(`${BASE}/thing/records`, headers, request);

export const getAssetsByThing = async (params, headers = {}) =>
	fetchPost(`${BASE}/thing/assets`, headers, params);

export const getThingStorage = async (params = {}, headers = {}) =>
	fetchPost(`${BASE}/thing/storage`, headers, params);
