import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body2 } from '@pw/components/Typography';
import formatNumber from '@pw/utilities/formatNumber';

function LayoutNumberWidget({ value, unit = null, label }) {
  return (
    <Stack className="layoutWidget stats">
      <FlexBox className="widget-content stats-content">
        <Body2 className="widget-label">{label}</Body2>
        <FlexBox style={{ width: 'auto' }}>
          <Box className="widget-text">
            <Body2 className="widget-text-secondary">
              {formatNumber(value)}
            </Body2>
          </Box>
        </FlexBox>
      </FlexBox>
    </Stack>
  );
}

export default LayoutNumberWidget;
