import Stack from '@mui/material/Stack';
import { unitField } from '@pw/components/admin/Things/UnitField';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import DependentThingModal from '@pw/components/ThingSelector/modals/DependentThingModal';
import AssetForm from '@pw/components_v2/Asset';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import LedgerSearch from '@pw/components_v2/search/ledger';
import { ThingSelector } from '@pw/components_v2/search/thing/ThingSelector';
import { ASSET_TYPES, MACHINE_TYPES_OPTIONS } from '@pw/consts/asset';
import { THING_TYPES } from '@pw/consts/thing';
import {
  LIQUID_UNIT_OPTIONS,
  MASS_UNIT_OPTIONS,
  TIME_UNIT_OPTIONS,
  UNITS_OPTIONS,
} from '@pw/consts/units';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

function capabilitiesFields(fields) {
  return {
    rate: unitField(fields?.rate),
    interval: unitField(fields?.interval),
    min: unitField(fields?.min),
    max: unitField(fields?.max),
    capacity: unitField(fields?.capacity),
    trigger: unitField(fields?.trigger),
  };
}

function Capabilities() {
  return (
    <FormikProvider path='capabilities'>
      <FlexBox>
        <FormikUnitField
          name='rate'
          label='Rate'
          options={[...LIQUID_UNIT_OPTIONS, ...MASS_UNIT_OPTIONS]}
        />
        <FormikUnitField
          name='interval'
          label='Interval'
          options={TIME_UNIT_OPTIONS}
        />
      </FlexBox>

      <FlexBox>
        <FormikUnitField name='min' label='Min' options={UNITS_OPTIONS} />
        <FormikUnitField name='max' label='Max' options={UNITS_OPTIONS} />
      </FlexBox>

      <FlexBox>
        <FormikUnitField
          name='capacity'
          label='Capacity'
          options={[...LIQUID_UNIT_OPTIONS, ...MASS_UNIT_OPTIONS]}
        />
        <FormikUnitField
          name='trigger'
          label='Trigger'
          options={[...LIQUID_UNIT_OPTIONS, ...MASS_UNIT_OPTIONS]}
        />
      </FlexBox>
    </FormikProvider>
  );
}

const options = MACHINE_TYPES_OPTIONS.map((v) => ({
  label: v.label,
  value: v.value?.join(','),
}));

const tabs = [
  {
    title: 'Parent',
    key: 'parent',
    properties: (entity) => ({
      ledger: [entity?.ledger, yup.object()],
    }),
    Component: ({ entity }) => (
      <Stack spacing='1.5rem'>
        <LedgerSearch field='ledger' label='Ledger' disabled={!!entity._id} />
      </Stack>
    ),
  },
  {
    title: 'Properties',
    key: 'properties',
    properties: (entity, converter) => ({
      properties: {
        type: [
          entity?.properties?.type,
          yup.string().required('Machine type is required!'),
        ],
        capabilities: capabilitiesFields(entity?.properties?.capabilities),
        parts: [entity?.parts, yup.array()],
      },
    }),
    Component: ({ entity }) => (
      <FormikProvider path='properties'>
        <Stack spacing='1.5rem'>
          <FormikSelect name='type' label='Type' options={options} />
          <Capabilities />
        </Stack>
      </FormikProvider>
    ),
  },
  {
    title: 'Parts',
    key: 'parts',
    Component: ({ entity }) => (
      <FormikProvider path='properties'>
        <ThingSelector
          initial={entity?.properties?.parts}
          label='Parts'
          field='parts'
          ThingModal={DependentThingModal}
          filter={{
            [SEARCH_TYPES.THINGS]: {
              types: [THING_TYPES.PARTS],
            },
          }}
        />
      </FormikProvider>
    ),
  },
];

function MachinePage(props) {
  return (
    <AssetForm
      assetLabel='Machine'
      assetType={ASSET_TYPES.MACHINE}
      tabs={tabs}
    />
  );
}

export default withAppLayout(MachinePage, { title: 'Machine Management' });
