import { Visibility } from '@mui/icons-material';
import {
  FormHelperText,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import DatePicker from '@pw/components/Forms/DatePicker';
import { useFormTemplateHandlerSave } from '@pw/components/TemplateHandler/hooks';
import { Body2 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { RootFormikProvider } from '@pw/providers/FormikProvider';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { processChangeSet } from '@pw/utilities/hooks/logic/useFormikUtils';
import { Form, Formik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { useCallback, useContext, useId, useMemo, useState } from 'react';

import TimePicker from '@pw/components/Forms/TimePicker';
import countries from './countryByCode.json';

export function RenderIF({ children, check }) {
  const form = useContext(FormikContext);
  return check(form) ? children : null;
}

export function FormikTextField({
  name,
  loading = false,
  className = 'field',
  InputLabelProps = {},
  InputProps = {},
  ...props
}) {
  const { values, withErrors, handleChange, readonly } =
    useContext(FormikContext);
  return (
    <TextField
      variant='filled'
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      InputProps={{ ...InputProps, disableUnderline: true, readOnly: readonly }}
      className={className}
      name={name}
      value={values?.[name]}
      onClick={(e) => e.stopPropagation()}
      onChange={handleChange}
      {...withErrors(name)}
      // avoid "Received false for a non-boolean attribute
      loading={loading || 'false'}
      onKeyDown={(e) => {
        if (e.key === 'Enter') e.preventDefault();
      }}
      {...props}
    />
  );
}

export function FormikTextAreaField({
  name,
  loading = false,
  className = 'field',
  InputLabelProps = {},
  InputProps = {},
  multiline = false,
  rows = 1,
  ...props
}) {
  const { values, withErrors, handleChange, readonly } =
    useContext(FormikContext);
  return (
    <TextField
      variant='filled'
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      InputProps={{ ...InputProps, disableUnderline: true, readOnly: readonly }}
      className={className}
      name={name}
      value={values?.[name]}
      onChange={handleChange}
      onClick={(e) => e.stopPropagation()}
      {...withErrors(name)}
      // avoid "Received false for a non-boolean attribute"
      loading={loading || 'false'}
      onKeyDown={(e) => {
        if (e.key === 'Enter') e.preventDefault();
      }}
      multiline={multiline}
      rows={rows}
      {...props}
    />
  );
}

export function FormikPhoneField({
  name,
  loading = false,
  className = 'field',
  InputLabelProps = {},
  InputProps = {},
  ...props
}) {
  const { values, withErrors, handleChange, readonly, handleBlur } =
    useContext(FormikContext);

  const handleChangeTel = (newPhone, info) => {
    handleChange({ target: { name, value: info.numberValue } });
  };

  return (
    <MuiTelInput
      fullWidth
      variant='filled'
      className={`identity ${className}`}
      label='Phone'
      name={name}
      value={values?.[name] ?? ''}
      onChange={handleChangeTel}
      onBlur={handleBlur}
      // defaultCountry={values?.country ?? ''}
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      InputProps={{ ...InputProps, disableUnderline: true, readOnly: readonly }}
      {...withErrors(name)}
      loading={loading || 'false'}
      onKeyDown={(e) => {
        if (e.key === 'Enter') e.preventDefault();
      }}
      {...props}
    />
  );
}

export function FormikMeasuresField({
  name,
  loading = false,
  className = 'field',
  unit = null,
  InputLabelProps = {},
  InputProps = {},
  ...props
}) {
  const { values, withErrors, handleChange, readonly } =
    useContext(FormikContext);

  const converter = useConverter();
  const m = useMemo(
    () => (unit ? converter.unit(unit) : null),
    [converter, unit],
  );

  const unitDisplay = useMemo(
    () =>
      m
        ? {
            endAdornment: (
              <>
                <Tooltip title={m.label}>
                  <Body2 sx={{ marginLeft: '0.75rem', fontWeight: '600' }}>
                    {m}
                  </Body2>
                </Tooltip>
                {!!InputProps?.endAdornment && InputProps?.endAdornment}
              </>
            ),
          }
        : {},
    [InputProps?.endAdornment, m],
  );

  return (
    <TextField
      variant='filled'
      type='number'
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      InputProps={{
        ...InputProps,
        ...unitDisplay,
        disableUnderline: true,
        readOnly: readonly,
      }}
      className={className}
      name={name}
      value={values?.[name] ?? ''}
      onChange={handleChange}
      loading={loading || 'false'}
      onKeyDown={(e) => {
        if (e.key === 'Enter') e.preventDefault();
      }}
      {...withErrors(name)}
      {...props}
    />
  );
}

export function FormikNumberField({
  name,
  loading = false,
  className = 'field',
  InputLabelProps = {},
  InputProps = {},
  ...props
}) {
  const { values, withErrors, handleChange, readonly } =
    useContext(FormikContext);

  return (
    <TextField
      variant='filled'
      type='number'
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      InputProps={{ ...InputProps, disableUnderline: true, readOnly: readonly }}
      className={className}
      name={name}
      value={values?.[name] ?? ''}
      onChange={handleChange}
      onClick={(e) => e.stopPropagation()}
      onFocus={(e) => e.target.select()}
      loading={loading || 'false'}
      onKeyDown={(e) => {
        if (e.key === 'Enter') e.preventDefault();
      }}
      {...withErrors(name)}
      {...props}
    />
  );
}

export function FormikPasswordField({
  name,
  children = null,
  loading = false,
  InputLabelProps = {},
  InputProps = {},
  ...props
}) {
  const { values, withErrors, handleChange, handleBlur } =
    useContext(FormikContext);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      variant='filled'
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      name={name}
      type={showPassword ? 'text' : 'password'}
      value={values?.[name]}
      onChange={handleChange}
      onBlur={handleBlur}
      {...withErrors(name)}
      // avoid "Received false for a non-boolean attribute
      loading={loading || 'false'}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position='end'>
            <Tooltip title='Show the password'>
              <Box
                onClick={() => setShowPassword((v) => !v)}
                sx={{ paddingRight: '1em', cursor: 'pointer' }}
              >
                <Visibility className='icon' />
              </Box>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      {...props}
    >
      {children}
    </TextField>
  );
}

export function FormikDatePicker({
  name,
  label = 'Select',
  inputFormat = 'DD/MM/YYYY',
  InputLabelProps = {},
  InputProps = {},
  fullWidth = false,
  backgroundColor = null,
  fontSize = null,
  onChange,
  onAccept,
  ...props
}) {
  const { values, withErrors, setFieldValue, readonly } =
    useContext(FormikContext);

  return (
    <DatePicker
      value={values?.[name]}
      readOnly={readonly}
      onAccept={(newValue) => {
        if (newValue && newValue.$D && newValue.$y && newValue.$m) {
          setFieldValue(name, Date.parse(newValue));
        }
        onAccept && onAccept(newValue);
      }}
      onChange={(newValue) => {
        if (newValue && newValue.$D && newValue.$y && newValue.$m) {
          setFieldValue(name, Date.parse(newValue));
        }
        onChange && onChange(newValue);
      }}
      inputFormat={inputFormat}
      // disableMaskedInput
      // openTo="year"
      views={['year', 'month', 'day']}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          fullWidth={fullWidth}
          variant='filled'
          InputLabelProps={{ ...InputLabelProps, shrink: true }}
          InputProps={{
            ...params?.InputProps,
            ...InputProps,
            disableUnderline: true,
            style: {
              backgroundColor: backgroundColor || null,
              fontSize:fontSize || null,
            },
          }}
          {...withErrors(name)}
          disabled={props?.disabled}
        />
      )}
    />
  );
}

export function FormikTimePicker({
  name,
  label = 'Select',
  inputFormat = 'HH:mm',
  InputLabelProps = {},
  InputProps = {},
  fullWidth = false,
	disabled,
  sx = {},
  onChange,
  onAccept,
  ...props
}) {
  const { values, withErrors, setFieldValue, readonly } =
    useContext(FormikContext);

  return (
    <TimePicker
      label={label}
      value={new Date(values?.[name])}
      readOnly={readonly}
      onAccept={(newValue) => {
        setFieldValue(name, newValue.$d.toUTCString());
        onAccept && onAccept(newValue);

      }}
      onChange={(newValue, text) => {
        setFieldValue(name, newValue.$d.toUTCString());
        onChange && onChange(newValue);
      }}
			disabled={disabled}
      inputFormat={inputFormat}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          fullWidth={fullWidth}
          variant='filled'
          InputLabelProps={{ ...InputLabelProps, shrink: true }}
          InputProps={{
            ...params?.InputProps,
            ...InputProps,
            disableUnderline: true,
          }}
          {...withErrors(name)}
        />
      )}
    />
  );
}

export function FormikCheckBox({ name, ...props }) {
  const { values, setFieldValue, readonly } = useContext(FormikContext);
  return (
    <Box sx={{ position: 'relative', minHeight: '36px' }}>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={values?.[name] ?? false}
            onChange={
              !readonly ? () => setFieldValue(name, !values?.[name]) : undefined
            }
            readOnly={readonly}
          />
        }
        {...props}
      />
    </Box>
  );
}

export function FormikRadioGroup({
  name,
  options = [],
  label,
  className = 'radio-group',
  ...props
}) {
  const { values, handleChange, readonly } = useContext(FormikContext);
  return (
    <FormControl component='fieldset' className={className} {...props}>
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <RadioGroup
        name={name}
        value={values?.[name] || ''}
        onChange={handleChange}
        disabled={readonly}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export function FormikSelect({
  name,
  label,
  options = [],
  InputLabelProps = { shrink: true },
  withNone = true,
  ...props
}) {
  const id = useId();
  const { values, withErrors, setFieldValue, readonly } =
    useContext(FormikContext);
  const { error, helperText } = withErrors(name) ?? {};

  return (
    <FormControl variant='filled' {...{ error }} {...props}>
      <InputLabel id={id} {...InputLabelProps}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        readOnly={readonly}
        value={values?.[name] ?? 'none'}
        label={label}
        onChange={(event) => {
          const newValue = event.target.value === "none" ? null : event.target.value;
          setFieldValue(name, newValue);
        }}
        inputProps={{ readOnly: readonly }}
        {...props}
      >
        {withNone && (
          <MenuItem value='none'>
            <em>None</em>
          </MenuItem>
        )}
        {options.map(({ label: lbl, value: val }) => (
          <MenuItem key={val} value={val}>
            {lbl}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const makeOptions = (items, valueField = 'key') =>
  Object.entries(items).map(([key, val]) => ({
    label: val,
    value: valueField === 'key' ? key : val,
  }));

export const countriesOptions = makeOptions(countries);
export function FormikCountrySelect({ name, label, ...props }) {
  return (
    <FormikSelect
      name={name}
      label={label}
      options={countriesOptions}
      {...props}
    />
  );
}

export function FormikForm({
  changeSet = {},
  enableReinitialize = true,
  onSubmit = (values) => {
    console.log('FormikForm values', values);
  },
  readonly = false,
  edit = false,
  children,
  ...restProps
}) {
  const saveAsTemplate = useFormTemplateHandlerSave();
  const { initialValues, validationSchema } = useMemo(
    () => processChangeSet(changeSet),
    [changeSet],
  );

  const handleOnsubmit = useCallback(
    async (values, api) => {
      if (values?.saveTemplate) {
        await saveAsTemplate(values, api);
      } else {
        onSubmit(values, api);
      }
    },
    [saveAsTemplate, onSubmit],
  );

  return (
    <Box>
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={initialValues}
        onSubmit={handleOnsubmit}
        validationSchema={validationSchema}
        {...restProps}
      >
        {(props) => (
          <RootFormikProvider formik={props} edit={edit} readonly={readonly}>
            <Form noValidate>{children}</Form>
          </RootFormikProvider>
        )}
      </Formik>
    </Box>
  );
}
