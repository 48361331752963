import * as yup from 'yup';

export function locationFields(fields = {}) {
	const {
		enable = !!fields?.location_id,
		location_id = null,
		bay_id = null,
		row_id = null,
		level_id = null,
	} = fields;

	// console.log('Location>', enable, location_id, bay_id, row_id, level_id);

	return {
		enable: [enable, yup.bool()],
		location_id: [
			location_id,
			yup.string().nullable().when('enable', {
				is: true,
				then: (schema) => schema.required('Facility is required!'),
				otherwise: (schema) => schema,
			}),
		],
		bay_id: [
			bay_id,
			yup.string().nullable().when('enable', {
				is: true,
				then: (schema) => schema.required('Bay is required!'),
				otherwise: (schema) => schema,
			}),
		],
		row_id: [row_id, yup.string().nullable()],
		level_id: [level_id, yup.string().nullable()],
	};
}
