import { Box, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikTextAreaField } from '@pw/components/Forms/FormikForm';
import RequestItem from '@pw/components_v2/elements/display/request/RequestItem';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import { useAiSearch } from '@pw/utilities/hooks/ai/useAiSearch';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useRequestsLazyQuery } from '@pw/utilities/hooks/service/useRequestsQuery';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

const model = [
  'Mongoose filter per the following properties, only specify the field if a value value is provided else skip the field',
  {
    'name': 'This is the name of the request',
    'created': 'This is the create date of the request',
    'ledger.name': 'This is the ledger of this request',
    'parent_request.name': 'This is the parent request of this request, is another request',
    'workflow.name': 'This is the workflow of the request',
    'assignees': 'Assignees is an array of assignees, it has id.target_id.name where name can be matched to assignee name'
  }
];

function Search({ label = 'Search', types, multi = false, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const { aiSearch } = useAiSearch();

  const [inputProps, setInputProps] = useState(null);
  const [search, setSearch] = useState(null);

  const [matches, initMatches, , upsertMatches] = useItemListManager({
    comp: COMP.target,
    initialData: []
  });

  const [selectedItems, , , upsertSelectedItems, removeSelectedItems] = useItemListManager({
    comp: COMP.target,
    initialData: []
  });

  const [doSearch, { data, isLoading: searching }] = useRequestsLazyQuery();
  const { items: results = [], page } = data ?? {};

  const changeSet = {
    prompt: ['', yup.string().required('Please enter a prompt!')]
  };

  const searchFn = useCallback((filter) => {
    const request = {
      filter_types: types,
      search: filter
    };
    console.log('Request', request);
    // Clear the existing results
    debounce(() => initMatches([]), 25);
    // Trigger search...
    doSearch(request);
  }, [types]);

  const handleGenerate = useCallback(
    (values) => {
      console.log('Values', values.prompt);

      setInputProps({
        endAdornment: <CircularProgress size={32} color="info" />
      });

      aiSearch(model, values.prompt)
      .then((b) => {
        console.log('Generated response', b);
        if (b['$match']) {
          console.log('Match expression', b['$match']);
          // Set this
          debounce(() => setSearch(b['$match']), 25);
          // Now trigger the actual search with this filter
          searchFn(b['$match']);
        }
      })
      .catch((e) => {
        console.log('Error', e.message);
        enqueueSnackbar(
          'Failed to generate filter from prompt, please try again!',
          {
            variant: 'error'
          }
        );
      })
      .finally(() => {
        setInputProps(null);
      });
    },
    [searchFn]
  );

  useEffect(() => {
    console.log('Search results...', results);
    if (results.length > 0) {
      initMatches(results);
    }
  }, [results, initMatches]);

  const selectMatch = useCallback((item, select) => {
    if (multi) {
      console.log('Item selected', select, item);
      if (select) {
        upsertSelectedItems(item);
      } else {
        removeSelectedItems(item);
      }
    } else {
      debounce(() => onClose([item]), 25);
    }
  }, [upsertSelectedItems, removeSelectedItems, onClose, multi]);

  return (
    <FormikForm changeSet={changeSet} onSubmit={handleGenerate}>
      <Stack sx={{ width: '100%' }} spacing="1.5rem">
        <FormikTextAreaField
          label={label}
          name="prompt"
          placeholder="Enter a prompt to search..."
          disabled={!!inputProps || searching}
          InputProps={inputProps}
          onKeyDown={() => {
          }}
          fullWidth
        />
        <BasicList Content={RequestItem} items={matches} identity={(i) => i._id} loading={searching} onSelect={selectMatch} />
      </Stack>
      {multi && (
        <Box className="action-buttons">
          <TextButton
            size="small"
            handleClick={() => onClose()}
            color="secondary"
            label="Cancel"
          />
          <FilledButton
            size="small"
            disabled={selectedItems.length === 0}
            handleClick={() => onClose(selectedItems)}
          >
            Select
          </FilledButton>
        </Box>
      )}
    </FormikForm>
  );
}

export default Search;
