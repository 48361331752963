import { Draw, Print, EditCalendar } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PrintLabel from '@pw/components/admin/Things/PrintLabel';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import CreatorProps from '@pw/components/CreatorProps';
import SvgAddNew from '@pw/components/icons/AddNew';
import SvgClone from '@pw/components/icons/Clone';
import SvgDelivery from '@pw/components/icons/Delivery';
import SvgPurchaseOrder from '@pw/components/icons/PurchaseOrder';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SKUQRCode from '@pw/components/QRImage/SkuQRCode';
import { H5 } from '@pw/components/Typography';
import RecipeDesign from '@pw/components_v2/ThingView/modals/recipe';
import ScheduleWorkflow from '@pw/components_v2/ThingView/modals/recipe/ScheduleWorkflow';

import { THING_EDIT_URL, THING_TYPES, FINISHED_GOODS_TYPES } from '@pw/consts/thing';
import { useIsAccountActive } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import printElement from '@pw/utilities/printElement';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ThingModals = {
	DESIGN: {
		show: (params) => (<RecipeDesign {...params} />)
	},
  SCHEDULE_WORKFLOW: {
		show: (params) => (<ScheduleWorkflow {...params} />)
  }
}

function ThingSummary({ entity, refresh, showControls = true }) {
	const navigate = useNavigate();
	const { _id, type } = entity;

	const readonly = useCheckCompany(entity);
	const userIsActive = useIsAccountActive();

	const printRef = useRef();

	const [activeModal, setActiveModal] = useState(null);

	const showActions = useMemo(
		() => userIsActive && !readonly,
		[readonly, userIsActive],
	);

	const handlePrint = (ref) => {
		const element = ref?.current?.innerHTML;
		printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
	};

	const handleNew = () => {
		const route = THING_EDIT_URL[type];
		console.log('Route', type, route);
		navigate(`${route}`);
	};

	const handleClone = () => {
		const route = THING_EDIT_URL[type];
		const str = JSON.stringify(entity);
		const base = Buffer.from(str).toString('base64');
		navigate(`${route}?clone=${base}`);
	};

	const handleDelivery = () => {
		navigate(`/app/delivery`, { state: { thing_id: _id } });
	};

	const handlePO = () => {
		navigate(`/app/purchase-order`, { state: { thing_id: _id } });
	};

	const onClose = (updatedThing) => {
		if (updatedThing) {
			refresh();
		} else {
			setActiveModal(null);
		}
	};

  console.log({ entity });

	return (
		<Stack spacing="1.5rem">
			<Box className='summary-qr'>
				<Box ref={printRef} sx={{ width: '100%', padding: '0.75rem' }}>
          <SKUQRCode sku={entity} fullScreen={false} />
        </Box>

        {showActions && (
          <IconCircleButton
            onClick={() => handlePrint(printRef)}
            variant='outlined'
            color='secondary'
						sx={{ position: 'absolute', top: '5px', right: '5px' }}
					>
            <Print height={24} width={24} />
          </IconCircleButton>
        )}
      </Box>

      <FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
        {showControls && (
          <>
            <TitledButton handleClick={handleNew} label='New'>
              <SvgAddNew height={24} width={24} />
            </TitledButton>

            {showActions && (
              <TitledButton handleClick={handleClone} label='Clone'>
                <SvgClone height={24} width={24} />
              </TitledButton>
            )}
          </>
        )}

        {showActions && (
          <>
            {[THING_TYPES.LIQUID].includes(type) && (
              <TitledButton
								handleClick={() => setActiveModal(ThingModals.DESIGN)}
								label='Recipe'
							>
                <Draw height={24} width={24} />
              </TitledButton>
            )}
            {entity.design?._id && (
              [THING_TYPES.LIQUID].includes(type) ||
              [FINISHED_GOODS_TYPES.BOTTLE].includes(entity.properties?.type)
            ) && (
              <TitledButton
								handleClick={() => setActiveModal(ThingModals.SCHEDULE_WORKFLOW)}
								label='Schedule'
							>
                <EditCalendar height={24} width={24} />
              </TitledButton>
            )}
            {![
              THING_TYPES.WASTE,
              THING_TYPES.WASTE,
              THING_TYPES.SERVICES,
            ].includes(type) && (
              <TitledButton handleClick={handleDelivery} label='Delivery'>
                <SvgDelivery height={24} width={24} />
              </TitledButton>
            )}
            {![THING_TYPES.WASTE, THING_TYPES.SERVICES].includes(type) && (
              <TitledButton handleClick={handlePO} label='PO'>
                <SvgPurchaseOrder height={24} width={24} />
              </TitledButton>
            )}
            {[THING_TYPES.FINISHED].includes(type) && (
              <PrintLabel thing={entity} />
            )}
          </>
        )}
      </FlexBox>

      {
        // TODO: create new ThingStats component
        /*
      showActions &&
        [
          THING_TYPES.LIQUID,
          THING_TYPES.TRACKED,
          THING_TYPES.RAW,
          THING_TYPES.EXPIRING,
          THING_TYPES.CONSUMABLE,
          THING_TYPES.FINISHED,
        ].includes(type) && <SKUStats entity={entity} />
        */}

			{activeModal && activeModal.show({ thing: entity, open: !!activeModal, onClose })}
		</Stack>
  );
}

export default ThingSummary;
