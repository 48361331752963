import AssetAccounts from '@pw/components/AssetAccounts';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import SvgAssets from '@pw/components/icons/Assets';
import SvgInvoice from '@pw/components/icons/Invoice';
import SvgTeams from '@pw/components/icons/Teams';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components_v2/Request';
import InvoiceForm from '@pw/components_v2/Request/InvoiceForm';
import SourceInventory from '@pw/components_v2/Request/sources';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

const filter = {
  [SEARCH_TYPES.ASSETS]: {
    types: [
      ASSET_TYPES.CASK,
      ASSET_TYPES.IBC,
      ASSET_TYPES.PALLET,
      ASSET_TYPES.CONTAINER
    ],
  },
  [SEARCH_TYPES.REQUESTS]: {
    types: [REQUEST_TYPES.DELIVERY, REQUEST_TYPES.TRANSFER]
  }
};

const tabs = [
  {
    title: 'Inventory',
    key: 'inventory',
    Icon: SvgAssets,
    properties: (entity) => ({ sources: [entity?.sources, yup.object()] }),
    Component: (props) => (<SourceInventory name='sources' filter={filter} {...props} />),
  },
  {
    title: 'Accounts',
    key: 'accounts',
    properties: (entity) => assetAccountsFields(entity?.accounts),
    Icon: SvgTeams,
    Component: (props) => (
      <FormikProvider path='properties'>
        <AssetAccounts {...props} />
      </FormikProvider>
    ),
  },
  {
    title: 'Invoice',
    key: 'invoice',
    Icon: SvgInvoice,
    properties: (entity) => ({ client: [entity?.client, yup.object()] }),
    Component: (props) => (<InvoiceForm {...props} />),
  },
];

function ChangeOwnerShipForm(props) {
  // const [FormSubmitter, submitForm] = useFormSubmissionHook();

  // const [[], [sourceAssets], initSources, SourceInventory, SourceModals] =
  //   useSourceInventoryHook({
  //     title: 'Assets',
  //     filter: {
  //       asset_types: [
  //         ASSET_TYPES.CASK,
  //         ASSET_TYPES.IBC,
  //         ASSET_TYPES.PALLET,
  //         ASSET_TYPES.CONTAINER,
  //       ],
  //       request_types: [REQUEST_TYPES.DELIVERY, REQUEST_TYPES.TRANSFER],
  //     },
  //     submitForm,
  //   });
  //
  // const [
  //   [destinationSkus],
  //   [],
  //   initDestinations,
  //   DestinationInventory,
  //   DestinationModals,
  // ] = useInventorySelectorHook({
  //   title: 'Services',
  //   filter: {
  //     thing_types: [THING_TYPES.SERVICES],
  //   },
  //   SKUModal: DestinationThingModal,
  // });

  // const changeSetGenerator = useMemo(() => (initialValues) => ({
  //   ...requestIdFields(initialValues),
  //   properties: propertyFields(initialValues.properties),
  //   client: clientFields(initialValues.client),
  // }), []);

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  // const handleInit = useCallback((entity) => {
  //   initSources({ ...entity, assets: entity?. sources, skus: entity?.sku_sources });
  //   initDestinations({ ...entity, skus: entity?.sku_destinations });
  // }, [initSources, initDestinations]);

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  // const handleBeforeSave = useCallback(
  //   (entity) => ({
  //     ...entity,
  //     sources: sourceAssets,
  //     sku_destinations: destinationSkus,
  //   }),
  //   [destinationSkus, sourceAssets],
  // );

  // const ChildElements = ({ entity, readonly }) => {
  //   return (
  //     <SourceInventory title="Inventory" filter={filter} entity={entity} readonly={readonly} />
  //   );
  // };

  return (
    <RequestForm
      requestLabel='Change Ownership'
      requestType={REQUEST_TYPES.CHANGE_OWNERSHIP}
      tabs={tabs}
    />
  );
}

export default withAppLayout(ChangeOwnerShipForm, {
  title: 'Transfer Ownership',
});
