import { AccountCircle, TakeoutDiningOutlined } from '@mui/icons-material';

import ListAlt from '@mui/icons-material/ListAlt';
import CaskIcon from '@pw/components/icons/CaskIcon';
import FillingTankIcon from '@pw/components/icons/FillingTank';
import IBCIcon from '@pw/components/icons/Ibc';
import LocationIcon from '@pw/components/icons/Location';
import PalletIcon from '@pw/components/icons/Pallet';
import SvgPick from '@pw/components/icons/Pick';
import ProductionIcon from '@pw/components/icons/Production';
import Production from '@pw/components/icons/Production';
import SvgRegauge from '@pw/components/icons/Regauge';
import SvgSample from '@pw/components/icons/Sample';
import SvgShip from '@pw/components/icons/Ship';
import TankerIcon from '@pw/components/icons/Tanker';
import SvgTransfer from '@pw/components/icons/Transfer';

function SourceTypeIcon({ type, ...props }) {
	const types = {
		// cask: BarrelIcon,
		cask: CaskIcon,
		ibc: IBCIcon,
		tanker: TankerIcon,
		pallet: PalletIcon,
		container: TakeoutDiningOutlined,
		filling_tank: FillingTankIcon,
		machine: ProductionIcon,
		// Request
		ship: SvgShip,
		pick: SvgPick,
		sample: SvgSample,
		regauge: SvgRegauge,
		transfer: SvgTransfer,
		restock: LocationIcon,
		delivery: TankerIcon,
		production_run: Production,
		change_ownership: AccountCircle,
	};
	const Component = types[type] ?? ListAlt;

	return <Component {...props} />;
}

export default SourceTypeIcon;
