import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import ContactSearch from '@pw/components_v2/search/contact';
import { CURRENCY_OPTIONS } from '@pw/consts/currencies';
import { TIME_UNIT_OPTIONS } from '@pw/consts/units';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as yup from 'yup';

function VendorModal({ item, open, onClose, readonly }) {
  const { enqueueSnackbar } = useSnackbar();

  // Properties from the vendor config..
  const { vendor, product, standard_cost, lead_time } = item ?? {};

  console.log('Item', item);

  const changeSet = useMemo(
    () => ({
      vendor: [vendor, yup.object()],
      product: [product ?? '', yup.string()],
      standard_cost: [
        standard_cost,
        yup.object().shape({
          value: yup.number().required('Unit price required!'),
          unit: yup.string(),
        }),
      ],
      lead_time: [
        lead_time,
        yup.object().shape({
          value: yup.number().required('Lead Time required!'),
          unit: yup.string(),
        }),
      ],
    }),
    [vendor, product, standard_cost, lead_time],
  );

  const handleSubmit = (vendor) => {
    try {
      console.log('Setting Vendor', vendor);
      onClose(vendor);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title={`${vendor?.target_id?.name} Product Settings`}>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing='1.5rem' sx={{ paddingTop: '0.5rem' }}>
          {/*<ContactSearch field='vendor' label='Partner' />*/}

          <FormikTextField label='Product Code' name='product' fullWidth />
          <FlexBox alignItems='top'>
            <FormikUnitField
              label='Unit Price'
              name='standard_cost'
              options={CURRENCY_OPTIONS}
              fullWidth
            />
            <FormikUnitField
              label='Lead Time'
              name='lead_time'
              options={TIME_UNIT_OPTIONS}
              fullWidth
            />
          </FlexBox>

          <FormButtons onCancel={onClose} readonly={readonly} />
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default VendorModal;
