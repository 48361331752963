import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Body2 } from '@pw/components/Typography';
import debounce from '@pw/utilities/debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

const shadowStyle = {
  '--shadow-color': '0deg 0% 70%',
  boxShadow: `0px 0.5px 0.5px hsl(var(--shadow-color) / 0.32),
    0px 1px 1px -0.9px hsl(var(--shadow-color) / 0.28),
    0px 2.1px 2px -1.8px hsl(var(--shadow-color) / 0.25),
    -0.1px 4.6px 4.5px -2.7px hsl(var(--shadow-color) / 0.21),
    -0.1px 9.3px 9px -3.5px hsl(var(--shadow-color) / 0.17)`,
  backgroundColor: '#fff',
  border: '1px solid #000',
  borderRadius: '4px'
};

function ToolbarSection({ title, Toolbar, disabled = false, children }) {
  const [show, setShow] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const [toolbarHeight, setToolbarHeight] = useState(0);
  const [minHeight, setMinHeight] = useState(0);

  const contentRef = useRef(null);
  const menuRef = useRef(null);
  const toolbarRef = useRef(null);

  const [touch, setTouch] = useState([0, 0]);

  useEffect(() => {
    // Calculate the natural width of the content
    const mH = menuRef.current?.scrollHeight ?? 0;
    const tH = toolbarRef.current?.scrollHeight ?? 0;
    const cH = contentRef.current?.scrollHeight ?? 0;
    setMenuHeight(mH);
    setToolbarHeight(mH + tH);
    setMinHeight(Math.max(cH, (mH + tH + 10)));
  }, [contentRef, menuRef, toolbarRef, show]);

  const handleGestureStart = useCallback((event) => {
    if (disabled) return;
    const v = event.touches?.[0]?.clientY ?? event.clientY;
    setTouch([v, v]);
    event.stopPropagation();
  }, [disabled]);

  const handleGestureMove = useCallback((event) => {
    if (disabled) return;
    const v = event.touches?.[0]?.clientY ?? event.clientY;
    setTouch((x) => [x[0], v]);
    event.stopPropagation();
  }, [disabled]);

  const handleGestureEnd = useCallback(() => {
    if (disabled) return;
    // Calculate the swipe direction
    const diffY = touch[1] - touch[0];
    if (diffY > 30) {
      debounce(() => setShow(true), 25);
    } else if (diffY < -30) {
      debounce(() => setShow(false), 25);
    }
  }, [touch, disabled]);

  const handleClick = useCallback((event) => {
    if (disabled) return;
    if (event.target === event.currentTarget) {
      debounce(() => setShow((v) => !v), 25);
    }
    event.stopPropagation();
  }, [disabled]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Box sx={{
      position: 'relative',
      border: '1px solid #efefef',
      borderRadius: '5px',
      overflow: 'hidden',
      width: '100%',
      minHeight: minHeight,
      transition: `min-height 0.2s ease-in-out`,
    }}>
      <Box
        onTouchStart={handleGestureStart}
        onTouchMove={handleGestureMove}
        onTouchEnd={handleGestureEnd}
        onClick={handleClick}
        // easing='cubic-bezier(.17,.67,.83,.67)'
        sx={{
          position: 'absolute',
          zIndex: 10,
          left: 0,
          width: '100%',
          border: '1px solid #fafafa',
          backgroundColor: '#fafafa',
          // maxHeight: show? minHeight : '42px',
          minHeight: show ? toolbarHeight : menuHeight,
          display: 'flex',
          overflow: 'hidden', // Prevent content from spilling
          transition: `background-color 0.2s ease-in-out,
            hidden 0.4s ease-in-out,
            min-height 0.75s ease-in-out,`,
          cursor: 'pointer',
          ...(show ? shadowStyle : {})
        }}
      >
        <Stack onClick={handleClick} spacing={0} sx={{ width: '100%' }}>
          <Box
            onClick={handleClick}
            ref={menuRef}
            sx={{ padding: '0.5rem', width: '100%', display: 'flex', alignItems: 'center', justifyItems: 'space-between' }}
          >
            <Body2 sx={{ flexGrow: 1 }} onClick={handleClick}><strong>{title}</strong></Body2>
            {!disabled && show && (
              <ExpandLessRounded onClick={handleClick} />
            )}
            {!disabled && !show && (
              <ExpandMoreRounded onClick={handleClick} />
            )}
          </Box>
          <Stack ref={toolbarRef} onClick={handleClick} direction="row" spacing="0.5rem" sx={{ padding: '0.25rem', display: (!disabled && show)? 'block': 'none' }}>
            <Toolbar onClose={handleClose} />
          </Stack>
        </Stack>
      </Box>
      <Stack ref={contentRef} sx={{ marginTop: '2.75rem', padding: '0.5rem' }} spacing="0.5rem" flexGrow={1}>
        {children}
      </Stack>
    </Box>
  );
}

export default ToolbarSection;
