import Stack from '@mui/material/Stack';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ContactSearch from '@pw/components_v2/search/contact';
import LedgerSearch from '@pw/components_v2/search/ledger';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import ThingForm from '@pw/components_v2/Thing';
import { THING_TYPES } from '@pw/consts/thing';
import * as yup from 'yup';

const tabs = [
  {
    title: 'Ownership',
    key: 'ownership',
    properties: (entity, converter) => ({
      client: [entity?.client, yup.object()],
      ledger: [entity?.ledger, yup.object()],
    }),
    Component: (props) => (
      <Stack spacing="1.5rem">
        <ContactSearch />
        <LedgerSearch />
      </Stack>
    )
  },
  {
    title: 'Vendors',
    key: 'vendors',
    properties: (entity, converter) => ({
      vendors: [entity?.vendors, yup.array()],
    }),
    Component: ({ entity }) => (
      <VendorSelector initialValues={entity?.vendors} />
    )
  },
  {
    title: 'Policies',
    key: 'policies',
    properties: (entity, converter) => ({
      policies: [entity?.policies, yup.array()],
    }),
    Component: ({ entity }) => (
      <PolicySelector initialValues={entity?.policies} />
    )
  }
];

function ThingPage(props) {
  return (
    <ThingForm
      thingLabel="Consumable"
      thingType={THING_TYPES.CONSUMABLE}
      tabs={tabs}
    />
  );
}

export default withAppLayout(ThingPage, { title: 'Consumables' });
