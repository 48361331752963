import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import QRImage from '@pw/components/QRImage';
import Address from '@pw/components_v2/labels/Address';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { ASSET_EDIT_URL } from '@pw/consts/asset';
import { COMPANY_TYPE_REVERSE } from '@pw/consts/company';
import debounce from '@pw/utilities/debounce';
import { useCallback, useState } from 'react';

function CompanyItem({ item, onSelect, onClick }) {
  const [, setSelected] = useState(false);

  console.log('Company', item);

  const handleNavigate = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (item?._id && item?.type) {
      const route = ASSET_EDIT_URL?.[item?.type];
      console.log('Routing to', route);
      window.open(`${route}/${item?._id}`, '_blank').focus();
    }
  }, [item]);

  const handleClick = useCallback((e) => {
    if (onSelect) {
      setSelected((v) => {
        debounce(() => onSelect(item, !v), 25);
        return !v;
      });
    }
    if (onClick) {
      debounce(() => onClick(item), 25);
    }
  }, [item]);

  return (
    <Stack direction="row" spacing="0.75rem" alignItems="center" onClick={handleClick} sx={{ position: 'relative' }}>
      <Tooltip title={item?._id}>
        <Box sx={{ padding: '3px', border: '1px solid #3f3f3f', borderRadius: '3px' }}>
          <QRImage size={56} boxSize={56} value={item?._id} />
        </Box>
      </Tooltip>
      <Stack spacing='0.25rem' sx={{ borderLeft: '1px solid #d9d9d9', paddingLeft: '0.75rem'}}>
        <EntryTitle value={item?.name} />
        <EntryLabel label="Profile" value={COMPANY_TYPE_REVERSE[item?.type]} />
        <Address value={item?.address} />
      </Stack>
    </Stack>
  );
}

export default CompanyItem;
