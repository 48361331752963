import ArrowForward from '@mui/icons-material/ArrowForward';
import { Skeleton, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Pagination from '@pw/components/Pagination';
import GeneralSearch from '@pw/components/Search/GeneralSearch';
import ListItemSkeleton from '@pw/components/sekeletons/ListItemSkeleton';
import { Body3, H5 } from '@pw/components/Typography';
import { PATH } from '@pw/components_v2/CompanyView/consts';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import CompanyItem from '@pw/components_v2/elements/display/company/CompanyItem';
import RequestItem from '@pw/components_v2/elements/display/request/RequestItem';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import { REQUEST_EDIT_URL, REQUEST_NAMES } from '@pw/consts/requests';
import styles from '@pw/styles/content.styles';
import { useCompaniesQueryPaginated } from '@pw/utilities/hooks/service/useCompaniesQueries';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyListItem from './CompanyListItem';
import CompanySearch from './CompanySearch';

function SearchForm() {
  const navigate = useNavigate();

  const goToItem = (params) => {
    const id = params?._id ?? 'new';
    console.log('Navigating to', params, PATH, id);
    navigate(`${PATH}/${id}`);
  };

  return (
    <CompanySearch
      types={{}}
      label='Company'
      buttonIcon={<ArrowForward />}
      onItemClick={goToItem}
    />
  );
}

function OrganizationsList() {
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState('5');

  const reqParams = useMemo(() => {
    return {
      limit: Number(pageSize),
    };
  }, [pageSize]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useCompaniesQueryPaginated(reqParams);

  const goToItem = (params) => {
    const id = params?._id ?? 'new';
    navigate(`${PATH}/${id}`, { replace: true });
  };

  return (
    <Stack spacing={0.5}>
      <Pagination
        hasNextPage={hasNext}
        hasPrevPage={hasPrev}
        onPressNextPage={loadNext}
        onPressPrevPage={loadPrev}
        onChangePageSize={setPageSize}
        defaultPageSize={pageSize}
      />
      <BasicList loading={isLoading} Content={CompanyItem} items={page} identity={(i) => i._id} item={(i) => i.company_id} onClick={goToItem} />
    </Stack>
    //   {!isLoading && page && (
    //     <Stack spacing={0.5}>
    //       <Pagination
    //         hasNextPage={hasNext}
    //         hasPrevPage={hasPrev}
    //         onPressNextPage={loadNext}
    //         onPressPrevPage={loadPrev}
    //         onChangePageSize={setPageSize}
    //         defaultPageSize={pageSize}
    //         pageSize={pageSize}
    //       />
    //       <Stack className='list'>
    //         {page.map((i) => (
    //           <CompanyListItem
    //             key={i._id}
    //             item={i}
    //             buttonIcon={<ArrowForward />}
    //             onClick={() => goToItem(i.company_id)}
    //           />
    //         ))}
    //       </Stack>
    //     </Stack>
    //   )}
    //   {!isLoading && (!page || page.length === 0) && (
    //     <Body3>Nothing yet.</Body3>
    //   )}
    //   {isLoading && (
    //     <Stack spacing={0.5}>
    //       <FlexBox justifyContent='flex-end' gap='2rem'>
    //         <Skeleton variant='rectangular' width={45} height={45} />
    //         <Skeleton variant='rectangular' width={60} height={35} />
    //       </FlexBox>
    //       <Stack className='list'>
    //         <ListItemSkeleton />
    //         <ListItemSkeleton />
    //         <ListItemSkeleton />
    //       </Stack>
    //     </Stack>
    //   )}
    // </Stack>
  );
}

function Navigation() {
  // Current tab
  const [tab, setTab] = useState('search');

  const handleChange = (event, newValue) => setTab(newValue);

  return (
    <Stack>
      <Tabs value={tab} onChange={handleChange} aria-label="latest companies" variant="fullWidth" >
        <Tab label="Search" {...a11yProps('search')} />
        <Tab label="Latest" {...a11yProps('latest')} />
      </Tabs>

      <CustomTabPanel value={tab} index="search">
        <SearchForm />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index="latest">
        <OrganizationsList />
      </CustomTabPanel>
    </Stack>
  );
}

export default withSmallDialogCard(Navigation);
