import { Box, Tab, Tabs } from '@mui/material';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import RequestID from '@pw/components/RequestID';
import TabbedViewSkeleton from '@pw/components/sekeletons/TabbedViewSkeleton';
import AccessLog from '@pw/components_v2/elements/AccessLog';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { REQUEST_EDIT_URL, REQUEST_STATUS } from '@pw/consts/requests';
import { INVENTORY_STATUS } from '@pw/consts/thing';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { upsertRequestThunk } from '@pw/redux/thunks/request';
import styles from '@pw/styles/content.styles';
import useReadOnly from '@pw/utilities/hooks/logic/useReadOnly';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import useRequestQuery from '@pw/utilities/hooks/service/useRequestQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import LatestAddedRequests from './LatestAddedRequests';
import RequestSummary from './RequestSummary';

const RequestFormHandlerImpl = withDialogCard(({
    requestType,
    entity,
    readonly = false,
    tabs = [],
    onSave
  }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [target, scroll] = useScrollTarget();
    // Current tab
    const [tab, setTab] = useState(entity?._id ? 'summary' : 'id');

    const changeSet = useMemo(() => ({
      name: [entity?.name ?? '', yup.string().required('Name is required!')],
      created: [entity?.created ?? Date.now(), yup.number().required('Date is required!')],
      due: [entity?.due ?? Date.now(), yup.number().required('Due Date is required!')],
      ...tabs.reduce((acc, t) => ({ ...acc, ...(t?.properties?.(entity) || {}) }), {}),
    }), [entity, tabs]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(scroll, []);

    const handleChange = (event, newValue) => setTab(newValue);

    const handleSubmit = useCallback(
      (values) => {
        // const sourceSummary = summarizeInventory(
        //   values?.sources?.assets ?? [],
        //   updatvaluesed?.sources?.things ?? []
        // );
        // const destSummary = summarizeInventory(
        //   values?.destinations?.assets ?? [],
        //   values?.destinations?.things ?? []
        // );

        const request = {
          ...entity,
          ...values,
        };

        console.log('Submitting', request);

        dispatch(upsertRequestThunk(request))
          .unwrap()
          .then((result) => {
            console.log('Mutated request', result);
            const route = REQUEST_EDIT_URL?.[requestType];
            navigate(`${route}/${result?._id}`, { replace: true });
          });
      },
      [entity],
    );

    console.log('entity', entity);

    return (
      <Box sx={styles} className='root' ref={target}>
        <FormikForm
          changeSet={changeSet}
          onSubmit={handleSubmit}
          enableReinitialize
          readonly={readonly}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label='request form'
            variant='scrollable'
          >
            {entity?._id && (
              <Tab
                label='Summary'
                {...a11yProps('summary')}
                iconPosition='start'
              />
            )}
            <Tab label='Request' {...a11yProps('id')} iconPosition='start' />
            {tabs.map(({ title, key, Icon }) => (
              <Tab
                label={title}
                {...(Icon ? { icon: <Icon /> } : {})}
                {...a11yProps(key)}
                iconPosition='start'
              />
            ))}
            {entity?.access_log && entity?.access_log.length > 0 && (
              <Tab label='Log' {...a11yProps('log')} />
            )}
          </Tabs>

          {entity?._id && (
            <CustomTabPanel value={tab} index='summary'>
              <RequestSummary entity={entity} refresh={onSave} />
            </CustomTabPanel>
          )}
          <CustomTabPanel value={tab} index='id'>
            <RequestID label='Name' />
          </CustomTabPanel>
          {tabs.map(({ key, Component }) => (
            <CustomTabPanel value={tab} index={key}>
              <Component entity={entity} readonly={readonly} />
            </CustomTabPanel>
          ))}
          {entity?.access_log && entity?.access_log.length > 0 && (
            <CustomTabPanel value={tab} index='log'>
              <AccessLog access_log={entity?.access_log} />
            </CustomTabPanel>
          )}
          {!['summary', 'log'].includes(tab) &&
            ![
              REQUEST_STATUS.ARCHIVED,
              REQUEST_STATUS.DONE,
              REQUEST_STATUS.REJECTED,
            ].includes(entity?.status) && <FormButtons readonly={readonly} />}
        </FormikForm>
      </Box>
    );
  }
);

function NewForm({
  requestType,
  requestLabel,
  ...rest
}) {
  let [searchParams] = useSearchParams();

  const entity = useMemo(() => {
    // See if there is a clone parameter
    let clone = searchParams.get('clone');
    // let imp = searchParams.get('import');

    if (clone) {
      try {
        const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
        clone = JSON.parse(cv);
      } catch (e) {
        console.log('Failed to decode clone!', e);
      }
    }

    if (clone) {
      console.log('Cloning', clone);
      const { sources = {}, destinations = {} } = clone;
      const { assets = [], things = [] } = sources;
      const { assets: destAssets = [], things: destThings = [] } = destinations;
      return {
        ...clone,
        _id: undefined,
        name: null,
        created: new Date().getTime(),
        progress: null,
        approvals: null,
        sources: {
          assets: assets.map((s) => ({
            ...s,
            processed: INVENTORY_STATUS.PENDING
          })),
          things: things.map((s) => ({
            ...s,
            entries: (s.entries ?? []).map((e) => ({
              ...e,
              storage: (e.storage ?? []).map((es) => ({
                ...es,
                processed: INVENTORY_STATUS.PENDING
              }))
            })),
            processed: INVENTORY_STATUS.PENDING
          }))
        },
        destinations: {
          assets: destAssets.map((s) => ({
            ...s,
            processed: INVENTORY_STATUS.PENDING
          })),
          things: destThings.map((s) => ({
            ...s,
            entries: (s.entries ?? []).map((e) => ({
              ...e,
              storage: (e.storage ?? []).map((es) => ({
                ...es,
                processed: INVENTORY_STATUS.PENDING
              }))
            })),
            processed: INVENTORY_STATUS.PENDING
          }))
        },
        request_status: REQUEST_STATUS.PENDING
      };
    }
    return { type: requestType };
  }, [requestType]);

  usePageTitleHook(`Create ${requestLabel}`);

  return (
    <RequestFormHandlerImpl requestType={requestType} entity={entity} {...rest} />
  );
}

function ShowForm({
  id,
  requestType,
  requestLabel,
  ...rest
}) {
  const { data: entity, error, refetch, isLoading } = useRequestQuery({ id });
  const { readonly } = useReadOnly(entity);

  usePageTitleHook(`${requestLabel} ${entity?.name ?? '...'}`);

  const refreshData = useCallback(() => refetch({ id }), [id, refetch]);

  return (
    <>
      {isLoading && (
        <TabbedViewSkeleton />
      )}

      {error && (
        <NewForm
          requestType={requestType}
          requestLabel={requestLabel}
          {...rest}
        />
      )}

      {entity && (
        <>
          <RequestFormHandlerImpl
            requestType={requestType}
            entity={entity}
            readonly={readonly}
            onSave={refreshData}
            {...rest}
          />
        </>
      )}
    </>
  );
}

function RequestForm(props) {
  let { id } = useParams();
  console.log('ID: ', id);

  return (
    <Box className="adaptive-content">

      {!id && <NewForm {...props} />}
      {id && <ShowForm id={id} {...props} />}

      <LatestAddedRequests label={props.requestLabel} requestType={props.requestType} />
    </Box>
  );
}

export default RequestForm;
