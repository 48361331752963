import SvgAssets from '@pw/components/icons/Assets';
import SvgInvoice from '@pw/components/icons/Invoice';
import SvgPolicy from '@pw/components/icons/Policy';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components_v2/Request';
import InvoiceForm from '@pw/components_v2/Request/InvoiceForm';
import {
  InventoryDestinations,
  InventorySources,
} from '@pw/components_v2/Request/sources';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import * as yup from 'yup';

const filter = {
  [SEARCH_TYPES.THINGS]: {
    types: [THING_TYPES.CONSUMABLE, THING_TYPES.FINISHED],
  },
  [SEARCH_TYPES.REQUESTS]: {
    types: [REQUEST_TYPES.PICK],
  },
};
const filterDestination = {
  [SEARCH_TYPES.THINGS]: {
    types: [THING_TYPES.FINISHED, THING_TYPES.SERVICES],
  },
};

const tabs = [
  {
    title: 'Goods',
    key: 'original_goods',
    Icon: SvgAssets,
    properties: (entity) => ({
      sources: [entity?.sources, yup.object()],
    }),
    Component: (props) => <InventorySources filter={filter} {...props} />,
  },
  {
    title: 'Services',
    key: 'services',
    Icon: SvgAssets,
    properties: (entity) => ({
      destinations: [entity?.destinations, yup.object()],
    }),
    Component: (props) => (
      <InventoryDestinations filter={filterDestination} {...props} />
    ),
  },

  {
    title: 'Policy',
    key: 'policy',
    Icon: SvgPolicy,
    properties: (entity) => ({
      policies: [entity?.policies, yup.object()],
    }),
    Component: (props) => <PolicySelector {...props} />,
  },
  {
    title: 'Invoice',
    key: 'invoice',
    Icon: SvgInvoice,
    properties: (entity) => ({ client: [entity?.client, yup.object()] }),
    Component: (props) => <InvoiceForm {...props} />,
  },
];

function PackForm() {
  //   const sourceProps = useMemo(
  //     () => ({
  //       title: 'Original Goods',
  //       filter: {
  //         request_types: [REQUEST_TYPES.PICK],
  //         thing_types: [THING_TYPES.CONSUMABLE, THING_TYPES.FINISHED],
  //       },
  //       assetFilter: (a) => false,
  //       // submitForm,
  //       ThingModal: SourceThingModal,
  //     }),
  //     [],
  //   );

  //   const [
  //     [sourceThings, , , upsertSourceThings, removeSourceThings],
  //     [],
  //     initSources,
  //     SourceInventory,
  //     SourceModals,
  //   ] = useInventorySelectorHook(sourceProps);

  //   const onDestinationThingAdded = useCallback(
  //     (thing) => {
  //       console.log(
  //         'Destination Thing added',
  //         thing?.properties?.things?.length,
  //         thing?.properties?.releases?.length,
  //         thing?.type,
  //         thing,
  //       );
  //       if (
  //         thing?.type === THING_TYPES.FINISHED &&
  //         (thing?.properties?.things?.length > 0 ||
  //           thing?.properties?.releases?.length > 0)
  //       ) {
  //         const amount = thing?.entries?.reduce(
  //           (acc, curr) => acc + Number(curr.amount),
  //           0,
  //         );
  //         console.log('Amount', amount);

  //         // which things to use
  //         let things = [];

  //         // Find the release
  //         const release_ids = thing?.entries
  //           ?.map((e) => e.release)
  //           .filter((s) => s);
  //         console.log('Releases IDs', release_ids);

  //         // Find the release
  //         const release_list = thing?.properties?.releases?.filter((r) =>
  //           release_ids.includes(r.id),
  //         );

  //         console.log('Releases', release_list);

  //         if (release_list && release_list.length > 0) {
  //           // See if there is a things list in the releases, if so, add all of them in there..
  //           release_list
  //             .map((r) => r.things)
  //             .flat()
  //             .filter((s) =>
  //               [THING_TYPES.CONSUMABLE, THING_TYPES.FINISHED].includes(s.type),
  //             )
  //             .forEach((s) => {
  //               const existing = things.find((k) => k._id === s._id);
  //               if (!existing) {
  //                 console.log(' --> adding thing', s);
  //                 things.push({
  //                   ...s,
  //                   amount: Number(amount) * Number(s?.amount),
  //                 });
  //               }
  //             });
  //         }

  //         if (things.length === 0) {
  //           things = thing?.properties?.things
  //             .filter((s) =>
  //               [THING_TYPES.CONSUMABLE, THING_TYPES.FINISHED].includes(s.type),
  //             )
  //             .map((s) => ({
  //               ...s,
  //               amount: Number(amount) * Number(s?.amount),
  //             }));
  //         }

  //         console.log('things', things);

  //         debounce(() => {
  //           upsertSourceThings(things);
  //         }, 25);
  //       }

  //       // TODO: Load policies
  //       // loadPolicies(thing?.policies);
  //     },
  //     [upsertSourceThings],
  //   );

  //   const onDestinationThingRemoved = useCallback(
  //     (thing) => {
  //       console.log('Destination thing removed', thing);
  //       // if (
  //       // 	sku?.sku_type === SKU_TYPES.FINISHED &&
  //       // 	sku?.properties?.skus?.length > 0
  //       // ) {
  //       // 	removeSourceSkus(sku?.properties?.skus);
  //       // }
  //     },
  //     [removeSourceThings],
  //   );

  //   const destProps = useMemo(
  //     () => ({
  //       title: 'Packed Goods & Services',
  //       filter: {
  //         thing_types: [THING_TYPES.FINISHED, THING_TYPES.SERVICES],
  //       },
  //       assetFilter: (a) => false,
  //       // submitForm,
  //       thingModalProps: { calculateThingLiquid: true },
  //       ThingModal: DestinationThingModal,
  //       onThingAdded: onDestinationThingAdded,
  //       onThingRemove: onDestinationThingRemoved,
  //     }),
  //     [onDestinationThingAdded, onDestinationThingRemoved],
  //   );

  //   const [
  //     [destinationThings],
  //     [],
  //     initDestinations,
  //     DestinationInventory,
  //     DestinationModals,
  //   ] = useInventorySelectorHook(destProps);

  //   const changeSetGenerator = useMemo(
  //     () => (initialValues) => ({
  //       ...requestIdFields(initialValues),
  //       policies: [initialValues?.policies, yup.array()],
  //       client: clientFields(initialValues?.client),
  //     }),
  //     [],
  //   );

  //   /**
  //    * Initialization function
  //    * @type {(function(*): void)|*}
  //    */
  //   const handleInit = useCallback(
  //     (entity) => {
  //       initSources({
  //         ...entity,
  //         things: entity?.sources?.things,
  //       });
  //       initDestinations({
  //         ...entity,
  //         things: entity?.destinations?.things,
  //       });
  //     },
  //     [initSources, initDestinations],
  //   );

  //   /**
  //    * Prior to saving the entity, this is called to inject in the sources
  //    * @type {function(*): *&{sources: *, sku_sources: *}}
  //    */
  //   const handleBeforeSave = useCallback(
  //     (entity) => {
  //       return {
  //         ...entity,
  //         sources: {
  //           things: sourceThings,
  //         },
  //         destinations: {
  //           things: destinationThings,
  //         },
  //       };
  //     },
  //     [sourceThings, destinationThings],
  //   );

  return (
    <>
      <RequestForm
        requestLabel='Pack'
        requestType={REQUEST_TYPES.PACK}
        tabs={tabs}
      >
        {/* <SourceInventory />
        <DestinationInventory />

        <PolicySelector /> */}
      </RequestForm>

      {/* <SourceModals />
      <DestinationModals /> */}
    </>
  );
}

export default withAppLayout(PackForm, { title: 'Pack' });
