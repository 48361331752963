import { WidgetsOutlined } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import SvgAssets from '@pw/components/icons/Assets';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import SvgSearch from '@pw/components/icons/Search';
import ScanQR from '@pw/components/ScanQR';
import { Body1 } from '@pw/components/Typography';
import CustomTabPanel, {
  a11yProps,
} from '@pw/components_v2/elements/CustomTabPanel';
import AssetItem from '@pw/components_v2/elements/display/asset/AssetItem';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import SearchForm from '@pw/components_v2/search/general/SearchForm';
import { ASSET_TYPES } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { getAssetThunk } from '@pw/redux/thunks/asset';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useAssetConfirmHook from '@pw/utilities/hooks/logic/useAssetConfirmHook';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useParentAssetHook from '@pw/utilities/hooks/logic/useParentAssetHook';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

function ThingItem({ item }) {
  return (
    <Stack>
      <EntryTitle value={item?.name} />
    </Stack>
  );
}

const shadowStyle = {
  '--shadow-color': '0deg 0% 70%',
  boxShadow: `0px 0.5px 0.5px hsl(var(--shadow-color) / 0.32),
    0px 1px 1px -0.9px hsl(var(--shadow-color) / 0.28),
    0px 2.1px 2px -1.8px hsl(var(--shadow-color) / 0.25),
    -0.1px 4.6px 4.5px -2.7px hsl(var(--shadow-color) / 0.21),
    -0.1px 9.3px 9px -3.5px hsl(var(--shadow-color) / 0.17)`,
  backgroundColor: '#fff',
  border: '1px solid #000',
  borderRadius: '5px',
  padding: '0.25rem',
};

function InventorySelector({
  title,
  entity,
  filter,
  readonly,
  AssetModal,
  ThingModal,
  updatedAssets,
  name,
  parentAssetSupport = false,
  thingConverter = (t) => ({ ...t?.thing, ...t }),
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const { values, setFieldValue } = useContext(FormikContext);
  const [fetchAsset, { isLoading: assetLoading }] = useAssetLazyQuery();

  const forms = useMemo(() => Object.keys(filter), [filter]);

  const [value, setValue] = useState(forms[0]);

  const [things, , , upsertThings, removeThings] = useItemListManager({
    id: (a) => a.thing?._id,
    comp: COMP._id,
    initialData: entity?.[name]?.things?.map((t) => thingConverter(t)),
  });

  const [assets, , , upsertAssets, removeAssets] = useItemListManager({
    id: (a) => a._id,
    comp: COMP._id,
    initialData: entity?.[name]?.assets,
  });

  // Currently selected thing
  const [selectedThing, setSelectedThing] = useState(null);
  // Currently selected asset
  const [selectedAsset, setSelectedAsset] = useState(null);

  const [applyParentAsset, changeParentAsset, closeParentAsset] =
    useParentAssetHook(parentAssetSupport, assets, upsertAssets);
  const assetConfirmHook = useAssetConfirmHook(assets, upsertAssets);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setFieldValue('assets', assets);
  }, [assets]);

  useEffect(() => {
    setFieldValue('things', things);
  }, [things]);

  useEffect(() => {
    if (updatedAssets) upsertAssets(updatedAssets);
  }, [updatedAssets]);

  const handleRemoveThing = useCallback(
    (thing) => {
      confirm({
        title: 'Remove Thing',
        content: <Body1>{`Remove ${thing?.name}?`}</Body1>,
      })
        .then(() => removeThings(thing))
        .catch(() => {});
    },
    [removeThings],
  );

  const handleThingUpdate = useCallback(
    (thing) => {
      if (thing) {
        if (thing.include_holder) {
          // Get the entries and storage within that
          const entries = thing.entries ?? [];
          const assetIds = {};
          entries.forEach((e) => {
            const { storage = [] } = e;
            storage.forEach((s) => {
              let v = true;
              // eslint-disable-next-line no-prototype-builtins
              if (assetIds.hasOwnProperty(s.asset_id)) {
                v = assetIds[s.asset_id];
              }
              console.log(
                'Storage state',
                s.asset_id,
                v,
                s.amount,
                s.available_quantity,
              );
              assetIds[s.asset_id] = v && s.amount >= s.available_quantity;
            });
          });
          const existingAssetIds = assets.map((a) => a.asset_id);
          const newAssetsIds = Object.entries(assetIds)
            .filter(([k, v]) => v && !existingAssetIds.includes(k))
            .map(([k]) => k);
          if (newAssetsIds.length > 0) {
            console.log('Need to load these assets', newAssetsIds);
            newAssetsIds.forEach((id) =>
              fetchAsset(id).then((r) =>
                debounce(() => upsertAssets({ ...r, asset: r }), 25),
              ),
            );
          }
        }
        upsertThings({ ...thing, thing });
      }
      if (thing?.trackedAssets?.length) {
        console.log(`thing?.trackedAssets `, thing?.trackedAssets);
        upsertAssets(
          thing?.trackedAssets?.map((a) => ({ ...a, asset: a })),
          25,
        );
      }
      setSelectedThing(null);
    },
    [upsertThings, upsertAssets, setSelectedThing],
  );

  const handleAssetUpdate = useCallback(
    async (asset) => {
      console.log(
        '>>handleAssetUpdate',
        asset?.asset_id,
        asset?.rw_asset_id,
        asset?.path,
        asset?.asset_type,
        asset?.children?.length,
      );
      if (asset) {
        const updatedAsset = applyParentAsset(asset);
        const result = assetConfirmHook(asset.asset_id, asset);
        if (!result) {
          upsertAssets(updatedAsset);
        }
      }
      setSelectedAsset(null);
    },
    [applyParentAsset, assetConfirmHook, upsertAssets, setSelectedAsset],
  );

  const handleRemoveAsset = useCallback(
    (asset) => {
      confirm({
        title: 'Remove Asset',
        content: <Body1>{`Remove ${asset?.asset?.name}?`}</Body1>,
      })
        .then(() => removeAssets(asset))
        .catch(() => {});
    },
    [removeAssets],
  );

  const Toolbar = () => {
    const [search, setSearch] = useState(false);

    const handleScan = useCallback(({ text: item }) => {
      console.log('Scanned: ', item);
      try {
        const assetId = item.split('/').reverse()[0];
        console.log('Asset ID', assetId);

        // Load the asset information
        dispatch(getAssetThunk({ id: assetId }))
          .unwrap()
          .then((asset) => {
            console.log('Loaded asset', asset);

            if (asset.type === ASSET_TYPES.PALLET) {
              // Get all the children and add those in..
              const children = (asset.child_assets ?? []).map((a) => ({
                ...a,
                asset: a,
              }));
              upsertAssets([{ ...asset, asset }, ...children]);
            } else {
              upsertAssets({ ...asset, asset });
            }
          });
      } catch (e) {
        enqueueSnackbar('Invalid QR Code!', { variant: 'warning' });
      }
    }, []);

    const handleSearchResult = useCallback(
      (selected, type) => {
        console.log('Search results', type, selected);
        if (selected && selected.length > 0) {
          if (type === SEARCH_TYPES.ASSETS) {
            upsertAssets(selected.map((a) => ({ ...a, asset: a })));
          }

          if (type === SEARCH_TYPES.REQUESTS) {
          }

          if (type === SEARCH_TYPES.THINGS) {
            upsertThings(selected.map((t) => ({ ...t, thing: t })));
          }
        }
        setSearch(false);
      },
      [upsertThings, upsertAssets],
    );

    const openSearch = useCallback(
      (e) => {
        setSearch(true);
      },
      [setSearch],
    );

    return (
      <>
        {filter[SEARCH_TYPES.ASSETS] && (
          <ScanQR onSuccess={handleScan} closeOnSuccess />
        )}
        <TitledButton
          handleClick={openSearch}
          label='Search'
          disabled={readonly}
        >
          <SvgSearch height={24} width={24} />
        </TitledButton>

        {!!search && (
          <SearchForm
            open={!!search}
            types={filter}
            multi={true}
            onClose={handleSearchResult}
          />
        )}
      </>
    );
  };

  const thingTools = [
    ...(ThingModal
      ? [
          {
            title: 'Edit thing',
            Icon: SvgEdit,
            handler: setSelectedThing,
          },
        ]
      : []),
    {
      title: 'Remove thing',
      Icon: SvgDelete,
      handler: handleRemoveThing,
    },
  ];

  const assetTools = [
    ...(AssetModal
      ? [
          {
            title: 'Edit asset',
            Icon: SvgEdit,
            handler: setSelectedAsset,
          },
        ]
      : []),
    {
      title: 'Remove asset',
      Icon: SvgDelete,
      handler: handleRemoveAsset,
    },
  ];

  return (
    <Stack spacing='1.5rem'>
      <Stack direction='row' spacing='0.5rem' sx={shadowStyle}>
        <Toolbar />
      </Stack>

      {(things.length > 0 || assets.length > 0) && (
        <Box
          sx={{
            border: '1px solid #d9d9d9',
            borderRadius: '5px',
            overflow: 'hidden',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='source inventory management'
          >
            {filter[SEARCH_TYPES.THINGS] && (
              <Tab
                label='Things'
                icon={<WidgetsOutlined />}
                {...a11yProps(SEARCH_TYPES.THINGS)}
                iconPosition='start'
              />
            )}
            {filter[SEARCH_TYPES.ASSETS] && (
              <Tab
                label='Assets'
                icon={<SvgAssets />}
                {...a11yProps(SEARCH_TYPES.ASSETS)}
                iconPosition='start'
              />
            )}
          </Tabs>

          {filter[SEARCH_TYPES.THINGS] && (
            <CustomTabPanel value={value} index={SEARCH_TYPES.THINGS}>
              <BasicList
                Content={ThingItem}
                items={things}
                tools={thingTools}
                identity={(i) => i._id}
                item={(i) => i}
                readonly={readonly}
              />
            </CustomTabPanel>
          )}
          {filter[SEARCH_TYPES.ASSETS] && (
            <CustomTabPanel value={value} index={SEARCH_TYPES.ASSETS}>
              <BasicList
                Content={AssetItem}
                items={assets}
                tools={assetTools}
                identity={(i) => i?._id}
                item={(i) => i}
                readonly={readonly}
              />
            </CustomTabPanel>
          )}

          {AssetModal && selectedAsset && (
            <AssetModal
              open={!!selectedAsset}
              item={selectedAsset}
              onClose={handleAssetUpdate}
            />
          )}
          {ThingModal && selectedThing && (
            <ThingModal
              open={!!selectedThing}
              item={selectedThing}
              onClose={handleThingUpdate}
            />
          )}
        </Box>
      )}
    </Stack>
  );
}

function Inventory(props) {
  return (
    <FormikProvider path='sources'>
      <InventorySelector {...props} />
    </FormikProvider>
  );
}

export default Inventory;

export function InventorySources(props) {
  return (
    <FormikProvider path='sources'>
      <InventorySelector {...props} name='sources' />
    </FormikProvider>
  );
}
export function InventoryDestinations(props) {
  return (
    <FormikProvider path='destinations'>
      <InventorySelector {...props} name='destinations' />
    </FormikProvider>
  );
}
