import Stack from '@mui/material/Stack';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetTag from '@pw/components/Tasks/AssetTag';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import AssigneeSelector from '@pw/components_v2/search/assignee/AssigneeSelector';
import { requestAssignThunk } from '@pw/redux/thunks/request';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function AssignModal({ tasks, onClose }) {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values) => {
      console.log(`values`, values);
      const requests = tasks.map((t) => t._id);
      const assignees = (values?.assignees ?? []).map((a) => a._id);
      dispatch(requestAssignThunk({ requests, assignees }))
        .unwrap()
        .then(() => onClose(true, true));
    },
    [tasks],
  );

  const changeSet = {
    assignees: [[], yup.array().of(yup.object())],
  };

  return (
    <ModalWithClose title='Assign Tasks' open onClose={onClose}>
      <FlexBox className='asset-container' justifyContent='start' spacing={1}>
        {tasks.map((task) => (
          <AssetTag
            key={task._id}
            name={task?.name}
            action={task.action}
            type={task.type}
          />
        ))}
      </FlexBox>
      <FormikForm changeSet={changeSet} onSubmit={onSubmit}>
        <Stack className='form' spacing='2rem'>
          <AssigneeSelector initialValues={[]} readonly={false} />
          <FormButtons readonly={false} onCancel={onClose} />
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default AssignModal;
