import { Box, Collapse, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FormikDatePicker, FormikForm } from '@pw/components/Forms/FormikForm';
import { Filter, Reports } from '@pw/components/icons';
import SvgForwardarrow from '@pw/components/icons/Forwardarrow';
import { Body2, Body3, H5 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import SummaryWidget from './SummaryWidget';
import { useStatsLazyQuery } from '@pw/utilities/hooks/service/useStatsQuery';

const LayoutDutyPayments = ({ currentWidth, currentHeight }) => {
  const [fetchWidgetData, { data: widgetData, isLoading, refetch }] =
    useStatsLazyQuery();
  const data = widgetData?.widgets[0].data;
  const totalDutyPaymentsCount = 15;
  const totalDutyPaymentsAmount = 12300;
  const nextDuePayments = [
    {
      id: 1,
      paymentId: 'DP1001',
      payeeName: 'Customs Office A',
      dueDate: '2025-01-22',
      totalAmount: 2500,
    },
    {
      id: 2,
      paymentId: 'DP1002',
      payeeName: 'Customs Office B',
      dueDate: '2025-01-23',
      totalAmount: 1800,
    },
    {
      id: 3,
      paymentId: 'DP1003',
      payeeName: 'Customs Office C',
      dueDate: '2025-01-26',
      totalAmount: 3200,
    },
    {
      id: 4,
      paymentId: 'DP1004',
      payeeName: 'Customs Office D',
      dueDate: '2025-01-28',
      totalAmount: 2400,
    },
    {
      id: 5,
      paymentId: 'DP1005',
      payeeName: 'Customs Office E',
      dueDate: '2025-01-30',
      totalAmount: 2400,
    },
  ];

  const isSmallScreen = currentWidth <= 600;
  const isMediumScreen = currentWidth > 600 && currentWidth <= 1024;
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  useEffect(() => {
    fetchWidgetData({ widget_ids: [9] });
  }, []);
  const changeSet = {
    start_date: [startDate, yup.number()],
    end_date: [endDate, yup.number()],
  };

  const onFilter = (values) => {
    const { start_date, end_date } = values;
    refetch({ start_date, end_date, widget_ids: [9]}).then((res) => {
      if (res) {
        setStartDate(start_date);
        setEndDate(end_date);
      }
    });
  };

  const styles = {
    filterButtonWrapper: {
      width: '50px',
      height: '42px',
      position: 'absolute',
      right: '4px',
      borderRadius: '5px',
      backgroundColor: '#C6C6C6',
    },
    updateButton: {
      width: isSmallScreen ? '100px' : 'calc(100vw * (250 / 1029))',
      height: isSmallScreen ? '40px' : '56px',
      borderRadius: '5px',
      backgroundColor: '#52535B',
      color: '#fff',
      fontSize: isSmallScreen ? '12px' : '14px',
      lineHeight: isSmallScreen ? '1.2' : '26px',
      fontWeight: '600',
    },
  };
  return (
    <Box className='content-row' id='LayoutDutyPayments'>
      <Box className='content-header'>
        <Box className='title-box'>
          <Reports height={43} width={29} />
          <Box>
            <H5>Duty Payments</H5>
            {!!startDate && !!endDate && (
              <Body3 sx={{ alignItems: 'center' }}>
                <Bolder style={{ paddingLeft: '0.25ch', fontWeight: '600' }}>
                  fr.
                </Bolder>
                {new Date(startDate).toLocaleDateString()}
                <SvgForwardarrow
                  width={17}
                  height={7}
                  style={{
                    marginLeft: '20px',
                    marginRight: '10px',
                  }}
                />
                <Bolder
                  style={{
                    paddingLeft: '0.25ch',
                    fontWeight: '600',
                  }}
                >
                  to.
                </Bolder>
                {new Date(endDate).toLocaleDateString()}
              </Body3>
            )}
          </Box>
        </Box>
        <Stack
          direction='row'
          justifyContent={'space-between'}
          alignItems={'center'}
          position={'relative'}
          padding={'10px'}
          gap={'10px'}
        >
          {isVisibleFilter && <Box sx={styles.filterButtonWrapper}></Box>}
          <IconCircleButton
            onClick={() => setIsVisibleFilter(!isVisibleFilter)}
          >
            <Filter color={isVisibleFilter ? '#fff' : '#7C7C7C'} />
          </IconCircleButton>
        </Stack>
      </Box>

      <Collapse in={isVisibleFilter}>
        <FormikForm changeSet={changeSet} onSubmit={onFilter}>
          <Stack
            gap={1.5}
            py={2}
            pl={1.5}
            pr={2}
            sx={{ backgroundColor: '#C6C6C6', borderRadius: '5px' }}
          >
            <Box display='flex' flexBasis='row' gap={0}>
              <FormikDatePicker
                name='start_date'
                label='From'
                fullWidth
                backgroundColor='#fff'
              />
              <FormikDatePicker
                name='end_date'
                label='To'
                fullWidth
                backgroundColor='#fff'
              />
              <FilledButton
                sx={{ marginLeft: '15px', ...styles.updateButton }}
                type='submit'
                // size='small'
                label='Update data'
                loading={isLoading}
              />
            </Box>
          </Stack>
        </FormikForm>
      </Collapse>

      <Box className='content-body content-3col'>
        <Stack className='content-section'>
          <H5 className='content-section-title'>
            Total Duty Payments in Period
          </H5>
          <Stack className='content-section-content line'>
            <Body2
              sx={{
                fontWeight: '700',
                color: '#393C3C',
              }}
            >
              {totalDutyPaymentsCount} Payments
            </Body2>
          </Stack>
        </Stack>
        <Stack className='content-section'>
          <H5 className='content-section-title'>Next 5 Due Payments</H5>
          <Stack className='content-section-content line widget-list'>
            <SummaryWidget
              data={nextDuePayments}
              renderItem={(item) => (
                <Stack direction={'column'}>
                  <Stack direction={'row'} gap={'0.5rem'}>
                    <Body2 className='widget-label'>Payment ID:</Body2>
                    <Body2 className='widget-text-secondary'>
                      {item.paymentId}
                    </Body2>
                  </Stack>
                  <Stack direction={'row'} gap={'0.5rem'}>
                    <Body2 className='widget-label'>Client:</Body2>
                    <Body2 className='widget-text-secondary'>
                      {item.payeeName}
                    </Body2>
                  </Stack>
                  <Stack direction={'row'} gap={'0.5rem'}>
                    <Body2 className='widget-label'>Due:</Body2>
                    <Body2 className='widget-text-secondary'>
                      {item.dueDate
                        ? new Date(item.dueDate).toLocaleDateString()
                        : 'N/A'}
                    </Body2>
                  </Stack>
                  <Stack direction={'row'} gap={'0.5rem'}>
                    <Body2 className='widget-label'>Total:</Body2>
                    <Body2 className='widget-text-secondary'>
                      ${item.totalAmount}
                    </Body2>
                  </Stack>
                </Stack>
              )}
            />
          </Stack>
        </Stack>
        <Stack className='content-section'>
          <H5 className='content-section-title'>
            Total Duty Payments in Period
          </H5>
          <Stack className='content-section-content'>
            <Body2
              sx={{
                fontWeight: '700',
                color: '#393C3C',
              }}
            >
              ${totalDutyPaymentsAmount}
            </Body2>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default LayoutDutyPayments;
