import { TaskOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LocationContent from '@pw/components/AssetListItem/LocationContent';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikTextAreaField } from '@pw/components/Forms/FormikForm';
import SvgAddNew from '@pw/components/icons/AddNew';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import { Body2 } from '@pw/components/Typography';
import AssetItem from '@pw/components_v2/elements/display/asset/AssetItem';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import { useAiSearch } from '@pw/utilities/hooks/ai/useAiSearch';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useAssetsLazyQuery } from '@pw/utilities/hooks/service/useAssetsQuery';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import dayjs  from 'dayjs';

dayjs.extend(customParseFormat);
//
// function Asset({ item, onSelect }) {
//   const { _id, name, type, location } = item;
//   const [selected, setSelected] = useState(false);
//   const handleSelect = useCallback(() => {
//     setSelected((v) => {
//       onSelect(item, !v);
//       return !v;
//     });
//   }, [setSelected]);
//
//   return (
//     <Box className='listItem'>
//       {selected && (
//         <Box className="listSelected">
//           <TaskOutlined className="check" />
//         </Box>
//       )}
//
//       <Stack className='listContent' spacing={0.75}>
//         <Body2><strong>{name}</strong></Body2>
//         <IDDisplay value={_id} />
//         <Stack spacing="1rem" direction="row" alignItems="center">
//           <SourceDisplay type={type} name={type?.toUpperCase()} />
//           <LocationContent location={location} />
//         </Stack>
//       </Stack>
//       <Stack className='listButtons'>
//         <IconCircleButton size='small' onClick={handleSelect}>
//           <SvgAddNew style={{ height: '16px', width: '16px' }} />
//         </IconCircleButton>
//       </Stack>
//     </Box>
//   );
// }

const model = [
  'Mongoose aggregation pipeline, only specify the field if a value value is provided else skip the field',
  {
    'name': 'This is the name of the asset',
    'created': 'This is the create date of the asset',
    'ledger.name': 'This is the ledger of this asset',
    'parent_asset.name': 'This is the parent asset of this asset, could be pallet or container',
    'properties.reference': 'This is the reference of the asset',
    'properties.tags': 'This is the tags of the asset and type is array',
    'thing.name': 'This is the finished goods type',
    'properties.thing.name': 'This is the wood type',
    'properties.liquid.thing.name': 'This is the liquid type',
    'properties.liquid.date': 'This is the production date of the liquid',
  },
];

const formats = {
  'en-US': ['MM/DD/YYYY', 'M/D/YYYY', 'MM/DD/Y', 'M/D/Y', 'YYYY-MM-DD', 'YYYYMMDD'],
};
const defaultFormats = ['DD/MM/YYYY', 'D/M/YYYY', 'D/M/Y', 'DD/MM/Y', 'YYYY-MM-DD', 'YYYYMMDD'];

function replaceToDateWithEpoch(query, locale = navigator.language || navigator.userLanguage) {
  if (typeof query !== "object" || query === null) return query;

  for (const key in query) {
    if (query[key] && typeof query[key] === "object") {
      // If $toDate is found, replace it with epoch milliseconds
      if ("$toDate" in query[key]) {
        query[key] = dayjs(query[key]["$toDate"], formats[locale] ?? defaultFormats, locale).valueOf();
      } else {
        // Recursively process nested objects
        query[key] = replaceToDateWithEpoch(query[key], locale);
      }
    }
  }

  return query;
}

function Search({ label = 'Search', types, multi = false, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const { aiSearch } = useAiSearch();

  const [inputProps, setInputProps] = useState(null);
  const [search, setSearch] = useState(null);

  const [matches, initMatches , , upsertMatches,] = useItemListManager({
    comp: COMP.target,
    initialData: [],
  });

  const [selectedItems, , , upsertSelectedItems, removeSelectedItems] = useItemListManager({
    comp: COMP.target,
    initialData: [],
  });

  const [doSearch, { data, isLoading: searching }] = useAssetsLazyQuery();
  const { items: results = [], page } = data ?? {};

  const changeSet = {
    prompt: ['', yup.string().required('Please enter a prompt!')],
  };

  const searchFn = useCallback((filter) => {
    const request = {
      filter_types: types,
      search: filter,
    };
    console.log('Request', request);
    // Clear the existing results
    debounce(() => initMatches([]), 25);
    // Trigger search...
    doSearch(request);
  }, [types]);

  const handleGenerate = useCallback(
    (values) => {
      console.log('Values', values.prompt);

      setInputProps({
        endAdornment: <CircularProgress size={32} color='info' />,
      });

      aiSearch(model, values.prompt)
      .then((b) => {
        console.log('Generated response', b);
        if (b['$match']) {
          const search = replaceToDateWithEpoch(b['$match']);
          console.log('Match expression', b['$match'], search);
          // Set this
          debounce(() => setSearch(search), 25);
          // Now trigger the actual search with this filter
          searchFn(search);
        }
      })
      .catch((e) => {
        console.log('Error', e.message);
        enqueueSnackbar(
          'Failed to generate filter from prompt, please try again!',
          {
            variant: 'error',
          },
        );
      })
      .finally(() => {
        setInputProps(null);
      });
    },
    [searchFn],
  );

  useEffect(() => {
    console.log('Search results...', results);
    if (results.length > 0) {
      initMatches(results);
    }
  }, [results, initMatches]);

  const selectMatch = useCallback((item, select) => {
    if (multi) {
      console.log('Item selected', select, item);
      if (select) {
        upsertSelectedItems(item);
      } else {
        removeSelectedItems(item);
      }
    } else {
      debounce(() => onClose([item]), 25);
    }
  }, [upsertSelectedItems, removeSelectedItems, onClose, multi]);

  return (
    <FormikForm changeSet={changeSet} onSubmit={handleGenerate}>
      <Stack sx={{ width: '100%' }} spacing="1.5rem">
        <FormikTextAreaField
          label={label}
          name='prompt'
          placeholder="Enter a prompt to search..."
          disabled={!!inputProps || searching}
          InputProps={inputProps}
          onKeyDown={() => {}}
          fullWidth
        />
        <BasicList Content={AssetItem} items={matches} item={(i) => i} identity={(i) => i._id} loading={searching} onSelect={selectMatch} />
      </Stack>
      {multi && (
        <Box className="action-buttons">
          <TextButton
            size="small"
            handleClick={() => onClose()}
            color="secondary"
            label="Cancel"
          />
          <FilledButton
            size="small"
            disabled={selectedItems.length === 0}
            handleClick={() => onClose(selectedItems)}
          >
            Select
          </FilledButton>
        </Box>
      )}
    </FormikForm>
  );
}

export default Search;
