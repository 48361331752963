import { Box } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikCheckBox, FormikForm, RenderIF } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import DesignerContext from '@pw/context/DesignerContext';
import debounce from '@pw/utilities/debounce';
import { useCallback, useContext, useMemo } from 'react';
import * as yup from 'yup';
import styles from './modal.styles';

function ModalWrapper({
  changeSetGenerator,
  useDefault = true,
  children
}) {
  const {
    selectedNode,
    nodesData,
    setSelectedNode,
    upsertNodesData
  } = useContext(DesignerContext);

  console.log('Selected node', selectedNode);

  const handleClose = useCallback(() => {
    setSelectedNode(null);
  }, [setSelectedNode]);

  const handleSubmit = useCallback((state) => {
    const existingData = nodesData?.find((n) => n.id === selectedNode?.id) ?? { id: selectedNode?.id };
    console.log('Updating node state', existingData, state);
    debounce(() => {
      upsertNodesData({ ...existingData, ...state });
      setSelectedNode(null);
    }, 25);
  }, [upsertNodesData, setSelectedNode, nodesData]);

  const changeSet = useMemo(() => {
    const existingData = nodesData?.find((n) => n.id === selectedNode?.id) ?? { id: selectedNode?.id };
    return {
      default: [existingData?.default ?? true, yup.boolean()],
      ...changeSetGenerator(existingData)
    };
  }, [changeSetGenerator, nodesData]);

  return (
    <ModalWithClose open={!!selectedNode} onClose={handleClose} title={selectedNode?.data?.label} modalStyles={styles}>
      <FormikForm
        changeSet={changeSet}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <FormWrapper>
          {useDefault && (
            <>
              <FormikCheckBox name="default" label="Default" />
              <RenderIF check={({ values }) => !values?.default}>
                {children}
              </RenderIF>
            </>
          )}
          {!useDefault && (
            <>
              {children}
            </>
          )}

          <Box className="action-buttons">
            <TextButton size="small" handleClick={handleClose} color="secondary">
              Cancel
            </TextButton>
            <FilledButton type="submit" size="small">
              Save
            </FilledButton>
          </Box>
        </FormWrapper>
      </FormikForm>
    </ModalWithClose>
  );
}

export default ModalWrapper;
