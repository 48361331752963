import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useEdgesState, useNodesState } from 'reactflow';
import * as yup from 'yup';
import { SaveRounded } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';

import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import FilledButton from '@pw/components/Buttons/FilledButton';

import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';

// import RecipeProvider from '@pw/components_v2/ThingView/modals/recipe/RecipeProvider';
// import { DESIGN_NODE_TYPE, DESIGN_TYPE } from '@pw/consts/designs';
import { upsertWorkflowThunk } from '@pw/redux/thunks/designs';
// import { upsertThingThunk } from '@pw/redux/thunks/thing';
import { COMP, ID } from '@pw/utilities/comp';
// import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';

import { useAccountId, useCompanyId, useCompanyName } from '@pw/redux/containers/User/hooks';

import {
  FormikForm,
	FormikDatePicker,
  FormikTimePicker,
  FormikRadioGroup,
} from '@pw/components/Forms/FormikForm';
import { useFormikContext } from 'formik';


const styles = {
  '.radiogroup': {
    '.MuiRadioGroup-root': { rowGap: '1rem' },
    '.MuiFormControlLabel-root': {
      minHeight: '56px',
      span: { alignSelf: 'center' },
    },
  },
};

const METHODS = {
  MANUAL: 'Manual',
  NEXT_AVAILABLE: 'Next available',
}

const nearest5Min = (mins) => Math.round(mins/5) * 5;

function FormContent(props) {
  const { changeSet } = props;
  const { values, setFieldValue } = useFormikContext();
  const [dateEpoch, setDateEpoch] = useState();
  const [timeEpoch, setTimeEpoch] = useState();
  const [scheduleEpoch, setScheduleEpoch] = useState();

  console.log({ values, scheduleEpoch, SE: new Date(scheduleEpoch) });

  const onDateChange = useCallback(({ $d }) => {
    const [_day, _month, _date, _year] = $d.toString().split(' ');
    // remove any time set
    const newDate = new Date(`${_month} ${_date}, ${_year}`);
    setDateEpoch(newDate.getTime());
  }, []);

  const onTimeChange = useCallback(({ $d }) => {
    const [, , , , _time, _tzo, _tz] = $d.toString().split(' ');
    const [h, m, s] = _time.split(':');
    const epochTime = ((h * 60 * 60) + (nearest5Min(m) * 60) /*  + (s * 1) */) * 1_000;
    setTimeEpoch(epochTime);
  }, []);

  useEffect(() => {
    if (!(dateEpoch && timeEpoch)) return;
    const _scheduleEpoch = dateEpoch + timeEpoch;
    setScheduleEpoch(_scheduleEpoch);
  }, [dateEpoch, timeEpoch]);

  const createHandler = useCallback(() => {

  }, []);

  return (
    <Stack direction="row">
      <Stack direction="column">
        <FormikRadioGroup name="schedule_method"
          value={values.schedule_method}
          className="radiogroup"
          options={Object.entries(METHODS).map(([k, v]) => (
            { value: v, label: v }
          ))}
        >
        </FormikRadioGroup>
      </Stack>

      <Stack direction="column" spacing={3}>
        <Stack direction="row" spacing={2}>
          <FormikDatePicker
            name='date'
            label='Start Date'
            required
            // should we disable is start is in past??
            // disabled={readonly || disabled}
            disabled={values.schedule_method !== METHODS.MANUAL}
            onChange={onDateChange}
          />
          <FormikTimePicker
            name='time'
            label='Start Time'
            required
            disabled={values.schedule_method !== METHODS.MANUAL}
            onChange={onTimeChange}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

function ScheduleModal({ thing, design, workflow = {}, open, onClose }) {
  const dispatch = useDispatch();
	const companyId = useCompanyId();

  const { _id, name } = design;
  const { start, end } = workflow;
  const defaultMethod = METHODS.MANUAL;


  const changeSet = useMemo(
    () => ({
      schedule_method: [defaultMethod ?? METHODS.MANUAL, yup.mixed().oneOf(Object.values(METHODS))],
      date: [start ?? Date.now(), yup.number().required('Date required!')],
      time: [start ?? Date.now(), yup.number().required('Date required!')],
    }),
    [start, defaultMethod],
  );

  const handleSave = useCallback(() => {
    // First we will save the design
    dispatch(upsertWorkflowThunk({
      design: _id,
      company_id: companyId,
      name: `${name} `,
      thing_id,
      start,
      // ...design,
    }))
    .unwrap()
    .then((workflow) => {
      // If previously we had no _id, means first time creating this design for this thing..
      // Update the thing with the recipe
      console.log('Upserted design', workflow._id);
      if (!_id) {
        const updatedThing = {
          ...(thing ?? {}),
          design: workflow._id,
        };
        console.log('Updating thing', updatedThing);
        // Update the thing too
        dispatch(upsertThingThunk(updatedThing))
        .unwrap()
        .then(onClose);
      } else {
        onClose();
      }
    });
  }, [thing, design, _id, start]);

  return (
    <ModalWithClose open={open} onClose={onClose} >
      <Stack sx={styles} className="root" spacing={2}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <H5>Schedule "{name}" Workfow</H5>
          </Stack>

          <FormikForm changeSet={changeSet} onSubmit={handleSave}>
            <FormContent changeSet={changeSet} />
          </FormikForm>

          <Stack direction="row" spacing={2}>
            {/* <IconButton
              onClick={handleSave}
              aria-label="Save"
            >
              <SaveRounded />
            </IconButton> */}
            {/* <IconButton
              onClick={() => onClose()}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton> */}
          </Stack>
            <FilledButton type='submit' size='small'>
              Schedule
            </FilledButton>
        </Stack>
      </Stack>
    </ModalWithClose>
  );
}

function ScheduleWorkflow({ thing, open, onClose }) {
  const { design } = thing;

  return (
    <ScheduleModal thing={thing} design={design} open={open} onClose={onClose} />
  );
}

export default ScheduleWorkflow;
