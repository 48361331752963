import { Box } from '@mui/material';
import SvgLocation from '@pw/components/icons/Location';
import { Body2 } from '@pw/components/Typography';
import { useMemo } from 'react';

function Address({ value }) {
  if (!value) return null;

  const address = useMemo(() => {
      const st = [value?.address_1, value?.address_2, value?.city, value?.state, value?.post_code, value?.country];
      return st.filter((s) => s).join(', ');
  }, [value]);

  return (
  <Box
    sx={{
      flexDirection: 'row',
      display: 'flex',
      gap: '0.25rem',
    }}
  >
    <SvgLocation style={{ width: '12px', height: 'auto' }} />
    <Body2 sx={{ flexGrow: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipses' }} title={value}>
      {address}
    </Body2>
  </Box>
  );
}

export default Address;
