import {
  CheckBoxRounded,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import {
  FormikCheckBox,
  FormikForm,
  FormikNumberField,
  FormikSelect,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import DutyPaidDisplay from '@pw/components/properties/DutyPaidDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import InventorySearch from '@pw/components/Search/InventorySearch';
import StorageSetupModal from '@pw/components/SKUSelector/modals/StorageSetup';
import { Body2, H5, Overline } from '@pw/components/Typography';
import { ASSET_NAMES } from '@pw/consts/asset';
import {
  THING_TYPES,
  WASTE_REASON_OPTIONS,
  WASTE_REASONS,
} from '@pw/consts/thing';
import FormikContext from '@pw/context/FormikContext';
import { useCompanyThings } from '@pw/redux/containers/User';
import calculateThingStorageLiquid from '@pw/utilities/calculateThingStorageLiquid';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useGetId from '@pw/utilities/hooks/logic/useGetId';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useProgress from '@pw/utilities/hooks/logic/useProgress';
import { useGetThingInventoryLazyQuery } from '@pw/utilities/hooks/service/useGetThingInventoryQuery';
import { groupBy } from '@pw/worker/utility';
import flatten from 'lodash.flatten';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

// function SKUSelection({
// 	name,
// 	selection,
// 	skuEntryItems,
// 	unit,
// 	isSelected,
// 	toggleSelection,
// }) {
// 	const { values } = useContext(FormikContext);
// 	const [storageAllocation, setStorageAllocation] = useState(null);

// 	const processSelection = useCallback(
// 		(item) => {
// 			if (isSelected(item)) {
// 				// Remove the amount..
// 				item.amount = 0;
// 				toggleSelection(item);
// 			} else {
// 				// Amount outstanding..
// 				const amount = values?.[name] ?? 0; // Total amount we need

// 				setStorageAllocation(item);
// 				// let selectedAmount =
// 				// 	selection?.reduce((v, i) => v + Number(i?.amount ?? 0), 0) ?? 0;
// 				// const storageList = flatten(skuEntryItems.map((e) => e.storage ?? []));
// 				// selectedAmount +=
// 				// 	storageList?.reduce((v, i) => v + Number(i?.amount ?? 0), 0) ?? 0;
// 				//
// 				// if (selectedAmount < amount) {
// 				// 	item.amount = amount - selectedAmount;
// 				// 	toggleSelection(item);
// 				// }
// 			}
// 		},
// 		[isSelected, toggleSelection, values, name, selection, skuEntryItems],
// 	);

// 	const handleStorageSelection = useCallback(
// 		(item) => {
// 			if (item && storageAllocation) {
// 				console.log('Storage selection', item);
// 				storageAllocation.amount = item.amount;
// 				toggleSelection(item);
// 			}
// 			setStorageAllocation(null);
// 		},
// 		[storageAllocation, toggleSelection],
// 	);

// 	if (!skuEntryItems || skuEntryItems.length === 0) return;

// 	return (
// 		<>
// 			<Stack spacing={0} className='list'>
// 				{skuEntryItems.map((entry) => (
// 					<Stack className='listItem storage-container' key={entry.path}>
// 						<Stack spacing={0.75} className='listContent'>
// 							<Stack
// 								spacing={0.5}
// 								sx={{ cursor: 'pointer', flexGrow: 1, textAlign: 'left' }}
// 							>
// 								<IDDisplay value={entry?.sku_id} />
// 								<SourceDisplay
// 									type={entry?.request_type}
// 									name={entry?.request_name}
// 								/>
// 							</Stack>
// 							{entry?.storage?.map((_storage) => (
// 								<ThingStorageItem
// 									key={_storage.asset_id}
// 									item={_storage}
// 									unit={unit}
// 									selected={isSelected(_storage)}
// 									onSelect={() => processSelection(_storage)}
// 								/>
// 							))}
// 						</Stack>
// 					</Stack>
// 				))}
// 			</Stack>
// 			{storageAllocation && (
// 				<StorageSetupModal
// 					open={!!storageAllocation}
// 					title='Pick'
// 					item={storageAllocation}
// 					onClose={handleStorageSelection}
// 				/>
// 			)}
// 		</>
// 	);
// }

function ThingStorageItem({ item, unit, selected = false, onSelect }) {
  const { asset, amount, available_quantity, duty_paid, reserved_quantity } =
    item;

  const { name, type } = asset ?? {};

  const onClick = useCallback(() => {
    if (onSelect) {
      onSelect(!selected);
    }
  }, [selected, onSelect]);

  return (
    <Box className='card' action={ASSET_NAMES[type]} onClick={onClick}>
      <Box className='card-tab'>
        {selected && <CheckBoxRounded className='check' />}
      </Box>
      <Stack
        className={`card-content ${duty_paid ? 'card-highlight' : ''}`}
        spacing={0.25}
      >
        <IDDisplay value={name} />
        <DutyPaidDisplay value={duty_paid} />
        <Divider variant='middle' sx={{ opacity: 0.6 }} />
        <AmountDisplay amount={amount?.value} unit={unit} />
        <AmountDisplay
          label='Reserved'
          amount={reserved_quantity}
          unit={unit}
        />
        <AmountDisplay
          label='Available'
          amount={available_quantity}
          unit={unit}
        />
      </Stack>
    </Box>
  );
}

function StorageItem({ item, onSelect, unit }) {
  return (
    <ThingStorageItem
      item={item}
      unit={unit}
      selected={item.selected ?? false}
      onSelect={(selected) => onSelect(item, selected)}
    />
  );
}

function ChildList({ label, items, update, unit }) {
  const handleSelect = useCallback(
    (item, selected) => {
      // Deselect any other option (so only allow single selects);
      const updatedItems = items.map((i) => ({
        ...i,
        selected: i._id === item._id ? selected : false,
      }));

      debounce(() => update(updatedItems), 25);
    },
    [update, items],
  );

  return (
    <Stack className='inventory' sx={{ width: '40%' }}>
      <Box className='inventory-header'>
        <Overline>{label}</Overline>
      </Box>
      <Box className='inventory-contents'>
        <Stack spacing={0.5}>
          {items.map((_item) => (
            <StorageItem
              key={_item._id}
              item={_item}
              onSelect={handleSelect}
              unit={unit}
            />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}

function StorageSetupModalWrapper({ selected, ...props }) {
  const { values } = useContext(FormikContext);
  const left = Math.max(values?.amount ?? 0 - selected, 0);
  if (left === 0) {
    return null;
  }
  return <StorageSetupModal left={left} {...props} />;
}

function GeneralSourceThing({
  item,
  onClose,
  include_tax_code = false,
  taxCodes = [],
  exportItems = false,
  calculateThingLiquid = false,
}) {
  const companyThings = useCompanyThings();
  const { enqueueSnackbar } = useSnackbar();
  const { path } = useGetId();

  const converter = useConverter();

  // We track the "amount" we want at the Thing Item level
  const {
    id,
    type,
    tax_code,
    amount,
    waste_amount,
    waste_reason,
    include_holder,
    entries = [],
    vendor,
    release,
    duty_paid = false,
    properties,
    vendors,
  } = item ?? {};

  const { unit, cased } = properties ?? {};
  const { releases = [] } = cased ?? {};

  console.log('GeneralSourceThing', item);

  const vendorOptions = useMemo(
    () =>
      (vendors ?? []).map((v) => ({
        label: `${v.vendor?.target_id?.name} ${v.product}`,
        value: v.vendor?._id,
      })),
    [vendors],
  );
  const releaseOptions = useMemo(
    () => (releases ?? []).map((v) => ({ label: v?.name, value: v })),
    [releases],
  );

  const [storageAllocation, setStorageAllocation] = useState(null);

  const existingStorage = useMemo(
    () => flatten(entries.map((e) => e.storage ?? [])),
    [entries],
  );

  const [available, , , upsertAvailable, removeAvailable] =
    useItemListManager();

  console.log('Available', available);

  const [selected, , , upsertSelected, removeSelected] = useItemListManager({
    initialData: existingStorage.map((i) => ({ ...i, selected: false })),
  });

  const selectedCount = useMemo(
    () =>
      selected.reduce(
        (v, i) =>
          v +
          Number(
            i?.amount ??
              (i?.available_quantity ?? 0) - (i?.reserved_quantity ?? 0),
          ),
        0,
      ),
    [selected],
  );

  console.log('Selected', selectedCount);

  const finished = useMemo(() => type === THING_TYPES.FINISHED, [type]);
  const consumed = useMemo(() => type === THING_TYPES.CONSUMABLE, [type]);

  const [refetch, { data: thingEntryItems = [], error, isLoading }] =
    useGetThingInventoryLazyQuery(entries);

  // const [selection, initSelection, toggleSelection, isSelected] = useSelections(
  // 	storage,
  // 	EQUAL.asset,
  // );

  const [ProgressBar, { setProgress }] = useProgress();

  const changeSet = useMemo(
    () => ({
      amount: [
        amount && unit ? converter.cx(amount ?? 0, null, unit) : (amount ?? ''),
        yup.number().positive('Must be positive!').required('Amount required!'),
      ],
      ...(consumed
        ? {
            waste_amount: [
              waste_amount && unit
                ? converter.cx(waste_amount ?? 0, null, unit)
                : (waste_amount ?? 0),
              yup.number().required('Waste Amount required!'),
            ],
            waste_reason: [waste_reason ?? WASTE_REASONS.broken, yup.string()],
          }
        : {}),
      include_holder: [!!include_holder, yup.boolean()],
      ...(include_tax_code
        ? {
            tax_code: [
              tax_code ?? '',
              yup.string().required('Tax code required!'),
            ],
          }
        : {}),
      vendor: [vendor, yup.string()],
      ...(finished
        ? {
            release: [
              release,
              yup.object().shape({
                id: yup.string(),
                name: yup.string(),
                liquid_type: yup.string(),
              }),
            ],
            duty_paid: [duty_paid, yup.boolean()],
          }
        : {}),
    }),
    [amount, finished],
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => setProgress(selectedCount), [selectedCount]);

  const handleSubmit = useCallback(
    (values) => {
        try {
          let selectedAmount = 0;
          const storages = groupBy(selected, 'thing_record');
          const entries = thingEntryItems.reduce((acc, entry) => {
            const selected_storages = storages[entry?.thing_record];
            if (selected_storages) {
              const storage = selected_storages.map((st) => {
                const liquid_amount =
                  finished && calculateThingLiquid
                    ? calculateThingStorageLiquid(
                        converter,
                        st.amount,
                        item,
                        companyThings,
                      )
                    : undefined;
                return {
                  ...st,
                  id: st?._id ?? undefined,
                  amount: unit ? converter.cx(st.amount, unit) : st.amount,
                  liquid_amount,
                  selected: undefined,
                };
              });
              // recalculate the amount used in this entry
              const amount = storage.reduce((v, i) => v + Number(i.amount), 0);
              selectedAmount += amount;
              acc.push({
                ...entry,
                storage,
                amount: unit ? converter.cx(amount, unit) : amount,
              });
            }
            return acc;
          }, []);

          console.log(
            'Selected amount',
            selectedAmount,
            values.amount,
            entries,
          );

          // let requiredAmount = Number(values.amount);
          const thing = {
            ...item,
            amount: unit ? converter.cx(values.amount, unit) : values.amount, // convert the amount back
            allocated_amount: unit
              ? converter.cx(selectedAmount, unit)
              : values.amount,
            include_holder: values.include_holder,
            vendor: values?.vendor,
            ...(finished
              ? {
                  release: values?.release,
                  duty_paid: values?.duty_paid,
                  liquid_amount: calculateThingLiquid
                    ? calculateThingStorageLiquid(
                        converter,
                        values.amount,
                        item,
                        companyThings,
                      )
                    : undefined,
                  allocated_liquid_amount: calculateThingLiquid
                    ? calculateThingStorageLiquid(
                        converter,
                        selectedAmount,
                        item,
                        companyThings,
                      )
                    : undefined,
                }
              : {}),
            entries,
            ...(include_tax_code
              ? {
                  tax_code: values?.tax_code,
                }
              : {}),
            ...(consumed
              ? {
                  waste_amount: values?.waste_amount
                    ? converter.cx(values?.waste_amount, unit)
                    : values?.waste_amount,
                  waste_reason: values?.waste_reason,
                }
              : {}),
          };
          console.log('Setting Thing', values, thing);
          onClose(thing);
        } catch (err) {
          enqueueSnackbar(err.message, { variant: 'error' });
        }
    },
    [
      item,
      finished,
      calculateThingLiquid,
      companyThings,
      thingEntryItems,
      selected,
    ],
  );

  const onLoadEntries = useCallback(
    async (values) => {
      const params = {
        // amount: values?.amount,
        // ...values,
        amount: Number(values?.amount) + Number(values?.waste_amount ?? 0),
        duty_paid: values?.duty_paid,
        vendor: values?.vendor,
        release: values?.release?.id,
        id: id,
        type: type,
      };
      const entryList = await refetch(params);
      console.log('Refetch', entryList);

      //   const storageList = flatten(entryList.map((e) => e.storage ?? [])).map(
      //     (i) => ({ ...i, amount: undefined }),
      //   );
      //   console.log('storageList', storageList);

      const unused = entryList.filter(
        (i) => !selected.find((s) => s.asset === i.asset),
      );
      console.log('Unused', unused);
      upsertAvailable(unused);
    },
    [path, refetch, id, upsertAvailable, selected],
  );

  // const saveDisabled = useMemo(() => !(completion === 100), [completion]);

  const handleAdd = useCallback(() => {
    const selectedItems = available
      .filter((s) => s.selected)
      .map((s) => ({ ...s, selected: false }));
    if (selectedItems.length === 1) {
      setStorageAllocation(selectedItems[0]);

      debounce(() => {
        removeAvailable(selectedItems);
        // upsertSelected(selectedItems);
      }, 25);
    }
  }, [available, selected]);

  const handleRemove = useCallback(() => {
    const selectedItems = selected
      .filter((s) => s.selected)
      .map((s) => ({ ...s, amount: undefined, selected: false }));
    debounce(() => {
      removeSelected(selectedItems);
      upsertAvailable(selectedItems);
    }, 25);
  }, [available, selected]);

  const handleStorageSelection = useCallback(
    (item) => {
      if (item) {
        upsertSelected(item);
      }
      setStorageAllocation(null);
    },
    [storageAllocation, upsertSelected],
  );

  return (
    <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
      <Stack spacing={2}>
        {finished && (
          <>
            <FormikSelect
              label='Release'
              name='release'
              options={releaseOptions}
              fullWidth
            />
            <FormikCheckBox name='duty_paid' label={<Body2>Duty Paid</Body2>} />
          </>
        )}
        <FormikSelect
          label='Vendor'
          name='vendor'
          options={vendorOptions}
          fullWidth
        />
        {consumed && (
          <FlexBox alignItems='top'>
            <FormikNumberField
              label='Waste amount'
              name='waste_amount'
              fullWidth
            />
            <FormikSelect
              label='Waste Reason'
              name='waste_reason'
              options={WASTE_REASON_OPTIONS}
              fullWidth
            />
          </FlexBox>
        )}
        <InventorySearch
          label='Amount'
          name='amount'
          unit={finished ? null : unit}
          fullWidth
          handler={onLoadEntries}
          searching={isLoading}
        />

        {exportItems && (
          <FormikCheckBox
            label='Include Holder'
            name='include_holder'
            fullWidth
          />
        )}
        {!!include_tax_code && (
          <FormikSelect
            label={'Tax Code'}
            name='tax_code'
            options={taxCodes}
            fullWidth
          />
        )}

        <Instructions>Please select from available inventory.</Instructions>

        <ProgressBar name='amount' waste_name='waste_amount' label='Amount' />
        <H5>Inventory</H5>
        <FlexBox alignItems='stretch' sx={{ position: 'relative' }}>
          <ChildList
            label='Available'
            items={available}
            update={upsertAvailable}
            unit={unit}
          />
          <Stack sx={{ width: '10%' }}>
            <IconButton
              onClick={handleAdd}
              disabled={!available.some((s) => s.selected)}
              aria-label='Add'
            >
              <KeyboardArrowRight />
            </IconButton>
            <IconButton
              onClick={handleRemove}
              disabled={!selected.some((s) => s.selected)}
              aria-label='Remove'
            >
              <KeyboardArrowLeft />
            </IconButton>
          </Stack>
          <ChildList
            label='Selected'
            items={selected}
            update={upsertSelected}
            unit={unit}
          />
        </FlexBox>

        <Errors />

        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={() => onClose()}
            color='secondary'
          >
            Cancel
          </TextButton>
          <FilledButton loading={isLoading} type='submit' size='small'>
            Save
          </FilledButton>
        </Box>
      </Stack>
      {storageAllocation && (
        <StorageSetupModalWrapper
          selected={selectedCount}
          open={!!storageAllocation}
          title='Pick'
          item={storageAllocation}
          onClose={handleStorageSelection}
          unit={unit}
        />
      )}
    </FormikForm>
  );
}

export default GeneralSourceThing;
