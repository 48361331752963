import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// from https://locize.com/blog/react-i18next/#why-i18next
i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: false,
		'lng': navigator.language || navigator.userLanguage,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			'en-US': {
				translation: {
					welcome: 'Welcome to our website (US)',
					bulk: 'Wine Gallons',
					bl: 'Wine Gallons',
					alcohol: 'Proof Gallons',
					la: 'Proof Gallons',
					strength: 'Proof',
					IBC: 'Tote',
					IBCs: 'Totes',
					abv: 'Proof',
					monthly: 'Monthly',
					instant: 'Instant',
					taxCode: 'Tax Category',
					taxCodeValidationError: 'Tax Category is required!',
					abvNew: 'Distilled At',
					abvTarget: 'Target Proof',
					abvRequired: 'Proof is required!',
					mash: 'Cooker',
					mashTun: 'Cooker',
					washback: 'Fermenter',
					mashCooler: 'Mash Cooler',
					dutyPaid: 'Require Tax Submission',
					dutyDue: 'Require Tax Submission',
					exciseId: 'DSP',
					registrationId: 'TIN',
					cask_type: 'Barrel Type',
				},
			},
			en: {
				translation: {
					welcome: 'Welcome to our website (EU)',
					bulk: 'Bulk',
					bl: 'Bulk',
					alcohol: 'Alcohol',
					la: 'Alcohol',
					strength: 'ABV',
					abv: 'ABV',
					monthly: 'W1 (Monthly)',
					instant: 'W5 (Instant)',
					taxCode: 'Tax Code',
					taxCodeValidationError: 'Tax Code is required!',
					abvNew: 'New Make',
					abvTarget: 'Target ABV',
					abvRequired: 'ABV is required!',
					mash: 'Mash',
					mashTun: 'Mash Tun',
					washback: 'Washback',
					mashCooler: 'Mash Cooler',
					dutyPaid: 'Require Duty Payment',
					dutyDue: 'Require Duty Payment',
					exciseId: 'Excise ID',
					registrationId: 'Company Registration ID',
					cask_type: 'Cask Type',
				},
			},
			ir: {
				translation: {
					welcome: 'Welcome to our website (EU)',
					bulk: 'Bulk',
					bl: 'Bulk',
					alcohol: 'Alcohol',
					la: 'Alcohol',
					strength: 'ABV',
					abv: 'ABV',
					monthly: 'Revenue Report',
					instant: 'Warrant',
					taxCode: 'Tax Code',
					taxCodeValidationError: 'Tax Code is required!',
					abvNew: 'New Make',
					abvTarget: 'Target ABV',
					abvRequired: 'ABV is required!',
					mash: 'Mash',
					mashTun: 'Mash Tun',
					washback: 'Washback',
					mashCooler: 'Mash Cooler',
					dutyPaid: 'Require Warrant',
					dutyDue: 'Require Warrant',
					exciseId: 'Excise ID',
					registrationId: 'Company Registration ID',
					cask_type: 'Cask Type',
				},
			},
		},
	});

export default i18n;
