function SvgChangeOwnership(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g
        clipPath='url(#ChangeOwnership_svg__clip0_8353_8946)'
        fill='currentColor'
      >
        <path d='M.114 16.757a.392.392 0 01-.037-.51c.69-.533 1.687-2.1 2.585-2.114.334-.005.516.12.806.238 1.038-1.088 2.01-1.998 3.6-2.105 2.612-.175 5.42.135 8.051.003.361.032.804.219 1.08.454.097.084.324.413.397.402.079-.049.154-.103.225-.161 1.057-.864 2.03-2.035 3.091-2.898a1.844 1.844 0 012.755 2.42c-1.333 1.602-2.43 3.67-3.788 5.22-.718.82-1.802 1.38-2.897 1.462-2.126.157-4.51-.19-6.613-.001-.26.023-.582.156-.736.37-.02.085.168.32.202.455.09.362.04.663-.158.974-.196.312-1.561 1.688-1.868 1.917-.39.29-.787.007-.635-.444l1.882-1.902.02-.409-5.1-5.104a.399.399 0 00-.498-.065L.625 16.795c-.135.122-.385.077-.51-.037v-.001zm20.912-6.393a1.34 1.34 0 00-.54.227l-3.572 3.327c-.014 1.123-.66 2.092-1.84 2.185-1.28.1-2.687-.08-3.98 0-.459-.027-.49-.696-.044-.766 1.28-.093 2.713.115 3.976 0 1.397-.127 1.43-2.1.046-2.303-2.592.123-5.334-.163-7.908-.002-1.413.088-2.269.915-3.132 1.937l4.003 4.004c.366-.273.77-.524 1.24-.571 2.08-.208 4.497.153 6.613.001.968-.07 1.813-.523 2.442-1.247 1.355-1.56 2.464-3.608 3.78-5.228.412-.768-.232-1.669-1.084-1.564zM7.276.016c2.033-.163 3.842.929 4.832 2.662l7.59.028 2.058 2.015a.401.401 0 01.074.41l-1.99 2.035c-.546.433-1-.574-1.42-.836-.138-.024-.814.917-1.1.954-.425.055-.868-.776-1.235-.954l-1.023.945-2.985.033c-1.694 3.125-5.96 3.6-8.264.84C1.24 5.066 3.31.334 7.276.016zm4.29 3.323c-.129-.125-.235-.459-.35-.631C9.068-.508 4.099.505 3.508 4.298c-.7 4.497 5.163 6.774 7.708 2.964.134-.2.275-.677.514-.731l3.044-.023c.168-.05.83-.881 1.064-1.044.533-.372.998.611 1.396.859.137.024.813-.917 1.1-.954.424-.055.867.776 1.234.954l1.32-1.32-1.554-1.55-7.606-.015a.616.616 0 01-.162-.1v.001z' />
        <path d='M5.886 3.082c2.626-.305 2.915 3.632.417 3.82-2.517.188-2.811-3.542-.417-3.82zm.046.765C4.515 4.072 4.784 6.32 6.3 6.13c1.47-.185 1.196-2.532-.368-2.284z' />
      </g>
      <defs>
        <clipPath id='ChangeOwnership_svg__clip0_8353_8946'>
          <path fill='#fff' d='M0 0h23v23H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgChangeOwnership;
