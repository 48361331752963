import { Box, Tab, Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import TabbedViewSkeleton from '@pw/components/sekeletons/TabbedViewSkeleton';
import AccessLog from '@pw/components_v2/elements/AccessLog';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import ContactSearch from '@pw/components_v2/search/contact';
import LedgerSearch from '@pw/components_v2/search/ledger';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import LatestAddedThings from '@pw/components_v2/Thing/LatestAddedThings';
import ThingSummary from '@pw/components_v2/Thing/ThingSummary';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { THING_EDIT_URL } from '@pw/consts/thing';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { upsertThingThunk } from '@pw/redux/thunks/thing';
import styles from '@pw/styles/content.styles';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useThingQuery } from '@pw/utilities/hooks/service/useThingQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

const ThingFormHandlerImpl = withDialogCard(
  ({
    thingType,
    entity,
    readonly = false,
    tabs = [],
    onSave
  }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [target, scroll] = useScrollTarget();
    // Current tab
    const [tab, setTab] = useState(entity?._id ? 'summary' : 'id');

    const changeSet = useMemo(() => ({
      name: [entity?.name, yup.string().required('Name is required!')],
      description: [
        entity?.description,
        yup.string().required('Description is required!')
      ],
      tags: [entity?.tags, yup.array().of(yup.string())],
      companies: [entity?.companies, yup.array().of(yup.object())],

      ...tabs.reduce((acc, t) => ({ ...acc, ...(t?.properties?.(entity) || {}) }), {})
    }), [entity, tabs]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(scroll, []);

    const handleChange = (event, newValue) => setTab(newValue);

    const handleSubmit = async (values) => {
      const request = {
        ...entity,
        ...values
      };

      console.log('Submitting', request);

      dispatch(upsertThingThunk(request))
      .unwrap()
      .then((result) => {
        console.log('Mutated thing', result);
        const route = THING_EDIT_URL?.[thingType];
        navigate(`${route}/${result?._id}`, { replace: true });
      });
    };

    return (
      <Box sx={styles} className="root" ref={target}>
        <FormikForm
          changeSet={changeSet}
          onSubmit={handleSubmit}
          enableReinitialize
          readonly={readonly}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="thing form"
            variant="scrollable"
          >
            {entity?._id && (
              <Tab
                label="Summary"
                {...a11yProps('summary')}
                iconPosition="start"
              />
            )}
            <Tab label="Thing" {...a11yProps('id')} iconPosition="start" />
            {tabs.map(({ title, key, Icon }) => (
              <Tab
                label={title}
                {...(Icon ? { icon: <Icon /> } : {})}
                {...a11yProps(key)}
                iconPosition="start"
              />
            ))}
            {entity?.access_log && entity?.access_log.length > 0 && (
              <Tab label="Log" {...a11yProps('log')} />
            )}
          </Tabs>

          {entity?._id && (
            <CustomTabPanel value={tab} index="summary">
              <ThingSummary entity={entity} refresh={onSave} />
            </CustomTabPanel>
          )}
          <CustomTabPanel value={tab} index="id">
            <Stack spacing="1.5rem">
              <FlexBox alignItems="top">
                <FormikTextField label="Name" name="name" fullWidth />
              </FlexBox>
              <FormikTextField label="Description" name="description" fullWidth />
              <TagSelect name="tags" type={TAG_CATEGORY.THING} />
            </Stack>
          </CustomTabPanel>
          {tabs.map(({ key, Component }) => (
            <CustomTabPanel value={tab} index={key}>
              <Component entity={entity} readonly={readonly} />
            </CustomTabPanel>
          ))}
          {entity?.access_log && entity?.access_log.length > 0 && (
            <CustomTabPanel value={tab} index="log">
              <AccessLog access_log={entity?.access_log} />
            </CustomTabPanel>
          )}
          {!['summary', 'log'].includes(tab) && (
            <FormButtons readonly={readonly} />
          )}
        </FormikForm>
      </Box>
    );
  }
);

function NewForm({ thingType, thingLabel, ...rest }) {
  const [searchParams] = useSearchParams();

  // // // AI stuff disabled for now
  // // // const [suggested, setSuggested] = useState(null);
  const clone = searchParams?.get('clone');

  const entity = useMemo(() => {
    let obj = {};
    if (clone) {
      try {
        const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
        obj = JSON.parse(cv);
      } catch (e) {
        console.log('Failed to decode clone!', e);
      }
    }
    return { ...obj, type: thingType };
  }, [thingType, clone]);

  usePageTitleHook(`Create ${thingLabel}`);

  return (
    <ThingFormHandlerImpl thingType={thingType} entity={entity} {...rest} />
  );
}

function ShowThing({ id, thingType, thingLabel, ...rest }) {
  const { data: entity, isError, refetch, isLoading } = useThingQuery({ id });
  const readonly = useCheckCompany(entity);

  console.log('ShowThing', id, readonly, entity);

  usePageTitleHook(`${thingLabel} ${entity?.name ?? '...'}`);

  const refreshData = useCallback(() => refetch({ id }), [id, refetch]);

  return (
    <>
      {isLoading && (
        <TabbedViewSkeleton />
      )}
      {!isLoading && (isError || !entity) && (
        <NewForm
          thingType={thingType}
          thingLabel={thingLabel}
          {...rest}
        />
      )}
      {!isLoading && entity && (
        <ThingFormHandlerImpl
          thingType={thingType}
          entity={entity}
          readonly={readonly}
          onSave={refreshData}
          {...rest}
        />
      )}
    </>
  );
}

function ThingFormHandler(props) {
  let { id } = useParams();

  // console.log('ThingFormHandler', id, props);

  return (
    <Box className="adaptive-content">
      {!id && <NewForm {...props} />}
      {id && <ShowThing id={id} {...props} />}
      <LatestAddedThings label={props.thingLabel} thingType={props.thingType} thingId={id} />
    </Box>
  );
}

export default ThingFormHandler;
