import { Box, Collapse } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import LayoutStatsWidget from '@pw/components/Dashboard/components/LayoutStatsWidget';
import {
  FormikDatePicker,
  FormikForm,
  FormikSelect,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, Body2, H5 } from '@pw/components/Typography';
import Filter from '@pw/components/icons/Filter';
import { UNIT } from '@pw/consts/units';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useReportOverviewLazyQuery } from '@pw/utilities/hooks/service/useReportOverviewQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import LayoutNumberWidget from './LayoutNumberWidget';

function LayoutReportWidget({ title, currentWidth }) {
  const [fetchReportData, { data, isLoading }] = useReportOverviewLazyQuery();

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const converter = useConverter();

  const bl_unit = converter.unit(UNIT.BL);
  const la_unit = converter.unit(UNIT.LA);
  const {
    new_assets_count = 0,
    new_requests_count = 0,
    total_bl = 0,
    total_la = 0,
    total_la_in = 0,
    total_la_out = 0,
    total_bl_in = 0,
    total_bl_out = 0,
    total_bl_storage = 0,
    total_la_storage = 0,
    total_bl_operation = 0,
    total_la_operation = 0,
    start_date,
    end_date,
    report_type,
  } = data ?? {};

  const [tb, ta] = useMemo(() => {
    return [
      converter.from(total_bl ?? 0, UNIT.BL),
      converter.from(total_la ?? 0, UNIT.LA),
    ];
  }, [total_bl, total_la]);

  const [li, lo] = useMemo(() => {
    return [
      converter.from(total_la_in ?? 0, UNIT.LA),
      converter.from(Math.abs(total_la_out ?? 0), UNIT.LA),
    ];
  }, [total_la_in, total_la_out]);

  const [bi, bo] = useMemo(() => {
    return [
      converter.from(total_bl_in ?? 0, UNIT.BL),
      converter.from(Math.abs(total_bl_out ?? 0), UNIT.BL),
    ];
  }, [total_bl_in, total_bl_out]);

  const [tbs, tls] = useMemo(() => {
    return [
      converter.from(total_bl_storage ?? 0, UNIT.BL),
      converter.from(total_la_storage ?? 0, UNIT.LA),
    ];
  }, [total_bl_storage, total_la_storage]);

  const [tbo, tlo] = useMemo(() => {
    return [
      converter.from(total_bl_operation ?? 0, UNIT.BL),
      converter.from(total_la_operation ?? 0, UNIT.LA),
    ];
  }, [total_bl_operation, total_la_operation]);

  const changeSet = useMemo(() => {
    return {
      // start_date: [
      //   start_date,
      //   yup.number().min(0, 'Invalid date!').required('Date is required!'),
      // ],
      // end_date: [
      //   end_date,
      //   yup.number().min(0, 'Invalid date!').required('Date is required!'),
      // ],
      report_type: [report_type, yup.string()],
    };
  }, [start_date, end_date, report_type]);

  const reports = [
    {
      value: 'HMRC',
      label: 'HMRC',
    },
    {
      value: 'TTB',
      label: 'TTB',
    },
  ];

  const onSubmit = useCallback(
    (values) => {
      console.log('values ', values);
      fetchReportData({
        start_date: values?.start_date,
        end_date: values?.end_date,
      });
    },
    [fetchReportData],
  );

  const isSmallScreen = currentWidth <= 600;
  const isMediumScreen = currentWidth > 600 && currentWidth <= 1024;

 const styles = {
  filterButtonWrapper: {
    width: '35px',
    height: '42px',
    position: 'absolute',
    right: '0',
    // top: '10px',
    borderRadius: '5px',
    backgroundColor: '#C6C6C6',

  },
 }
  return (
    <Stack className='content-section'>
      <Stack>
      <FlexBox sx={{position:"relative"}}>
          <H5 className='content-section-title' sx={{alignSelf:"start"}}>
            {title}
          </H5>
          {isExpanded && <Box sx={styles.filterButtonWrapper}></Box>}
          <IconCircleButton onClick={toggleExpanded} sx={{width:"36px", height:"36px"}}>
            <Filter color={isExpanded ? '#fff' : '#7C7C7C'} />
          </IconCircleButton>
        </FlexBox>
              <Collapse in={isExpanded}>
        <FormikForm changeSet={changeSet} onSubmit={onSubmit}>
          <Stack
            gap={1.5}
            py={2}
            pl={1.5}
            pr={2}
            sx={{ backgroundColor: '#C6C6C6', borderRadius: '5px' }}
          >
            <Box display='flex' flexBasis='row' gap={0}>
              <FormikDatePicker
                name='start_date'
                label='From'
                fullWidth
                backgroundColor='#fff'
                fontSize={"12px"}
              />
              <FormikDatePicker
                name='end_date'
                label='To'
                fullWidth
                backgroundColor='#fff'
                fontSize={"12px"}
              />
            </Box>
            <Box>
                <FormikSelect
                  name='report_type'
                  label='Report Type'
                  options={reports}
                  fullWidth
                  backgroundColor='#fff'
                />
              </Box>
          </Stack>
        </FormikForm>
      </Collapse>
      </Stack>
      <Stack
        className='content-section-content'
      >
        <Stack sx={{marginBottom:"1rem"}}>
        <Body1 sx={{color:"#393c3c", padding: '0.3rem'}}>Summary</Body1>
        <LayoutNumberWidget value={new_assets_count} label='Asset Count' />
        <Box sx={styles.sectionDivider} />
        <LayoutNumberWidget value={new_requests_count} label='Request Count' />
        </Stack>
        <Stack>
        <Body1 sx={{color:"#393c3c", padding: '0.3rem'}}>TOTAL</Body1>
        <LayoutStatsWidget
          primary={{ value: tb, unit: bl_unit }}
          secondary={{ value: ta, unit: la_unit }}
          label='Total Liquid'
        />
        <LayoutStatsWidget
          primary={{ value: bi, unit: bl_unit }}
          secondary={{ value: li, unit: la_unit }}
          label='Bulk In'
        />
        <LayoutStatsWidget
          primary={{ value: bo, unit: bl_unit }}
          secondary={{ value: lo, unit: la_unit }}
          label='Bulk Out'
        />
        <LayoutStatsWidget
          primary={{ value: tbs, unit: bl_unit }}
          secondary={{ value: tls, unit: la_unit }}
          label='Storage'
        />
        <LayoutStatsWidget
          primary={{ value: tbo, unit: bl_unit }}
          secondary={{ value: tlo, unit: la_unit }}
          label='Operations'
          border={false}
        />
        </Stack>
      </Stack>
      {/* <FlexBox className='content-section-footer'>
        <TitledButton
          handleClick={() => navigate(`/app/duty-submission`)}
          label='Submit'
          // disabled={!isVisibleButton}
        >
          <UploadOutlined height={24} width={24} />
        </TitledButton>
      </FlexBox> */}
    </Stack>
  );
}

export default LayoutReportWidget;
