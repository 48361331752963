import { Stack } from '@mui/material';
import VendorModal from '@pw/components/admin/VendorProperties/VendorModal';
import TitledButton from '@pw/components/Buttons/TitledButton';
import SvgDelete from '@pw/components/icons/Delete';
import { Body1 } from '@pw/components/Typography';
import RoleSelectorModal from '@pw/components_v2/CompanyView/modals/users/RoleSelectorModal';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import Search from '@pw/components_v2/search/general';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import { RELATIONSHIP_TYPE } from '@pw/consts/relationship';
import FormikContext from '@pw/context/FormikContext';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useState } from 'react';

function Vendor({ item }) {
  const { vendor, product } = item;
  return (
    <Stack>
      <EntryTitle value={vendor?.target_id?.name} />
      <EntryLabel label="Product" value={product} />
    </Stack>
  );
}

const search = {
  [SEARCH_TYPES.CONTACTS]: {
    types: [RELATIONSHIP_TYPE.PARTNER],
  }
};

function VendorSelector({ field = 'vendors', initialValues, readonly }) {
  const { setFieldValue } = useContext(FormikContext);
  const confirm = useConfirm();

  const [vendors, initVendors, , upsertVendors, removeVendors] = useItemListManager({
    id: (v) => v.vendor,
    comp: COMP._id,
    initialData: [],
  });

  useEffect(() => {
    if (initialValues) {
      initVendors(initialValues);
    }
  }, [initialValues, initVendors]);

  useEffect(() => {
    debounce(() => setFieldValue(field, vendors), 25);
  }, [vendors]);


  const handleRemove = useCallback((removedVendor) => {
    if (removedVendor) {
      confirm({
        title: 'Remove Vendor',
        content: <Body1>{`Remove '${removedVendor?.target_id?.name}'?`}</Body1>,
      })
      .then(() => removeVendors(removedVendor))
      .catch(() => {
      });
    }
  }, [removeVendors]);

  const Toolbar = ({ onClose }) => {
    const [selectedVendor, setSelectedVendor] = useState(null);

    const handleClear = useCallback(() => {
      onClose();
      confirm({
        title: 'Clear Vendors',
        content: <Body1>Clear all?</Body1>,
      })
      .then(() => debounce(() => initVendors([]), 25))
      .catch(() => {
      });
    }, [initVendors]);

    const handleSelect = useCallback((value) => {
      if (value && value.length === 1) {
        debounce(() => {
          setSelectedVendor({ vendor: value[0] });
        }, 25);
      }
    }, [setSelectedVendor, onClose]);

    const handleClose = useCallback((value) => {
      if (value) {
        debounce(() => {
          upsertVendors(value);
        }, 25);
      }
      onClose();
      setSelectedVendor(null);
    }, [upsertVendors, onClose]);

    return (
      <>
        <Search multi={false} types={search} onSelected={handleSelect} />
        {vendors && vendors.length > 0 && (
          <TitledButton handleClick={handleClear} label="Clear">
            <SvgDelete height={24} width={24} />
          </TitledButton>
        )}
        {!!selectedVendor && (
          <VendorModal open={!!selectedVendor} item={selectedVendor} readonly={readonly} onClose={handleClose} />
        )}
      </>
    )
  }

  const tools = [
    {
      title: 'Remove vendor',
      Icon: SvgDelete,
      handler: handleRemove
    }
  ];

  return (
    <ToolbarSection title="Vendors" Toolbar={Toolbar}>
      <BasicList Content={Vendor} items={vendors} tools={tools} identity={(i) => i.vendor?._id} />
    </ToolbarSection>
  );
}

export default VendorSelector;
