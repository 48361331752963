import { Divider } from '@mui/material';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import LiquidProperties, {
  liquidPropertyFields,
} from '@pw/components/Liquid/LiquidProperties';
import RequestForm from '@pw/components_v2/Request';
import { Overline } from '@pw/components/Typography';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';
import SvgAssets from '@pw/components/icons/Assets';
import {
  InventoryDestinations,
  InventorySources,
} from '@pw/components_v2/Request/sources';
import SvgPolicy from '@pw/components/icons/Policy';
import SvgInvoice from '@pw/components/icons/Invoice';
import InvoiceForm from '@pw/components_v2/Request/InvoiceForm';

function Properties() {
  return (
    <FormikProvider path='properties'>
      <Divider>
        <Overline>Regauge</Overline>
      </Divider>

      <LiquidProperties name='liquid' useWeight />
    </FormikProvider>
  );
}

function propertyFields(fields = {}, converter) {
  const { liquid = {} } = fields;

  return {
    liquid: liquidPropertyFields(liquid, converter, true, false),
  };
}

const filter = {
  [SEARCH_TYPES.ASSETS]: {
    types: [ASSET_TYPES.CASK, ASSET_TYPES.IBC, ASSET_TYPES.FILLING_TANK],
  },
};
const filterDestination = {
  [SEARCH_TYPES.THINGS]: {
    types: [THING_TYPES.SERVICES],
  },
};

const tabs = [
  {
    title: 'Inventory',
    key: 'inventory',
    Icon: SvgAssets,
    properties: (entity) => ({
      sources: [entity?.sources, yup.object()],
    }),
    Component: (props) => <InventorySources filter={filter} {...props} />,
  },
  {
    title: 'Services',
    key: 'services',
    Icon: SvgAssets,
    properties: (entity) => ({
      destinations: [entity?.destinations, yup.object()],
    }),
    Component: (props) => (
      <InventoryDestinations filter={filterDestination} {...props} />
    ),
  },

  {
    title: 'Policy',
    key: 'policy',
    Icon: SvgPolicy,
    properties: (entity) => ({
      policies: [entity?.policies ?? [], yup.array()],
    }),
    Component: (props) => <PolicySelector {...props} />,
  },
  {
    title: 'Properties',
    key: 'propertieS',
    Icon: SvgInvoice,
    properties: (entity) => ({
      properties: [entity?.properties ?? {}, yup.object()],
    }),
    Component: (props) => <Properties {...props} />,
  },
  {
    title: 'Invoice',
    key: 'invoice',
    Icon: SvgInvoice,
    properties: (entity) => ({ client: [entity?.client, yup.object()] }),
    Component: (props) => <InvoiceForm {...props} />,
  },
];

function RegaugeForm(props) {
  //   const [searchParams] = useSearchParams();
  //   const [fetchAsset] = useAssetLazyQuery();
  //   const asset = searchParams.get('asset');

  //   const [
  //     [],
  //     [sourceAssets, initSourceAssets],
  //     initSources,
  //     SourceInventory,
  //     SourceModals,
  //   ] = useSourceInventoryHook({
  //     title: 'Assets',
  //     filter: {
  //       asset_types: [
  //         ASSET_TYPES.CASK,
  //         ASSET_TYPES.IBC,
  //         ASSET_TYPES.FILLING_TANK,
  //       ],
  //     },
  //   });

  //   const [
  //     [destinationThings],
  //     [],
  //     initDestinations,
  //     DestinationInventory,
  //     DestinationModals,
  //   ] = useInventorySelectorHook({
  //     title: 'Services',
  //     filter: {
  //       thing_types: [THING_TYPES.SERVICES],
  //     },
  //     ThingModal: DestinationThingModal,
  //   });

  //   const changeSetGenerator = useMemo(
  //     () => (initialValues) => ({
  //       ...requestIdFields(initialValues),
  //       properties: propertyFields(initialValues?.properties, converter),
  //       policies: [initialValues?.policies, yup.array()],
  //       client: clientFields(initialValues?.client),
  //     }),
  //     [converter],
  //   );

  //   /**
  //    * Initialization function
  //    * @type {(function(*): void)|*}
  //    */
  //   const handleInit = useCallback(
  //     (entity) => {
  //       initSources({
  //         ...entity,
  //         assets: entity?.sources?.assets,
  //         things: entity?.sources?.things,
  //       });
  //       initDestinations({ ...entity, things: entity?.destinations?.things });
  //     },
  //     [initSources, initDestinations],
  //   );

  //   /**
  //    * Prior to saving the entity, this is called to inject in the sources
  //    * @type {function(*): *&{sources: *, sku_sources: *}}
  //    */
  //   const handleBeforeSave = useCallback(
  //     (entity) => {
  //       const properties = convertProperties(entity?.properties ?? {}, converter);

  //       return {
  //         ...entity,
  //         sources: {
  //           assets: sourceAssets.map((a) => ({
  //             ...a,
  //             properties: { ...a.properties, ...properties },
  //           })),
  //         },
  //         destinations: {
  //           things: destinationThings,
  //         },
  //         properties,
  //       };
  //     },
  //     [destinationThings, sourceAssets],
  //   );

  //   useEffect(() => {
  //     if (asset) {
  //       fetchAsset({ id: asset }).then((a) => {
  //         debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
  //       });
  //     }
  //   }, [asset, initSourceAssets, fetchAsset]);

  return (
    <>
      <RequestForm
        requestLabel='Regauge'
        requestType={REQUEST_TYPES.REGAUGE}
        tabs={tabs}
      >
        {/* <SourceInventory />
        <DestinationInventory />

        <Properties />

        <PolicySelector /> */}
      </RequestForm>

      {/* <SourceModals />
      <DestinationModals /> */}
    </>
  );
}

export default withAppLayout(RegaugeForm, { title: 'Regauge' });
