import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import Releases from '@pw/components/admin/Releases';
import {
  FormikDatePicker,
  FormikNumberField,
  FormikSelect,
} from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5, Overline } from '@pw/components/Typography';
import {
  FINISHED_GOODS_TYPE_OPTIONS,
  FINISHED_GOODS_TYPES,
  THING_TYPES,
} from '@pw/consts/thing';
import {
  ABV_UNIT_OPTIONS,
  LIQUID_UNIT_OPTIONS,
  MASS_UNIT_OPTIONS,
} from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { mt2 } from '@pw/styles/margins';
import { useContext, useMemo } from 'react';
import * as yup from 'yup';
import { ThingSelector } from '@pw/components_v2/search/thing/ThingSelector';
import DependentThingModal from '@pw/components/ThingSelector/modals/DependentThingModal';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import ThingSearch from '@pw/components_v2/search/thing';
import ContactSearch from '@pw/components_v2/search/contact';
import LedgerSearch from '@pw/components_v2/search/ledger';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import ThingFormHandler from '@pw/components_v2/Thing';

function casedProperties(fields = {}) {
  const {
    count = 1,
    size = {},
    abv = {},
    // Configured releases of these cased goods
    releases = [],
  } = fields;

  return {
    count: [count, yup.number()],
    size: unitField(size),
    abv: unitField(abv),
    releases: [releases, yup.array().of(yup.object())],
  };
}

function packProperties(fields = {}) {
  const {
    // BOM for the pack
    things = [],
  } = fields;

  return {
    things: [things, yup.array().of(yup.object())],
  };
}

function containerProperties(fields = {}) {
  const {
    container = {},
    liquid = {},
    // If available on the marketplace, we need to have this setup
    collection = {},
    listing_status = 'active',
    listing_date = Date.now(),
    sale_price,
    storage_price,
  } = fields;

  return {
    container: [container, yup.object()],
    liquid: [liquid, yup.object()],
    collection_id: [collection, yup.object()],
    listing_status: [listing_status, yup.string()],
    listing_date: [listing_date, yup.number()],
    sale_price: [sale_price, yup.number()],
    storage_price: [storage_price, yup.number()],
  };
}

function BottleProperties() {
  return (
    <>
      <FlexBox alignItems='top'>
        <FormikNumberField label='Bottles per Case' name='count' fullWidth />
        <FormikUnitField
          label='Bottle Size'
          name='size'
          options={LIQUID_UNIT_OPTIONS}
          fullWidth
        />
      </FlexBox>
      <FormikUnitField label='Strength' name='abv' options={ABV_UNIT_OPTIONS} />
      <Releases />
    </>
  );
}

function ContainerProperties() {
  return (
    <>
      <ThingSearch
        field='container_thing'
        label='Container Type'
        types={[THING_TYPES.TRACKED]}
      />
      <ThingSearch
        field='liquid_thing'
        label='Liquid Type'
        types={[THING_TYPES.LIQUID]}
      />
      <H5>Marketplace Link</H5>
      <FlexBox alignItems='top'>
        <FormikSelect
          label='Status'
          name='listing_status'
          options={[
            { label: 'Active', value: 'active' },
            { label: 'Paused', value: 'paused' },
            { label: 'Completed', value: 'completed' },
          ]}
          fullWidth
        />
        <FormikDatePicker
          sx={{ ...mt2 }}
          name='listing_date'
          label='Listing Date'
          fullWidth
          required
        />
      </FlexBox>

      <FlexBox alignItems='top'>
        <FormikNumberField label='List Price' name='sale_price' fullWidth />
        <FormikNumberField
          label='Storage Price'
          name='storage_price'
          fullWidth
        />
      </FlexBox>
    </>
  );
}

function propertyFields(fields = {}) {
  const {
    type = FINISHED_GOODS_TYPES.BOTTLE,
    cased,
    pack,
    container,
    gross_weight,
    net_weight,
  } = fields;

  return {
    type: [type, yup.string().required('Type is required')],
    cased: casedProperties(cased),
    pack: packProperties(pack),
    container: containerProperties(container),
    gross_weight: unitField(gross_weight),
    net_weight: unitField(net_weight),
  };
}

function ItemProperties() {
  const { values } = useContext(FormikContext);
  const type = useMemo(() => values?.['type'], [values]);
  return (
    <>
      {type === FINISHED_GOODS_TYPES.BOTTLE && (
        <FormikProvider path='cased'>
          <BottleProperties />
        </FormikProvider>
      )}
      {type === FINISHED_GOODS_TYPES.PACK && (
        <FormikProvider path='pack'>
          <ThingSelector
            initial={values?.pack?.things}
            label='BOM'
            field='things'
            ThingModal={DependentThingModal}
            filter={{
              [SEARCH_TYPES.THINGS]: {
                types: [THING_TYPES.CONSUMABLE, THING_TYPES.FINISHED],
              },
            }}
          />
        </FormikProvider>
      )}
      {type === FINISHED_GOODS_TYPES.CONTAINER && (
        <FormikProvider path='container'>
          <ContainerProperties />
        </FormikProvider>
      )}
    </>
  );
}

function PropertiesImpl() {
  return (
    <Stack spacing={3}>
      <FormikSelect
        name='type'
        label='Type'
        options={FINISHED_GOODS_TYPE_OPTIONS}
      />
      <ItemProperties />

      <Divider>
        <Overline>Shipping Settings</Overline>
      </Divider>
      <FlexBox alignItems='top'>
        <FormikUnitField
          label='Gross Weight'
          name='gross_weight'
          options={MASS_UNIT_OPTIONS}
          fullWidth
        />
        <FormikUnitField
          label='Net Weight'
          name='net_weight'
          options={MASS_UNIT_OPTIONS}
          fullWidth
        />
      </FlexBox>
    </Stack>
  );
}

function Properties(props) {
  return (
    <FormikProvider path='properties'>
      <PropertiesImpl {...props} />
    </FormikProvider>
  );
}

const tabs = [
  {
    title: 'Properties',
    key: 'properties',
    properties: (entity) => ({
      properties: propertyFields(entity?.properties),
    }),
    Component: Properties,
  },
  {
    title: 'Ownership',
    key: 'ownership',
    properties: (entity) => ({
      client: [entity?.client, yup.object()],
      ledger: [entity?.ledger, yup.object()],
    }),
    Component: () => (
      <Stack spacing='1.5rem'>
        <ContactSearch />
        <LedgerSearch />
      </Stack>
    ),
  },
  {
    title: 'Vendors',
    key: 'vendors',
    properties: (entity, converter) => ({
      vendors: [entity?.vendors, yup.array()],
    }),
    Component: ({ entity }) => (
      <VendorSelector initialValues={entity?.vendors} />
    ),
  },
  {
    title: 'Policies',
    key: 'policies',
    properties: (entity, converter) => ({
      policies: [entity?.policies, yup.array()],
    }),
    Component: ({ entity }) => (
      <PolicySelector initialValues={entity?.policies} />
    ),
  },
];

function ThingPage(props) {
  return (
    <ThingFormHandler
      thingLabel='Finished Good'
      thingType={THING_TYPES.FINISHED}
      tabs={tabs}
    />
  );
}

export default withAppLayout(ThingPage, {
  title: 'Finished Goods',
});
