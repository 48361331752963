function SvgDesigner(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M8.776 25c-.06-.052-.243-.198-.243-.268v-4.025h-.896c-.128 0-.253-.238-.268-.365-.1-.87.075-1.934.004-2.827.067-.43.521-.283.821-.344l-1.93-3.038c-.048-.293.402-.609.572-.818C8.95 10.727 10.36 7.54 11.69 4.493c.262-.256.495-.234.693.071 1.331 3.023 2.73 6.18 4.828 8.751.17.21.62.525.572.818l-1.93 3.038c.3.06.754-.086.82.344-.07.892.106 1.956.005 2.827-.015.127-.14.365-.268.365h-.896v4.024c0 .07-.184.217-.242.269H8.776zM12.41 6.61v5.536c.942.334 1.46 1.321.988 2.263-.573 1.145-2.26 1.102-2.79-.059-.42-.923.121-1.89 1.027-2.204V6.609l-1.186 2.44c-.798 1.518-1.653 3-2.693 4.363-.108.141-.604.645-.573.77l1.964 3.005 5.665.011.142-.052 1.91-3.038c-1.323-1.523-2.328-3.28-3.268-5.059L12.41 6.61zm-.545 6.304c-.949.154-.753 1.731.316 1.538.93-.168.773-1.716-.316-1.538zm4.034 5.061H8.146v1.952H15.9v-1.952zm-1.162 2.732H9.308v3.512h5.429v-3.512z'
        fill='currentColor'
      />
      <path
        d='M10.472 1.194V.292c0-.161.271-.258.41-.27.752-.069 1.61.053 2.374.005.104-.013.318.188.318.265v.902h7.39c.343-.95 1.298-1.477 2.248-.994 1.156.587 1.08 2.304-.106 2.808-.933.396-1.813-.123-2.142-1.033h-4.482l.596.35a7.447 7.447 0 012.988 3.92c.458.126 1.203-.24 1.264.435.052.574.052 1.724 0 2.298-.022.245-.1.382-.364.413-.501.058-1.876.058-2.377 0-.224-.026-.338-.142-.363-.366-.086-.752.064-1.667.004-2.437-.013-.104.187-.32.263-.32h.751c-.847-2.44-3.11-4.115-5.67-4.293v.903c0 .16-.271.258-.41.27-.752.069-1.61-.053-2.374-.005-.104.012-.318-.188-.318-.265v-.903c-2.56.175-4.824 1.852-5.67 4.293h.75c.078 0 .277.216.264.32-.06.77.09 1.686.004 2.437-.026.226-.14.34-.363.366-.503.058-1.875.058-2.377 0-.264-.03-.343-.169-.365-.413a16.999 16.999 0 010-2.298c.062-.675.807-.31 1.265-.434a7.444 7.444 0 012.988-3.92l.596-.35H3.081c-.375 1.138-1.76 1.593-2.644.709C-.137 2.11-.147 1.097.414.51c.878-.917 2.286-.472 2.667.684h7.39zM1.396.816C.462.968.622 2.53 1.712 2.353c.934-.152.774-1.715-.316-1.537zM12.798.804h-1.55v1.56h1.55V.805zm9.535.012c-.934.152-.775 1.715.316 1.537.933-.152.774-1.715-.316-1.537zM5.043 7.048h-1.55v1.56h1.55v-1.56zm15.51 0H19v1.56h1.551v-1.56z'
        fill='currentColor'
      />
    </svg>
  );
}
export default SvgDesigner;
