import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { TEMPERATURE_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      output_temp: unitField(initialValues?.output_temp),
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator}>
      <FormikUnitField name="output_temp" label="Output Temperature" options={TEMPERATURE_UNIT_OPTIONS} />
    </ModalWrapper>
  );
}

export default NodeModal;
