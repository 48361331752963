import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import SvgSearch from '@pw/components/icons/Search';

function SearchButton({ onClick, ...restProps }) {
	return (
		<IconCircleButton variant='contained' onClick={onClick} {...restProps}>
			<SvgSearch />
		</IconCircleButton>
	);
}

export default SearchButton;
