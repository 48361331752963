import { Tab, Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import BaseUnits from '@pw/components/BaseUnits';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';
import Address from '@pw/components_v2/Address';
import CompanyCompliance from '@pw/components_v2/CompanyView/CompanyCompliance';
import CompanySummary from '@pw/components_v2/CompanyView/CompanySummary';
import CompanyTypeSelector from '@pw/components_v2/CompanyView/CompanyTypeSelector';
import CustomTabPanel, { a11yProps } from '@pw/components_v2/elements/CustomTabPanel';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { upsertCompanyThunk } from '@pw/redux/thunks/company';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CompanyLimits from './CompanyLimits';

function Editor({
  entity,
  changeSet,
  edit = false,
  onBeforeSave,
  onSave,
  readonly,
}) {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(entity?._id ? 'summary' : 'id');

  const handleChange = (event, newValue) => setTab(newValue);

  const handleSubmit = async (values) => {
    const updated = onBeforeSave ? onBeforeSave(values) : values;

    const request = {
      ...(entity ?? {}),
      ...updated,
    };

    console.log('Submitting', request);

    dispatch(upsertCompanyThunk(request))
      .unwrap()
      .then((response) => onSave(response));
  };

  return (
    <>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit} edit={edit}>
        <Stack>
          <Tabs value={tab} onChange={handleChange} aria-label="request form" variant="scrollable" >
            {entity?._id && (
              <Tab label="Summary" {...a11yProps('summary')} iconPosition="start" />
            )}
            <Tab label="Company" {...a11yProps('id')} iconPosition="start" />
            <Tab label="Compliance" {...a11yProps('compliance')} iconPosition="start" />
            <Tab label="Contact" {...a11yProps('contact')} iconPosition="start" />
            <Tab label="Properties" {...a11yProps('properties')} iconPosition="start" />
          </Tabs>

          {entity?._id && (
            <CustomTabPanel value={tab} index="summary">
              <CompanySummary entity={entity} refresh={onSave} />
            </CustomTabPanel>
          )}

          <CustomTabPanel value={tab} index="id">
            <Stack spacing="1.5rem">
              <FormikTextField name='name' label='Name' fullWidth required />
              <FormikTextField name='public_name' label='Display Name' fullWidth required />
            </Stack>
          </CustomTabPanel>

          <CustomTabPanel value={tab} index="compliance">
            <CompanyCompliance />
          </CustomTabPanel>

          <CustomTabPanel value={tab} index="contact">
            <Stack spacing='1.5rem'>
              <H5>General Contact</H5>
              <FormikTextField name='telephone' label='Telephone' fullWidth />
              <FormikTextField name='email' label='Email' fullWidth />
              <Address />
            </Stack>
          </CustomTabPanel>

          <CustomTabPanel value={tab} index="properties">
            <Stack spacing='1.5rem'>
              <CompanyTypeSelector />

              <CompanyLimits entity={entity} companyId={entity?._id} />

              <BaseUnits />
            </Stack>
          </CustomTabPanel>

          {tab !== 'summary' && (
            <FormButtons readonly={readonly} />
          )}
        </Stack>
      </FormikForm>
    </>
  );
}

export default Editor;
