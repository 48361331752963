import { Stack } from '@mui/material';
import { FormikDatePicker, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikContext from '@pw/context/FormikContext';
import { useContext } from 'react';

export default function AssetID({ label, disabled = false }) {
	return (
		<Stack spacing="1.5rem">
			<FormikTextField
				name='name'
				label={label}
				fullWidth
				disabled={disabled}
				required
			/>
			<FormikDatePicker name='created' label='Date' required 	disabled={disabled} />
		</Stack>
	);
}
