function SvgExpenseReport(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 17 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M2.475 22c-.158-.114-.33-.212-.47-.344-.58-.55-1.147-1.114-1.727-1.666A.844.844 0 010 19.34C.002 13.14.002 6.938.002.74c0-.486.266-.74.77-.74h15.457c.503 0 .77.254.77.739 0 6.2 0 12.4.002 18.6a.848.848 0 01-.278.652c-.574.547-1.136 1.105-1.712 1.65-.144.137-.323.24-.486.359h-.31c-.161-.12-.337-.224-.482-.36-.442-.415-.873-.842-1.317-1.273-.43.418-.841.826-1.264 1.223-.161.151-.35.274-.525.41h-.31c-.191-.14-.396-.267-.569-.427-.418-.388-.822-.792-1.24-1.198-.07.063-.125.108-.175.158-.391.378-.776.763-1.175 1.132-.14.13-.315.225-.475.336h-.31c-.17-.131-.352-.252-.51-.396-.293-.27-.577-.55-.86-.832-.135-.136-.26-.283-.394-.431-.478.463-.904.888-1.343 1.3-.145.135-.32.24-.482.359h-.31V22zM1.33 1.297V11.501c0 2.492 0 4.985.002 7.477 0 .082-.005.193.044.242.41.41.83.808 1.257 1.219l1.372-1.331c.414-.4.748-.401 1.16-.002l1.414 1.376c.054-.081.088-.157.144-.212.425-.418.851-.834 1.284-1.246.3-.286.684-.285.985 0 .328.31.652.624.97.942.16.157.304.328.506.549.085-.122.122-.196.178-.252.415-.407.831-.814 1.252-1.215.333-.318.702-.318 1.032-.001.42.402.839.807 1.252 1.216.06.06.089.147.14.235.447-.435.819-.805 1.2-1.163a.445.445 0 00.15-.357c-.004-5.815-.004-11.63-.004-17.444v-.237H1.332z'
        fill='currentColor'
      />
      <path
        d='M4.48 4.155V3.59c.004-.343.225-.593.523-.59.298.003.512.25.515.597v.565c.226 0 .438-.014.648.003a.51.51 0 01.458.48c.02.249-.124.485-.351.555A.96.96 0 016 5.23c-.45.003-.899-.005-1.347.004a.607.607 0 00-.586.804c.08.256.297.42.579.427.264.006.53-.003.794.006.825.026 1.49.704 1.556 1.578.06.814-.507 1.593-1.282 1.76-.062.014-.124.025-.196.04v.58C5.513 10.763 5.299 11 5 11c-.298 0-.515-.237-.519-.571-.002-.185 0-.37 0-.58h-.484c-.387 0-.621-.201-.623-.534-.003-.34.232-.543.631-.543H5.32c.376-.002.647-.264.645-.619-.002-.35-.266-.602-.635-.609-.294-.004-.59.014-.88-.021-.834-.1-1.442-.81-1.449-1.665a1.68 1.68 0 011.412-1.685c.017-.003.033-.008.07-.018h-.001zM13.498 16.53c.09 0 .164-.003.237 0 .16.008.268.108.265.239-.003.128-.108.22-.262.23-.035.002-.07 0-.106 0H3.367c-.058 0-.118 0-.174-.011a.233.233 0 01-.193-.22.232.232 0 01.195-.228c.05-.01.101-.008.152-.01.046-.002.092 0 .155 0v-.138-3.27c0-.258.069-.322.345-.322h2.32c.257 0 .335.073.335.315v3.407H7v-.128-4.681c0-.242.076-.313.334-.313h2.344c.242 0 .322.075.322.303v4.819h.498v-.137-6.038c0-.043-.002-.088.004-.13.017-.132.106-.215.249-.215.832-.003 1.664-.003 2.496 0 .155 0 .242.088.25.234.003.04.001.08.001.12v6.175-.001zm-.505-.006v-6.053h-1.98v6.053h1.98zm-3.5-.001V11.87H7.506v4.653h1.989zm-3.504 0v-3.252H4.006v3.253h1.983z'
        fill='currentColor'
      />
      <path fill='currentColor' d='M11 10h2v7h-2zM7 11h3v6H7zM4 13h2v4H4z' />
    </svg>
  );
}
export default SvgExpenseReport;
