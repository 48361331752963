import { Scheduler } from '@bitnoi.se/react-scheduler/dist/index.umd';
import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import "@bitnoi.se/react-scheduler/dist/style.css";
import Box from '@mui/material/Box';
import styles from './styles';
import { getFromLS, saveRToLS } from '@pw/components/ProcessDesigner/functions/localStorage';
import { useSearchParams } from 'react-router-dom';
import { parseRecipe } from '@pw/components/ProductionSchedule/Timeline/functions';

import { useCompanyDesignsQuery } from '@pw/utilities/hooks/service/useCompanyDesignsQuery';
import { useDesignQuery } from '@pw/utilities/hooks/service/useDesignQuery';

import { getWorkflow, listWorkflows, listDesigns, getDesign } from '@pw/services/design.service';


import { useCompanyThings, useCurrentCompany } from '@pw/redux/containers/User';
import { getThingsByType } from '@pw/services/thing.service';
import { DESIGN_TYPE } from '@pw/consts/designs';
import { THING_TYPES } from '@pw/consts/thing';

import TopBar from '@pw/components/ProductionSchedule/Timeline/TopBar';

const viewModes = [
  { label: 'Day', key: 2 },
  { label: 'Week', key: 1 },
  { label: 'Month', key: 0 },
];

const defaultTaskDuration = 8 * 60 * 60 * 1_000;

const timeShort = (date) => {
  const [h, m,] = date.toLocaleTimeString().split(':');
  return `${h}:${m}`;
}

export default function RequestScheduler() {
  const schedulerRef = useRef();
  const buttonsRef = useRef();
  const [params] = useSearchParams();
  const company = useCurrentCompany();
  const [design_types, design_options] = useMemo(() => {
    if (!company) return;
    const types = [];
    const options = [];

    if (company.features?.bottling) {
      types.push(DESIGN_TYPE.BOTTLING_LINE);
      options.push({ label: 'Bottling', value: DESIGN_TYPE.BOTTLING_LINE });
    }
    if (company.features?.production) {
      types.push(DESIGN_TYPE.DISTILLERY);
      options.push({ label: 'Distillation', value: DESIGN_TYPE.DISTILLERY });
    }
    return [types, options];
  }, [company]);

  const today = new Date();

  const [liquids, setLiquids] = useState([]);
  const [liquidId, setLiquidId] = useState('');
  const [design, setDesign] = useState();
  const [workflows, setWorkflows] = useState([]);
  const [timelineItems, setTimelineItems] = useState([]);
  const [filterButtonState, setFilterButtonState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [viewMode, setViewMode] = useState(2);
  const [range, setRange] = useState({
    startDate: today,
    endDate: new Date( new Date(today).setDate(today.getDate() + 1) ),
  });

  // const daysVisible = useMemo(() =>
  //   ( range.endDate.getTime() - range.startDate.getTime() )
  //   / (24 * 60 * 60 * 1_000)
  // , [range?.startDate, range?.endDate]);

  // useEffect(() => {
  //   console.log({ daysVisible });
  // }, [daysVisible]);

  // useEffect(() => {
  //   const { startDate, endDate } = range;
  //   console.log({ startDate, endDate });
  // }, [range?.startDate, range?.endDate]);

  // const getDateOffsets = (direction, viewMode, range) => {
  //   const currentDate = new Date(range.startDate);
  //   console.log('getDateOffsets', { viewMode, currentDate, direction });
  //   // 0 - Month, 1 - Week, 2 - Day
  //   switch (viewMode) {
  //     case 0:
  //       return (direction === '+')
  //       ? currentDate.setMonth(currentDate.getMonth() + 1)
  //       : currentDate.setMonth(currentDate.getMonth() - 1);
  //     case 1:
  //       return (direction === '+')
  //       ? currentDate.setDate(`${currentDate.getDate() + 7}`)
  //       : currentDate.setDate(`${currentDate.getDate() - 7}`)
  //     case 2:
  //       return (direction === '+')
  //       ? currentDate.setDate(`${currentDate.getDate() + 1}`)
  //       : currentDate.setDate(`${currentDate.getDate() - 1}`)
  //   }
  // };

  // const createDateOffset = (viewMode, startDate, direction) => {
  //   const d = new Date(startDate);
  //   const offset = getDateOffsets(direction, viewMode, {startDate});
  //   const newDate = new Date(offset);
  //   console.log('createDateOffset', { d, offset, startDate, newDate });
  //   return newDate;
  // }

  // const handleRangeChange = useCallback((direction) => {
  //   console.log('----', { direction, viewMode, startDate: range.startDate });
  //   const startDate = createDateOffset(viewMode, range.startDate, direction);
  //   const endDate = createDateOffset(viewMode, startDate, '+');

  //   console.log('---> ', { startDate, endDate });

  //   // if (range.startDate === startDate && range.endDate === enddate) return;
  //   setRange({ startDate, endDate });
  // }, [range?.startDate, viewMode]);




  const loadLiquids = async (callback) => {
    let { items: _liquids = null } = await getThingsByType({ 
      type: THING_TYPES.LIQUID 
    });
    console.log({ _liquids });
    if (typeof callback === 'function' && liquids) callback(_liquids);
  }


  const loadWorkflow = async(query = {}, callback) => {
    const _workflows = await listWorkflows({ ...query });
    console.log('loadWorkflows', { _workflow });
    if (typeof callback === 'function') callback(_workflow);
  }
  const loadWorkflowsList = async(callback) => {
    const _workflows = await listWorkflows({});
    console.log('loadWorkflows', { _workflows });
    if (typeof callback === 'function') callback(_workflows);
  }

  useEffect(() => {
    loadWorkflowsList((_workflows) => {
      if (_workflows?.items?.length) setWorkflows(_workflows.items)
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    // const recipes = getFromLS('design');
    loadLiquids((_liquids) => {
      console.log({ _liquids });
      setLiquids(_liquids);
      // const target = _liquids.find(({ thing_id }) => thing_id === liquidId);
      // if (target) setLiquid(target);
      setIsLoading(false);
    });
  }, []);

  const liquid = useMemo(() => liquids.find(
    ({ _id }) => _id === liquidId
  ), [liquids, liquidId]);

  const designId = liquid?.design;

  // console.log({ liquids, liquid, designId });

  // const workflows = useMemo(() => {
  //   return listWorkflows({ });
  // }, [designId])

  // const { data: design } = useDesignQuery({ id: designId });

  const loadDesign = async (id, callback) => {
    console.log('loadDesign', { id });
    const _design = await getDesign({ id });
    console.log({ _design });
    if (callback && _design) callback(_design);
  }

  // console.log({ design });

  // console.log({ res });
  // const design = {};

  // const workflows = useMemo(() => design?._id ? [design] : []
  // ,[design?._id]);

  useEffect(() => {
    if (!designId) return;
    setIsLoading(true);
    loadDesign(designId, (_design) => {
      setDesign(_design);
    });

    setIsLoading(false);
  }, [designId]);

  useEffect(() => {
    if (!design?._id) return;
    const _workflows = [design];
    setWorkflows(_workflows);
  }, [design]);


  // useEffect(() => {
  //   console.log('UE', { design });
  //   if (!design) return;
  // //   const _workflows = loadWorkflows(
  // //     { search: {
  // //     start: { $lte: '' }, end: { $gte: '' }
  // //   }}, 
  // //   (_workflows) => {
  // //     console.log('loadWorkflows CB', { _workflows });
  // //     setWorkflows(_workflows);
  // //   }
  // // );
  
  // }, [design]);

  const now = new Date().getTime();

  // useEffect(() => {
  //   if (!workflows?.length) return;
  //   let parsed = workflows.map((w) => parseRecipe(w));
  //   parsed = parsed.filter((p) => Boolean(p));
  //   setParsedWorkflows(parsed);
  //   console.log({ parsed });
  // }, [workflows]);

  // forward interaction in our TopBar to the widget's controla - yuck
  useEffect(() => {
    if (!schedulerRef.current) return;
    console.log({ ref: schedulerRef.current});
      const container = schedulerRef.current.querySelector('#reactSchedulerOutsideWrapper > div > div > div:last-child > div:first-child > div:first-child');

      const pagination = container.childNodes[1];
      const [pagPrev, pagToday, pagNext] = pagination.querySelectorAll('button');

      const zoom = container.childNodes[2];
      const [zoomOut, zoomIn] = zoom.querySelectorAll('button');

      buttonsRef.current = {
        pagPrev, pagToday, pagNext, zoomIn, zoomOut,
      };
  }, [schedulerRef?.current, buttonsRef]);

  // const parseWorkflows = useCallback(() => {
  //   if (!workflows?.length) return [];
  //   const parsed = workflows.map((w) => parseRecipe(w));
  //   return parsed.filter((p) => Boolean(p));
  // }, [workflows]);

  // useEffect(() => {
  //   if (!workflows?.length) return;
  //   const _parsedWorkflows = parseWorkflows();
  //   console.log({ _parsedWorkflows });
  //   setParsedWorkflows(parsedWorkflows);
  // }, [workflows]);

  console.log({ viewMode });


  // const createTimelineItems = useCallback(() => {
  //   console.log('createTimelineItems', { parsedWorkflows })
  //   if (!parsedWorkflows) return [];
  //   let currentTime = new Date().getTime();
  //   const twoHoursAgo = new Date().setHours(-2);
  //   const ret = (parsedWorkflows).map((b) => {
  //     const workflowStart = b.start || twoHoursAgo;
  //     const timings = [workflowStart || currentTime];
  //     b.steps.forEach((s, i) => {
  //       if (i === 0 && b.start && s.duration) {
  //         currentTime = new Date(b.start).getTime() + s.duration;
  //       } else {
  //         currentTime += ( s.duration || defaultTaskDuration );
  //       }
  //       timings.push(currentTime);
  //     });

  //     console.log({ timings });

  //     return {
  //       id: b._id,
  //       label:  {
  //         // icon: null,
  //         title: b.name,
  //         subtitle: b.description,
  //       },
  //       data: viewMode > 0
  //       ? b.steps.map((s, i) => {
  //         const start = new Date(timings[i] + 1);
  //         const end = new Date(timings[i] + (
  //           s.duration || defaultTaskDuration
  //         ));
  //         const step = {
  //           id: s.id,
  //           title: s.label,
  //           subtitle: `${timeShort(start)} - ${timeShort(end)}`,
  //           // add one ms to prevent overlapping which creates a 2nd row
  //           startDate: start,
  //           endDate: end,
  //           bgColor: '#00A89D'
  //         };
  //         return step;
  //       })
  //       // when viewing more days, merge whole workflow to 1 step as too small
  //       // to visualize & label individually
  //       : [{
  //         id: b.id,
  //         title: b.recipe_name,
  //         // subtitle: `${timeShort(new Date(timings[0]))} - ${timeShort(new Date(timings[timings.length -1]))}`,
  //         startDate: timings[0],
  //         endDate: timings[timings.length -1],
  //         bgColor: '#00A89D'
  //       }]
  //     };
  //   });
  //   return ret;
  // }, [parsedWorkflows, viewMode]);

  // const parsedWorkflows = useMemo(() => {
  //   console.log('timelineItems', { workflows });
  //   return workflows;
  // }, [workflows]);

  useEffect(() => {
    if (!workflows?.length) return;

    let currentTime = new Date().getTime();

    const _timelineItems = workflows.map((w) => {

      const workflowStart = w.start || currentTime;
      const timings = [workflowStart || currentTime];
      (w.steps || [w]).forEach((s, i) => {
        if (i === 0 && w.start && s.duration) {
          currentTime = new Date(w.start).getTime() + s.duration;
        } else {
          currentTime += ( s.duration || defaultTaskDuration );
        }
        timings.push(currentTime);
      });

      const ret = {
        id: w._id,
        label: {
          title: w.name,
          subtitle: w.type,
        },
        data: (viewMode > 0 && w.steps)
        ? w.steps.map((s, i) => {
          const start = new Date(timings[i] + 1);
          const end = new Date(timings[i] + (
            s.duration || defaultTaskDuration
          ));
          const step = {
            id: s.id,
            title: s.label,
            subtitle: `${timeShort(start)} - ${timeShort(end)}`,
            // add one ms to prevent overlapping which creates a 2nd row
            startDate: start,
            endDate: end,
            bgColor: '#00A89D'
          };
          return step;
        })
        // when viewing more days, merge whole workflow to 1 step as too small
        // to visualize & label individually
        : [{
          id: w.id,
          title: w.name,
          subtitle: `${timeShort(new Date(timings[0]))} - ${timeShort(new Date(timings[timings.length -1]))}`,
          startDate: timings[0],
          endDate: timings[timings.length -1],
          bgColor: '#00A89D'
        }]
      };

      return ret;
    });

    console.log({ _timelineItems });
    setTimelineItems(_timelineItems);
  }, [workflows, viewMode]);


  const config = useMemo(() => ({
    zoom: viewMode,
    filterButtonState: -1,
    showTooltip: false,
    showThemeToggle: false,
    theme: 'light'
  }), [viewMode]);

  return (
    <Box sx={styles} className="production-scheduler-root">
      <Box className="container">
        <TopBar 
          {...{
            items: liquids, item: liquidId, setItem: setLiquidId, workflowId: designId, workflow: design,
            buttonsRef,
            workflows, setWorkflows,
            range, setRange, // handleRangeChange,
            viewMode, setViewMode,
          }}
        />
        <Box className="timeline-container" ref={schedulerRef}>
        <Scheduler
          data={timelineItems}
          isLoading={isLoading}
          onRangeChange={(r) => { console.log('range change', r); setRange(r) }}
          onTileClick={(clickedResource) => console.log(clickedResource)}
          onItemClick={(item) => console.log(item)}
          // onFilterData={() => {
          //   // Some filtering logic...
          //   setFilterButtonState(1);
          // }}
          // onClearFilterData={() => {
          //   // Some clearing filters logic...
          //   setFilterButtonState(0)
          // }}
          config={config}
        />
        </Box>
      </Box>
    </Box>
  );
}
