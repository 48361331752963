import { Stack } from '@mui/material';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import AssetSearch from '@pw/components_v2/search/asset';
import { ASSET_TYPES } from '@pw/consts/asset';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      machine: [initialValues?.machine, yup.object()],
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator} useDefault={false}>
      <Stack  sx={{ pt: '1rem' }} spacing={2}>
        <AssetSearch field="machine" label='Machine' types={[ASSET_TYPES.MACHINE]} />
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
