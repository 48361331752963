function SvgSalesOrder(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 17 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M2.475 22c-.158-.114-.33-.212-.47-.344-.58-.55-1.147-1.114-1.727-1.666A.844.844 0 010 19.34C.002 13.14.002 6.938.002.74c0-.486.266-.74.77-.74h15.457c.503 0 .77.254.77.739 0 6.2 0 12.4.002 18.6a.848.848 0 01-.278.652c-.574.547-1.136 1.105-1.712 1.65-.144.137-.323.24-.486.359h-.31c-.161-.12-.337-.224-.482-.36-.442-.415-.873-.842-1.317-1.273-.43.418-.841.826-1.264 1.223-.161.151-.35.274-.525.41h-.31c-.191-.14-.396-.267-.569-.427-.418-.388-.822-.792-1.24-1.198-.07.063-.125.108-.175.158-.391.378-.776.763-1.175 1.132-.14.13-.315.225-.475.336h-.31c-.17-.131-.352-.252-.51-.396-.293-.27-.577-.55-.86-.832-.135-.136-.26-.283-.394-.431-.478.463-.904.888-1.343 1.3-.145.135-.32.24-.482.359h-.31V22zM1.33 1.297V11.501c0 2.492 0 4.985.002 7.477 0 .082-.005.193.044.242.41.41.83.808 1.257 1.219l1.372-1.331c.414-.4.748-.401 1.16-.002l1.414 1.376c.054-.081.088-.157.144-.212.425-.418.851-.834 1.284-1.246.3-.286.684-.285.985 0 .328.31.652.624.97.942.16.157.304.328.506.549.085-.122.122-.196.178-.252.415-.407.831-.814 1.252-1.215.333-.318.702-.318 1.032-.001.42.402.839.807 1.252 1.216.06.06.089.147.14.235.447-.435.819-.805 1.2-1.163a.445.445 0 00.15-.357c-.004-5.815-.004-11.63-.004-17.444v-.237H1.332z'
        fill='currentColor'
      />
      <g clipPath='url(#SalesOrder_svg__clip0_7936_63637)'>
        <path
          d='M4.015 10.947c.029.11.074.225.138.353.582 1.15 1.414 2.13 2.227 3.017.185.202.412.392.674.563.294.191.548.155.8-.115.112-.12.202-.239.268-.355.722-1.264 1.601-2.425 2.44-3.507.835-1.077 1.667-2.12 2.648-3.029.191-.177.383-.389.603-.666.059-.074.096-.167.136-.265l.05-.119a1.177 1.177 0 00-.29-.621c-.204-.228-.378-.26-.623-.115a3.137 3.137 0 00-.638.48c-.159.162-.319.322-.479.481-.576.577-1.172 1.173-1.702 1.802-.59.7-1.15 1.445-1.69 2.165-.216.287-.439.584-.66.875-.064.082-.123.17-.181.254-.071.105-.145.213-.226.314-.111.137-.205.198-.304.197-.101 0-.197-.066-.31-.212l-.18-.235c-.384-.496-.78-1.009-1.175-1.51-.232-.293-.424-.432-.661-.432-.164 0-.35.066-.586.194l-.01.007c-.03.015-.057.03-.083.047-.172.11-.232.252-.186.432z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='SalesOrder_svg__clip0_7936_63637'>
          <path fill='#fff' transform='translate(4 6)' d='M0 0h10v9H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgSalesOrder;
